import EmployeeIcon from '@mui/icons-material/People';

import FederalTaxChatList from './FederalTaxChatList';

const resource = {
  list: FederalTaxChatList,
  icon: EmployeeIcon,
};

export default resource;
