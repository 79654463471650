import EmployeeIcon from '@mui/icons-material/People';

import EmployeeMasterCreate from './EmployeeMasterCreate';
import EmployeeMasterList from './EmployeeMasterList';
import EmployeeMasterEdit from './EmployeeMasterEdit';
import EmployeeMasterView from './EmployeeMasterView';

const resource = {
  list: EmployeeMasterList,
  create: EmployeeMasterCreate,
  edit: EmployeeMasterEdit,
  show: EmployeeMasterView,
  icon: EmployeeIcon,
};

export default resource;
