import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';

export const CustomDatePicker = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div style={{ width: '40%' }}>
        <Label>{props.label}</Label>
      </div>
      <div style={{ width: '60%' }}>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue ? new Date(props.defaultValue) : ''}
          render={({ field }) => (
            <div style={{ display: 'flex', gap: '0.5em' }}>
              <DatePicker
                placeholderText="Select date"
                className="datepickerStyle"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat="dd-MM-yyyy"
                readOnly={props.disabled}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '1em',
    'align-items': 'center',
    '& > *': {
      flex: '1 1 100%',
    },
    '& > label': {
      'margin-left': 'auto',
    },
    '& > div  >div': {
      width: '100%',
    },
  },
  item: {},
});

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.5em;
`;
