import * as React from 'react';
import { Datagrid, EditButton, ExportButton, TextInput, TopToolbar } from 'react-admin';
import BooleanField from '../../../components/BooleanField';
import BooleanInput from '../../../components/BooleanInput';
import DateField from '../../../components/DateField';
import EmailField from '../../../components/EmailField';
import List from '../../../components/List';
import SelectInput from '../../../components/SelectInput';
import TextField from '../../../components/TextField';
import { RegisteredAgentStatus, PaymentStatus } from './../../../config/constants';

const Filters = [
  <TextInput source="company" label="Company" alwaysOn />,
  <SelectInput
    source="state"
    label="State"
    choices={[
      { id: 'DELAWARE', name: 'Delaware' },
      { id: 'WYOMING', name: 'Wyoming' },
    ]}
    alwaysOn
  />,
  <SelectInput source="status" label="Status" choices={RegisteredAgentStatus} alwaysOn />,
  <SelectInput source="paymentStatus" label="Payment Status" choices={PaymentStatus} alwaysOn />,
];
const RegisteredAgentPaymentsListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const RegisteredAgentPaymentsList = () => {
  return (
    <List
      title={'"List of all registered agent Fee recieved"'}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<RegisteredAgentPaymentsListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sort={{ field: 'id', order: 'DESC' }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <EmailField source="email" label="Email" />
        <TextField source="company" label="Company" />
        <TextField source="state" label="State" />
        <TextField source="month" label="Due Month" />
        <DateField source="dateOfFormation" label="Date of Formation" />
        <DateField source="modifiedDate" label="Received On (UTC)" />
        <TextField source="paymentStatus" label="Payment Status" />
        <TextField source="status" label="Status" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default RegisteredAgentPaymentsList;
