import styled from '@emotion/styled';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReportParameters } from './ReportParameters';
import { useForm } from 'react-hook-form';

export interface ReportListProps {
  reports: any[];
  masterdata: any[];
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: '100%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export const ReportList = (props: ReportListProps) => {
  const reports = props.reports.filter((report) => report.id >= 10);
  const [value, setValue] = React.useState(0);
  const { handleSubmit, reset, control } = useForm();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Wrapper>
      <ReportListWrapper>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {reports &&
              reports?.length &&
              reports.map((row: any, index) => (
                <StyledTab label={row.displayName} {...a11yProps(index++)} />
              ))}
          </Tabs>
          {reports &&
            reports?.length &&
            reports.map((row: any, index) => (
              <TabPanel value={value} index={index++}>
                <ReportParameters
                  reportId={row.id}
                  title={row.displayName}
                  parameters={row.parameters}
                  masterdata={props.masterdata}
                  control={control}
                />
              </TabPanel>
            ))}
        </Box>
      </ReportListWrapper>
    </Wrapper>
  );
};

const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 53px;
  display: flex;
  align-items: center;
  color: #005eff;
`;

const Wrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
`;
const ReportListWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
`;

const StyledTab = styled(Tab)`
  font-family: Camphor;
  align-items: flex-start;
  text-align: left !important;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 4px 0px 0px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
