import * as React from 'react';
import { useState, useRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';

import { useNotify, useDataProvider } from 'react-admin';
import styled from '@emotion/styled';
import Select from 'react-select';

import { TextField, DateField, FunctionField, Labeled } from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import SubmitButton from '../forms/SubmitButton';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
import { Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import PhoneCallForwardingForm from '../forms/PhoneCallForwardingForm';
import PhoneRechargeForm from '../forms/PhoneRechargeForm';
import { CSVLink, CSVDownload } from 'react-csv';

const ServiceProviders = [
  { value: 'STATE_FILING_DELAWARE_INC', label: 'HBS', type: 'STATE_FILING_SERVICE' },
  { value: 'STATE_FILING_WYOMING_AGENTS', label: 'Wyoming Agents', type: 'STATE_FILING_SERVICE' },
  { value: 'STATE_FILING_STARTGLOBAL', label: 'Startglobal', type: 'STATE_FILING_SERVICE' },
  { value: 'REGISTERED_AGENT_DELAWAREINC', label: 'HBS', type: 'REGISTERED_AGENT_SERVICE' },
  {
    value: 'REGISTERED_AGENT_WYOMINGAGENTS',
    label: 'Wyoming Agents',
    type: 'REGISTERED_AGENT_SERVICE',
  },
  { value: 'EIN_STARTGLOBAL', label: 'Startglobal', type: 'EIN_SERVICE' },
  { value: 'EIN_STARTGLOBAL_FAX', label: 'FAX', type: 'EIN_SERVICE' },
  { value: 'EIN_USACORPS', label: 'USACorps', type: 'EIN_SERVICE' },
  { value: 'BANKING_MERCURY', label: 'Mercury', type: 'BANKING_SERVICE' },
  { value: 'BANKING_RELAYFI', label: 'Relayfi', type: 'BANKING_SERVICE' },
  { value: 'VIRTUAL_PHONE_NUMBER_SONTEL', label: 'Sonetel', type: 'VIRTUAL_PHONE_NUMBER' },
];
const WorkflowStatusArray = [
  { value: 'NOT_STARTED', label: 'NOT_STARTED' },
  { value: 'STARTED', label: 'STARTED' },
  { value: 'PROCESSED', label: 'PROCESSED' },
  { value: 'REJECTED', label: 'REJECTED' },
  { value: 'ERROR', label: 'ERROR' },
  { value: 'ONHOLD', label: 'ONHOLD' },
];
const CurrentStatusArray = [
  { value: 'NOT_STARTED', label: 'NOT_STARTED' },
  { value: 'STARTED', label: 'STARTED' },
  { value: 'WAITING_FOR_DELIVERY_FROM_PROVIDER', label: 'Waiting for Delivery' },
  { value: 'RECEIVED_DELIVERY_FROM_PROVIDER', label: 'Received' },
  { value: 'PREPARING_FOR_CLIENT_DELIVERY', label: 'Preparing Client Delivery' },
  { value: 'DELIVERED_TO_CLIENT', label: 'Delivered' },
];

export const SonetelServices = ({ data }) => {
  const [loading, setIsloading] = React.useState(true);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [value, setValue] = React.useState(0);
  const [workflows, setWorkflows] = React.useState([]);
  // const getWorkflowHeader = type =>  workflows.find(wf => wf.type === type);

  React.useEffect(() => {
    const url = `update/company/${data.id}/workflow`;
    dataProvider
      .get(url, { data })
      .then(({ data, error }) => {
        console.log('workfloe', data);
        if (error) {
          throw new Error(error);
        }
        setWorkflows(data);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={10} sm={10} md={10}>
          {loading ? (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          ) : (
            <SonetelDetail data={data} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const SonetelDetail = ({ data }) => {
  const [loading, setIsloading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(true);

  const [processingUsageDownload, setProcessingUsageDownload] = React.useState(false);
  const csvLinkRef = React.useRef(null);
  const [usageRecords, setUsageRecords] = React.useState([]);
  const [phoneNumberInfo, setPhoneNumberInfo] = React.useState({});

  const CSVheaders = [
    { label: 'Time', key: 'description' },
    { label: 'from', key: 'month01' },
    { label: 'to', key: 'month02' },
    { label: 'subAccount', key: 'month03' },

    { label: 'phnum', key: 'month04' },
    { label: 'Service', key: 'month05' },
    { label: 'Amount', key: 'month06' },
  ];

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const handleUsageDownloadAction = () => {
    setProcessingUsageDownload(true);
    const url = `phone-number-registration-details/company/${data.id}/usage`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log(data);
        if (error) {
          throw new Error(error);
        }
        setUsageRecords([]);
        csvLinkRef.current?.link?.click();
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessingUsageDownload(false));
  };

  React.useEffect(() => {
    setIsloading(true);
    const url = `phone-number-registration-details/company/${data.id}/info`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log(data);
        if (error) {
          throw new Error(error);
        }
        setPhoneNumberInfo(data);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [refresh, setRefresh]);

  if (loading) {
    return (
      <>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </>
    );
  }

  if (!phoneNumberInfo.isSubscribedForService) {
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid container item xs={6}>
            <Box>
              <Chip label="Not subscribed " color="error" variant="contained" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={1}>
        {phoneNumberInfo.status === 'SUBACCOUNT_NOT_CREATED' ? (
          <Grid container item xs={6}>
            <Box>
              <Chip label={phoneNumberInfo.statu} color="error" variant="contained" />
            </Box>
          </Grid>
        ) : (
          <Grid container item xs={6}>
            <Grid item xs={6}>
              <Labeled label="Account ID">
                <TextField record={phoneNumberInfo} source="accountId"></TextField>
              </Labeled>
            </Grid>

            <Grid item xs={6}>
              <Labeled label="Account Status">
                <TextField record={phoneNumberInfo} source="status"></TextField>
              </Labeled>
            </Grid>

            <Grid item xs={6}>
              <Labeled label="Balance (USD)">
                <TextField record={phoneNumberInfo} source="creditBalance"></TextField>
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="US No.">
                <TextField record={phoneNumberInfo} source="fullPhoneNumber"></TextField>
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="Connect To">
                <TextField record={phoneNumberInfo} source="connectTo"></TextField>
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="Call Rate (USD/minute)">
                <TextField record={phoneNumberInfo} source="usageFee"></TextField>
              </Labeled>
            </Grid>
          </Grid>
        )}

        <Grid container item xs={6}>
          <PhoneCallForwardingForm data={data} />
          <PhoneRechargeForm data={data} />
          <CancelNumber data={data} />
          <SonetelGenerateNewNumber data={data} disabled={!!phoneNumberInfo?.fullPhoneNumber} />

          <Grid container item sx={{ margin: '4px', padding: '2em' }} style={{ marginTop: '1em' }}>
            <Grid item xs={6} spacing={1}></Grid>
            <Grid item xs={3}>
              <LoadingButton
                loading={loading}
                variant="contained"
                loadingPosition="end"
                type="submit"
                fullWidth
                color="primary"
                onClick={() => setRefresh(!refresh)}
              >
                Refresh
              </LoadingButton>
              <hr />
              <LoadingButton
                loading={processingUsageDownload}
                variant="contained"
                loadingPosition="end"
                type="submit"
                fullWidth
                color="primary"
                // disabled={!subscriptionDTO?.phnum}
                disabled
                onClick={() => handleUsageDownloadAction()}
              >
                Download Usage
              </LoadingButton>
              <CSVLink
                data={usageRecords}
                filename={'Usage Records.csv'}
                style={{
                  display: 'none',
                  visibility: 'hidden',
                }}
                ref={csvLinkRef}
                headers={CSVheaders}
                target="_blank"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const SonetelGenerateNewNumber = ({ data, disabled }) => {
  const [processing, setProcessing] = React.useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleAction = () => {
    setProcessing(true);
    const companyId = data.id;
    const url = `update/company/${companyId}/virtual-phone-number/regenerate`;
    dataProvider
      .post(url, {})
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('New number updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Grid container item style={{ padding: '1em', alignItems: 'center' }}>
      <Grid item xs={6} spacing={1}>
        Generate new Number
      </Grid>
      <Grid item xs={3}>
        <LoadingButton
          style={{ paddingRight: '2rem' }}
          loading={processing}
          variant="contained"
          loadingPosition="end"
          type="submit"
          fullWidth
          color="warning"
          disabled={disabled}
          onClick={() => handleAction()}
        >
          Generate
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
const CancelNumber = ({ data, disabled }) => {
  const [processing, setProcessing] = React.useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleAction = () => {
    setProcessing(true);
    const companyId = data.id;
    const url = `update/company/${companyId}/virtual-phone-number/expire`;
    dataProvider
      .post(url, {})
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Number deleted successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Grid container item style={{ padding: '1em', alignItems: 'center' }}>
      <Grid item xs={6} spacing={1}>
        Cancel existing number
      </Grid>
      <Grid item xs={3}>
        <LoadingButton
          style={{ paddingRight: '2rem' }}
          loading={processing}
          variant="contained"
          loadingPosition="end"
          type="submit"
          fullWidth
          color="error"
          disabled={disabled}
          onClick={() => handleAction()}
        >
          Cancel
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
export const CustomSelect2 = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl required={props.required} sx={{ m: 1, minWidth: 0.9 }}>
      <Label>{props.label}</Label>
      <Controller
        render={({ field }) => (
          // <Select {...field} {...props}>
          //     {props.options.map( ({value, displayName}) =>  <MenuItem value={value}>{displayName}</MenuItem>)}
          // </Select>
          <Select
            {...field}
            {...props}
            options={props.option}
            defaultValue={props.option.filter((option) => option.value === props.defaultValue)}
          ></Select>
        )}
        name={props.name}
        control={props.control}
      />
      {/* Issue of using mui select  */}
      {/* <InputLabel id="demo-controlled-open-select-label">{props.label}</InputLabel> */}
      {/* <Controller
      render={({ field }) => (
          <Select {...field} {...props}
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            //value={props.defaultValue}
            //defaultValue={props.defaultValue}
            //value={value}
            label={props.label}
            //onChange={handleChange}
          >
            {props.option?.map(o => <MenuItem value={o.value}>{o.text}</MenuItem>)}
          </Select>
          )} 
      name={props.name}
      control={props.control}
      /> */}
    </FormControl>
  );
};

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.5em;
`;
