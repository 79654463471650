import List from '../../components/List';
import { AutocompleteInput, Datagrid, EditButton, Identifier, RaRecord } from 'react-admin';
import TextField from '../../components/TextField';
import ReferenceField from '../../components/ReferenceField';
import NumberField from '../../components/NumberField';
import DateField from '../../components/DateField';
import TextInput from '../../components/TextInput';
import NumberInput from '../../components/NumberInput';
import SelectInput from '../../components/SelectInput';
import SelectField from '../../components/SelectField';
import { PaymentStatus } from '../../config/constants';
const PaymentStatusArray = PaymentStatus.map((f) => ({
  id: f.id,
  name: f.name,
}));
const Filters = [<TextInput source="companyName" label="Company" />];

const MercuryApprovedAccountsViewList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={(id: Identifier, resource: string, record: RaRecord) => {
          return `/company-master-data/${record?.companyId}`;
        }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />

        <TextField source="companyName" label="Company Name" />
        <TextField source="email" label="Email" />
        <TextField source="accountStatus" label="Status" />

        <DateField source="applicationSubmittedDate" label="Submitted On" />

        <DateField source="approvedDate" label="Approved On" />
      </Datagrid>
    </List>
  );
};

export default MercuryApprovedAccountsViewList;
