import React, { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import PulseLoader from 'react-spinners/PulseLoader';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import dataProvider from '../../../config/dataProvider';
import { useNotify } from 'react-admin';

const override = css`
  display: block;
  margin: auto;
  position: absolute;
  left: 55%;
`;

const Wrapper = styled.div`
  border: 1px solid #d4ddeb;
  border-radius: 6px;
  padding: 32px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
`;
const StatusLabel = styled.div``;

enum STATUS {
  NONE = 'NONE',
  UPLOADING = 'UPLOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  DONE = 'DONE',
}
export type FileUploadProps = {
  onSuccess: (logo: any) => void;
  onError: () => void;
  companyId: number;
};
export const StateTaxUpload = (props: FileUploadProps) => {
  const [upload, setUpload] = useState(STATUS.NONE);
  const notify = useNotify();
  const maxSize = 102400000;
  const onDrop = async (acceptedFiles: File[]) => {
    setUpload(STATUS.UPLOADING);
    if (!acceptedFiles) {
      return;
    }
    dataProvider
      .upload('company-documents/upload', {
        data: {
          files: acceptedFiles[0],
          companyId: props.companyId,
          purpose: 'FRANCHISE_TAX_REPORT_CONFIRMATION_FROM_STATE',
        },
      })
      .then((data) => {
        setUpload(STATUS.SUCCESS);
        notify('File uploaded successfully', { type: 'success' });
        setUpload(STATUS.DONE);
      })
      .catch((error) => {
        notify('Oh no.. Error occured', { type: 'error' });
        props.onError();
      });
  };

  return (
    <>
      <Dropzone accept="application/pdf" minSize={0} maxSize={maxSize} onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections }) => {
          const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;

          return (
            <Wrapper {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive && 'Drag or click to upload tax document.'}
              {isDragReject && 'File type not accepted, sorry!'}
              {isFileTooLarge && <div className="text-danger mt-2">{'File too Large'}</div>}
              <CloudUploadIcon />
            </Wrapper>
          );
        }}
      </Dropzone>
      {upload === STATUS.UPLOADING && (
        <PulseLoader css={override} size={12} margin={2} color={'#123abc'} loading={true} />
      )}
    </>
  );
};
