import * as React from 'react';
import styled from '@emotion/styled';
import { ReferenceFieldProps } from 'react-admin';
import { ReferenceField as ReferenceFieldDefault } from 'react-admin';

export interface ReferenceFieldProp extends ReferenceFieldProps {}

const ReferenceField = (props: ReferenceFieldProp) => {
  return <ReferenceFieldDefault {...props}>{props.children}</ReferenceFieldDefault>;
};

export default ReferenceField;
