import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  RaRecord,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
} from 'react-admin';
import AutocompleteInput from '../../components/AutocompleteInput';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import ReferenceField from '../../components/ReferenceField';
import ReferenceInput from '../../components/ReferenceInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { ChatStatus, RecentYears } from '../../config/constants';
import { BookkeepingStatus } from '../../config/constants';
import { PaymentStatus } from '../../config/constants';

const ChatStatusArray = ChatStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="companyId" label="Company Ref." alwaysOn />,
  <TextInput source="companyName" label="Company " alwaysOn />,

  <SelectInput source="chatStatus" choices={ChatStatusArray} alwaysOn />,
  <SelectInput source="year" choices={RecentYears} alwaysOn />,
  // <ReferenceInput label="Company"  source="companyId" reference="company-master-data" alwaysOn >
  //      <AutocompleteInput optionText="companyFullName" />
  // </ReferenceInput>
];
const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};
const CompanyBookkeepingChatList = () => {
  return (
    <List
      filters={Filters}
      filterDefaultValues={{ chatStatus: 'OPEN' }}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <ReferenceField
          source="companyRef"
          label="Company"
          reference="company-master-data"
          link={(record: RaRecord, reference: string) => {
            return `/${reference}/${record.companyRef}?tab=bookkeeping,subTab=chats`;
          }}
        >
          <FunctionField
            label="Company"
            render={(record: { companyName: any; companyNameType: any }) =>
              `${record.companyName} ${record.companyNameType}`
            }
          />
        </ReferenceField>
        {/* <TextField source="state"  label="State"/> */}
        {/* <TextField source="responsibleParty"  label="Owner"/> */}
        <TextField source="status" label="Status" />
        {/* <SelectField source="chatStatus" label="Chat Status" choices={ChatStatusArray}/> */}
        {/* <TextField source="chatStatus"  label="Chat Status"/> */}
        {/* <TextField source="year"  label="Year"/> */}
        <TextField source="transactionCount" label="Total Transactions" />
        <SelectField source="paymentStatus" label="Payment" choices={PaymentStatus} />

        <FunctionField
          label="Message"
          render={(record: { message: any }) =>
            `${
              record.message.length > 50 ? record.message.substring(0, 50) + '...' : record.message
            }`
          }
        />
      </Datagrid>
    </List>
  );
};

export default CompanyBookkeepingChatList;
