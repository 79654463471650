import styled from '@emotion/styled';
import React from 'react';
import { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import BookkeeperChatHelp from './BookkeeperChatHelp';
import BookkeeperChatList from './BookkeeperChatList';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import { useMemo } from 'react';
import last from 'lodash.last';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import { hasPermission } from '../../lib/SGHelper';

const chatActors = ['TAX_EXPERT', 'BOOKKEEPER', 'SUPPORT', 'ACCOUNT_MANAGER'];

function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name = 'Anonymous') {
  let initials;

  if (name.includes(' ')) {
    initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  } else {
    initials = name[0][1];
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}
export const BookkeeperChats = () => {
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();

  const [error, setError] = useState(false);
  const [reload, setReload] = React.useState(false);

  const [currentMessage, setCurrentMessage] = React.useState('');
  const [chatId, setChatId] = React.useState();

  const [openChats, setOpenChats] = React.useState([]);
  const [chats, setChats] = React.useState([]);

  const [selectedActor, setSelectedActor] = React.useState('TAX_EXPERT');
  const [selectedCompany, setSelectedCompany] = React.useState();

  const filteredChats = openChats.filter((c) => c.owner === selectedActor);
  const uniqueCompanies = filteredChats.reduce((acc, current) => {
    const x = acc.find((item) => item.companyId === current.companyId);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  console.log({
    filteredChats,
    uniqueCompanies,
  });

  //load all open chats
  React.useEffect(() => {
    setIsLoading(true);
    dataProvider
      .get(`chat_master/open`)
      .then((data) => {
        console.log('open chats', data.data);
        setOpenChats(data.data);
      })
      .catch((error) => {
        console.log('error', error);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [reload]);

  const onCompanySelect = (chat) => {
    console.log(chat);
    setSelectedCompany(chat);
  };

  React.useEffect(() => {
    setIsLoading(true);
    if (!selectedCompany) {
      return;
    }
    dataProvider
      .get(`chat_master/${selectedCompany.companyId}/owner/${selectedActor}`)
      .then((data) => {
        console.log('open chats', data.data);
        setChats(data.data);
      })
      .catch((error) => {
        console.log('error', error);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [selectedCompany, selectedActor]);

  const addChat = (msg) => {
    setChats([
      ...chats,
      {
        message: msg,
        sendFrom: selectedActor,
        type: 'CHAT',
        copmanyId: selectedCompany.companyId,
        createdDate: new Date(),
      },
    ]);
  };
  const onChangeOfInputMessage = (e) => {
    setCurrentMessage(e.target.value);
  };

  const reLoadChats = () => setReload(!reload);

  const onKeyPressOfInputMessage = (e) => {
    if (e.key == 'Enter') {
      if (!currentMessage) {
        return;
      }
      saveChatMessage(currentMessage);
      addChat(currentMessage);
      setCurrentMessage('');
    }
  };

  const saveChatMessage = (msg) => {
    if (!msg) {
      return;
    }
    const data = {
      message: msg,
      companyId: Number(selectedCompany.companyId),
      owner: selectedActor,
      sendFrom: selectedActor,
    };

    dataProvider
      .post(`chat_master/reply/${selectedCompany.id}`, { data })
      .then((data, error) => {
        if (error) {
          throw new Error(error);
        }
        // setReload(!reload);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => {});
  };

  return (
    <Wrapper>
      <HeaderWrap>
        <Header>Chats</Header>
      </HeaderWrap>
      <BodyWrapper>
        <ChatWrapp>
          <>
            {' '}
            <Wrapper>
              <List
                sx={{ width: '100%', maxWidth: 250, bgcolor: 'background.paper', fontSize: '8px' }}
              >
                {chatActors.map((actor) => (
                  <MenuItem
                    onClick={() => setSelectedActor(actor)}
                    selected={selectedActor === actor}
                  >
                    <ListItem
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                      // onClick={() => {
                      //   setSelectedActor(actor);
                      // }}
                    >
                      {/* <ListItemAvatar>
                        <Avatar {...stringAvatar(`${actor}`)} />
                      </ListItemAvatar> */}
                      <ListItemText primary={actor} />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </MenuItem>
                ))}
              </List>
            </Wrapper>
          </>
          {uniqueCompanies.length ? (
            <>
              <BookkeeperChatList chats={uniqueCompanies} onClick={onCompanySelect} />
              <ChatWrapper>
                <div
                  style={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    height: '50vh',
                  }}
                >
                  <MessageList messages={chats} />
                </div>
                <div
                  style={{
                    padding: '16px 16px',
                    display: 'flex',
                    border: '1px solid #EEEEEE',
                    borderRadius: '4px',
                    width: '-webkit-fill-available',
                    display: 'flex',
                    gap: '1em',
                  }}
                >
                  <TextField
                    id="filled-basic"
                    label=""
                    variant="filled"
                    placeholder="Type here!"
                    fullWidth
                    value={currentMessage}
                    onChange={onChangeOfInputMessage}
                    onKeyPress={onKeyPressOfInputMessage}
                  />
                  <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={() => {
                      saveChatMessage(currentMessage);
                      if (currentMessage) {
                        addChat(currentMessage);
                        setCurrentMessage('');
                      }
                    }}
                  >
                    Send
                  </Button>
                </div>
              </ChatWrapper>
              {/* {selectedCompany?.companyId && (
                <BookkeeperChatHelp
                  companyId={selectedCompany?.companyId}
                  chatId={chatId}
                  onSuccess={reLoadChats}
                />
              )} */}
            </>
          ) : (
            <NoDataFound>Sorry no open chats found!!!</NoDataFound>
          )}
        </ChatWrapp>
      </BodyWrapper>
    </Wrapper>
  );
};

export default BookkeeperChats;

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  over-flow: auto;
  width: 50%;
  background: white;
  height: 70vh;
  padding: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
`;

const NoDataFound = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444c66;
  text-align: center;
`;

const ChatWrapp = styled.div`
  display: flex;
  // width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  height: 74vh;
  background: #84b1ff2b;
  border-radius: 6px;
  padding: 12px 16px;
`;

const MessageList = (props) => {
  const senderGroupedMessages = useMemo(
    () =>
      props.messages.reduce((arr, m) => {
        const sender = m.sendFrom;
        if (!last(arr)) {
          arr.push({
            messages: [m],
            sendFrom: sender,
          });
        } else if (last(arr)?.sendFrom === sender) {
          last(arr)?.messages.push(m);
        } else {
          arr.push({
            messages: [m],
            sendFrom: sender,
          });
        }
        return arr;
      }, []),
    [props.messages]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      {senderGroupedMessages.map((mg) => (
        <MessageGroup key={mg.messages[0].id} {...mg} />
      ))}
    </div>
  );
};

const MessageGroup = (props) => {
  return (
    <MessageGroupWrapper>
      {props.sendFrom !== 'CLIENT' ? (
        <div className="pt-2 pr-2">
          <AccountCircleIcon />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.8em',
          alignItems: props.sendFrom !== 'CLIENT' ? 'flex-end' : 'flex-start',
          width: '100%',
        }}
      >
        {props.messages.map((m, i) => (
          <Message key={m.id} {...m} last={i === props.messages.length - 1} />
        ))}
      </div>
    </MessageGroupWrapper>
  );
};

const MessageGroupWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: flex-start;
`;

const formatDate = (date) => {
  const lastMessageDate = new Date(date);
  const defaultConfig = {
    month: 'short',
    day: 'numeric',
  };

  return lastMessageDate.toLocaleDateString('en-US', {
    ...defaultConfig,
  });
};
const Message = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: props.last && props.sendFrom !== 'CLIENT' ? 'flex-end' : 'unset',
    }}
  >
    <div
      style={{
        maxWidth: '30rem',
        width: 'fit-content',
        border: '1px solid #EEEEEE',
        padding: '10px 16px',
        background: props.sendFrom !== 'CLIENT' ? '#d4eeda' : '#ffdada',
        borderRadius: '4px',
      }}
    >
      {props.type === 'CHAT' ? <span>{props.message}</span> : null}
    </div>
    {props.last ? (
      <div
        style={{
          textAlign: props.sendFrom !== 'CLIENT' ? 'end' : 'start',
          marginTop: '4px',
          fontSize: '10px',
          color: '#929398',
        }}
      >
        {formatDate(props.createdDate)}
      </div>
    ) : null}
  </div>
);
