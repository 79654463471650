import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  email,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { ListHeaderActionsEdit } from '../../components/ListHeaderActions';
import { Row } from '../../components/Row';
import { WorkflowStatus } from './../../config/constants';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const MailReceivedLogEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />}>
      <SimpleForm defaultValues={{}}>
        <Row>
          <div style={{ width: '100%' }}>
            <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
              <Box mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="id" label="Mail Ref." disabled />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="companyName" label="Company" disabled />
              </Box>
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="type" label="Type" disabled />
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source="status"
                label="Status"
                choices={WorkflowStatusArray}
                isRequired
              />
            </Box>
          </div>
        </Row>

        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="remarks" label="Remarks" multiline resettable fullWidth />
            </Box>
          </div>
        </Row>

        <Separator />
      </SimpleForm>
    </Edit>
  );
};

export default MailReceivedLogEdit;
