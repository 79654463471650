import * as React from 'react';
import { Datagrid, EditButton } from 'react-admin';
import BooleanField from '../../components/BooleanField';
import BooleanInput from '../../components/BooleanInput';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';

const Filters = [
  <BooleanInput source="valid" label="Valid" alwaysOn />,
  <TextInput source="phone" label="Phone Number" alwaysOn />,
];

const PhoneNumberRegistrationDetailList = () => {
  return (
    <List
      filters={Filters}
      filterDefaultValues={{ valid: 'false' }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="phone" label="Phone number" />
        <TextField source="carrier" label="Carrier" />
        <TextField source="countryName" label="Country" />
        <TextField source="countryCode" label="Country code" />
        <TextField source="countryPrefix" label="Country Prefix" />
        <TextField source="internationalFormat" label="International Format" />
        <TextField source="lineType" label="Line Type" />
        <TextField source="localFormat" label="Local Format" />
        <BooleanField source="valid" label="Valid" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default PhoneNumberRegistrationDetailList;
