import * as React from 'react';
import { CloneButton, Datagrid, EditButton, ShowButton } from 'react-admin';
import AutocompleteInput from '../../components/AutocompleteInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import NumberField from '../../components/NumberField';
import ReferenceField from '../../components/ReferenceField';
import ReferenceInput from '../../components/ReferenceInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';

const Filters = [
  <TextInput source="email" label="Email" alwaysOn />,
  // <ReferenceInput label="Franchise" source="franchiseId" reference="franchise-master" alwaysOn >
  //     <AutocompleteInput optionText="team" alwaysOn/>
  // </ReferenceInput >
];

const FranchiseMasterList = () => {
  return (
    <List title="List of all Franchises" filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="team" label="Team" />
        <TextField source="status" label="Status" />
        <TextField source="language" label="Language" />
        <TextField source="paymentType" label="Payment Type" />
        <NumberField source="amount" label="Amount" />
        <EditButton />
        <ShowButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
};

export default FranchiseMasterList;
