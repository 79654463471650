import List from '../../components/List';
import { AutocompleteInput, Datagrid, EditButton, Identifier, RaRecord } from 'react-admin';
import TextField from '../../components/TextField';
import NumberField from '../../components/NumberField';
import DateField from '../../components/DateField';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';

const Filters = [
  <TextInput source="companyName" label="Company" alwaysOn />,
  <SelectInput
    source="accountStatus"
    choices={[
      { id: 'initiated', name: 'Initiated' },
      { id: 'approved', name: 'Approved' },
      { id: 'rejected', name: 'Rejected' },
      { id: 'application_submitted', name: 'Submitted' },
    ]}
    alwaysOn
  />,
];

const MercuryAccountsViewList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={(id: Identifier, resource: string, record: RaRecord) => {
          return `/company-master-data/${record?.companyId}`;
        }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />

        <TextField source="companyName" label="Company Name" />
        <TextField source="accountNumber" label="AccountNumber" />
        <TextField source="email" label="Email" />
        <NumberField source="amount" label="Amount" />
        <TextField source="accountStatus" label="Status" />

        <TextField source="signupLink" label="Link" />
        <DateField source="applicationSubmittedDate" label="Submitted On" />

        <DateField source="approvedDate" label="Approved On" />
        <DateField source="rejectedDate" label="Rejected On" />
        <TextField source="reason" label="Reason" />
      </Datagrid>
    </List>
  );
};

export default MercuryAccountsViewList;
