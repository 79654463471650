import { useState, useCallback } from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { palettes, monthNames } from '../../../../config/constants';

const COLORS = palettes[0];

const RADIAN = Math.PI / 180;
export interface RenderCustomizedLabelProps {
  cx?: any;
  cy?: any;
  midAngle?: any;
  innerRadius?: any;
  outerRadius?: any;
  percent?: any;
  index?: any;
  data: any[];
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const KPIStatewiseCompaniesChart = (props: { kpis: any[] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_: any, index: React.SetStateAction<number>) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  if (!props.kpis || props.kpis.length == 0) {
    return null;
  }
  const numberOfCompanies = props.kpis.filter(
    (kpi: any) => kpi?.parameter === 'NUMBER_OF_COMPANIES'
  );
  const data = numberOfCompanies.map((company) => {
    return {
      name: company.state,
      value: company.ytd,
    };
  });

  return (
    <PieChart width={400} height={200}>
      <Pie
        data={data}
        cx={200}
        cy={100}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={50}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend layout="vertical" align="left" verticalAlign="middle" />
    </PieChart>
  );
};
