import styled from '@emotion/styled';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Cell } from 'recharts';
import { monthShortNames, palettes } from '../../../../config/constants';
import { amountFormat } from '../../../../lib/formatter';
import { getKPIMonths } from '../KpiInstanceDetail';

const GraphTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444c66;
`;

const GraphItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-around;
  gap: 1em;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const KPICompanyFormationGraphWrapper = (props: { kpis: any[] }) => {
  if (!props.kpis || props.kpis.length == 0) {
    return null;
  }

  const getMonthwiseTotalOfAllStates = (kpis: any[], parameter: string, monthString: string) => {
    if (!kpis || kpis.length <= 0) {
      return 0;
    }
    return kpis
      .filter((kpi: any) => kpi?.parameter === parameter)
      .reduce((total: number, parameter: any) => total + parameter[monthString], 0);
  };

  const data = Array.from(Array(getKPIMonths(props.kpis)).keys()).map((month) => {
    //month is zero based
    const monthIndex = month + 1;
    const monthString = 'month' + (monthIndex >= 10 ? monthIndex : '0' + monthIndex);
    return {
      name: monthShortNames[month],
      'Formation Revenue': getMonthwiseTotalOfAllStates(
        props.kpis,
        'TOTAL_STATE_FILING_FORMATION_REVENUE',
        monthString
      ),
      'Formation Profit': getMonthwiseTotalOfAllStates(
        props.kpis,
        'TOTAL_STATE_FILING_FORMATION_PROFIT',
        monthString
      ),

      Ads: getMonthwiseTotalOfAllStates(props.kpis, 'ADS_EXPENSES', monthString),
      Ops: getMonthwiseTotalOfAllStates(props.kpis, 'OPERATIONS_EXPENSES', monthString),
      'Formation Profit (after Ads, Ops)': getMonthwiseTotalOfAllStates(
        props.kpis,
        'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS',
        monthString
      ),
    };
  });
  const COLORS = palettes[0];

  return (
    <GraphItem>
      <GraphTitle>Monthly Company Formation breakup</GraphTitle>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 2,
          right: 2,
          left: 2,
          bottom: 5,
        }}
        barGap={0}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value) =>
            new Intl.NumberFormat('en', { notation: 'compact', compactDisplay: 'short' }).format(
              value
            )
          }
        />
        <Tooltip formatter={amountFormat} />
        <Legend formatter={amountFormat} />
        <Bar dataKey="Formation Revenue" fill={COLORS[0]}></Bar>
        <Bar dataKey="Formation Profit" fill={COLORS[1]}></Bar>
        <Bar dataKey="Ads" fill={COLORS[2]}></Bar>
        <Bar dataKey="Ops" fill={COLORS[3]}></Bar>
        <Bar dataKey="Formation Profit (after Ads, Ops)">
          {data.map((d, index) => (
            <Cell
              key={`cell-${index}`}
              fill={d['Formation Profit (after Ads, Ops)'] < 0 ? '#F00' : '#0F0'}
            />
          ))}
        </Bar>
      </BarChart>
    </GraphItem>
  );
};
