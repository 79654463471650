import * as React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  Identifier,
  RaRecord,
  ShowButton,
  usePermissions,
} from 'react-admin';
import DateField from '../../../components/DateField';
import EmailField from '../../../components/EmailField';
import List from '../../../components/List';
import ReferenceField from '../../../components/ReferenceField';
import ReferenceInput from '../../../components/ReferenceInput';
import SelectInput from '../../../components/SelectInput';
import TextField from '../../../components/TextField';
import TextInput from '../../../components/TextInput';
import { CompanyIncorporationStatus, US_STATES, PaymentStatus } from '../../../config/constants';
import { hasPermission } from '../../../lib/SGHelper';

const Filters = [
  <ReferenceInput label="Franchise" source="franchiseId" reference="franchise-master" alwaysOn>
    <AutocompleteInput optionText="team" alwaysOn />
  </ReferenceInput>,
  <TextInput source="email" alwaysOn />,
  <TextInput source="companyFullName" label="Company" alwaysOn />,

  <SelectInput
    source="state"
    choices={US_STATES.map((s: { id: any; label: any }) => {
      return {
        id: s.id,
        name: s.label,
      };
    })}
    alwaysOn
  />,

  <TextInput source="fullName" label="Res. Party" alwaysOn />,

  /*<TextInput source="state" alwaysOn/>,*/
  /*<SelectInput source="paymentStatus" choices={[
                { id: 'PAID', name: 'Paid' },
                { id: 'NOT_PAID', name: 'Not Paid' },
             ]} alwaysOn/>,*/
  /*<TextInput source="country" alwaysOn/>*/
];

const PiiFilters = [
  <ReferenceInput label="Franchise" source="franchiseId" reference="franchise-master" alwaysOn>
    <AutocompleteInput optionText="team" alwaysOn />
  </ReferenceInput>,
  <TextInput source="companyFullName" label="Company" alwaysOn />,
  <SelectInput
    source="state"
    choices={US_STATES.map((s: { id: any; label: any }) => {
      return {
        id: s.id,
        name: s.label,
      };
    })}
    alwaysOn
  />,
  <TextInput source="fullName" label="Res. Party" alwaysOn />,

  /*<TextInput source="state" alwaysOn/>,*/
  /*<SelectInput source="paymentStatus" choices={[
                { id: 'PAID', name: 'Paid' },
                { id: 'NOT_PAID', name: 'Not Paid' },
             ]} alwaysOn/>,*/
  /*<TextInput source="country" alwaysOn/>*/
];

const ExistingCompanyApprovalList = () => {
  const { permissions } = usePermissions();
  const showPii = hasPermission(
    [
      'ROLE_SUPER_ADMIN',
      'ROLE_SALES',
      'ROLE_GROWTH_HACKER',
      'ROLE_EIN_SERVICE_STARTGLOBAL',
      'ROLE_SDR',
    ],
    permissions
  );

  return (
    <List
      filters={showPii ? Filters : PiiFilters}
      title="List of all companies"
      filterDefaultValues={{ incorporationStatus: 'PENDING_FOR_DOCUMENT_VERIFICATION' }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={(id: Identifier, resource: string, record: RaRecord) => {
          return `/company-master-data/${record?.id}`;
        }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField label="Company" source="companyFullName" />
        {showPii && <EmailField source="email" label="Email" />}
        <TextField label="Res. Party" source="fullName" />
        <TextField source="paymentStatus" label="Payment Status" />
        <TextField source="state" label="State" />
        <TextField source="incorporationStatus" label="Incorporation Status" />
        <ReferenceField source="franchiseId" reference="franchise-master">
          <TextField source="team" label="Team" />
        </ReferenceField>
        <DateField source="createdDate" label="Created Date" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ExistingCompanyApprovalList;
