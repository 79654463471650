import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useGetList, Datagrid, useList, List, useNotify, useDataProvider } from 'react-admin';
import SelectInput from '../../../components/SelectInput';
import TextInput from '../../../components/TextInput';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

/**
 * This component will only show the startup payments
 */
const CompanyStartupPayments = ({ companyId }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={1}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <StyledTab label={'One time payment'} {...a11yProps(0)} />
          </Tabs>
        </Grid>
        <Grid item xs={10} sm={10} md={11}>
          <TabPanel value={value} index={0}>
            <Payments companyId={companyId} />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyStartupPayments;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const startupPaymentProducts = [
  'EXISTING_COMPANY_ONBOARDING_PAYMENT',
  'COMPANY_INCORPORATION',
  'SPLIT_PRICING_COMPANY_ONBOARDING_BUNDLED',
  'VIRTUAL_PHONE_NUMBER',
  'MAILING_ADDRESS',
  'COMPANY_ONBOARDING_BUNDLED',
  'OPERATING_AGREEMENT_GENERATION',
];
const Payments = ({ companyId }) => {
  const [reload, setReload] = React.useState(true);
  const [loading, setIsloading] = React.useState(false);
  const [payments, setPayments] = React.useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    setIsloading(true);
    const url = `payment-ledger/company/${companyId}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('payments', data);
        const startupPaymentData = data.filter((d) => {
          const product = d.type || d.product;
          return startupPaymentProducts?.indexOf(product) >= 0;
        });
        setPayments(startupPaymentData);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);
  if (loading) {
    return null;
  }
  const total = payments?.reduce((partialSum, a) => partialSum + a.amount, 0);

  return payments && Array.isArray(payments) && payments.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Purpose</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Reference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((row, index) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{payments.length - index}</TableCell>
              <TableCell component="th" scope="row">
                {row.type || row.product}
              </TableCell>
              <TableCell align="right">${parseFloat(row.amount / 100).toFixed(2)}</TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{row.createdDate}</TableCell>
              <TableCell align="right">{row.paymentIntentId}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2} align="right">
              <h2>
                <b>Total</b>
              </h2>
            </TableCell>
            <TableCell align="right">
              <h2>
                <b>${parseFloat(total / 100).toFixed(2)}</b>
              </h2>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div style={{ alignSelf: 'center' }}>No Stripe payments found !!!</div>
  );
};

const StyledTab = styled(Tab)`
  // min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
