import { useShowController } from 'react-admin';
import { SubscriptionHome } from './SubscriptionHome';

export const SubscriptionData = (props) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  console.log(record);
  if (!record) {
    return null;
  }
  return <SubscriptionHome data={record} />;
};
