import { Box, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Labeled, TextField } from 'react-admin';

export interface SubscriptionDetailsProps {
  data: any;
}
const url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3002/#/company-master-data/'
    : 'https://admin4.startglobal.co/#/company-master-data/';

export const SubscriptionDetails = (props: SubscriptionDetailsProps) => {
  const getCompanyMasterData = (companyId: any) => {
    window.open(url + companyId + '/show', '_blank');
  };
  return (
    <Box>
      {props.data ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Company">
                  <TextField record={props.data} source="companyFullName"></TextField>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Company Ref.">
                  <TextField
                    className="companyRef"
                    record={props.data}
                    source="companyRef"
                    onClick={() => {
                      getCompanyMasterData(props.data.companyRef);
                    }}
                  ></TextField>
                </Labeled>
              </Grid>
            </Grid>
            <Spacer />
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Client">
                  <TextField record={props.data} source="fullName"></TextField>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Email">
                  <TextField record={props.data} source="email"></TextField>
                </Labeled>
              </Grid>
            </Grid>
            <Spacer />
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Subscription Plan">
                  <TextField record={props.data} source="plan"></TextField>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Subscription Status">
                  <Chip
                    label={props.data?.subscriptionStatus}
                    color={getVariantBasedOnSubscriptionStatus(props.data?.subscriptionStatus)}
                    variant="filled"
                  />
                </Labeled>
              </Grid>
            </Grid>
            <Spacer />
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="State">
                  <TextField record={props.data} source="state"></TextField>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Country">
                  <TextField record={props.data} source="country"></TextField>
                </Labeled>
              </Grid>
            </Grid>
            <Spacer />
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Tel">
                  <TextField record={props.data} source="tel"></TextField>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Website">
                  <TextField record={props.data} source="website"></TextField>
                </Labeled>
              </Grid>
            </Grid>
            <Spacer />
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Franchise">
                  <TextField record={props.data} source="franchise"></TextField>
                </Labeled>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div>No Subscription data found !!!</div>
      )}
    </Box>
  );
};
const Spacer = () => <Box m={1}>&nbsp;</Box>;

const getVariantBasedOnSubscriptionStatus = (d: any) => {
  switch (d + '') {
    case 'ACTIVE':
      return 'success';
    case 'CANCELED':
      return 'error';
    case 'PAST_DUE':
      return 'error';
    case 'INCOMPLETE_EXPIRED':
      return 'error';
    default:
      return 'info';
  }
};
