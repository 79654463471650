import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { WorkflowStatus } from './../../config/constants';

const Filters = [<TextInput source="email" label="Email" alwaysOn />];

const EmailBlackList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="email" label="Email" />

        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default EmailBlackList;
