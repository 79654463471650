import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  AutocompleteInput,
  Show,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout,
} from 'react-admin';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../../components/SectionTitle';
import Separator from '../../../components/Separator';
import TextInput from '../../../components/TextInput';
import DateInput from '../../../components/DateInput';
import {
  ListHeaderActionsEdit,
  ListHeaderActionsView,
} from '../../../components/ListHeaderActions';
import { Employee } from '../../../types';
import NumberInput from '../../../components/NumberInput';
import { Currency } from '../../../config/constants';
import ReferenceInput from '../../../components/ReferenceInput';
import SelectInput from '../../../components/SelectInput';
import { transformDate } from '../../../lib/SGHelper';
import BooleanInput from '../../../components/BooleanInput';
import { Grid } from '@mui/material';
import LabelField from '../../../components/LabelField';
import { LabelType } from '../../../config/LabelType';
import BooleanField from '../../../components/BooleanField';
const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));
const transform = (data: { dateOfFormation: any }) => {
  const modifiedData = {
    ...data,
    dateOfFormation: transformDate(data.dateOfFormation, 'dd MMMM, yyyy'),
  };
  return modifiedData;
};
const CompanyReinstatementView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderActionsView />}>
      <TabbedShowLayout>
        <Tab label="Reinstatement Details">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SimpleShowLayout>
                <Wrapper>
                  <LabelField source="id" label="Ref." fullWidth labelType={LabelType.TEXT_FIELD} />
                  <LabelField
                    source="email"
                    label="Email"
                    fullWidth
                    labelType={LabelType.EMAIL_FIELD}
                  />
                  <LabelField
                    source="company"
                    label="Company"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="state"
                    label="State"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="paymentStatus"
                    label="Payment Status"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="stateFileNo"
                    label="State File No"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />

                  <LabelField
                    source="status"
                    label="Status"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="workflowStatus"
                    label="Workflow Status"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                </Wrapper>
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;
const EmployeeWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Box = styled.div`
  display: flex;
  gap: 1em;
`;
export default CompanyReinstatementView;
