import styled from '@emotion/styled';
import { Show, ShowProps } from 'react-admin';

import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import { ListHeaderList } from '../../components/ListHeaderActions';
import { SubscriptionData } from './SubscriptionData';

const SubscriptionMasterDataView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} component="div" actions={<ListHeaderList />}>
      <SubscriptionData {...props} />
    </Show>
  );
};

const EmployeeDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default SubscriptionMasterDataView;
