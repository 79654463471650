// in src/posts.js
import * as React from 'react';

import {
  List,
  TextField,
  ReferenceField,
  EditButton,
  SimpleForm,
  CloneButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  NumberField,
  Filter,
  BooleanInput,
  BooleanField,
  TextInput,
  required,
  Datagrid,
} from 'react-admin';

const DataFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Company" source="company" alwaysOn />
  </Filter>
);

export const DataList = (props) => (
  <List
    title="List of all Emails which are blacklisted"
    filters={<DataFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="Company Ref." />
      <TextField source="company" />
      <TextField source="email" />
      <TextField source="currency" />
      <NumberField source="availableBalance" />
      <NumberField source="currentBalance" />
      <ShowButton />
    </Datagrid>
  </List>
);
