// in src/posts.js
import * as React from 'react';
import styled from '@emotion/styled';

import { useNotify, useDataProvider } from 'react-admin';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';
import SubmitButton from '../forms/SubmitButton';
import LoadingButton from '@mui/lab/LoadingButton';

import { Box, Typography } from '@mui/material';

const ToggleListItem = (props) => {
  const [processing, setProcessing] = React.useState(props.exists);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleAction = (e) => {
    const type = props.type.toLowerCase(); //can be product or feature
    const action = props.action;
    const companyId = props.companyId;

    const url = `update/company/${companyId}/${type}`;
    const data = {
      companyId,
      enabled: e.target.checked,
    };
    data[type] = action;
    setProcessing(true);
    dataProvider
      .post(url, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };

  return (
    <ListItem
      secondaryAction={
        <>
          <Switch
            defaultChecked={props.checked}
            color={props.color ? props.color : 'primary'}
            onClick={handleAction}
          />{' '}
        </>
      }
    >
      <ListItemText primary={props.help} />
    </ListItem>
  );
};

const ActionListItem = (props) => {
  const [processing, setProcessing] = React.useState(props.exists);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleAction = () => {
    setProcessing(true);
    const type = props.type.toLowerCase(); //can be action
    const action = props.action;
    const companyId = props.companyId;

    const url = `update/company/${companyId}/${type}`;
    const data = {
      companyId,
      actionType: action,
    };
    dataProvider
      .post(url, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Action processed successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Grid container item sx={{ margin: '4px' }}>
      <Grid item xs={8} spacing={4} sx={{ paddingTop: 1 }}>
        <Label>{props.help}</Label>
      </Grid>
      <Grid item xs={4}>
        <LoadingButton
          style={{ paddingRight: '2rem' }}
          loading={processing}
          variant="contained"
          loadingPosition="end"
          type="submit"
          fullWidth
          color={props.color ? props.color : 'primary'}
          onClick={() => handleAction(props.companyId, props.type)}
        >
          {props.label}
        </LoadingButton>

        {/*<Button onClick={() => handleAction(props.companyId, props.type)} variant="contained" color={props.color ? props.color : "primary"} fullWidth >{props.label}</Button>*/}
      </Grid>
    </Grid>
  );
};
const ActionAdminEmails = [
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_ADMIN_INITIATE_STATE_FILING',
    label: 'Initiate',
    help: 'Initiate State Filing',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_ADMIN_INITIATE_EIN',
    label: 'Initiate',
    help: 'Initiate EIN ',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_ADMIN_MERCURY_ACCOUNT_APPROVED',
    label: 'Send',
    help: 'Mercury Account Approved (to admin)',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_ADMIN_MERCURY_APPEAL_DATA',
    label: 'Send',
    help: 'Send appeal data (to admin)',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_ADMIN_RELAYFI_ACCOUNT_APPROVED',
    label: 'Send',
    help: 'Relay Account Approved (to admin)',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_ADMIN_RELAYFI_SIGNUP',
    label: 'Send',
    help: 'Signup Data for Relay',
  },
];
const ActionUserEmails = [
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_ACCOUNT_ACTIVATE',
    label: 'Send',
    help: 'Send activation Email',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_PASSWORD_RESET',
    label: 'Send',
    help: 'Send Password Reset Email',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_STATE_FORMATION_DATA',
    label: 'Send',
    help: 'Send State Formation Details',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REGISTERED_AGENT_DATA',
    label: 'Send',
    help: 'Send Registered Agent Details',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_EIN_DATA',
    label: 'Send',
    help: 'Send EIN Details',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_PHONE_DATA',
    label: 'Send',
    help: 'Send Virtual Phone Details',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_FEEDBACK_REQUEST',
    label: 'Send',
    help: 'Send feedback Request',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_PAYPAL_INVOICE',
    label: 'Generate',
    help: 'Generate Paypal Invoice ',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_SMS_NOTIFICATION',
    label: 'Send',
    help: 'Send sms notification',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_MAIL_NOTIFICATION',
    label: 'Send',
    help: 'Send mail notification',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_MERCURY_ACCOUNT_INITIATED',
    label: 'Send',
    help: 'Send Mercury Initiated',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_MERCURY_APPEAL_DATA',
    label: 'Send',
    help: 'Send Mercury Appeal Link',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_RELAYFI_ACCOUNT_INITIATED',
    label: 'Send',
    help: 'Send Relayfi Initiated',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_PROCESS_STARTED',
    label: 'Send',
    help: 'Send process Started',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_PAYMENT_FAILED',
    label: 'Send',
    help: 'Send payment failed',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_POST_INCORPORATION_LEGAL_NOTICE_FOR_CORPORATION',
    label: 'Send',
    help: 'Send legal help needed for CORP',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_OPERATING_AGREEMENT',
    label: 'Send',
    help: 'Send Operating Agreement',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_BYLAWS',
    label: 'Send',
    help: 'Send Bylaws',
  },
];
const ActionReminders = [
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_DATA_VERIFICATION',
    label: 'Send',
    help: 'Data Verification Reminder',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_SS4_SIGNATURE',
    label: 'Send',
    help: 'SS4 Reminder',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_PASSPORT_UPLOAD',
    label: 'Send',
    help: 'Passport Reminder',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_COMPANY_NAME_CORRECTION',
    label: 'Send',
    help: 'Company Name Correction Reminder',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_FRANCHISE_TAX',
    label: 'Send',
    help: 'Franchise Tax Reminder',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_SIGNUP_1',
    label: 'Send',
    help: 'Signup Reminder 1 ',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_SIGNUP_2',
    label: 'Send',
    help: 'Signup Reminder 2',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_PAYMENT_1',
    label: 'Send',
    help: 'Payment Reminder 1',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_PAYMENT_2',
    label: 'Send',
    help: 'Payment Reminder 2',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_PAYMENT_3',
    label: 'Send',
    help: 'Payment Reminder 3',
  },
  {
    type: 'ACTION',
    action: 'SEND_EMAIL_REMINDER_PAYMENT_4',
    label: 'Send',
    help: 'Payment Reminder 4',
  },
];
const Actions = [
  {
    type: 'ACTION',
    action: 'ACTION_INITIATE_COMPANY_INCORPORATION_WORKFLOW',
    label: 'Initiate',
    help: 'Initiate auto workflows',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_ADDITIONAL_INFO',
    label: 'Generate',
    help: 'Generate Proxy Details',
  },
  {
    type: 'ACTION',
    action: 'ACTION_UPDATE_CRM',
    label: 'Update',
    help: 'Update CRM',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_SS4_TO_SIGN',
    label: 'Generate',
    help: 'Generate SS4',
  },
  {
    type: 'ACTION',
    action: 'ACTION_INITIATE_MERCURY',
    label: 'Initiate',
    help: 'Initiate Mercury',
  },
  {
    type: 'ACTION',
    action: 'ACTION_INITIATE_MERCURY_APPEAL',
    label: 'Initiate',
    help: 'Initiate Mercury Appeal',
  },
  {
    type: 'ACTION',
    action: 'DELETE_MERCURY_DATA',
    label: 'Delete',
    help: 'Delete Mercury',
  },
  {
    type: 'ACTION',
    action: 'ACTION_INITIATE_RELAYFI',
    label: 'Initiate',
    help: 'Initiate Relayfi',
  },
  {
    type: 'ACTION',
    action: 'DELETE_RELAYFI_DATA',
    label: 'Delete',
    help: 'Delete Relayfi',
  },
  {
    type: 'ACTION',
    action: 'ACTION_CHECK_AVAILABILITY_OF_COMPANY_NAME',
    label: 'Check',
    help: 'Check Company Name',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_PAYPAL_INVOICE',
    label: 'Generate',
    help: 'Genreate Paypal',
  },
  {
    type: 'ACTION',
    action: 'ACTION_CONVERT_PDF_PASSPORT_TO_JPG',
    label: 'Convert',
    help: 'Convert PDF passport to jpg',
  },
  {
    type: 'ACTION',
    action: 'ACTION_CONVERT_HEIC_PASSPORT_TO_JPG',
    label: 'Convert',
    help: 'Convert HEIC passport to jpg',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_OPERATING_AGREEMENT',
    label: 'OA',
    help: 'Generate Operating Agreement',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_BYLAWS',
    label: 'Bylaws',
    help: 'Generate Bylaws',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_INITIAL_RESOLUTION',
    label: 'Initial Resolution',
    help: 'Generate Initial Resolution',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_8822B_FOR_RESPONSIBLE_PARTY_CHANGE_TO_SIGN',
    label: 'Initiate 8822-B',
    help: 'Generate 8822-B',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_DISSOLUTION_DOCUMENT',
    label: 'Generate Dissolution Document',
    help: 'Generate Dissolution Document',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_RA_TRANSFER_TO_SIGN',
    label: 'Initiate Wyoming RA Transfer',
    help: 'Initiate Wyoming RA Transfer',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_EIN_CANCELLATION_REQUEST',
    label: 'EIN Cancellation Request',
    help: 'EIN Cancellation Request',
  },
  // {
  //   type: 'ACTION',
  //   action: 'ACTION_GENERATE_TAX_OFFICER_RESOLUTION',
  //   label: 'Initiate Tax Officer Resolution',
  //   help: 'Initiate Tax Officer Resolution',
  // },
  {
    type: 'ACTION',
    action: 'CONSENT_AND_INDEMNIFICATION_AGREEMENT_FOR_FORMATION_FILING',
    label: 'Generate Consent and Indemnification Agreement',
    help: 'Generate Consent and Indemnification Agreement',
  },
  {
    type: 'ACTION',
    action: 'ACTION_SEND_ARTICLE_OF_ORGANIZATION_AMENDMENT_FOR_SIGNATURE',
    label: 'Send Article of Organization Amendment For Signature',
    help: 'Send Article of Organization Amendment For Signature',
  },
  {
    type: 'ACTION',
    action: 'ACTION_GENERATE_CERTIFICATE_OF_FORMATION_TO_SIGN',
    label: 'Generate Certificate of formation (DE) to sign',
    help: 'Generate Certificate of formation (DE) to sign',
  },
];

const ActionSchedulers = [
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_SIGNUP',
    label: 'Schedule',
    help: 'Schedule signup Reminders',
  },
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_PAYMENT',
    label: 'Schedule',
    help: 'Schedule Payment Reminders',
  },
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_DATA_VERIFICATION',
    label: 'Schedule',
    help: 'Schedule Data verification Reminders',
  },
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_SS4_SIGNATURE',
    label: 'Schedule',
    help: 'Schedule ss4 signing Reminders',
  },
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_COMPANY_NAME_NOT_AVAILABLE',
    label: 'Schedule',
    help: 'Company not available Reminders',
  },
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_PASSPORT_UPLOAD',
    label: 'Schedule',
    help: 'Schedule passport upload Reminders',
  },
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_FRANCHISE_TAX',
    label: 'Schedule',
    help: 'Schedule franchise tax Reminders',
  },
  {
    type: 'ACTION',
    action: 'SCHEDULE_REMINDERS_FOR_REGISTERED_AGENT_FEE',
    label: 'Schedule',
    help: 'Schedule registered agent fee Reminders',
  },
];

const ActionEvents = [
  {
    type: 'ACTION',
    action: 'EVENT_SIGNIN',
    label: 'Trigger',
    help: 'Signin ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_FORGOT_PASSWORD_REQUESTED',
    label: 'Trigger',
    help: 'Forgot password Requested ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_PASSWORD_RESET_DONE_VIA_URL',
    label: 'Trigger',
    help: 'Password reset successfully ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_PAYMENT_CONFIRMED',
    label: 'Trigger',
    help: 'Payment Confirmed ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_PAYMENT_SUBSCRIPTION_CONFIRMED',
    label: 'Trigger',
    help: 'Subscripton Confirmed ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_SIGNUP_COMPLETED',
    label: 'Trigger',
    help: 'Signup Completed ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_PAYMENT_SUBSCRIPTION_FAILED',
    label: 'Trigger',
    help: 'Subscription failed ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_PAYMENT_FAILED',
    label: 'Trigger',
    help: 'Payment Failed ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_EMAIL_VERIFIED',
    label: 'Trigger',
    help: 'Email verified ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_PASSPORT_UPLOADED_SUCCESS',
    label: 'Trigger',
    help: 'Passport uploaded ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_DATA_VERIFICATION_SUCCESS',
    label: 'Trigger',
    help: 'Data Verified ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_PHONE_BLOCKED_AS_SPAM',
    label: 'Trigger',
    help: 'Phone blocked as spam ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_CREATE_LEAD',
    label: 'Trigger',
    help: 'Lead Generated ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_COUPON_NOT_AVAILABLE',
    label: 'Trigger',
    help: 'Coupon Not Available ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_COUPON_AVAILABLE',
    label: 'Trigger',
    help: 'Coupon Available ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_BANK_ACCOUNT_CONNECTED_THROUGH_PLAID',
    label: 'Trigger',
    help: 'Bank Account connected ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_BANK_ACCOUNT_REMOVED_THROUGH_PLAID',
    label: 'Trigger',
    help: 'Bank Account removed ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_UPDATE_COMPANY_DATA',
    label: 'Trigger',
    help: 'Company Data Updated ',
  },
  {
    type: 'ACTION',
    action: 'EVENT_EMAIL_BLOCKED_AS_SPAM',
    label: 'Trigger',
    help: 'Email blocked as spam ',
  },
];
const Products = [
  {
    type: 'PRODUCT',
    action: 'STATE_FILING',
    help: 'Enable State Filing',
  },
  {
    type: 'PRODUCT',
    action: 'REGISTERED_AGENT',
    help: 'Enable Registered Agent',
  },
  {
    type: 'PRODUCT',
    action: 'EIN',
    help: 'Enable EIN',
  },
  {
    type: 'PRODUCT',
    action: 'BANK_ACCOUNT',
    help: 'Enable Bank Account',
  },
  {
    type: 'PRODUCT',
    action: 'VIRTUAL_PHONE_NUMBER',
    help: 'Enable Virtual Phone Number',
  },
  {
    type: 'PRODUCT',
    action: 'BOOKKEEPING',
    help: 'Enable Bookkeeping',
  },
];

const Features = [
  {
    type: 'FEATURE',
    action: 'DISABLE_AUTOMATION',
    help: 'Disable Automation',
  },
  {
    type: 'FEATURE',
    action: 'SSN_ITIN_EDITABLE',
    help: 'Enable SSN/ITIN Editable from dashboard',
  },
  {
    type: 'FEATURE',
    action: 'DO_NOT_ASK_FEEDBACK_AFTER_BANK_ACCOUNT',
    help: 'Do not ask feedback after company incorporation',
  },
  {
    type: 'FEATURE',
    action: 'ENABLE_MERCURY_APPEAL',
    help: 'Enable Mercury Appeal',
  },
  {
    type: 'FEATURE',
    action: 'LLC_MAINTENANCE_SUBSCRIPTION_PLAN_ENABLED',
    help: 'Enable Subscription Option',
  },
  {
    type: 'FEATURE',
    action: 'ENABLE_OPERATING_AGREEMENT_MANAGEMENT',
    help: 'Enable Operating Agreement Management',
  },
  {
    type: 'FEATURE',
    action: 'ENABLE_EIN_CHOICE',
    help: 'Enable EIN Choice',
  },
];

const DangerZones = [
  {
    type: 'FEATURE',
    action: 'DISABLE_SIGNIN_BY_USER',
    help: 'Disable Sign-in',
  },
  {
    type: 'FEATURE',
    action: 'BLACKLIST_COMPANY',
    help: 'Blacklist Company',
  },
  {
    type: 'FEATURE',
    action: 'FREEZE_COMPANY',
    help: 'Freeze Company',
  },
  {
    type: 'FEATURE',
    action: 'DO_NOT_SEND_NOTIFICATION_EMAILS',
    help: 'Do not send notification emails',
  },
  {
    type: 'ACTION',
    action: 'DELETE_COMPANY',
    label: 'Delete',
    help: 'Delete Company',
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ActionTab = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8} spacing={4}>
        <Grid container>
          {Actions.map((a) => (
            <ActionListItem companyId={data.id} {...a} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
const ActionUserEmailTab = ({ data }) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={6}>
        {ActionUserEmails.filter((a, index) => index % 2 === 0).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
      <Grid item xs={6} sx={{ paddingLeft: 2 }}>
        {ActionUserEmails.filter((a, index) => index % 2 === 1).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
    </Grid>
  );
};
const ActionReminderTab = ({ data }) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={6}>
        {ActionReminders.filter((a, index) => index % 2 === 0).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
      <Grid item xs={6} sx={{ paddingLeft: 2 }}>
        {ActionReminders.filter((a, index) => index % 2 === 1).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
    </Grid>
  );
};
const ActionSchedulerTab = ({ data }) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={6}>
        {ActionSchedulers.filter((a, index) => index % 2 === 0).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
      <Grid item xs={6} sx={{ paddingLeft: 2 }}>
        {ActionSchedulers.filter((a, index) => index % 2 === 1).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
    </Grid>
  );
};
const ActionEventTab = ({ data }) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={6}>
        {ActionEvents.filter((a, index) => index % 2 === 0).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
      <Grid item xs={6} sx={{ paddingLeft: 2 }}>
        {ActionEvents.filter((a, index) => index % 2 === 1).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
    </Grid>
  );
};
const ActionAdminEmailTab = ({ data }) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={6}>
        {ActionAdminEmails.filter((a, index) => index % 2 === 0).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
      <Grid item xs={6} sx={{ paddingLeft: 2 }}>
        {ActionAdminEmails.filter((a, index) => index % 2 === 1).map((a) => (
          <ActionListItem companyId={data.id} {...a} />
        ))}
      </Grid>
    </Grid>
  );
};
const ProductTab = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
        <List>
          {Products.map((a) => (
            <ToggleListItem
              checked={data?.products?.indexOf(a.action) >= 0}
              companyId={data.id}
              {...a}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
const FeatureTab = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
        <List>
          {Features.map((a) => (
            <ToggleListItem
              checked={data?.features?.indexOf(a.action) >= 0}
              companyId={data.id}
              {...a}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
const DangerZoneTab = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
        <List>
          {DangerZones.filter((a, index) => a.type === 'FEATURE').map((a) => (
            <ToggleListItem
              checked={data?.features?.indexOf(a.action) >= 0}
              companyId={data.id}
              {...a}
              color="error"
            />
          ))}
          {DangerZones.filter((a, index) => a.type === 'ACTION').map((a) => (
            <ActionListItem companyId={data.id} {...a} color="error" />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;

const CompanyActions = ({ data }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      {' '}
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <StyledTab
              label="Actions"
              {...a11yProps(0)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="User Emails"
              {...a11yProps(1)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Admin Emails"
              {...a11yProps(2)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Reminders"
              {...a11yProps(3)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Schedulers"
              {...a11yProps(4)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Events"
              {...a11yProps(5)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />

            <StyledTab
              label="Products"
              {...a11yProps(6)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Features"
              {...a11yProps(7)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Danger Zone"
              {...a11yProps(8)}
              style={{ alignItems: 'flex-start' }}
              sx={{ color: 'error' }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          <TabPanel value={value} index={0}>
            <ActionTab data={data} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ActionUserEmailTab data={data} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ActionAdminEmailTab data={data} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ActionReminderTab data={data} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ActionSchedulerTab data={data} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ActionEventTab data={data} />
          </TabPanel>

          <TabPanel value={value} index={6}>
            <ProductTab data={data} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <FeatureTab data={data} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <DangerZoneTab data={data} />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyActions;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-size: 0.875rem;
`;
