import * as React from 'react';
import { Show } from 'react-admin';
import { ListHeaderList } from '../../components/ListHeaderActions';
import { CompanyData, CompanyDataWrapper } from './CompanyData';
import { CompanyHome } from './CompanyHome';

/**
 * This is a generic component, which can be called from any list.
 * Only requirement is, the record should have a prop with companyId
 */
export const CompanyMasterDataGenericEdit = ({ permissions, ...props }) => {
  return (
    <Show {...props} component="div" actions={<ListHeaderList />}>
      <CompanyDataWrapper permissions={permissions} {...props} />
    </Show>
  );
};

export default CompanyMasterDataGenericEdit;
