import List from '../../components/List';
import { AutocompleteInput, Datagrid, EditButton, Identifier, RaRecord } from 'react-admin';
import TextField from '../../components/TextField';
import NumberField from '../../components/NumberField';
import DateField from '../../components/DateField';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import { CompanyComplianceStatus, CompanyServiceComplianceStatus } from '../../config/constants';

const Filters = [
  <TextInput source="companyName" label="Company" alwaysOn />,
  <SelectInput source="registeredAgentStatus" choices={CompanyServiceComplianceStatus} alwaysOn />,
  <SelectInput source="franchiseTaxStatus" choices={CompanyServiceComplianceStatus} alwaysOn />,
  <SelectInput source="status" choices={CompanyComplianceStatus} alwaysOn />,
  <TextInput source="state" label="State" alwaysOn />,
];

const EntityManagementViewList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={(id: Identifier, resource: string, record: RaRecord) => {
          return `/company-master-data/${record?.companyId}?tab=compliance`;
        }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />

        <TextField source="companyName" label="Company Name" />
        <TextField source="state" label="State" />

        <TextField source="email" label="Email" />

        <TextField source="registeredAgentStatus" label="RA" />
        <TextField source="franchiseTaxStatus" label="Franchise Tax" />
        <TextField source="status" label="Status" />

        <DateField source="cancellationDate" label="Cancellation Date" />
        <DateField source="atRiskChangedDate" label="At Risk On" />
        <TextField source="inactiveChangedDate" label="InActive On" />
      </Datagrid>
    </List>
  );
};

export default EntityManagementViewList;
