import EmailBlackList from './EmailBlackList';
import EmailBlackListEdit from './EmailBlackListEdit';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import EmailBlackListShow from './EmailBlackListShow';
import EmailBlackListCreate from './EmailBlackListCreate';

const resource = {
  list: EmailBlackList,
  show: EmailBlackListShow,
  edit: EmailBlackListEdit,
  icon: AttachEmailIcon,
  create: EmailBlackListCreate,
};

export default resource;
