import * as React from 'react';
import styled from '@emotion/styled';
import { SimpleForm, ShowProps, Edit, AutocompleteInput } from 'react-admin';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../../components/SectionTitle';
import Separator from '../../../components/Separator';
import TextInput from '../../../components/TextInput';
import DateInput from '../../../components/DateInput';
import { ListHeaderActionsEdit } from '../../../components/ListHeaderActions';
import { Employee } from '../../../types';
import NumberInput from '../../../components/NumberInput';
import { Currency } from '../../../config/constants';
import ReferenceInput from '../../../components/ReferenceInput';
import SelectInput from '../../../components/SelectInput';
import { transformDate } from '../../../lib/SGHelper';
import BooleanInput from '../../../components/BooleanInput';
import { RegisteredAgentTransferStatus, PaymentStatus } from './../../../config/constants';
import LabelField from '../../../components/LabelField';
import { LabelType } from '../../../config/LabelType';

const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));
const transform = (data: { dateOfFormation: any }) => {
  const modifiedData = {
    ...data,
    dateOfFormation: transformDate(data.dateOfFormation, 'dd MMMM, yyyy'),
  };
  return modifiedData;
};
const RegisteredAgentTransferEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />} transform={transform}>
      <SimpleForm>
        <EmployeeWrapper>
          <BasicDetais>
            <SectionTitle label="Details" />
            <TextInput source="id" label="Ref." disabled />
            {/* <Box>
              <TextInput
                type="email"
                source="registeredAgentTransferDetail.email"
                label="Email"
                fullWidth
                disabled
              />
              <TextInput
                source="registeredAgentTransferDetail.company"
                label="Company"
                fullWidth
                disabled
              />
            </Box> */}
            <Box>
              {/* <TextInput
                source="registeredAgentTransferDetail.state"
                label="State"
                fullWidth
                disabled
              /> */}
              <TextInput
                source="registeredAgentTransferDetail.paymentStatus"
                label="Payment Status"
                fullWidth
                disabled
              />
            </Box>
            <Box>
              <LabelField
                source="registeredAgentTransferDetail.url"
                label="Url"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
            </Box>
            <SelectInput
              source="registeredAgentTransferDetail.status"
              label="Status"
              choices={RegisteredAgentTransferStatus}
            />
            ,
          </BasicDetais>
        </EmployeeWrapper>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const EmployeeWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Box = styled.div`
  display: flex;
  gap: 1em;
`;
export default RegisteredAgentTransferEdit;
