import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  SelectInput,
  TopToolbar,
} from 'react-admin';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';

const Filters = [
  <TextInput source="email" label="Email" alwaysOn />,
  <TextInput source="company" label="Company" alwaysOn />,
];
const SS4ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};

const SS4List = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<SS4ListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyId" label="Company Ref." />
        <TextField source="company" label="Company" />
        <TextField source="state" label="State" />
        <TextField source="team" label="Team" />
        <TextField source="remarks" label="Remarks" />
        <TextField source="provider" />
      </Datagrid>
    </List>
  );
};

export default SS4List;
