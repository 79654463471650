// in src/posts.js
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { CustomTextField } from '../../../components/CustomTextField';
import SubmitButton from './SubmitButton';

import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';

const PhoneRechargeForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`update/company/${companyId}/virtual-phone-number/recharge`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Recharged successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item xs={6}>
          <CustomTextField control={control} label="Amount" name="amount" />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Recharge" />
        </Grid>
      </Grid>
    </form>
  );
};

export default PhoneRechargeForm;
