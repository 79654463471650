// in src/posts.js
import * as React from 'react';

import {
  List,
  TextField,
  ReferenceField,
  EditButton,
  SimpleForm,
  CloneButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  Filter,
  BooleanInput,
  BooleanField,
  TextInput,
  required,
  Create,
  AutocompleteInput,
} from 'react-admin';

import { FranchiseMaster } from './../../config/constants';

const franchiseIdNameArray = FranchiseMaster.map((f) => ({
  id: f.id,
  name: f.label,
}));

export const FranchiseChangeLogCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Company" source="companyId" reference="company-master-data">
          <AutocompleteInput optionText="companyFullName" />
        </ReferenceInput>
        <SelectInput source="oldFranchiseId" label="From" choices={franchiseIdNameArray} />,
        <SelectInput source="newFranchiseId" label="To" choices={franchiseIdNameArray} />
      </SimpleForm>
    </Create>
  );
};

export default FranchiseChangeLogCreate;
