import * as React from 'react';
import { Datagrid } from 'react-admin';
import AutocompleteInput from '../../components/AutocompleteInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import ReferenceField from '../../components/ReferenceField';
import ReferenceInput from '../../components/ReferenceInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { Franchise } from '../../types';

const Filters = [
  <TextInput source="email" label="Email" alwaysOn />,
  // <ReferenceInput source="franchiseId" reference="franchise-master" alwaysOn>
  //     <AutocompleteInput
  //         optionText={(choice?: Franchise) =>
  //             choice?.id
  //                 ? `${choice.team}`
  //                 : ''
  //         }
  //      fullWidth
  //      />
  // </ReferenceInput>,
  // <ReferenceInput label="Franchise" source="franchiseId" reference="franchise-master" alwaysOn >
  //     <AutocompleteInput optionText="team" alwaysOn/>
  // </ReferenceInput >
];

const CompanyLeadsList = () => {
  return (
    <List title="List of all Leads" filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <EmailField source="email" label="Email" />
        <ReferenceField source="franchiseId" reference="franchise-master">
          <TextField source="team" />
        </ReferenceField>
        <TextField source="phone" />
        <TextField source="timezoneOffset" />
        <TextField source="timeZone" />
        <DateField source="createdDate" label="Created On (UTC)" />
      </Datagrid>
    </List>
  );
};

export default CompanyLeadsList;
