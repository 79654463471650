import * as React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  ExportButton,
  FunctionField,
  RaRecord,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { BoiStatus } from '../../config/constants';
import ReferenceField from '../../components/ReferenceField';
// import {PaymentStatus} from '../../config/constants'

const BoiStatusArray = BoiStatus.filter((f) =>
  ['IN_PROGRESS', 'WAITING_FOR_INFO', 'CONFIRMED', 'REJECTED'].includes(f.id)
).map((f) => ({
  id: f.id,
  name: f.name,
}));

// const PaymentStatusArray = PaymentStatus.map(f =>  ({
//     id : f.id,
//     name : f.name
// }));

const BE12SurveyListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

//get the current url path.
//if it ends with /onhold, then do not inlude the SelectInput filter for status
const currentUrl = window.location.pathname;
console.log('currentUrl', currentUrl);

const Filters = [
  <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
  <SelectInput source="status" label="Status" choices={BoiStatusArray} alwaysOn />,
  <TextInput source="dateOfFormationYear" label="Fromed In" alwaysOn />,
];
const OnholdViewFilter = [
  <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
];

const BoiMasterList = () => {
  const isOnholdView = window.location.hash?.endsWith('onhold');
  return (
    <List
      filters={isOnholdView ? OnholdViewFilter : Filters}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<BE12SurveyListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'edit'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <ReferenceField
          source="companyId"
          label="Company Name"
          reference="company-master-data"
          link={(record: RaRecord, reference: string) => {
            return '';
          }}
        >
          <TextField source="companyFullName" />
        </ReferenceField>
        {/* <TextField source="year" label="Year" /> */}
        <TextField source="dateOfFormationYear" label="Formed In" />
        <TextField source="dateOfFormation" label="Date of Formation" />

        <SelectField source="status" label="Status" choices={BoiStatusArray} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default BoiMasterList;
