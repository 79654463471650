import * as React from 'react';
import styled from '@emotion/styled';
import { ReferenceInputProps } from 'react-admin';
import { ReferenceInput as ReferenceInputDefault } from 'react-admin';

export interface ReferenceInputProp extends ReferenceInputProps {}

const ReferenceInput = (props: ReferenceInputProp) => {
  return (
    <ReferenceInputDefault
      source={props.source}
      reference={props.reference}
      disabled={props.disabled}
    >
      {props.children}
    </ReferenceInputDefault>
  );
};

export default ReferenceInput;
