import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  EditButton,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
} from 'react-admin';
import BooleanInput from '../../../components/BooleanInput';
import DateField from '../../../components/DateField';
import EmailField from '../../../components/EmailField';
import List from '../../../components/List';
import TextField from '../../../components/TextField';
import TextInput from '../../../components/TextInput';
import { WorkflowStatus } from './../../../config/constants';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="id" label="Ref." alwaysOn />,
  <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
];

const IndividualExtensionList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="email" label="Email" />
        <TextField source="firstName" />
        <TextField source="lastName" />

        <TextField source="addressLine1" />
        <TextField source="state" />
        <TextField source="country" />
        <TextField source="city" />
        <TextField source="postalCode" />

        <TextField source="isUsResident" />
        <TextField source="isUsCitizen" />

        <TextField source="ssn" />
        <TextField source="isJointFiling" />
        <TextField source="spousesSsn" />

        <TextField source="isForm1040Required" />
        <TextField source="haveReceivedWagesAsEmployee" />

        <TextField source="haveItin" />
        <TextField source="itin" />
        <NumberField source="estimatedTax" />
        <NumberField source="taxPaid" />
        <TextField source="isPayingRemainingFullAmount" />
        <TextField source="remainingAmountPayable" />
        <TextField source="followsCalendarYearForTaxPurpose" />
        <TextField source="taxStartDate" />
        <TextField source="taxEndDate" />
        <TextField source="year" />
        <TextField source="dateOfBirth" />
        <TextField source="spouseFirstName" />
        <TextField source="spouseLastName" />
        <TextField source="spouseDateOfBirth" />
        <SelectField source="status" choices={WorkflowStatusArray} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default IndividualExtensionList;
