import * as React from 'react';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import SubmitButton from './../../forms/SubmitButton';
import { useNotify, useDataProvider } from 'react-admin';

import { CustomCheckBox } from '../../../../components/CustomCheckBox';

const FederalTaxStatusForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.companyId;
  const id = data?.id;

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [value, setValue] = React.useState(data.faxable);
  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`federal-tax/company/${companyId}/federal-tax/${id}/faxable`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomCheckBox
            control={control}
            name="faxable"
            //onClick={() => setIsCompany(isCompany => !isCompany)}
            onClick={() => {
              setValue(!value);
            }}
            checked={value}
            label="Send via fax ?"
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Update Fax Delivery" />
        </Grid>
      </Grid>
    </form>
  );
};

export default FederalTaxStatusForm;
