import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNotify, useDataProvider } from 'react-admin';
import { SubscriptionDetails } from '../../sg-view-subscription-master-data/SubscriptionDetails';
import { Invoices } from '../../sg-view-subscription-master-data/Invoices';

const CompanySubscription = ({ data }) => {
  const [value, setValue] = React.useState(0);
  const [loading, setIsloading] = React.useState(false);
  const [subscriptionData, setSubscriptionData] = React.useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    setIsloading(true);
    const url = `sg_view_subscription_master_data/company/${data.id}`;
    dataProvider
      .get(url, { data })
      .then(({ data, error }) => {
        console.log('sg_view_subscription_master_data', data);
        setSubscriptionData(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, []);

  return (
    <Box>
      {subscriptionData ? (
        <Grid container spacing={1}>
          <Grid item xs={2} sm={2} md={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <StyledTab label={'Details'} {...a11yProps(0)} />
              <StyledTab label={'Invoices'} {...a11yProps(1)} />
            </Tabs>
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <TabPanel value={value} index={0}>
              <SubscriptionDetails data={subscriptionData.subscription} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Invoices data={subscriptionData.invoices} />
            </TabPanel>
          </Grid>
        </Grid>
      ) : (
        <div>No Data found</div>
      )}
    </Box>
  );
};

export default CompanySubscription;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
