import EmployeeIcon from '@mui/icons-material/People';

import ExpenseMasterCreate from './ExpenseMasterCreate';
import ExpenseMasterList from './ExpenseMasterList';
import ExpenseMasterEdit from './ExpenseMasterEdit';
import ExpenseMasterView from './ExpenseMasterView';

const resource = {
  list: ExpenseMasterList,
  edit: ExpenseMasterEdit,
  create: ExpenseMasterCreate,
  show: ExpenseMasterView,
  icon: EmployeeIcon,
};

export default resource;
