import * as React from 'react';
import { Create, SimpleForm, email, SelectInput, BooleanInput } from 'react-admin';
import { Box, Typography } from '@mui/material';
import Separator from '../../components/Separator';
import SectionTitle from '../../components/SectionTitle';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { Row } from '../../components/Row';
import { validateForm } from './KPIConfigParamValidateForm';

import { KPIParameterType } from './../../config/constants';
import { KPIState } from './../../config/constants';
import { KPIType } from './../../config/constants';

const KPIParameterTypeArray = KPIParameterType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPIStateArray = KPIState.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPITypeArray = KPIType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPIConfigParamCreate = () => (
  <Create>
    <SimpleForm
      defaultValues={{
        startDate: new Date(),
      }}
      validate={validateForm}
    >
      <Row>
        <div style={{ width: '100%' }}>
          <SectionTitle label="KPI Configuration Parameters" />
          <SelectInput source="type" label="KPI Type" choices={KPITypeArray} fullWidth isRequired />
          <SelectInput source="state" label="State" choices={KPIStateArray} fullWidth />
          <SelectInput
            source="parameter"
            label="Parameter Type"
            choices={KPIParameterTypeArray}
            fullWidth
            isRequired
          />
          <TextInput source="description" label="Description" fullWidth isRequired />
          <TextInput source="value" label="Value" fullWidth isRequired />
          <BooleanInput source="isValid" label="Active" />
        </div>
      </Row>
      <Separator />
    </SimpleForm>
  </Create>
);

export default KPIConfigParamCreate;
