import EmployeeIcon from '@mui/icons-material/People';

import EmailRegistrationEdit from './EmailRegistrationEdit';
import EmailRegistrationView from './EmailRegistrationView';
import EmailRegistrationList from './EmailRegistrationList';

const resource = {
  list: EmailRegistrationList,
  edit: EmailRegistrationEdit,
  show: EmailRegistrationView,
  icon: EmployeeIcon,
};

export default resource;
