import * as React from 'react';
import styled from '@emotion/styled';
import { SelectFieldProps } from 'react-admin';
import { SelectField as SelectFieldDefault } from 'react-admin';

export interface SelectFieldProp extends SelectFieldProps {}

const SelectField = (props: SelectFieldProp) => {
  return (
    <SelectFieldDefault
      source={props.source}
      label={props.label}
      choices={props.choices}
      fullWidth={props.fullWidth}
    />
  );
};

export default SelectField;
