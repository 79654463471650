import * as React from 'react';
import styled from '@emotion/styled';
import { NumberFieldProps } from 'react-admin';
import { NumberField as NumberFieldDefault } from 'react-admin';

export interface NumberFieldProp extends NumberFieldProps {}

const NumberField = (props: NumberFieldProp) => {
  return (
    <NumberFieldDefault source={props.source} label={props.label} fullWidth={props.fullWidth} />
  );
};

export default NumberField;
