import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  NumberField,
  RaRecord,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import ReferenceField from '../../components/ReferenceField';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { KPIParameterType } from '../../config/constants';
import { KPIState } from '../../config/constants';
import { KPIType } from '../../config/constants';
import {
  PaymentStatus,
  FederalTaxStatus,
  BookkeepingStatus,
  SubscriptionStatus,
} from '../../config/constants';
import { hasPermission } from './../../lib/SGHelper';
import { RecentYears } from './../../config/constants';

const FederalTaxStatusArray = FederalTaxStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const BookkeepingStatusArray = BookkeepingStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const SubscriptionStatusArray = SubscriptionStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const Filters = [
  <TextInput source="company" label="Company" alwaysOn />,
  <TextInput source="state" label="State" />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="country" />,

  <TextInput source="responsibleParty" alwaysOn />,
  // <BooleanInput source="bankConnectedFlag" alwaysOn/>,
  // <BooleanInput source="zeroTransactionFlag" alwaysOn/>,
  // <BooleanInput source="confirmBooksFlag" alwaysOn/>,
  <SelectInput source="subscriptionStatus" choices={SubscriptionStatusArray} alwaysOn />,
  <SelectInput source="status" choices={FederalTaxStatusArray} alwaysOn />,
  <SelectInput source="bookkeepingStatus" choices={BookkeepingStatusArray} alwaysOn />,
  // <SelectInput source="bookkeepingPaymentStatus" choices={PaymentStatus} alwaysOn />
];

const cpaConfig = {
  Filters: [
    <TextInput source="company" label="Company" alwaysOn />,
    <TextInput source="responsibleParty" alwaysOn />,
    // <TextInput source="companyName" label="Company " alwaysOn />,

    <SelectInput
      source="status"
      choices={FederalTaxStatusArray.filter(
        (f) =>
          [
            'WAITING_FOR_TAX_DUE_CALCULATION',
            'PENDING_PAYMENT_OF_TAX_DUE',
            'WAITING_FOR_CPA_DOCUMENTS',
            'WAITING_FOR_CLIENT_SIGNATURE',
            'WAITING_FOR_CPA_CONFIRMATION',
            'TAX_PAID_AND_CONFIRMED',
            'WAITING_FOR_FAX',
            'WAITING_FOR_FAX_CONFIRMATION',
            'SUBMITTED_FOR_REVISION',
          ].indexOf(f.id) >= 0
      )}
      alwaysOn
    />,
    <SelectInput source="year" choices={RecentYears} alwaysOn />,
  ],
  filterDefaultValues: { status: 'WAITING_FOR_TAX_DUE_CALCULATION' },
};

const ListActions = () => {
  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  return (
    <TopToolbar>
      <FilterButton />
      {!isCPA && <ExportButton maxResults={4000} />}
    </TopToolbar>
  );
};
const FederalTaxSummaryList = () => {
  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  return (
    <List
      filters={isCPA ? cpaConfig.Filters : Filters}
      filterDefaultValues={isCPA ? cpaConfig.filterDefaultValues : {}}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" />
        {!isCPA && <TextField source="email" />}

        <ReferenceField
          source="companyRef"
          label="Company"
          reference="company-master-data"
          link={(record: RaRecord, reference: string) => {
            return `/${reference}/${record.companyRef}?tab=federal-tax`;
          }}
        >
          <FunctionField
            label="Company"
            render={(record: { companyName: any; companyNameType: any }) =>
              `${record.companyName} ${record.companyNameType}`
            }
          />
        </ReferenceField>

        <TextField source="state" />
        <TextField source="responsibleParty" />
        <TextField source="country" />
        {!isCPA && <TextField source="tel" />}
        {!isCPA && <TextField source="team" />}
        {!isCPA && <TextField source="subscriptionStatus" label="Subscription" />}

        <DateField source="dateOfFormation" />
        <TextField source="companyRef" />
        <TextField source="year" />
        <TextField source="status" />
        <NumberField source="grossIncome" label="Gross Income" />
        {!isCPA && <NumberField source="estimateCharge" label="Estimate" />}
        {!isCPA && <NumberField source="estimateChargeStatus" label="Payment Status" />}

        {!isCPA && <BooleanField source="bankConnectedFlag" label="Bank Connected?" />}

        <BooleanField source="zeroTransactionFlag" label="Confirmed Zero Transactions?" />
        {!isCPA && <BooleanField source="confirmBooksFlag" label="Confirmed Books?" />}
        {!isCPA && <BooleanField source="authorizedFlag" label="Authorized?" />}

        <DateField source="dueDate" />
        {!isCPA && <NumberField source="transactionCount" />}
        {!isCPA && <NumberField source="bookkeepingAmount" label="Bookkeeping Estimate" />}
        {!isCPA && <TextField source="bookkeepingStatus" label="Bookkeeping Status" />}
        {!isCPA && <TextField source="bookkeepingPaymentStatus" label="Bookeeping Payment" />}
        {/* <ShowButton label="Upload EIN"/> */}
      </Datagrid>
    </List>
  );
};

export default FederalTaxSummaryList;
