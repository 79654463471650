import * as React from 'react';
import styled from '@emotion/styled';
import { BooleanInputProps } from 'react-admin';
import { BooleanInput as BooleanInputDefault } from 'react-admin';

export interface BooleanInputProp extends BooleanInputProps {}

const BooleanInput = (props: BooleanInputProp) => {
  return (
    <BooleanInputDefault source={props.source} label={props.label} fullWidth={props.fullWidth} />
  );
};

export default BooleanInput;
