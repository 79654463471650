import EmployeeIcon from '@mui/icons-material/People';

import FederalTaxBookkeepingWorkflowList from './FederalTaxBookkeepingWorkflowList';

const resource = {
  list: FederalTaxBookkeepingWorkflowList,
  icon: EmployeeIcon,
};

export default resource;
