import { Grid } from '@mui/material';
import * as React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';
import SubmitButton from './../../forms/SubmitButton';

import { CustomSelect } from '../../../../components/CustomSelect';

const FederalTaxPenaltyStatus = ({ data, disabled }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.companyId;
  const id = data?.id;

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`federal-tax/company/${companyId}/federal-tax/${id}/penalty-status`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="Penalty Status"
            name="penaltyStatus"
            defaultValue={data.penaltyStatus}
            disabled={disabled}
            options={[
              { value: 'NO_PENALTY', label: 'N/A' },
              { value: 'PENALTY_NOTICE_RECEIVED', label: 'Penalty notice received from IRS' },
              // { value: 'PENALTY_CALCULATED', label: 'Penalty has been calculated' },
              // { value: 'PENALTY_APPLIED', label: 'Penalty has been applied' },
              { value: 'PENALTY_PAID', label: 'Penalty has been paid' },
              { value: 'PENALTY_WAIVED', label: 'Penalty has been waived' },
              { value: 'PENALTY_DISPUTED', label: 'Penalty is under dispute' },
              { value: 'PENALTY_RESOLVED', label: 'Penalty resolved' },
            ]}
          />
        </Grid>

        <Grid item xs={3}>
          {!disabled && <SubmitButton processing={processing} label="Update Penalty Status" />}
        </Grid>
      </Grid>
    </form>
  );
};

export default FederalTaxPenaltyStatus;
