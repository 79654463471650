import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNotify, useDataProvider } from 'react-admin';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { Container, Divider } from '@mui/material';
import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

const bull = (
  <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    •
  </Box>
);

const CompanyNotes = ({ data }) => {
  const [reload, setReload] = React.useState(true);
  const [loading, setIsloading] = React.useState(false);
  const [notes, setNotes] = React.useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [note, setNote] = React.useState('');

  React.useEffect(() => {
    setIsloading(true);
    const url = `company_notes/company/${data.id}`;
    dataProvider
      .get(url, { data })
      .then(({ data, error }) => {
        console.log('company_notes', data);
        setNotes(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  const onAddNewNote = () => {
    //api.call(itemName, itemDest) // or whatever you want
    dataProvider
      .post(`company_notes/`, {
        data: {
          companyId: data.id,
          note,
        },
      })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Note added successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
        setReload(!reload);
        setNote('');
        setOpen(false);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  };

  const onNoteDelete = (id) => {
    dataProvider
      .post(`company_notes/delete/${id}`)
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Note deleted successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
        setReload(!reload);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  };

  return (
    <Container>
      <Grid container spacing={1} style={{ marginBottom: '1rem' }}>
        <Button
          onClick={handleOpen}
          variant="contained"
          color="warning"
          sx={{ height: 40, marginLeft: 'auto' }}
        >
          Add Note
        </Button>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>Notes</DialogTitle>
          <DialogContent>
            <DialogContentText>Add a note about this company.</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="note"
              label="Note"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={onAddNewNote}>Add</Button>
          </DialogActions>
        </Dialog>
      </Grid>
      {notes ? (
        <Grid container spacing={1}>
          {notes.map((d) => {
            return (
              <Container style={{ paddingBottom: '0.6rem' }}>
                <Card variant="outlined">
                  <CardContent>
                    {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Word of the Day
                            </Typography> */}
                    <Typography variant="h6" component="div">
                      {d.note}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {d.createdBy}
                    </Typography>
                    <Typography variant="body2">{d.createdDate}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => onNoteDelete(d.id)}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Container>
            );
          })}
        </Grid>
      ) : (
        <div>No Data found</div>
      )}
    </Container>
  );
};

export default CompanyNotes;
