// in src/posts.js
import * as React from 'react';
import { useForm } from 'react-hook-form';
import SubmitButton from './SubmitButton';

import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import { CustomSelect } from '../../../components/CustomSelect';

const StateForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`update/company/${companyId}/state`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="State"
            name="state"
            defaultValue={data.company.state}
            options={[
              { value: 'ALABAMA', label: 'ALABAMA' },
              { value: 'ALASKA', label: 'ALASKA' },
              { value: 'ARIZONA', label: 'ARIZONA' },
              { value: 'ARKANSAS', label: 'ARKANSAS' },
              { value: 'CALIFORNIA', label: 'CALIFORNIA' },
              { value: 'COLORADO', label: 'COLORADO' },
              { value: 'CONNECTICUT', label: 'CONNECTICUT' },
              { value: 'DELAWARE', label: 'DELAWARE' },
              { value: 'FLORIDA', label: 'FLORIDA' },
              { value: 'GEORGIA', label: 'GEORGIA' },
              { value: 'HAWAII', label: 'HAWAII' },
              { value: 'IDAHO', label: 'IDAHO' },
              { value: 'ILLINOIS', label: 'ILLINOIS' },
              { value: 'INDIANA', label: 'INDIANA' },
              { value: 'IOWA', label: 'IOWA' },
              { value: 'KANSAS', label: 'KANSAS' },
              { value: 'KENTUCKY', label: 'KENTUCKY' },
              { value: 'LOUISIANA', label: 'LOUISIANA' },
              { value: 'MAINE', label: 'MAINE' },
              { value: 'MARYLAND', label: 'MARYLAND' },
              { value: 'MASSACHUSETTS', label: 'MASSACHUSETTS' },
              { value: 'MICHIGAN', label: 'MICHIGAN' },
              { value: 'MINNESOTA', label: 'MINNESOTA' },
              { value: 'MISSISSIPPI', label: 'MISSISSIPPI' },
              { value: 'MISSOURI', label: 'MISSOURI' },
              { value: 'MONTANA', label: 'MONTANA' },
              { value: 'NEBRASKA', label: 'NEBRASKA' },
              { value: 'NEVADA', label: 'NEVADA' },
              { value: 'NEW_HAMPSHIRE', label: 'NEW_HAMPSHIRE' },
              { value: 'NEW_JERSEY', label: 'NEW_JERSEY' },
              { value: 'NEW_MEXICO', label: 'NEW_MEXICO' },
              { value: 'NEW_YORK', label: 'NEW_YORK' },
              { value: 'NORTH_CAROLINA', label: 'NORTH_CAROLINA' },
              { value: 'NORTH_DAKOTA', label: 'NORTH_DAKOTA' },
              { value: 'OHIO', label: 'OHIO' },
              { value: 'OKLAHOMA', label: 'OKLAHOMA' },
              { value: 'OREGON', label: 'OREGON' },
              { value: 'PENNSYLVANIA', label: 'PENNSYLVANIA' },
              { value: 'RHODE_ISLAND', label: 'RHODE_ISLAND' },
              { value: 'SOUTH_CAROLINA', label: 'SOUTH_CAROLINA' },
              { value: 'SOUTH_DAKOTA', label: 'SOUTH_DAKOTA' },
              { value: 'TENNESSEE', label: 'TENNESSEE' },
              { value: 'TEXAS', label: 'TEXAS' },
              { value: 'UTAH', label: 'UTAH' },
              { value: 'VERMONT', label: 'VERMONT' },
              { value: 'VIRGINIA', label: 'VIRGINIA' },
              { value: 'WASHINGTON', label: 'WASHINGTON' },
              { value: 'WEST_VIRGINIA', label: 'WEST_VIRGINIA' },
              { value: 'WISCONSIN', label: 'WISCONSIN' },
              { value: 'WYOMING', label: 'WYOMING' },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Update State" />
        </Grid>
      </Grid>
    </form>
  );
};

export default StateForm;
