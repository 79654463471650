import VisitorIcon from '@mui/icons-material/People';

import FranchiseChangeLogList from './FranchiseChangeLogList';
import FranchiseChangeLogCreate from './FranchiseChangeLogCreate';
import FranchiseChangeLogShow from './FranchiseChangeLogShow';

const resource = {
  list: FranchiseChangeLogList,
  create: FranchiseChangeLogCreate,
  show: FranchiseChangeLogShow,
  icon: VisitorIcon,
};

export default resource;
