import * as React from 'react';
import {
  CreateButton,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  ListButton,
  ShowButton,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { Box, Divider, Grid, Typography } from '@mui/material';

const ListHeaderActionsEdit = () => {
  return (
    <TopToolbar>
      <ShowButton />
      <ListButton />
      <DeleteButton />
    </TopToolbar>
  );
};

const ListHeaderActionsView = () => {
  return (
    <TopToolbar>
      <EditButton />
      <ListButton />
      <DeleteButton />
    </TopToolbar>
  );
};
const ListHeaderList = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  );
};

const BackButton = () => {
  return (
    <TopToolbar>
      <BackButton />
    </TopToolbar>
  );
};
export { ListHeaderActionsEdit, ListHeaderActionsView, BackButton, ListHeaderList };
