import CompanyArchivedIcon from '@mui/icons-material/People';

import CompanyArchivedMasterCreate from './CompanyArchivedMasterCreate';
import CompanyArchivedMasterList from './CompanyArchivedMasterList';
import CompanyArchivedMasterEdit from './CompanyArchivedMasterEdit';
import CompanyArchivedMasterView from './CompanyArchivedMasterView';

const resource = {
  list: CompanyArchivedMasterList,
  create: CompanyArchivedMasterCreate,
  edit: CompanyArchivedMasterEdit,
  show: CompanyArchivedMasterView,
  icon: CompanyArchivedIcon,
};

export default resource;
