export const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;
  if (!values.amount) {
    errors.amount = 'ra.validation.required';
  }
  if (!values.currency) {
    errors.currency = 'ra.validation.required';
  }
  if (!values.startDate) {
    errors.startDate = 'ra.validation.required';
  }
  if (!values.endDate) {
    errors.endDate = 'ra.validation.required';
  }

  return errors;
};
