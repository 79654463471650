import EmployeeIcon from '@mui/icons-material/People';

import CompanyBookkeepingList from './CompanyBookkeepingList';
import CompanyBookkeepingShow from './CompanyBookkeepingShow';

const resource = {
  list: CompanyBookkeepingList,
  show: CompanyBookkeepingShow,
  icon: EmployeeIcon,
};

export default resource;
