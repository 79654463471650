import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  RaRecord,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
} from 'react-admin';
import AutocompleteInput from '../../components/AutocompleteInput';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import ReferenceField from '../../components/ReferenceField';
import ReferenceInput from '../../components/ReferenceInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { ChatStatus, RecentYears } from '../../config/constants';
import { BookkeepingStatus } from '../../config/constants';
import { PaymentStatus } from '../../config/constants';

const ChatStatusArray = ChatStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="companyName" label="Company " alwaysOn />,
  // <ReferenceInput label="Company"  source="companyId" reference="company-master-data" alwaysOn >
  //      <AutocompleteInput optionText="companyFullName" />
  // </ReferenceInput>
];

const postRowStyle = (record: any, index: number) => ({
  backgroundColor: record.daysToPenalty < 10 ? '#800000' : 'grey',
});

/**
 * This function handles all the complications
 */
const getClientAction = (record: any) => {
  const action = {
    CATEGORIZATION: 'TBD Categorisation',
    ANSWER_QUERY: 'TBD Answer Query',
    START_BOOKEEPING: 'TBD Start Bookkeeping',
    CONNECT_BANK: 'TBD Connect Bank',
    PAY_BOOKKEEPING_ESTIMATE: 'TBD Pay Book Estimate',
    NO_ACTION_REQUIRED: 'No Action Required',
    PAY_FEDERAL_TAX_ESTIMATE: 'TBD Pay Fed Estimate',
    SIGN_DOCUMENTS: 'TBD Sign Docs',
    PAY_TAX: 'TBD Pay Tax',
  };

  //primary status
  if (record.bkStatus === 'ACTIVE') {
    //have paid bookkeeping, but not completed
    if (record.queriesUnanswered > 0) {
      return action.ANSWER_QUERY;
    }
    if (record.categoryNotEntered > 0) {
      return action.CATEGORIZATION;
    }
    if (record.unconfirmed > 0 || record.userConfirmationStatus != 12) {
      return action.START_BOOKEEPING;
    }
  }

  //we have started federal tax, so no need to consider bookkeeping
  if (
    [
      'WAITING_FOR_TAX_DUE_CALCULATION',
      'WAITING_FOR_CPA_DOCUMENTS',
      'WAITING_FOR_CPA_CONFIRMATION',
      'TAX_PAID_AND_CONFIRMED',
      'WAITING_FOR_FAX',
      'WAITING_FOR_FAX_CONFIRMATION',
      'TAX_PAID_EXTERNALLY',
      'PAID_EXTERNALLY',
      'SUBMITTED_FOR_REVISION',
    ].indexOf(record.ftStatus) >= 0
  ) {
    return action.NO_ACTION_REQUIRED;
  }

  if (record.ftStatus == 'PENDING_PAYMENT_OF_TAX_DUE') {
    return action.PAY_TAX;
  }
  if (record.ftStatus == 'WAITING_FOR_CLIENT_SIGNATURE') {
    return action.SIGN_DOCUMENTS;
  }

  //All PAID status for federal tax are now taken care of.

  if (record.bkStatus == 'CLOSED' || record.bkStatus == 'COMPLETED_EXTERNALLY') {
    //bookkeeping is completedl
    if (record.ftStatus == 'PENDING_PAYMENT_OF_FILING_CHARGES') {
      return action.PAY_FEDERAL_TAX_ESTIMATE;
    }
  }

  if (record.bkStatus == 'ACTIVE') {
    if (record.ftStatus == 'WAITING_FOR_BOOKKEEPING') {
      return action.NO_ACTION_REQUIRED;
    }
  }

  if (record.bkStatus === 'WAITING_FOR_BANK_CONNECTION') {
    return action.CONNECT_BANK;
  }

  if (record.ftStatus == 'PENDING_PAYMENT_OF_FILING_CHARGES') {
    return action.PAY_FEDERAL_TAX_ESTIMATE;
  }

  return record.ftStatus;
};

const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      {/* <ExportButton/> */}
    </TopToolbar>
  );
};
const FederalTaxBookkeepingWorkflowList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'chatStatus':'OPEN'}}
      // sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        // sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        // rowClick={'show'}
        //rowStyle={postRowStyle}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyName" label="Company" />

        <TextField source="bkStartDate" label="BK Entry" />
        <TextField source="bkEndDate" label="BK Exit" />
        <TextField source="bkDays" label="BK Days" />

        <TextField source="ftStartDate" label="FT Entry" />
        <TextField source="ftEndDate" label="FT Exit" />
        <TextField source="ftDays" label="FT Days" />

        <FunctionField label="Client Action" render={getClientAction} />
      </Datagrid>
    </List>
  );
};

export default FederalTaxBookkeepingWorkflowList;
