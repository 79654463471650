import * as React from 'react';
import { Datagrid, EditButton, ExportButton, TextInput, TopToolbar } from 'react-admin';
import BooleanField from '../../../components/BooleanField';
import BooleanInput from '../../../components/BooleanInput';
import DateField from '../../../components/DateField';
import EmailField from '../../../components/EmailField';
import List from '../../../components/List';
import SelectInput from '../../../components/SelectInput';
import TextField from '../../../components/TextField';
import {
  CompanyReinstatementStatus,
  PaymentStatus,
  WorkflowStatus,
} from './../../../config/constants';

const Filters = [
  <TextInput source="company" label="Company" alwaysOn />,
  <SelectInput
    source="state"
    label="State"
    choices={[
      { id: 'DELAWARE', name: 'Delaware' },
      { id: 'WYOMING', name: 'Wyoming' },
    ]}
    alwaysOn
  />,
  <SelectInput source="status" label="Status" choices={CompanyReinstatementStatus} alwaysOn />,
  <SelectInput
    source="workflowStatus"
    label="Workflow Status"
    choices={WorkflowStatus.map((d) => {
      return {
        ...d,
        name: d.label,
      };
    })}
    alwaysOn
  />,

  <SelectInput source="paymentStatus" label="Payment Status" choices={PaymentStatus} alwaysOn />,
];
const CompanyReinstatementListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const CompanyReinstatementList = () => {
  return (
    <List
      title="List of all Reinstatements"
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<CompanyReinstatementListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sort={{ field: 'id', order: 'DESC' }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <EmailField source="email" label="Email" />
        <TextField source="company" label="Company" />
        <TextField source="state" label="State" />

        <TextField source="paymentStatus" label="Payment Status" />
        <TextField source="stateFileNo" label="State File No" />

        <TextField source="status" label="Status" />
        <TextField source="workflowStatus" label="Workflow Status" />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CompanyReinstatementList;
