import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { WorkflowStatus } from './../../config/constants';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="id" label="Ref." alwaysOn />,
  <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
];

const FaxLogList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyId" label="Company Ref." />
        <ReferenceField source="companyId" reference="company-master-data" link="show">
          <TextField source="companyFullName" />
        </ReferenceField>
        <SelectField source="status" choices={WorkflowStatusArray} />
        <DateField
          source="createdDate"
          label="Started On"
          showTime
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        />

        <TextField source="faxNumber" />
        <TextField source="remarks" />

        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default FaxLogList;
