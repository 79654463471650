import styled from '@emotion/styled';
import React from 'react';
import { useState } from 'react';
import { Loading, useDataProvider } from 'react-admin';
import { ReportList } from './ReportList';

export const Reports = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [reports, setReports] = useState<any[]>([]);
  const [report, setReport] = useState<any>();
  const dataProvider = useDataProvider();
  const [masterdata, setMasterdata] = useState<any>();

  React.useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dataProvider.getList('reports/generation', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      }),
      dataProvider.get('reports/masterdata', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      }),
    ])
      .then((values) => {
        setReports(values[0].data);
        setMasterdata(values[1].data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  // React.useEffect(() => {
  //     setIsLoading(true);
  //     dataProvider.getList('reports/generation', {pagination: { "page": 1, "perPage": 1000 },sort: { "field": "id", "order": "ASC"},filter: {}})
  //         .then((data: any) => {
  //             setReports(data.data)
  //         }).catch((error: any) => {
  //             console.log("error", error);
  //             setError(true);
  //         }).finally(() => setIsLoading(false))
  // }, [])

  // React.useEffect(() => {
  //     setIsLoading(true);
  //     dataProvider.get('reports/masterdata', {pagination: { "page": 1, "perPage": 1000 },sort: { "field": "id", "order": "ASC"},filter: {}})
  //         .then((data: any) => {
  //             setMasterdata(data.data)
  //         }).catch((error: any) => {
  //             console.log("error", error);
  //             setError(true);
  //         }).finally(() => setIsLoading(false))
  // }, [])

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR</p>;
  }

  if (!reports) return null;

  return (
    <Wrapper>
      <HeaderWrap>
        <Header>Reports Manager</Header>
      </HeaderWrap>
      <BodyWrapper>
        {reports != null && reports.length ? (
          <ReportList reports={reports} masterdata={masterdata} />
        ) : (
          <NoDataFound>Sorry no reports found!!!</NoDataFound>
        )}
      </BodyWrapper>
    </Wrapper>
  );
};

export default Reports;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
`;

const NoDataFound = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444c66;
  text-align: center;
`;
