import * as React from 'react';
import { Create, SimpleForm, email, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Box, Typography } from '@mui/material';
import Separator from '../../components/Separator';
import SectionTitle from '../../components/SectionTitle';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { Row } from '../../components/Row';
import { validateForm } from './CompanyArchivedMasterValidateForm';

const CompanyArchivedMasterCreate = () => (
  <Create>
    <SimpleForm defaultValues={{}} validate={validateForm}>
      <Row>
        <div style={{ width: '100%' }}>
          <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceInput label="Company" source="companyId" reference="company-master-data">
                <AutocompleteInput optionText="companyFullName" />
              </ReferenceInput>
            </Box>
          </Box>
        </div>
      </Row>
      <Row>
        <div style={{ width: '50%' }}>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="reason" label="Reason" multiline resettable fullWidth isRequired />
          </Box>
        </div>
      </Row>

      <Separator />
    </SimpleForm>
  </Create>
);

export default CompanyArchivedMasterCreate;
