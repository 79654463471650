import EmployeeIcon from '@mui/icons-material/People';

import list from './BoiMasterList';
import view from './BoiMasterView';

const resource = {
  list,
  edit: view,
  icon: EmployeeIcon,
};

export default resource;
