import EmployeeIcon from '@mui/icons-material/People';

import EinServiceList from './FederalTaxSummaryList';
import EinServiceView from './FederalTaxSummaryView';
import FederalTaxRevisionList from './FederalTaxRevisionList';

const resource = {
  list: EinServiceList,
  revisionList: FederalTaxRevisionList,
  show: EinServiceView,
  icon: EmployeeIcon,
};

export default resource;
