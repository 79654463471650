import * as React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FunctionField,
  ReferenceField,
  ShowButton,
} from 'react-admin';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import NumberField from '../../components/NumberField';
import NumberInput from '../../components/NumberInput';
import ReferenceInput from '../../components/ReferenceInput';
import SelectInput from '../../components/SelectInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { Employee } from '../../types';

const Filters = [
  <ReferenceInput label="Franchise" source="franchiseId" reference="franchise-master" alwaysOn>
    <AutocompleteInput optionText="team" alwaysOn />
  </ReferenceInput>,
  <TextInput source="email" alwaysOn />,
  <TextInput source="companyName" label="Company" alwaysOn />,
  <SelectInput
    source="state"
    choices={[
      { id: 'DELAWARE', name: 'DELAWARE' },
      { id: 'WYOMING', name: 'WYOMING' },
    ]}
    alwaysOn
  />,
  <SelectInput
    source="subscriptionStatus"
    choices={[
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'CANCELED', name: 'CANCELED' },
      { id: 'PAST_DUE', name: 'PAST_DUE' },
      { id: 'INCOMPLETE_EXPIRED', name: 'INCOMPLETE_EXPIRED' },
    ]}
    alwaysOn
  />,
];

const SubscriptionMasterDataList = () => {
  const currentYear = new Date().getFullYear();
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyRef" label="Company Ref." />
        <ReferenceField
          source="companyRef"
          label="Company"
          reference="company-master-data"
          link="show"
        >
          {/* <TextField source="companyName" /> */}
          <FunctionField
            label="Company"
            render={(record: { companyName: any; companyNameType: any }) =>
              `${record.companyName} ${record.companyNameType}`
            }
          />
        </ReferenceField>
        <EmailField source="email" label="Email" />
        <ReferenceField source="franchiseId" reference="franchise-master" link="show">
          <TextField source="team" />
        </ReferenceField>
        <TextField source="fullName" label="Client" />
        <TextField source="plan" label="Subscription Plan" />
        <TextField source="state" label="State" />
        <TextField source="subscriptionStatus" label="Subscription Status" />
        <TextField source="country" label="Country" />
        <TextField source="industry" />

        <TextField source="website" />
        <DateField source="dateOfFormation" />
        <NumberField
          source="income3"
          label={`Income ${currentYear - 2}`}
          options={{ style: 'currency', currency: 'USD' }}
        />
        <NumberField
          source="income2"
          label={`Income ${currentYear - 1}`}
          options={{ style: 'currency', currency: 'USD' }}
        />
        <NumberField
          source="income1"
          label={`Income ${currentYear}`}
          options={{ style: 'currency', currency: 'USD' }}
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default SubscriptionMasterDataList;
