import styled from '@emotion/styled';
import React from 'react';
import { useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import FederalTaxChatHelp from './FederalTaxChatHelp';
import FederalTaxChatListCustom from './FederalTaxChatListCustom';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import { useMemo } from 'react';
import last from 'lodash.last';
import { useGetOne } from 'react-admin';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { RestartAltOutlined } from '@mui/icons-material';

export const FederalTaxChats = () => {
  const notify = useNotify();
  const [loading, setIsloading] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const [payLoad, setPayLoad] = useState([]);
  const [selectedChat, setSelectedChat] = useState();
  const [error, setError] = useState(false);
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [messageId, setMessageId] = React.useState(0);
  const [currentMessage, setCurrentMessage] = React.useState('');
  const [chatId, setChatId] = React.useState();

  React.useEffect(() => {
    setIsLoading(true);
    dataProvider
      .get(`federal-tax-chat/open/companies`)
      .then((data) => {
        setPayLoad(data.data);
        setSelectedChat(data.data[0]);
        loadChats(data.data[0].companyId);
      })
      .catch((error) => {
        console.log('error', error);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [reload]);

  const onChatSelect = (chat) => {
    console.log(chat);
    setSelectedChat(chat);
    loadChats(chat.companyId);
    getCompanyDetails(chat.companyId);
  };

  const getCompanyDetails = (companyRef) => {};

  const loadChats = (companyRef) => {
    setIsloading(true);
    const url = `federal-tax-chat/company/${companyRef}/chats`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        const _ms = data.map((m) => {
          const myMessage = m.sender === 'CLIENT';
          const chatId = m.id;
          return {
            id: chatId,
            content: {
              type: 'text',
              body: m.message,
            },
            date: m.createdDate,
            sender: myMessage,
          };
        });
        setData(_ms);
        if (_ms[0]?.id) {
          setChatId(_ms[0]?.id);
        }
        if (_ms.length > 0) {
          setMessageId(_ms[0].id);
        }
        setMessages(_ms.reverse());
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  };

  React.useEffect(() => {
    if (selectedChat?.companyId) {
      loadChats(selectedChat.companyId);
    }
  }, [selectedChat]);

  const addChat = (msg) => {
    let messages_ = messages;

    setMessages([
      ...messages,
      {
        content: {
          type: 'text',
          body: msg,
        },
        sender: false,
      },
    ]);
  };
  const onChangeOfInputMessage = (e) => {
    setCurrentMessage(e.target.value);
  };

  const onKeyPressOfInputMessage = (e) => {
    if (e.key == 'Enter') {
      if (!currentMessage) {
        return;
      }
      saveChatMessage(currentMessage);
      addChat(currentMessage);
      setCurrentMessage('');
    }
  };

  const saveChatMessage = (msg) => {
    if (!msg) {
      return;
    }
    const data = {
      message: msg,
      companyId: Number(selectedChat.companyId),
    };

    dataProvider
      .post(`federal-tax-chat/company/chats/${messageId}`, { data })
      .then((data, error) => {
        if (error) {
          throw new Error(error);
        }
        if (reload) {
          setReload(!reload);
        } else {
          setReload(!reload);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => {});
  };

  const reLoadChats = () => {
    if (reload) {
      setReload(!reload);
    } else {
      setReload(!reload);
    }
  };

  if (isLoading) {
    return null;
  }
  return (
    <Wrapper>
      <HeaderWrap>
        <Header>Federal Tax Chats</Header>
      </HeaderWrap>
      <BodyWrapper>
        {payLoad.length ? (
          <ChatWrapp>
            <FederalTaxChatListCustom chats={payLoad} onClick={onChatSelect} />
            <ChatWrapper>
              <div
                style={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  height: '50vh',
                }}
              >
                <MessageList messages={messages} />
              </div>
              <div
                style={{
                  padding: '16px 16px',
                  display: 'flex',
                  border: '1px solid #EEEEEE',
                  borderRadius: '4px',
                  width: '-webkit-fill-available',
                  display: 'flex',
                  gap: '1em',
                }}
              >
                <TextField
                  id="filled-basic"
                  label=""
                  variant="filled"
                  placeholder="Type here!"
                  fullWidth
                  value={currentMessage}
                  onChange={onChangeOfInputMessage}
                  onKeyPress={onKeyPressOfInputMessage}
                />
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={() => {
                    saveChatMessage(currentMessage);
                    if (currentMessage) {
                      addChat(currentMessage);
                      setCurrentMessage('');
                    }
                  }}
                >
                  Send
                </Button>
              </div>
            </ChatWrapper>
            {selectedChat.companyId && (
              <FederalTaxChatHelp
                companyId={selectedChat.companyId}
                chatId={chatId}
                onSuccess={reLoadChats}
              />
            )}
          </ChatWrapp>
        ) : (
          <NoDataFound>Sorry no open chats found!!!</NoDataFound>
        )}
      </BodyWrapper>
    </Wrapper>
  );
};

export default FederalTaxChats;

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  over-flow: auto;
  width: 50%;
  background: white;
  height: 70vh;
  padding: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
`;

const NoDataFound = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444c66;
  text-align: center;
`;

const ChatWrapp = styled.div`
  display: flex;
  // width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 74vh;
  background: #84b1ff2b;
  border-radius: 6px;
  padding: 12px 16px;
`;

const MessageList = (props) => {
  const senderGroupedMessages = useMemo(
    () =>
      props.messages.reduce((arr, m) => {
        const sender = m.sender;
        if (!last(arr)) {
          arr.push({
            messages: [m],
            sender,
          });
        } else if (last(arr)?.sender === sender) {
          last(arr)?.messages.push(m);
        } else {
          arr.push({
            messages: [m],
            sender,
          });
        }
        return arr;
      }, []),
    [props.messages]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      {senderGroupedMessages.map((mg) => (
        <MessageGroup key={mg.messages[0].id} {...mg} />
      ))}
    </div>
  );
};

const MessageGroup = (props) => {
  return (
    <MessageGroupWrapper>
      {props.sender ? (
        <div className="pt-2 pr-2">
          <AccountCircleIcon />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.8em',
          alignItems: !props.sender ? 'flex-end' : 'flex-start',
          width: '100%',
        }}
      >
        {props.messages.map((m, i) => (
          <Message key={m.id} {...m} last={i === props.messages.length - 1} />
        ))}
      </div>
    </MessageGroupWrapper>
  );
};

const MessageGroupWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: flex-start;
`;

const Message = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: props.last && !props.sender ? 'flex-end' : 'unset',
    }}
  >
    <div
      style={{
        maxWidth: '30rem',
        width: 'fit-content',
        border: '1px solid #EEEEEE',
        padding: '10px 16px',
        background: !props.sender ? '#d4eeda' : '#ffdada',
        borderRadius: '4px',
      }}
    >
      {props.content.type === 'text' ? <span>{props.content.body}</span> : null}
    </div>
    {props.last ? (
      <div
        style={{
          textAlign: !props.sender ? 'end' : 'start',
          marginTop: '4px',
          fontSize: '10px',
          color: '#929398',
        }}
      >
        {props.date}
      </div>
    ) : null}
  </div>
);
