import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';

import { Controller } from 'react-hook-form';

import { TextareaAutosize } from '@mui/base';
export const CustomTextAreaField = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} style={props.style}>
      <div>
        <Label>{props.label}</Label>
      </div>
      <div>
        <Controller
          render={({ field }) => <TextareaAutosize style={{ width: 300 }} {...field} {...props} />}
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
        />
      </div>
    </div>
  );
};
const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.5em;
`;
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '1em',
    'align-items': 'center',
    '& > *': {
      flex: '1 1 100%',
    },
    '& > label': {
      'margin-left': 'auto',
    },
    '& > div  >div': {
      width: '100%',
    },
  },
  item: {},
});
