import EmployeeIcon from '@mui/icons-material/People';

import KPIConfigParamCreate from './KPIConfigParamCreate';
import KPIConfigParamEdit from './KPIConfigParamEdit';
import KPIConfigParamView from './KPIConfigParamView';
import KPIConfigParamList from './KPIConfigParamList';

const resource = {
  list: KPIConfigParamList,
  create: KPIConfigParamCreate,
  edit: KPIConfigParamEdit,
  show: KPIConfigParamView,
  icon: EmployeeIcon,
};

export default resource;
