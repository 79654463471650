// in src/posts.js
import * as React from 'react';
import styled from '@emotion/styled';

import LoadingButton from '@mui/lab/LoadingButton';

const SubmitButton = ({ label, processing }) => {
  return (
    <StyledLoadingButton
      style={{ paddingRight: '2rem' }}
      loading={processing}
      variant="contained"
      loadingPosition="end"
      type="submit"
      fullWidth
    >
      {label}
    </StyledLoadingButton>
  );
};

export default SubmitButton;

const StyledLoadingButton = styled(LoadingButton)`
  background-color: #444c66;
  min-width: 270px;
  &:hover {
    background-color: #1a1a1a;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
