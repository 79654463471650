import BusinessIcon from '@mui/icons-material/Business';
import { CompanyMasterDataEdit } from './CompanyMasterDataEdit';
import { CompanyMasterDataGenericEdit } from './CompanyMasterDataGenericEdit';

import CompanyProductMasterList from './CompanyMasterDataList';
import HatchlyCompanyMasterDataList from './HatchlyCompanyMasterDataList';

const resource = {
  list: CompanyProductMasterList,
  icon: BusinessIcon,
  show: CompanyMasterDataEdit,
  genericShow: CompanyMasterDataGenericEdit,
  hatchlyList: HatchlyCompanyMasterDataList,
};

export default resource;
