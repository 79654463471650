import EmployeeIcon from '@mui/icons-material/People';

// import KPIConfigParamCreate from './KPIConfigParamCreate';
// import KPIConfigParamEdit from './KPIConfigParamEdit';
// import KPIConfigParamView from './KPIConfigParamView';
import PassportList from './PassportList';

const resource = {
  list: PassportList,
  // create: KPIConfigParamCreate,
  // edit : KPIConfigParamEdit,
  // show:KPIConfigParamView,
  icon: EmployeeIcon,
};

export default resource;
