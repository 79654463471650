import { Box, Tabs, Typography, Tab } from '@mui/material';
import React from 'react';
import { CompanyFormation } from './CompanyFormation';
import { KpiHome } from './KpiHome';
import { KpiParameterList } from './KpiParameterList';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import AssessmentIcon from '@mui/icons-material/Assessment';
import styled from '@emotion/styled';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export interface KpiTabsProps {
  kpis: any[];
  year: number;
}

export const KpiTabs = (props: KpiTabsProps) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 2, borderColor: '#1a1a1a' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab
            icon={<HomeIcon className="iconStyle" />}
            iconPosition="start"
            label="Financial Summary"
            {...a11yProps(0)}
          />
          <StyledTab
            icon={<StoreIcon className="iconStyle" />}
            iconPosition="start"
            label="Company Summary"
            {...a11yProps(1)}
          />
          <StyledTab
            icon={<AssessmentIcon className="iconStyle" />}
            iconPosition="start"
            label="KPI"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <KpiHome kpis={props.kpis} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CompanyFormation kpis={props.kpis} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <KpiParameterList kpis={props.kpis} year={props.year} />
      </TabPanel>
    </Box>
  );
};

const StyledTab = styled(Tab)`
  min-height: 69px;
  min-width: 164px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 4px 0px 0px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
