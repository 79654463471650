import FaxLogList from './FaxLogList';
import FaxLogEdit from './FaxLogEdit';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import FaxLogShow from './FaxLogShow';

const resource = {
  list: FaxLogList,
  show: FaxLogShow,
  edit: FaxLogEdit,
  icon: AttachEmailIcon,
};

export default resource;
