import * as React from 'react';
import styled from '@emotion/styled';
import { NumberInputProps } from 'react-admin';
import { NumberInput as NumberInputDefault } from 'react-admin';

export interface NumberInputProp extends NumberInputProps {}

const NumberInput = (props: NumberInputProp) => {
  return (
    <NumberInputDefault
      source={props.source}
      label={props.label}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      isRequired={props.isRequired}
    />
  );
};

export default NumberInput;
