import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import SubmitButton from '../SubmitButton';
import { CustomSelect } from '../../../../components/CustomSelect';

const OAChangesAmmendOAForm = ({ data, instance }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    // dataProvider.post(`company-legal-documents/operating-agreement/${companyId}`, { data })
    //     .then(({ data, error }) => {
    //         if (data?.key === 'status' && data?.value === 'ok') {
    //             notify("OA Deleted successfully", 'success');
    //             return;
    //         }
    //         if (error) {
    //             throw new Error(error);
    //         }
    //     }).catch(error => {
    //         notify(error.message || error.error || error.code || error, 'error');
    //     }).finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="What is required to make amendments to OA?"
            name="decisionToAmendOA"
            defaultValue={instance.decisionToAmendOA}
            options={[
              { label: 'Consent of members/member', value: 'MEMBER' },
              {
                label: 'Consent of members/member and board of managers',
                value: 'MEMBER_AND_BOARD',
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Update" />
        </Grid>
      </Grid>
    </form>
  );
};

export default OAChangesAmmendOAForm;
