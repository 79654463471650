import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export interface ConfirmationPopupProps {
  handleAction: () => void;
  onClose: () => void;
  message: string;
  title: string;
  action: string;
  open: boolean;
}
export const ConfirmationPopUp = (props: ConfirmationPopupProps) => {
  const handleClose = () => {
    props.onClose();
  };

  const handleAction = () => {
    props.onClose();
    props.handleAction();
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CLOSE</Button>
        <Button onClick={handleAction}>{props.action}</Button>
      </DialogActions>
    </Dialog>
  );
};
