import * as React from 'react';
import styled from '@emotion/styled';
import { SelectInputProps } from 'react-admin';
import { SelectInput as SelectInputDefault } from 'react-admin';

export interface SelectInputProp extends SelectInputProps {}

const SelectInput = (props: SelectInputProp) => {
  return (
    <SelectInputDefault
      source={props.source}
      label={props.label}
      choices={props.choices}
      fullWidth={props.fullWidth}
      isRequired={props.isRequired}
    />
  );
};

export default SelectInput;
