// in src/posts.js
import * as React from 'react';
import Button from '@mui/material/Button';
import { useNotify, useDataProvider } from 'react-admin';
import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { ConfirmationPopupDocumentDownload } from './ConfirmationPopupDocumentDownload';
import { ConfirmationPopUp } from './ConfirmationPopUp';

import BorderColorIcon from '@mui/icons-material/BorderColor';

const DocumentListItem = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openSignatureConfirmation, setOpenSignatureConfirmation] = React.useState(false);

  const [exists, setExists] = React.useState(props.exists);
  const [preview, setPreview] = React.useState(false);
  const [url, setUrl] = React.useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const allowSendingForSignature = (purpose) => {
    return ['EIN_NAME_CORRECTION_2848'].includes(purpose);
  };
  const handleDownload = () => {
    const _url = props.uuid
      ? `company-documents/download/uuid/${props.uuid}`
      : `company-documents/download/${props.companyId}/${props.purpose}`;
    dataProvider
      .get(_url, {})
      .then((payload) => {
        if (payload && payload.data && payload.data.url) {
          window.open(payload.data.url, '_blank');
        }
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const handleDelete = () => {
    dataProvider
      .post(`company-documents/delete/company/${props.companyId}/uuid/${props.uuid}`)
      .then((data) => {
        console.log(data);
        setExists(false);
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const handleUpload = ({ target }) => {
    const purpose = target.id;
    const acceptedFiles = target.files;
    if (!purpose) {
      notify('Unable to upload file', 'error');
    }
    if (!acceptedFiles) {
      return;
    }
    dataProvider
      .upload('company-documents/upload', {
        data: {
          files: acceptedFiles[0],
          companyId: props.companyId,
          purpose: purpose,
        },
      })
      .then((data) => {
        notify('File uploaded successfully', 'success');
        setExists(true);
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const previewDocument = (companyId, purpose) => {
    const _url = props.uuid
      ? `company-documents/download/uuid/${props.uuid}`
      : `company-documents/download/${props.companyId}/${props.purpose}`;

    dataProvider
      .get(_url, {})
      .then((payload) => {
        if (payload && payload.data && payload.data.url) {
          setUrl(payload.data.url);
          setPreview(true);
        }
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const sendDocumentForSignature = (id) => {
    dataProvider
      .post(`company-documents/signature/${id}`, {})
      .then((payload) => {
        if (payload && payload.data && payload.data.url) {
          notify('Initiated Signature workflow', 'success');
        }
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };

  function getDeletConfirm(companyId, purpose) {
    handleDelete();
  }

  function closePopup(close) {
    setOpen(false);
  }

  const openPopup = () => {
    setOpen(true);
  };
  return (
    <DocumentWrapper>
      <Wrapper>
        <Label>{props.text}</Label>
        <div>
          <input
            accept="application/zip, application/pdf, image/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id={props.purpose}
            style={{ display: 'none' }}
            onChange={handleUpload}
            type="file"
          />
          <label htmlFor={props.purpose}>
            <Tooltip title="Upload">
              <IconButton
                edge="end"
                aria-label="upload"
                component="span"
                disabled={exists || props.readOnly}
              >
                <UploadIcon className="documentIcons" />
              </IconButton>
            </Tooltip>
          </label>
          <Tooltip title="Download">
            <IconButton
              edge="end"
              aria-label="download"
              disabled={!exists}
              onClick={() => handleDownload(props.id, props.purpose, props.uuid)}
            >
              <DownloadIcon className="documentIcons" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              edge="end"
              aria-label="delete"
              disabled={!exists || props.readOnly}
              onClick={() => openPopup()}
            >
              <DeleteIcon className="documentIcons" />
            </IconButton>
          </Tooltip>
          <ConfirmationPopupDocumentDownload
            open={open}
            onClose={closePopup}
            confirmDelete={getDeletConfirm}
            companyId={props.companyId}
            purpose={props.purpose}
          />
          <Tooltip title="Preview">
            <IconButton
              edge="end"
              aria-label="preview"
              disabled={!exists}
              onClick={() => previewDocument(props.id, props.purpose)}
            >
              <PreviewIcon className="documentIcons" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Send for signature">
            <IconButton
              edge="end"
              aria-label="send for signature"
              disabled={!allowSendingForSignature(props.purpose)}
              onClick={() => setOpenSignatureConfirmation(true)}
            >
              <BorderColorIcon className="documentIcons" />
            </IconButton>
          </Tooltip>
          <ConfirmationPopUp
            open={openSignatureConfirmation}
            onClose={() => setOpenSignatureConfirmation(false)}
            handleAction={() => sendDocumentForSignature(props.id)}
            title="Send for signature"
            message="Are you sure you want to send this document for signature?"
            action="SEND"
          />
        </div>
      </Wrapper>
      {preview && (
        <PreviewWrapper>
          <div
            style={{
              paddingTop: '50px',
              position: 'relative',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
              alignItems: 'flex-end',
            }}
          >
            <>
              <StyledButton onClick={() => setPreview(false)} variant="contained">
                CLOSE PREVIEW
              </StyledButton>
              <iframe
                style={{
                  width: '100%',
                  height: '88vh',
                  borderRadius: '12px',
                  boxShadow: '0px 4px 40px rgb(0 0 0 / 12%)',
                }}
                src={url}
              />
            </>
          </div>
        </PreviewWrapper>
      )}
    </DocumentWrapper>
  );
};

const StyledButton = styled(Button)`
  background-color: #7c7c7c;
  cursor: pointer;
  &:hover {
    background-color: #1a1a1a;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;

const PreviewWrapper = styled.div``;
const Label = styled.div`
  // font-family: "Camphor";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  // color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export default DocumentListItem;
