// in src/posts.js
import * as React from 'react';
import { useForm } from 'react-hook-form';
import SubmitButton from './SubmitButton';

import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import { CustomDatePicker } from '../../../components/CustomDatePicker';

const DateOfFormationForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    /**
     * data contains dateOfFormation, whose value is coming as Date Object, with Timezone details.
     * In the backend, it is stored as LocalDate. So, we need to convert it to LocalDate.
     */
    const dateOfFormation = data.dateOfFormation;
    data.dateOfFormation = `${dateOfFormation.getFullYear()}-${String(
      dateOfFormation.getMonth() + 1
    ).padStart(2, '0')}-${String(dateOfFormation.getDate()).padStart(2, '0')}`;

    dataProvider
      .post(`update/company/${companyId}/state-filing/date-of-formation`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={8}>
          <CustomDatePicker
            control={control}
            label="Date Of Formation"
            name="dateOfFormation"
            defaultValue={data?.stateFilingDetails?.dateOfFormation || ''}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Update Formation" />
        </Grid>
      </Grid>
    </form>
  );
};

export default DateOfFormationForm;
