import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import SubmitButton from '../../forms/SubmitButton';
import { useNotify, useDataProvider } from 'react-admin';

import { CustomSelect } from '../../../../components/CustomSelect';
import { Label } from 'recharts';

const BookkeepingStatusForm = ({ data, isBookkeeper, disabled }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.companyId;
  const id = data?.id;

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`company_bookkeeping_master/company/${companyId}/books/${id}/status`, { data })
      .then(({ data, error }) => {
        if (data?.id) {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="Bookeeping Status"
            name="status"
            defaultValue={data.status}
            disabled={disabled}
            options={
              isBookkeeper
                ? [
                    { value: 'ACTIVE', label: 'Active' },
                    { value: 'CLOSED', label: 'Closed' },
                    { value: 'ONHOLD', label: 'Onhold' },
                  ]
                : [
                    { value: 'NOT_STARTED', label: 'Not Started' },
                    { value: 'WAITING_FOR_BANK_CONNECTION', label: 'Bank Not Connected' },
                    { value: 'WAITING_FOR_TRANSACTIONS', label: 'No Transactions Yet' },
                    { value: 'WAITING_FOR_PAYMENT', label: 'Not Paid' },
                    { value: 'ACTIVE', label: 'Active' },
                    { value: 'CLOSED', label: 'Closed' },
                    { value: 'ONHOLD', label: 'Onhold' },
                    { value: 'TERMINATED', label: 'Terminated' },
                    { value: 'COMPLETED_EXTERNALLY', label: 'Completed Externally' },
                  ]
            }
          />
        </Grid>
        <Grid item xs={3}>
          {!disabled && <SubmitButton processing={processing} label="Update Status" />}
        </Grid>
      </Grid>
    </form>
  );
};

export default BookkeepingStatusForm;
