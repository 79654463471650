import * as React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Skeleton from '@mui/material/Skeleton';
import DeleteIcon from '@mui/icons-material/Delete';
import SubmitButton from '../forms/SubmitButton';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import { useNotify, useDataProvider } from 'react-admin';
import { useForm } from 'react-hook-form';

import { CustomTextField } from '../../../components/CustomTextField';
import { CustomCheckBox } from '../../../components/CustomCheckBox';

const CompanyOwner = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [owner, setOwner] = React.useState(props.owner);
  const [isCompany, setIsCompany] = React.useState(!!props.owner.isCompany);

  const deleteOwner = (companyId, ownerId) => {
    if (!ownerId || !companyId) {
      notify('Owner is not saved');
      return;
    }
    setProcessing(true);
    dataProvider
      .post(`company-master-data/${companyId}/owner/${ownerId}/delete`, {})
      .then(({ data, error }) => {
        if (error) {
          throw new Error(error);
        }
        notify('Owner removed successfully', 'success');
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  const onSubmit = (values) => {
    console.log('onSubmit', {
      ...values,
    });
    if (owner.id) {
      //update
      dataProvider
        .update(`company-master-data/${props.companyId}/owner`, {
          id: owner.id,
          data: { ...values },
        })
        .then(({ data, error }) => {
          if (error) {
            throw new Error(error);
          }
          notify('Owner updated successfully', 'success');
        })
        .catch((error) => {
          notify(error.message || error.error || error.code || error, 'error');
        })
        .finally(() => setProcessing(false));
    } else {
      dataProvider
        .post(`company-master-data/${props.companyId}/owner`, { data: { ...values } })
        .then(({ data, error }) => {
          if (error) {
            throw new Error(error);
          }
          setOwner({
            ...owner,
            id: data.id,
          });
          notify('Owner Added successfully', 'success');
        })
        .catch((error) => {
          notify(error.message || error.error || error.code || error, 'error');
        })
        .finally(() => setProcessing(false));
    }
  };

  if (owner == null) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={2}>
            <CustomTextField
              control={control}
              name="firstName"
              defaultValue={owner.firstName}
              label={isCompany ? 'Company' : 'First Name'}
              required
            />
          </Grid>
          <Grid item xs={2}>
            {
              <CustomTextField
                control={control}
                name="lastName"
                defaultValue={owner.lastName}
                label="Last Name"
                disabled={isCompany}
              />
            }
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              control={control}
              name="proxyEmail"
              defaultValue={owner.proxyEmail}
              label="Proxy Email"
            />
          </Grid>
          <Grid item xs={1}>
            <CustomTextField
              control={control}
              name="percent"
              defaultValue={owner.percent}
              label="Percentage"
              required
            />
          </Grid>
          <Grid item xs={3} style={{ alignSelf: 'center' }}>
            <SubmitButton processing={processing} label={owner.id ? 'Update' : 'Add'} />
          </Grid>
          <Grid item xs={1} style={{ alignSelf: 'center' }}>
            <Tooltip title="Delete this Owner">
              <IconButton
                color="error"
                edge="end"
                aria-label="preview"
                onClick={() => deleteOwner(props.companyId, owner.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid>
            <CustomCheckBox
              control={control}
              name="isCompany"
              onClick={() => setIsCompany((isCompany) => !isCompany)}
              checked={isCompany}
              label="Is Owner a Company ?"
            />
          </Grid>
        </Grid>
      </form>
      <Divider />
    </>
  );
};

const CompanyOwners = (props) => {
  const [owners, setOwners] = React.useState(props.data.company?.owners);
  const addOwner = () => {
    setOwners([...owners, {}]);
  };
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={10} sm={10} md={10}>
          <Button variant="contained" onClick={() => addOwner()}>
            Add Owner
          </Button>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          {owners?.map((o) => (
            <CompanyOwner owner={o} companyId={props.data.company.id} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyOwners;
