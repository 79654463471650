import React from 'react';
import { Box, Tabs, Typography, Tab } from '@mui/material';
import styled from '@emotion/styled';
import { SubscriptionDetails } from './SubscriptionDetails';
import { Invoices } from './Invoices';

export interface SubscriptionHomeProps {
  data: any;
}
export const SubscriptionHome = (props: SubscriptionHomeProps) => {
  const [value, setValue] = React.useState(0);
  const isEditableAllowed = () => {
    return true;
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <BodyWrapper>
      <Box sx={{ width: '100%' }} aria-label="basic tabs ">
        <Box sx={{ borderBottom: 2, borderColor: '#1a1a1a' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons={false}
            style={{ height: '30px' }}
          >
            <StyledTab label="Subscription Details" {...a11yProps(0)} />
            <StyledTab label="Invoices" {...a11yProps(1)} disabled={!isEditableAllowed()} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SubscriptionDetails data={props.data.subscription} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Invoices data={props.data.invoices} />
        </TabPanel>
      </Box>
    </BodyWrapper>
  );
};

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 4px 0px 0px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
  // @media (min-width: 1200px){
  //     max-width:1100px;
  // }
  // @media (min-width: 1536px){
  //     max-width:fit-content;
  // }
`;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
