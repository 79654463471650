import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
export const CustomTextField = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} style={props.style}>
      {/*<div><label>{props.label}</label></div>*/}
      <div>
        <Controller
          render={({ field }) => <TextField {...field} {...props} />}
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '1em',
    'align-items': 'center',
    '& > *': {
      flex: '1 1 100%',
    },
    '& > label': {
      'margin-left': 'auto',
    },
    '& > div  >div': {
      width: '100%',
    },
  },
  item: {},
});

export const CustomTextFieldStyled = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} style={props.style}>
      {/*<div><label>{props.label}</label></div>*/}
      <div className="marginTop32">
        <Controller
          render={({ field }) => <TextField className="marginTop32" {...field} {...props} />}
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
          className="marginTop32"
        />
      </div>
    </div>
  );
};
