import styled from '@emotion/styled';
import { useState } from 'react';
import MediaQuery from 'react-responsive';
import { KPIMonthlyTotalProfitAndTotalRevenueStackedChart } from '../kpi-dashboard/kpi-charts/KPIMonthlyTotalProfitAndTotalRevenueStackedChart';
import { KPITotalRevenueStackedGraphWrapper } from '../kpi-dashboard/kpi-charts/KPITotalRevenueStackedGraphWrapper';
import { getFormatedYtdValue } from '../kpi-dashboard/KpiInstanceDetail';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import React from 'react';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessIcon from '@mui/icons-material/Business';
import { KPICompanyFormationGraphWrapper } from '../kpi-dashboard/kpi-charts/KPICompanyFormationGraphWrapper';
import { KPICompanyPnLGraphWrapper } from '../kpi-dashboard/kpi-charts/KPICompanyPnLGraphWrapper';

const ExpenseSummary = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const SpendWrap = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-between;
  gap: 1em;
  background: #ffffff;
  padding: 16px 32px;
  cursor: pointer;
  // &:hover {
  //     .iconStyle{
  //         fill: #669eff  !important;
  //         border-radius: 26px;
  //         padding: 8px;
  //         background: #005cff5e;
  //                 }
  //     background: #1a1a1a;
  //     color: white;
  //   }
`;

const SummaryWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  justify-content: flex-start;
  gap: 1em;
`;
const SummaryWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 1em;
`;
const GraphBg = styled.div`
  background: white;
  border-radius: 4px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  gap: 1em;
  width: 100%;
`;

export interface KpiHomeProps {
  kpis: any[];
}
/**
 * show financial summary
 */
export const KpiHome = (props: KpiHomeProps) => {
  const totalProfit = props.kpis.find((kpi: any) => kpi?.parameter === 'TOTAL_PROFIT').ytd;
  const totalRevenue = props.kpis.find((kpi: any) => kpi?.parameter === 'TOTAL_REVENUE').ytd;
  const totalAds = props.kpis.find((kpi: any) => kpi?.parameter === 'ADS_EXPENSES').ytd;
  const totalOps = props.kpis.find((kpi: any) => kpi?.parameter === 'OPERATIONS_EXPENSES').ytd;
  const totalCompanies = props.kpis
    .filter((kpi: any) => kpi?.parameter === 'NUMBER_OF_COMPANIES')
    .reduce((total: number, parameter: any) => total + parameter?.ytd, 0);
  //
  //const totalRevenue = props.kpis.find((kpi: any) => kpi?.parameter === "ADS_EXPENSES").ytd;

  const totalSpend = totalRevenue - totalProfit;

  return (
    <SummaryWrapper>
      <SummaryWrapperLeft>
        <ExpenseSummary>
          <SpendWrap>
            <TrendingUpIcon className="iconStyle" />
            <div style={{ fontSize: '0.8rem' }}>{'Total Revenue'}</div>
            <div style={{ fontWeight: '600' }}>
              {getFormatedYtdValue(totalRevenue, 'TOTAL_PROFIT')}
            </div>
          </SpendWrap>
          <SpendWrap>
            <TrendingDownIcon className="iconStyle" />
            <div style={{ fontSize: '0.8rem' }}>{'Total Spends'}</div>
            <div style={{ fontWeight: '600' }}>
              {getFormatedYtdValue(totalSpend, 'TOTAL_PROFIT')}
            </div>
          </SpendWrap>
          <SpendWrap>
            <AccountBalanceIcon className="iconStyle" />
            <div style={{ fontSize: '0.8rem' }}>{'Total Profit'}</div>
            <div style={{ fontWeight: '600' }}>
              {getFormatedYtdValue(totalProfit, 'TOTAL_PROFIT')}
            </div>
          </SpendWrap>
        </ExpenseSummary>
        <ExpenseSummary>
          <SpendWrap>
            <BusinessIcon className="iconStyle" />
            <div style={{ fontSize: '0.8rem' }}>{'Total Companies'}</div>
            <div style={{ fontWeight: '600' }}>{totalCompanies}</div>
          </SpendWrap>
          <SpendWrap>
            <ShoppingCartCheckoutIcon className="iconStyle" />
            <div style={{ fontSize: '0.8rem' }}>{'Total Ads'}</div>
            <div style={{ fontWeight: '600' }}>{getFormatedYtdValue(totalAds, 'AMOUNT')}</div>
          </SpendWrap>
          <SpendWrap>
            <ShoppingCartCheckoutIcon className="iconStyle" />
            <div style={{ fontSize: '0.8rem' }}>{'Total Ops'}</div>
            <div style={{ fontWeight: '600' }}>{getFormatedYtdValue(totalOps, 'AMOUNT')}</div>
          </SpendWrap>
        </ExpenseSummary>

        <GraphBg>
          <KPITotalRevenueStackedGraphWrapper kpis={props.kpis} />
        </GraphBg>
      </SummaryWrapperLeft>
      <SummaryWrapperRight>
        <GraphBg style={{ width: '100%' }}>
          <KPICompanyPnLGraphWrapper kpis={props.kpis} />
          {/* <KPIMonthlyTotalProfitAndTotalRevenueStackedChart kpis={props.kpis} /> */}
        </GraphBg>
        <GraphBg>
          <KPICompanyFormationGraphWrapper kpis={props.kpis} />
        </GraphBg>
      </SummaryWrapperRight>
    </SummaryWrapper>
  );
};
