import EmployeeIcon from '@mui/icons-material/People';

import CompanyReinstatementList from './CompanyReinstatementList';
import CompanyReinstatementEdit from './CompanyReinstatementEdit';
import CompanyReinstatementView from './CompanyReinstatementView';

const resource = {
  list: CompanyReinstatementList,
  edit: CompanyReinstatementEdit,
  show: CompanyReinstatementView,
  icon: EmployeeIcon,
};

export default resource;
