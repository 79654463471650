// in src/posts.js
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import { CustomTextField } from '../../../components/CustomTextField';
import { CustomSelect } from '../../../components/CustomSelect';
import SubmitButton from './SubmitButton';
import { CustomDatePicker } from '../../../components/CustomDatePicker';

const CompanyReceiptCreateForm = ({ companyId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    if (!data['paymentType']) {
      notify('Payment type not specified', 'error');
      setProcessing(false);
      return;
    }
    if (!data['amount']) {
      notify('Amount not specified', 'error');
      setProcessing(false);
      return;
    }
    if (!data['currency']) {
      notify('Currency not specified', 'error');
      setProcessing(false);
      return;
    }
    data['companyId'] = companyId;
    dataProvider
      .post(`company-receipts/create/${companyId}`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Receipt created successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={12} className="styledGrid">
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="Payment type *"
            name="paymentType"
            options={[
              { label: 'COMPANY_INCORPORATION', value: 'COMPANY_INCORPORATION' },
              { label: 'TELEPHONE_RECHARGE', value: 'TELEPHONE_RECHARGE' },
              { label: 'FEDERAL_TAX_ESTIMATED_AMOUNT', value: 'FEDERAL_TAX_ESTIMATED_AMOUNT' },
              { label: 'FRANCHISE_TAX_ESTIMATED_AMOUNT', value: 'FRANCHISE_TAX_ESTIMATED_AMOUNT' },
              { label: 'REGISTERED_AGENT_YEARLY_DUE', value: 'REGISTERED_AGENT_YEARLY_DUE' },
              { label: 'ACTUAL_FEDERAL_TAX_OWED', value: 'ACTUAL_FEDERAL_TAX_OWED' },
              {
                label: 'EXISTING_COMPANY_ONBOARDING_PAYMENT',
                value: 'EXISTING_COMPANY_ONBOARDING_PAYMENT',
              },
              { label: 'OTHERS', value: 'OTHERS' },
              { label: 'STATE_FORMATION_FEE', value: 'STATE_FORMATION_FEE' },
              { label: 'EIN_FEE', value: 'EIN_FEE' },
              { label: 'BANK_ACCOUNT_FEE', value: 'BANK_ACCOUNT_FEE' },
              { label: 'VIRTUAL_PHONE_NUMBER_FEE', value: 'VIRTUAL_PHONE_NUMBER_FEE' },
              { label: 'FEDERAL_TAX_EXTENSION_FEE', value: 'FEDERAL_TAX_EXTENSION_FEE' },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField control={control} label="Amount *" name="amount" />
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="Currency *"
            name="currency"
            options={[{ label: 'USD', value: 'USD' }]}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomDatePicker control={control} label="Date" name="receiptDateTime" />
        </Grid>
        <Grid item xs={7}>
          <SubmitButton processing={processing} label="Create receipt" />
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyReceiptCreateForm;
