import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  SelectInput,
  TopToolbar,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';

const EmailRegistrationListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};

const Filters = [
  <BooleanInput source="valid" label="Active" alwaysOn />,
  <TextInput source="email" label="Email" alwaysOn />,
];

const EmailRegistrationList = () => {
  return (
    <List
      filters={Filters}
      filterDefaultValues={{ valid: 'true' }}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<EmailRegistrationListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="email" label="Email" />
        <BooleanField source="valid" label="Valid" />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default EmailRegistrationList;
