import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from '@emotion/styled';
import React from 'react';
import dataProvider from '../../config/dataProvider';
import { useNotify, usePermissions } from 'react-admin';
import { BoiStatus } from '../../config/constants';
import { hasPermission } from '../../lib/SGHelper';
import { BoiMasterUpload } from './BoiMasterUpload';

const BoiStatusArray = BoiStatus.filter((f) =>
  ['IN_PROGRESS', 'WAITING_FOR_INFO', 'CONFIRMED', 'REJECTED'].includes(f.id)
).map((f) => ({
  id: f.id,
  name: f.name,
}));

export interface BE12SurveyStatusProps {
  data: any;
  onSuccess?: () => void;
}
export const BoiStatusField = (props: BE12SurveyStatusProps) => {
  const [status, setStatus] = useState(props.data?.boiMaster?.status);
  const [processing, setProcessing] = useState(false);
  const id = props.data?.id;
  const companyId = props.data?.boiMaster?.companyId;
  const notify = useNotify();
  const [upload, setUpload] = useState(false);
  const { permissions } = usePermissions();

  const handleUploadSuccess = () => {
    setUpload(true);

    if (typeof props.onSuccess === 'function') {
      props.onSuccess();
    }
  };
  const handleUploadError = () => {
    setUpload(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const submit = (_data: any) => {
    setProcessing(true);
    _data = {
      id: props.data?.id,
      status: status,
    };
    dataProvider
      .post(`boi_master/${props.data?.id}`, { data: _data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', { type: 'success' });
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Wrapper>
      <StatusUpdate>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={status}
            label="Status"
            onChange={handleChange}
          >
            {BoiStatusArray.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <LoadingButton
          loading={processing}
          variant="contained"
          loadingPosition="end"
          type="submit"
          fullWidth
          onClick={() => submit(props.data)}
        >
          Update Status
        </LoadingButton>
      </StatusUpdate>
      <div style={{ width: '100%', display: 'flex', gap: '4em', justifyContent: 'space-between' }}>
        <div style={{ width: '50%', display: 'flex', gap: '1em', flexDirection: 'column' }}>
          <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
            Upload BOI Filing Document
          </div>
          <BoiMasterUpload
            purpose="BOI_FILING_CONFIRMATION"
            label="Boi Filing document"
            onSuccess={handleUploadSuccess}
            onError={handleUploadError}
            year={new Date().getFullYear()}
            companyId={companyId}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
`;

const StatusUpdate = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 50%;
`;
