import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  FunctionField,
  RaRecord,
  ReferenceField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import BooleanField from '../../../components/BooleanField';
import BooleanInput from '../../../components/BooleanInput';
import DateField from '../../../components/DateField';
import EmailField from '../../../components/EmailField';
import List from '../../../components/List';
import SelectInput from '../../../components/SelectInput';
import TextField from '../../../components/TextField';
import {
  CompanyDissolutionStatus,
  FederalTaxStatus,
  PaymentStatus,
} from './../../../config/constants';
import { hasPermission } from '../../../lib/SGHelper';
const FederalTaxStatusArray = FederalTaxStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const Filters = [
  <TextInput source="company" label="Company" alwaysOn />,
  <SelectInput
    source="state"
    label="State"
    choices={[
      { id: 'DELAWARE', name: 'Delaware' },
      { id: 'WYOMING', name: 'Wyoming' },
    ]}
    alwaysOn
  />,
  <SelectInput source="status" label="Status" choices={CompanyDissolutionStatus} alwaysOn />,
  <SelectInput source="paymentStatus" label="Payment Status" choices={PaymentStatus} alwaysOn />,
];

const CPAFilters = [
  <TextInput source="company" label="Company" alwaysOn />,
  <SelectInput
    source="state"
    label="State"
    choices={[
      { id: 'DELAWARE', name: 'Delaware' },
      { id: 'WYOMING', name: 'Wyoming' },
    ]}
    alwaysOn
  />,
  <SelectInput source="status" label="Status" choices={CompanyDissolutionStatus} alwaysOn />,
  <SelectInput
    source="finalTaxStatus"
    label="Final Tax Status"
    choices={FederalTaxStatusArray}
    alwaysOn
  />,
];

const CompanyDissolutionListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const CompanyDissolutionList = () => {
  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  return (
    <List
      title={'"List of all Reinstatements"'}
      filters={isCPA ? CPAFilters : Filters}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<CompanyDissolutionListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sort={{ field: 'id', order: 'DESC' }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        {!isCPA && <EmailField source="email" label="Email" />}
        <ReferenceField
          source="companyId"
          label="Company"
          reference="company-master-data"
          link={(record: RaRecord, reference: string) => {
            return `/${reference}/${record.companyId}?tab=federal-tax`;
          }}
        >
          <FunctionField
            label="Company"
            render={(record: { companyName: any; companyNameType: any }) =>
              `${record.companyName} ${record.companyNameType}`
            }
          />
        </ReferenceField>
        <TextField source="state" label="State" />

        {!isCPA && <TextField source="paymentStatus" label="Payment Status" />}
        <TextField source="status" label="Status" />
        <TextField source="finalTaxStatus" label="Final Tax Status" />

        {!isCPA && <BooleanField source="signed" label="Signed" />}

        {!isCPA && <EditButton />}
      </Datagrid>
    </List>
  );
};

export default CompanyDissolutionList;
