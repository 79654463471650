import { email } from 'react-admin';

export const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required';
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required';
  }
  if (!values.designation) {
    errors.designation = 'ra.validation.required';
  }
  if (!values.tel) {
    errors.tel = 'ra.validation.required';
  }
  if (!values.startDate) {
    errors.startDate = 'ra.validation.required';
  }
  if (!values.email) {
    errors.email = 'ra.validation.required';
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  if (!values.email2) {
    errors.email2 = 'ra.validation.required';
  } else {
    const error = email()(values.email2);
    if (error) {
      errors.email2 = error;
    }
  }
  return errors;
};
