//import CompanyArchivedMasterCreate from './CompanyArchivedMasterCreate';
import MailReceivedLogList from './MailReceivedLogList';
import MailReceivedLogsEdit from './MailReceivedLogsEdit';
// import CompanyArchivedMasterView from './CompanyArchivedMasterView';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';

const resource = {
  list: MailReceivedLogList,
  // create: CompanyArchivedMasterCreate,
  edit: MailReceivedLogsEdit,
  // show:CompanyArchivedMasterView,
  icon: AttachEmailIcon,
};

export default resource;
