import * as React from 'react';
import { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';
import { useTranslate, DashboardMenuItem, MenuItemLink, useSidebarState } from 'react-admin';

import SubMenu, { Props } from './SubMenu';
import { isPropertySignature } from 'typescript';
import { Icon } from '@mui/material';

export interface MenuProps extends Props {
  dense: boolean;
  subitems?: Array<{ to: string; label: string; icon: ReactElement }>;
}

const MenuItem = (props: MenuProps) => {
  return (
    <SubMenu
      handleToggle={props.handleToggle}
      isOpen={props.isOpen}
      name={props.name}
      icon={props.icon}
      dense={props.dense}
    >
      {props.subitems && props.subitems.length
        ? props.subitems.map(({ to, label, icon }) => {
            return (
              <MenuItemLink
                to={to}
                state={{ _scrollToTop: true }}
                primaryText={label}
                leftIcon={icon}
                dense={props.dense}
              />
            );
          })
        : null}
    </SubMenu>
  );
};

export default MenuItem;
