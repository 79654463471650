import EmployeeIcon from '@mui/icons-material/People';

import CompanyBookkeepingChatList from './CompanyBookkeepingChatList';
import CompanyBookkeepingChatShow from './CompanyBookkeepingChatShow';

const resource = {
  list: CompanyBookkeepingChatList,
  show: CompanyBookkeepingChatShow,
  icon: EmployeeIcon,
};

export default resource;
