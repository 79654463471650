export const amountFormat = (value: number) =>
  isNaN(value)
    ? ''
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value);

export const percentageFormat = (value: number) =>
  isNaN(value) ? '' : new Intl.NumberFormat('en-US', { style: 'percent' }).format(value / 100);
