import React from 'react';
import { Box, Typography } from '@mui/material';

export const EmptyPage = () => (
  <Box textAlign="center" m={1}>
    <Typography variant="h4" paragraph>
      No data available
    </Typography>
  </Box>
);
