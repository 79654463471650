import EmployeeIcon from '@mui/icons-material/People';

import ActionItemList from './ActionItemList';
import ActionItemEdit from './ActionItemEdit';
import ActionItemShow from './ActionItemView';

const resource = {
  list: ActionItemList,
  edit: ActionItemEdit,
  show: ActionItemShow,
  icon: EmployeeIcon,
};

export default resource;
