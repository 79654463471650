import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  TextInput,
  TopToolbar,
  downloadCSV,
} from 'react-admin';
import BooleanField from '../../components/BooleanField';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import SelectInput from '../../components/SelectInput';
import TextField from '../../components/TextField';
import jsonExport from 'jsonexport/dist';

const Filters = [
  <TextInput source="email" label="Email" alwaysOn />,
  <TextInput source="phone" label="Phone" alwaysOn />,
  <TextInput source="firstName" label="First Name" alwaysOn />,
  <TextInput source="lastName" label="Last Name" alwaysOn />,
];

const exporter = (posts: any) => {
  const postsForExport = posts.map((post: any) => {
    const { uuid, ...rest } = post;
    rest.phone = '' + post.phone;
    return rest;
  });
  jsonExport(
    postsForExport,
    {
      headers: ['id', 'email', 'firstName', 'lastName', 'phone'], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'tax-estimator-list'); // download as 'posts.csv` file
    }
  );
};

const TaxEstimatorAddonListActions = () => {
  return (
    <TopToolbar>
      <ExportButton exporter={exporter} />
    </TopToolbar>
  );
};

const TaxEstimatorAddonList = () => {
  return (
    <List
      title={'StartGlobal Tax Estimator Addon'}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<TaxEstimatorAddonListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sort={{ field: 'id', order: 'DESC' }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <EmailField source="email" label="Email" />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <TextField source="phone" label="Phone" />
      </Datagrid>
    </List>
  );
};

export default TaxEstimatorAddonList;
