import * as React from 'react';
import { AppBar, Logout, UserMenu, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from '@emotion/styled';

const CustomUserMenu = () => (
  <UserMenu>
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  return (
    <AppBar
      {...props}
      color="secondary"
      elevation={1}
      userMenu={<CustomUserMenu />}
      sx={{
        '& .RaAppBar-toolbar': {
          backgroundColor: '#1a1a1a',
        },
      }}
    >
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />
      {/* {isLargeEnough && <Logo />} */}
      {isLargeEnough && (
        <LogoWrapper>
          <svg
            width="30"
            height="30"
            viewBox="0 0 61 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.7828 8.17858L20.7869 8.17879V8.17215C20.7856 8.17433 20.7842 8.17646 20.7828 8.17858ZM20.7828 8.17858L20.7774 8.17838C20.6097 8.17195 20.445 8.16557 20.2771 8.16557C13.4948 8.16557 7.99854 13.6709 7.99854 20.4572C7.99854 27.2434 13.4981 32.7487 20.2771 32.7487C20.4482 32.7487 20.6192 32.7421 20.7869 32.7356C18.5174 29.1861 17.1951 24.9747 17.1951 20.4539C17.1951 15.9357 18.5158 11.7268 20.7828 8.17858ZM32.559 20.4572C32.559 23.2 31.661 25.7353 30.1414 27.7801C28.6218 25.732 27.7206 23.1967 27.7206 20.4572C27.7206 17.7176 28.6218 15.1823 30.1414 13.1342C31.661 15.179 32.559 17.7144 32.559 20.4572Z"
              fill="#91B9FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.2804 35.3994C21.0731 35.3994 21.8526 35.3367 22.6157 35.2152V35.2116C23.9709 36.8119 25.5431 38.2279 27.2864 39.4064C25.1024 40.22 22.7407 40.6644 20.2771 40.6644C9.14648 40.6644 0.0913086 31.5995 0.0913086 20.4572C0.0913086 9.31476 9.14648 0.25 20.2738 0.25C22.7374 0.25 25.0991 0.694488 27.2831 1.50783C25.5398 2.68658 23.9709 4.10246 22.6124 5.70267C21.8526 5.58086 21.0731 5.51829 20.2771 5.51829C12.0476 5.51829 5.35731 12.2222 5.35731 20.4605C5.35731 28.6988 12.0508 35.3994 20.2804 35.3994ZM40.0123 40.6675C28.8817 40.6675 19.8265 31.6031 19.8265 20.4605C19.8265 9.31805 28.8817 0.25 40.0123 0.25C51.1432 0.25 60.198 9.31476 60.198 20.4572C60.198 31.5995 51.1432 40.6644 40.0123 40.6644V40.6675ZM40.0123 5.51829C31.7827 5.51829 25.0892 12.2189 25.0892 20.4572C25.0892 28.6955 31.7827 35.3959 40.0123 35.3959C48.2419 35.3959 54.9355 28.6955 54.9355 20.4572C54.9355 12.2189 48.2419 5.51829 40.0123 5.51829ZM36.631 8.62996C34.8844 9.13044 33.2957 10.0096 31.9571 11.1752C33.9865 13.727 35.2002 16.9538 35.2002 20.4573C35.2002 23.9607 33.9832 27.1908 31.9571 29.7393C33.2925 30.9014 34.8812 31.7808 36.631 32.2845C39.0387 28.9557 40.4662 24.8694 40.4662 20.4573C40.4662 16.0451 39.042 11.9556 36.631 8.62996Z"
              fill="white"
            />
          </svg>
          <LogoText>StartGlobal Admin</LogoText>
        </LogoWrapper>
      )}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default CustomAppBar;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
`;
const LogoText = styled.div`
  font-weight: bold;
  font-size: medium;
  letter-spacing: 1px;
`;
