import * as React from 'react';
import { Datagrid, EditButton, SelectInput } from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { KPIParameterType } from './../../config/constants';
import { KPIState } from './../../config/constants';
import { KPIType } from './../../config/constants';

const KPIParameterTypeArray = KPIParameterType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPIStateArray = KPIState.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPITypeArray = KPIType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <BooleanInput source="isValid" label="Active" alwaysOn />,
  <SelectInput source="type" label="KPI Type" choices={KPITypeArray} alwaysOn />,
  <SelectInput source="state" label="State" choices={KPIStateArray} alwaysOn />,
  <SelectInput
    source="parameter"
    label="Parameter Type"
    choices={KPIParameterTypeArray}
    alwaysOn
  />,
];

const KPIConfigParamList = () => {
  return (
    <List
      filters={Filters}
      filterDefaultValues={{ isValid: 'true' }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="type" label="KPI Type" />
        <TextField source="state" label="State" />
        <TextField source="parameter" label="Parameter Type" />
        <TextField source="description" label="Description" />
        <TextField source="value" label="Value" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default KPIConfigParamList;
