import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useNotify, useDataProvider } from 'react-admin';
import styled from '@emotion/styled';
import Select from 'react-select';

import { TextField, DateField, FunctionField, Labeled } from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import SubmitButton from '../forms/SubmitButton';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
import { Controller } from 'react-hook-form';

const ServiceProviders = [
  { value: 'STATE_FILING_DELAWARE_INC', label: 'HBS', type: 'STATE_FILING_SERVICE' },
  { value: 'STATE_FILING_WYOMING_AGENTS', label: 'Wyoming Agents', type: 'STATE_FILING_SERVICE' },
  { value: 'STATE_FILING_STARTGLOBAL', label: 'Startglobal', type: 'STATE_FILING_SERVICE' },
  { value: 'REGISTERED_AGENT_DELAWAREINC', label: 'HBS', type: 'REGISTERED_AGENT_SERVICE' },
  {
    value: 'REGISTERED_AGENT_WYOMINGAGENTS',
    label: 'Wyoming Agents',
    type: 'REGISTERED_AGENT_SERVICE',
  },
  {
    value: 'REGISTERED_AGENT_NORTHWEST_AGENTS',
    label: 'NorthWest Agents',
    type: 'REGISTERED_AGENT_SERVICE',
  },
  { value: 'EIN_STARTGLOBAL', label: 'Startglobal', type: 'EIN_SERVICE' },
  { value: 'EIN_USACORPS', label: 'USACorps', type: 'EIN_SERVICE' },
  { value: 'BANKING_MERCURY', label: 'Mercury', type: 'BANKING_SERVICE' },
  { value: 'BANKING_RELAYFI', label: 'Relayfi', type: 'BANKING_SERVICE' },
  { value: 'VIRTUAL_PHONE_NUMBER_SONTEL', label: 'Sonetel', type: 'VIRTUAL_PHONE_NUMBER' },
  { value: 'EIN_STARTGLOBAL_FAX', label: 'Fax', type: 'EIN_SERVICE' },
];
const WorkflowStatusArray = [
  { value: 'NOT_STARTED', label: 'NOT_STARTED' },
  { value: 'STARTED', label: 'STARTED' },
  { value: 'PROCESSED', label: 'PROCESSED' },
  { value: 'REJECTED', label: 'REJECTED' },
  { value: 'ERROR', label: 'ERROR' },
  { value: 'ONHOLD', label: 'ONHOLD' },
];
const CurrentStatusArray = [
  { value: 'NOT_STARTED', label: 'NOT_STARTED' },
  { value: 'STARTED', label: 'STARTED' },
  { value: 'WAITING_FOR_DELIVERY_FROM_PROVIDER', label: 'Waiting for Delivery' },
  { value: 'RECEIVED_DELIVERY_FROM_PROVIDER', label: 'Received' },
  { value: 'PREPARING_FOR_CLIENT_DELIVERY', label: 'Preparing Client Delivery' },
  { value: 'DELIVERED_TO_CLIENT', label: 'Delivered' },
];

export const CompanyServices = ({ data }) => {
  const [loading, setIsloading] = React.useState(true);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [value, setValue] = React.useState(0);
  const [workflows, setWorkflows] = React.useState([]);
  // const getWorkflowHeader = type =>  workflows.find(wf => wf.type === type);

  React.useEffect(() => {
    const url = `update/company/${data.id}/workflow`;
    dataProvider
      .get(url, { data })
      .then(({ data, error }) => {
        console.log('workfloe', data);
        if (error) {
          throw new Error(error);
        }
        setWorkflows(data);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={10} sm={10} md={10}>
          {loading ? (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          ) : (
            <CompanyProviders data={data} workflows={workflows} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const CompanyProviders = ({ data, workflows }) => {
  const getWorkflowHeader = (type) => workflows.find((wf) => wf.type === type);
  const getWorkflowHeaderByTypeAndProvider = (type, provider) =>
    workflows.find((wf) => wf.type === type && wf.provider === provider);
  console.log('workflows', workflows);
  if (workflows.length === 0) {
    return <div style={{ alignSelf: 'center' }}>No active services found !!!</div>;
  }
  return (
    <>
      <CompanyProvider workflow={getWorkflowHeader('STATE_FILING_SERVICE')} label="State Filing" />
      <CompanyProvider
        workflow={getWorkflowHeader('REGISTERED_AGENT_SERVICE')}
        label="Registered Agent"
      />
      <CompanyProvider workflow={getWorkflowHeader('EIN_SERVICE')} label="Ein Service" />
      <CompanyProvider workflow={getWorkflowHeader('VIRTUAL_PHONE_NUMBER')} label="Virtual Phone" />
      <CompanyProvider
        workflow={getWorkflowHeaderByTypeAndProvider('BANKING_SERVICE', 'BANKING_RELAYFI')}
        label="Relayfi"
      />
      <CompanyProvider
        workflow={getWorkflowHeaderByTypeAndProvider('BANKING_SERVICE', 'BANKING_MERCURY')}
        label="Mercury"
      />
    </>
  );
};

const CompanyProvider = ({ workflow, label }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const companyId = workflow?.companyId;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (values) => {
    /**
     * Only changed values are coming in values. This is a temporary workaround
     */
    const data = {};
    data['provider'] = values?.provider?.value || workflow.provider;
    data['status'] = values?.status?.value || workflow.status;
    data['currentStatus'] = values?.currentStatus?.value || workflow.currentStatus;
    setProcessing(true);
    dataProvider
      .post(`update/company/${companyId}/workflow/${workflow.id}`, {
        data: { ...workflow, ...data },
      })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };

  if (!workflow || !workflow.id) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="subtitle1" style={{ marginTop: 20 }}>
            {label}
          </Typography>
        </Grid>

        <Grid item xs={2} style={{ alignSelf: 'flex-end' }}>
          <CustomSelect2
            control={control}
            name="provider"
            defaultValue={workflow.provider}
            label="Provider"
            required
            option={ServiceProviders.filter((s) => s.type === workflow.type)}
          />
        </Grid>
        <Grid item xs={2} style={{ alignSelf: 'flex-end' }}>
          <CustomSelect2
            control={control}
            name="status"
            defaultValue={workflow.status}
            label="Status"
            required
            option={WorkflowStatusArray}
          />
        </Grid>
        <Grid item xs={4} style={{ alignSelf: 'flex-end' }}>
          <CustomSelect2
            control={control}
            name="currentStatus"
            defaultValue={workflow.currentStatus}
            label="Current Status"
            required
            option={CurrentStatusArray}
          />
        </Grid>
        <Grid item xs={2} style={{ alignSelf: 'flex-end' }}>
          <SubmitButton processing={processing} label="Update" fullWidth />
        </Grid>
        {workflow.status === 'STARTED' && workflow.remarks && (
          <Grid item xs={12}>
            <Alert severity="error">{workflow.remarks}</Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export const CustomSelect2 = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl required={props.required} sx={{ m: 1, minWidth: 0.9 }}>
      <Label>{props.label}</Label>
      <Controller
        render={({ field }) => (
          // <Select {...field} {...props}>
          //     {props.options.map( ({value, displayName}) =>  <MenuItem value={value}>{displayName}</MenuItem>)}
          // </Select>
          <Select
            {...field}
            {...props}
            options={props.option}
            defaultValue={props.option.filter((option) => option.value === props.defaultValue)}
          ></Select>
        )}
        name={props.name}
        control={props.control}
      />
      {/* Issue of using mui select  */}
      {/* <InputLabel id="demo-controlled-open-select-label">{props.label}</InputLabel> */}
      {/* <Controller
      render={({ field }) => (
          <Select {...field} {...props}
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            //value={props.defaultValue}
            //defaultValue={props.defaultValue}
            //value={value}
            label={props.label}
            //onChange={handleChange}
          >
            {props.option?.map(o => <MenuItem value={o.value}>{o.text}</MenuItem>)}
          </Select>
          )} 
      name={props.name}
      control={props.control}
      /> */}
    </FormControl>
  );
};

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.5em;
`;
