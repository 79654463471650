import * as React from 'react';
import styled from '@emotion/styled';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  EditButton,
  TopToolbar,
  ListButton,
} from 'react-admin';
import { Grid } from '@mui/material';

import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import LabelField from '../../components/LabelField';
import { LabelType } from '../../config/LabelType';
import { ListHeaderActionsView } from '../../components/ListHeaderActions';

const EmailRegistrationView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderActionsView />}>
      <TabbedShowLayout>
        <Tab label="Email Registration Details">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SimpleShowLayout>
                <EmailRegistrationDetails>
                  <LabelField source="id" label="Ref." fullWidth labelType={LabelType.TEXT_FIELD} />
                  <LabelField
                    source="email"
                    label="Email"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                </EmailRegistrationDetails>
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const EmailRegistrationDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default EmailRegistrationView;
