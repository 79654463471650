import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  AutocompleteInput,
  useEditController,
  BooleanField,
  usePermissions,
} from 'react-admin';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../../components/SectionTitle';
import Separator from '../../../components/Separator';
import TextInput from '../../../components/TextInput';
import DateInput from '../../../components/DateInput';
import { ListHeaderActionsEdit } from '../../../components/ListHeaderActions';
import { Employee } from '../../../types';
import NumberInput from '../../../components/NumberInput';
import { Currency } from '../../../config/constants';
import ReferenceInput from '../../../components/ReferenceInput';
import SelectInput from '../../../components/SelectInput';
import { hasPermission, transformDate } from '../../../lib/SGHelper';
import BooleanInput from '../../../components/BooleanInput';
import { CompanyDissolutionStatus, FederalTaxStatus } from './../../../config/constants';
import { Grid } from '@mui/material';
import DocumentListItem from '../../../components/DocumentListItem';

const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));
const transform = (data: any) => {
  console.log('data', data);
  const modifiedData = {
    id: data.id,
    status: data.data.status,
    finalTaxStatus: data.data.finalTaxStatus,
    notes: data.data?.notes,
  };
  console.log('modifiedData', modifiedData);

  return modifiedData;
};
const CompanyDissolutionEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useEditController(props);

  const signedDocument = record?.documents?.find((d: any) => d.purpose === 'DISSOLUTION_SIGNED');
  const confirmationDocument = record?.documents?.find(
    (d: any) => d.purpose === 'DISSOLUTION_CONFIRMATION'
  );

  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />} transform={transform}>
      <SimpleForm>
        <EmployeeWrapper>
          <BasicDetais>
            <SectionTitle label="Details" />
            <TextInput source="data.id" label="Ref." disabled />
            <Box>
              {!isCPA && <TextInput type="email" source="email" label="Email" fullWidth disabled />}
              <TextInput source="data.company" label="Company" fullWidth disabled />
            </Box>
            <Box>
              <TextInput source="data.state" label="State" fullWidth disabled />
              <TextInput source="data.paymentStatus" label="Payment Status" fullWidth disabled />
            </Box>
            <Box>
              <SelectInput source="data.status" label="Status" choices={CompanyDissolutionStatus} />
              <SelectInput
                source="data.finalTaxStatus"
                label="Final Tax Status"
                choices={FederalTaxStatus.map((f) => {
                  return {
                    id: f.id,
                    name: f.label,
                  };
                })}
              />
            </Box>
            <Box>
              <TextInput source="data.notes" multiline fullWidth />
            </Box>
          </BasicDetais>
        </EmployeeWrapper>
        <Separator />
        <Grid container spacing={2}>
          <Grid ml={6} item xs={6}>
            {record && signedDocument && (
              <>
                <DocumentListItem
                  companyId={record?.data.companyId}
                  purpose="DISSOLUTION_SIGNED"
                  text="Signed Document"
                  exists={!!signedDocument}
                  readOnly
                  uuid={signedDocument?.uuid}
                />
              </>
            )}

            <>
              <DocumentListItem
                companyId={record?.data?.companyId}
                purpose="DISSOLUTION_CONFIRMATION"
                text="Confirmation Document"
                exists={!!confirmationDocument}
                uuid={signedDocument?.uuid}
              />
            </>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const EmployeeWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Box = styled.div`
  display: flex;
  gap: 1em;
`;
export default CompanyDissolutionEdit;
