import EmployeeIcon from '@mui/icons-material/People';

import BE12SurveyList from './BE12SurveyList';
import BE12SurveyView from './BE12SurveyView';

const resource = {
  list: BE12SurveyList,
  show: BE12SurveyView,
  icon: EmployeeIcon,
};

export default resource;
