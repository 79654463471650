// in src/posts.js
import * as React from 'react';
import { useForm } from 'react-hook-form';
import SubmitButton from './SubmitButton';
import { CustomTextField } from '../../../components/CustomTextField';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PreviewIcon from '@mui/icons-material/Preview';
import styled from '@emotion/styled';
import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import { FileUpload } from '../file-uploads/FileUpload';

const PassportDetailsForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [upload, setUpload] = React.useState(false);
  const [passportUploaded, setPassportUploaded] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [previewMode, setPreviewMode] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [url, setUrl] = React.useState();
  const paspportExist = data?.documents.some((e) => e.purpose === 'PASSPORT');
  React.useEffect(() => {
    if (paspportExist) {
      setPassportUploaded(true);
    }
  }, [passportUploaded]);
  const onSubmit = (data) => {
    setProcessing(true);
    console.log(data);
    data = {
      ...data,
      /**
       * by default, dateOfBirth comes as browser timezone.
       * This is sent to backend, which convert it to UTC.
       * In our case, dateOfBirth will be in IST, but when it convert it to UTC, date may go back one day.
       * As this is dob and we are concerned only on the date, we are doing this workaround.
       * Ideally react-datepicker should have provided some option to define the timezone(UTC)
       */
      dateOfBirth: new Date(
        Date.UTC(
          data?.dateOfBirth.getFullYear(),
          data?.dateOfBirth.getMonth(),
          data?.dateOfBirth.getDate()
        )
      ),
    };
    dataProvider
      .post(`update/company/${companyId}/passport-details`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  const handleUploadSuccess = (logo) => {
    setUpload(true);
    setPassportUploaded(true);
  };
  const handleUploadError = () => {
    setUpload(false);
    setPassportUploaded(false);
  };

  const handleDownload = (companyId, purpose) => {
    dataProvider
      .get(`company-documents/download/${companyId}/${purpose}`, {})
      .then((payload) => {
        if (payload && payload.data && payload.data.url) {
          window.open(payload.data.url, '_blank');
        }
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const handleDelete = (companyId, purpose) => {
    setOpen(false);
    dataProvider
      .post(`company-documents/delete/${companyId}/${purpose}`)
      .then((data) => {
        setUpload(false);
        setPassportUploaded(false);
        setPreview(false);
        notify('File Deleted  successfully', { type: 'success' });
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const previewClose = () => {
    setPreviewMode(false);
    setPreview(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const previewDocument = (companyId, purpose) => {
    dataProvider
      .get(`company-documents/download/${companyId}/${purpose}`, {})
      .then((payload) => {
        if (payload && payload.data && payload.data.url) {
          setUrl(payload.data.url);
          setPreviewMode(true);
          setPreview(true);
        }
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', marginLeft: '20px' }}>
      {previewMode ? (
        <>
          <Grid container>
            <Grid container xs={5}>
              <Grid xs={12}>
                <Grid item xs={10}>
                  <CustomTextField
                    control={control}
                    label="Passport No."
                    name="passportNumber"
                    defaultValue={data?.passportDetails?.passportNumber}
                  />
                </Grid>
                <Grid item xs={10}>
                  <CustomTextField
                    control={control}
                    label="Gender (M/F/O)"
                    name="gender"
                    defaultValue={data?.passportDetails?.gender}
                  />
                </Grid>
                <Grid item xs={10}>
                  <CustomDatePicker
                    control={control}
                    label="D.O.B"
                    name="dateOfBirth"
                    defaultValue={data?.passportDetails?.dateOfBirth}
                  />
                </Grid>
                <Grid item xs={10}>
                  <CustomTextField
                    control={control}
                    label="Citizenship"
                    name="citizenship"
                    defaultValue={data?.passportDetails?.citizenship}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: '1em' }}>
                  <SubmitButton processing={processing} label="Update Passport Details" />
                </Grid>
                <Grid container xs={12} style={{ margin: '1em', gap: '1em', display: 'flex' }}>
                  {!passportUploaded && (
                    <div
                      style={{ width: '50%', display: 'flex', gap: '1em', flexDirection: 'column' }}
                    >
                      <div
                        style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}
                      >
                        Upload Passport
                      </div>
                      <FileUpload
                        onSuccess={handleUploadSuccess}
                        onError={handleUploadError}
                        companyId={companyId}
                        purpose="PASSPORT"
                      />
                    </div>
                  )}
                  {passportUploaded && (
                    <>
                      <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
                        <div
                          style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}
                        >
                          Download
                        </div>
                        <CloudDownloadIcon
                          className="fileActionStyle"
                          onClick={() => handleDownload(companyId, 'PASSPORT')}
                        />
                      </div>
                      <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
                        <div
                          style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}
                        >
                          Delete
                        </div>
                        <DeleteIcon className="fileActionStyle" onClick={() => handleClickOpen()} />
                      </div>
                      <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
                        <div
                          style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}
                        >
                          Preview
                        </div>
                        <PreviewIcon
                          className="fileActionStyle"
                          onClick={() => previewDocument(companyId, 'PASSPORT')}
                        />
                      </div>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {'Delete Passport document'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Do you really want to delete the file?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>CLOSE</Button>
                          <Button onClick={() => handleDelete(companyId, 'PASSPORT')}>
                            DELETE
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={7} style={{ gap: '1em', display: 'flex' }}>
              {preview && (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1em',
                    alignItems: 'flex-end',
                  }}
                >
                  <>
                    <StyledButton onClick={() => previewClose()} variant="contained">
                      CLOSE PREVIEW
                    </StyledButton>
                    <iframe
                      style={{
                        width: '100%',
                        height: '88vh',
                        borderRadius: '12px',
                        boxShadow: '0px 4px 40px rgb(0 0 0 / 12%)',
                      }}
                      src={url}
                    />
                  </>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container>
          <Grid item xs={6}>
            <CustomTextField
              control={control}
              label="Passport No."
              name="passportNumber"
              defaultValue={data?.passportDetails?.passportNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              control={control}
              label="Gender (M/F/O)"
              name="gender"
              defaultValue={data?.passportDetails?.gender}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDatePicker
              control={control}
              label="D.O.B"
              name="dateOfBirth"
              defaultValue={data?.passportDetails?.dateOfBirth}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              control={control}
              label="Citizenship"
              name="citizenship"
              defaultValue={data?.passportDetails?.citizenship}
            />
          </Grid>
          <Grid item xs={4} style={{ padding: '1em' }}>
            <SubmitButton processing={processing} label="Update Passport Details" />
          </Grid>
          <Grid container item spacing={2} style={{ margin: '1em', gap: '1em', display: 'flex' }}>
            {!passportUploaded && (
              <div style={{ width: '50%', display: 'flex', gap: '1em', flexDirection: 'column' }}>
                <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
                  Upload Passport
                </div>
                <FileUpload
                  onSuccess={handleUploadSuccess}
                  onError={handleUploadError}
                  companyId={companyId}
                  purpose="PASSPORT"
                />
              </div>
            )}
            {passportUploaded && (
              <>
                <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
                  <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
                    Download
                  </div>
                  <CloudDownloadIcon
                    className="fileActionStyle"
                    onClick={() => handleDownload(companyId, 'PASSPORT')}
                  />
                </div>
                <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
                  <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
                    Delete
                  </div>
                  <DeleteIcon className="fileActionStyle" onClick={() => handleClickOpen()} />
                </div>
                <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
                  <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
                    Preview
                  </div>
                  <PreviewIcon
                    className="fileActionStyle"
                    onClick={() => previewDocument(companyId, 'PASSPORT')}
                  />
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{'Delete Passport document'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to delete the file?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>CLOSE</Button>
                    <Button onClick={() => handleDelete(companyId, 'PASSPORT')}>DELETE</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
          <Grid container item spacing={2} style={{ margin: '1em', gap: '1em', display: 'flex' }}>
            {preview && (
              <div
                style={{
                  paddingTop: '50px',
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1em',
                  alignItems: 'flex-end',
                }}
              >
                <>
                  <StyledButton onClick={() => setPreview(false)} variant="contained">
                    CLOSE PREVIEW
                  </StyledButton>
                  <iframe
                    style={{
                      width: '100%',
                      height: '88vh',
                      borderRadius: '12px',
                      boxShadow: '0px 4px 40px rgb(0 0 0 / 12%)',
                    }}
                    src={url}
                  />
                </>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default PassportDetailsForm;

const StyledButton = styled(Button)`
  background-color: #7c7c7c;
  &:hover {
    background-color: #1a1a1a;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
