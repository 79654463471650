import * as React from 'react';
import { Datagrid, EditButton } from 'react-admin';
import BooleanField from '../../components/BooleanField';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import List from '../../components/List';
import NumberField from '../../components/NumberField';
import SelectField from '../../components/SelectField';
import SelectInput from '../../components/SelectInput';
import TextField from '../../components/TextField';
import { Currency, ExpenseType } from '../../config/constants';

const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));

const ExpenseTypeArray = ExpenseType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <SelectInput source="currency" label="Currency" choices={CurrencyArray} alwaysOn />,
  <SelectInput source="type" label="Expense type" choices={ExpenseTypeArray} alwaysOn />,
  <BooleanInput source="isValid" label="Active" alwaysOn />,
];

const ExpenseMasterList = () => {
  return (
    <List
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      filterDefaultValues={{ isValid: 'true' }}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sort={{ field: 'id', order: 'DESC' }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <SelectField source="type" label="Expense type" choices={ExpenseTypeArray} />
        <TextField source="currency" label="Currency" />
        <NumberField source="amount" label="Amount" />
        <DateField source="startDate" label="Start date" />
        <DateField source="endDate" label="End date" />
        <BooleanField source="isValid" label="Active" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ExpenseMasterList;
