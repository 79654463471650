import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  RaRecord,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import AutocompleteInput from '../../components/AutocompleteInput';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import ReferenceField from '../../components/ReferenceField';
import ReferenceInput from '../../components/ReferenceInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { hasPermission } from '../../lib/SGHelper';
import { RecentYears } from './../../config/constants';
import { BookkeepingStatus } from './../../config/constants';
import { PaymentStatus } from './../../config/constants';

const BookkeepingStatusArray = BookkeepingStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="companyId" label="Company Ref." alwaysOn />,
  // <TextInput source="companyName" label="Company " alwaysOn />,

  <SelectInput source="status" choices={BookkeepingStatusArray} alwaysOn />,
  <SelectInput source="year" choices={RecentYears} alwaysOn />,
  // <ReferenceInput label="Company"  source="companyId" reference="company-master-data" alwaysOn >
  //      <AutocompleteInput optionText="companyFullName" />
  // </ReferenceInput>
];
const bookKeeperConfig = {
  Filters: [
    <TextInput source="companyId" label="Company Ref." alwaysOn />,
    // <TextInput source="companyName" label="Company " alwaysOn />,

    <SelectInput
      source="status"
      choices={BookkeepingStatusArray.filter(
        (f) => ['ACTIVE', 'CLOSED', 'ONHOLD'].indexOf(f.id) >= 0
      )}
      alwaysOn
    />,
    <SelectInput source="year" choices={RecentYears} alwaysOn />,
  ],
  filterDefaultValues: { status: 'ACTIVE', year: 2022 },
};
const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};
const CompanyBookkeepingList = () => {
  const { permissions } = usePermissions();
  const isBookkeeper = hasPermission('ROLE_BOOK_KEEPER', permissions);
  return (
    <List
      filters={isBookkeeper ? bookKeeperConfig.Filters : Filters}
      filterDefaultValues={isBookkeeper ? bookKeeperConfig.filterDefaultValues : {}}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <ReferenceField
          source="companyId"
          label="Company"
          reference="company-master-data"
          link={(record: RaRecord, reference: string) => {
            return `/${reference}/${record.companyId}?tab=bookkeeping`;
          }}
        >
          <FunctionField
            label="Company"
            render={(record: { companyName: any; companyNameType: any }) =>
              `${record.companyName} ${record.companyNameType}`
            }
          />
        </ReferenceField>
        <TextField source="bookkeepingStartDate" label="Started On" />
        {/* <TextField source="endDate" label="End Date" /> */}
        <TextField source="year" label="Year" />
        <TextField source="transactionCount" label="Total Transactions" />
        <TextField source="questions" label="Queries" />

        <TextField source="unAnswered" label="Unanswered" />

        <TextField source="supportedDocuments" label="Supported Documents" />

        <SelectField source="paymentStatus" label="Payment" choices={PaymentStatus} />
        <SelectField source="status" label="Status" choices={BookkeepingStatusArray} />
      </Datagrid>
    </List>
  );
};

export default CompanyBookkeepingList;
