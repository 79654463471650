import * as React from 'react';
import styled from '@emotion/styled';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useShowController,
  FunctionField,
  Labeled,
  useNotify,
  useDataProvider,
} from 'react-admin';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import LabelField from '../../../components/LabelField';
import { LabelType } from '../../../config/LabelType';
import { ListHeaderList } from '../../../components/ListHeaderActions';
import { Box, FormHelperText, Typography } from '@mui/material';
import { StateTaxStatus } from './StateTaxStatus';

const StateTaxView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderList />}>
      <MyShow {...props} />
    </Show>
  );
};

const EmployeeDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default StateTaxView;

const MyShow = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const company = record?.company;
  const stateFilingDetails = record?.stateFilingDetails;
  const einDetails = record?.einDetails;
  const stateTax = record?.stateTax;
  return (
    <TabbedShowLayout>
      <Tab label="Basic Details">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SimpleShowLayout>
              <EmployeeDetails>
                <LabelField
                  record={stateFilingDetails}
                  source="dateOfFormationString"
                  label="Formed On"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <Labeled label="Industry">
                  <FunctionField
                    record={company}
                    render={(record: { industry: string }) =>
                      `${
                        record?.industry?.startsWith('mercury_')
                          ? record.industry.substring(8)
                          : record.industry
                      }`
                    }
                  />
                </Labeled>
                <Labeled label="Company">
                  <FunctionField
                    record={company}
                    render={(record: { companyName: any; companyNameType: any }) =>
                      `${record.companyName} ${record.companyNameType}`
                    }
                  />
                </Labeled>
                <LabelField
                  record={company}
                  source="companyType"
                  label="Company"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={company}
                  source="state"
                  label="State"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={company}
                  source="website"
                  label="Website"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={einDetails}
                  source="ein"
                  label="EIN"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={stateFilingDetails}
                  source="stateFileNo"
                  label="State File No."
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={stateTax}
                  source="issuedShares"
                  label="Issued Shares"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={stateTax}
                  source="grossAssets"
                  label="Gross Assets"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={company}
                  source="businessDescription"
                  label="Description"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
              </EmployeeDetails>
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={6}>
            <SimpleShowLayout>
              <EmployeeDetails style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Label>Responsible Party</Label>
                  <div style={{ marginTop: '0.2em' }}>
                    <Box>
                      <Typography variant="body2">
                        {record?.company?.responsiblePartyDetails?.firstName}{' '}
                        {record?.company?.responsiblePartyDetails?.lastName}
                      </Typography>
                      <Typography variant="body2">
                        {record?.company?.responsiblePartyDetails?.addressLine1}
                      </Typography>
                      <Typography variant="body2">
                        {record?.company?.responsiblePartyDetails?.city}
                      </Typography>
                      <Typography variant="body2">
                        {record?.company?.responsiblePartyDetails?.stateOrProvince}{' '}
                        {record?.company?.responsiblePartyDetails?.postalCode}
                      </Typography>
                      <Typography variant="body2">
                        {record?.company?.responsiblePartyDetails?.country}
                      </Typography>
                      <Typography variant="body2">
                        {record?.company?.responsiblePartyDetails?.tel}
                      </Typography>
                    </Box>
                  </div>
                </div>
                <div>
                  <Label>Registered Agent</Label>
                  <div style={{ marginTop: '0.2em' }}>
                    <Box>
                      <Typography variant="body2">
                        {record?.registeredAgentDetails?.addressLine1}
                      </Typography>
                      <Typography variant="body2">
                        {record?.registeredAgentDetails?.city}
                      </Typography>
                      <Typography variant="body2">
                        {record?.registeredAgentDetails?.stateOrProvince}{' '}
                        {record?.registeredAgentDetails?.postalCode}{' '}
                        {record?.registeredAgentDetails?.country}
                      </Typography>
                    </Box>
                  </div>
                </div>
              </EmployeeDetails>
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={3}>
            <SimpleShowLayout>
              <EmployeeDetails style={{ display: 'flex', flexDirection: 'column' }}>
                {record?.company?.owners?.length > 0 && (
                  <>
                    <Label> Owners </Label>
                    {record.company.owners.map((o: any) => {
                      return (
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography variant="body2">
                            {o.firstName} {o.lastName} {o.percent}%
                          </Typography>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </EmployeeDetails>
            </SimpleShowLayout>
          </Grid>
          {record?.company?.directors?.length > 0 && (
            <>
              <Grid item xs={3}>
                <SimpleShowLayout>
                  <EmployeeDetails style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormHelperText> Directors </FormHelperText>
                    {record.company.directors.map((o: any) => {
                      return (
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography variant="body2">
                            {o.firstName} {o.lastName}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </EmployeeDetails>
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={3}>
                <SimpleShowLayout>
                  <EmployeeDetails style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormHelperText> Officers and Roles </FormHelperText>
                    {record.company.officers.map((o: any) => {
                      return (
                        <>
                          <Grid item xs={6}>
                            <Typography variant="body2">{o.role}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">{o.fullName}</Typography>
                          </Grid>
                        </>
                      );
                    })}
                  </EmployeeDetails>
                </SimpleShowLayout>
              </Grid>
            </>
          )}
          <Spacer />
          <Grid item xs={12}>
            <SimpleShowLayout>
              <StateTaxStatus data={record} />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Tab>
      <Tab label="Documents Details">
        <Grid container spacing={2}>
          {record && Array.isArray(record?.documents) && (
            <>
              <Grid item xs={12} md={6}>
                <List>
                  <DocumentListItem
                    companyId={record?.company.id}
                    purpose="PASSPORT"
                    text="Passport"
                    exists={record?.documents.some(
                      (e: { purpose: string }) => e.purpose === 'PASSPORT'
                    )}
                  />
                  {record?.documents.some(
                    (e: { purpose: string }) => e.purpose === 'CERTIFICATE_OF_INCORPORATION'
                  ) && (
                    <DocumentListItem
                      companyId={record?.company.id}
                      purpose="CERTIFICATE_OF_INCORPORATION"
                      text="State Filing"
                      exists={record?.documents.some(
                        (e: { purpose: string }) => e.purpose === 'CERTIFICATE_OF_INCORPORATION'
                      )}
                    />
                  )}
                  {record?.documents.some(
                    (e: { purpose: string }) => e.purpose === 'STATE_FILING_INFORMATION'
                  ) && (
                    <DocumentListItem
                      companyId={record?.company.id}
                      purpose="STATE_FILING_INFORMATION"
                      text="State Filing"
                      exists={record?.documents.some(
                        (e: { purpose: string }) => e.purpose === 'STATE_FILING_INFORMATION'
                      )}
                    />
                  )}

                  {record?.company.state === 'WYOMING' && (
                    <DocumentListItem
                      companyId={record?.company.id}
                      purpose="INITIAL_RESOLUTION"
                      text="Initial Resolution"
                      exists={record?.documents.some(
                        (e: { purpose: string }) => e.purpose === 'INITIAL_RESOLUTION'
                      )}
                    />
                  )}
                  {record?.company.companyType === 'LLC' &&
                    record?.company.state === 'DELAWARE' && (
                      <DocumentListItem
                        companyId={record?.company.id}
                        purpose="STATEMENT_OF_AUTHORISED_PERSON"
                        text="Statement of Authorised Person"
                        exists={record?.documents.some(
                          (e: { purpose: string }) => e.purpose === 'STATEMENT_OF_AUTHORISED_PERSON'
                        )}
                      />
                    )}
                  {record?.company.companyType === 'CORPORATION' &&
                    record?.company.state === 'DELAWARE' && (
                      <DocumentListItem
                        companyId={record?.company.id}
                        purpose="ACTION_OF_INCORPORATOR"
                        text="Action of incorporator"
                        exists={record?.documents.some(
                          (e: { purpose: string }) => e.purpose === 'ACTION_OF_INCORPORATOR'
                        )}
                      />
                    )}

                  <DocumentListItem
                    companyId={record?.company.id}
                    purpose="EIN"
                    text="EIN"
                    exists={record?.documents.some((e: { purpose: string }) => e.purpose === 'EIN')}
                  />
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </Tab>
    </TabbedShowLayout>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;
const DocumentListItem = (props: {
  exists: any;
  companyId: any;
  purpose: string | undefined;
  id?: any;
  text: any;
}) => {
  const [exists, setExists] = React.useState(props.exists);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const handleDownload = (id: number, purpose: any) => {
    dataProvider
      .get(`company-documents/download/${props.companyId}/${props.purpose}`, {})
      .then((payload: any) => {
        if (payload && payload.data && payload.data.url) {
          window.open(payload.data.url, '_blank');
        }
      })
      .catch((error: any) => {
        notify('Oh no.. Error occured', { type: 'warning' });
      });
  };

  const handleUpload = (target: any) => {
    const purpose = target.id;
    const acceptedFiles = target.files;
    if (!purpose) {
      notify('Unable to upload file', { type: 'error' });
    }
    if (!acceptedFiles) {
      return;
    }
    dataProvider
      .upload('company-documents/upload', {
        data: {
          files: acceptedFiles[0],
          companyId: props.companyId,
          purpose: purpose,
        },
      })
      .then((data: any) => {
        notify('File uploaded successfully', { type: 'success' });
        setExists(true);
      })
      .catch((error: any) => {
        notify('Oh no.. Error occured', { type: 'warning' });
      });
  };
  return (
    <ListItem
      secondaryAction={
        <>
          <input
            accept="application/zip, application/pdf, image/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id={props.purpose}
            style={{ display: 'none' }}
            onChange={handleUpload}
            type="file"
          />

          <IconButton
            edge="end"
            aria-label="download"
            disabled={!exists}
            onClick={() => handleDownload(props.id, props.purpose)}
          >
            <DownloadIcon />
          </IconButton>
        </>
      }
    >
      <ListItemText primary={props.text} />
    </ListItem>
  );
};
