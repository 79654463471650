import * as React from 'react';
import { useTranslate } from 'react-admin';
import { Box, Divider, Grid, Typography } from '@mui/material';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

export default SectionTitle;
