import MercuryAccountsViewList from './MercuryAccountsViewList';
import MercuryApprovedAccountsViewList from './MercuryApprovedAccountsViewList';

import Leaderboard from '@mui/icons-material/Leaderboard';
const resource = {
  list: MercuryAccountsViewList,
  approvedList: MercuryApprovedAccountsViewList,
  icon: Leaderboard,
};

export default resource;
