export const ProductType = [
  { id: 'STATE_FILING', label: 'State Filings' },
  { id: 'REGISTERED_AGENT', label: 'Registered Agent' },
  { id: 'EIN', label: 'EIN' },
  { id: 'BANK_ACCOUNT', label: 'Bank Account' },
  { id: 'VIRTUAL_PHONE_NUMBER', label: 'US Phone Number' },
  { id: 'BOOKKEEPING', label: 'Bookkeeping' },
  { id: 'WORRY_FREE_CARD', label: 'Worry Free Card' },
  { id: 'QUICK_PAY', label: 'Quikpay' },
  { id: 'TAX', label: 'Tax' },
];
export const CompanyProductPaymentType = [
  { id: 'ONE_TIME_PAYMENT', label: 'One time payment' },
  { id: 'RECURRING_MONTHLY', label: 'Monthly (Recurring)' },
  { id: 'RECURRING_YEARLY', label: 'Yearly (Recurring)' },
  { id: 'CUSTOM', label: 'Custom' },
];
export const ActionOwner = [
  { id: 'SYSTEM', label: 'System' },
  { id: 'ADMIN', label: 'Admin' },
  { id: 'USER', label: 'User' },
  { id: 'UNKNOWN', label: 'Unknown' },
];
export const ActionStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'STARTED', label: 'Started' },
  { id: 'PROCESSED', label: 'Processed' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'ERROR', label: 'Error' },
  { id: 'ONHOLD', label: 'Onhold' },
];
export const ActionType = [
  { id: 'SEND_EMAIL_ADMIN_INITIATE_STATE_FILING' },
  { id: 'SEND_EMAIL_ADMIN_INITIATE_EIN' },
  { id: 'SEND_EMAIL_ADMIN_MERCURY_ACCOUNT_APPROVED' },
  { id: 'SEND_EMAIL_ADMIN_MERCURY_APPEAL_DATA' },
  { id: 'SEND_EMAIL_ADMIN_RELAYFI_ACCOUNT_APPROVED' },
  { id: 'SEND_EMAIL_ADMIN_RELAYFI_SIGNUP' },
  { id: 'SEND_EMAIL_ADMIN_WY_SIGNUP' },
  { id: 'SEND_EMAIL_ADMIN_DELAY_EIN' },
  { id: 'SEND_EMAIL_ADMIN_DELAY_STATE_FILING' },
  { id: 'SEND_EMAIL_ADMIN_DELAY_PASSPORT_UPLOAD' },
  { id: 'SEND_EMAIL_ADMIN_DELAY_SS4_SIGNING' },
  { id: 'SEND_EMAIL_ADMIN_NOTIFY_FEDERAL_TAX_CHAT_CPA' },
  { id: 'SEND_EMAIL_ADMIN_NOTIFY_FEDERAL_TAX_CHAT_BOOKKEEPER' },
  { id: 'SEND_EMAIL_PASSWORD_RESET,' },
  { id: 'SEND_EMAIL_ACCOUNT_ACTIVATE' },
  { id: 'SEND_EMAIL_STATE_FORMATION_DATA' },
  { id: 'SEND_EMAIL_REGISTERED_AGENT_DATA' },
  { id: 'SEND_EMAIL_EIN_DATA' },
  { id: 'SEND_EMAIL_PHONE_DATA' },
  { id: 'SEND_EMAIL_FEEDBACK_REQUEST' },
  { id: 'SEND_EMAIL_PAYPAL_INVOICE' },
  { id: 'SEND_EMAIL_SMS_NOTIFICATION' },
  { id: 'SEND_EMAIL_MAIL_NOTIFICATION' },
  { id: 'SEND_EMAIL_PROCESS_STARTED' },
  { id: 'SEND_EMAIL_MERCURY_ACCOUNT_INITIATED' },
  { id: 'SEND_EMAIL_MERCURY_ACCOUNT_APPROVED' },
  { id: 'SEND_EMAIL_MERCURY_ACCOUNT_REJECTED' },
  { id: 'SEND_EMAIL_MERCURY_APPEAL_DATA' },
  { id: 'SEND_EMAIL_RELAYFI_ACCOUNT_INITIATED' },
  { id: 'SEND_EMAIL_RELAYFI_SIGNUP' },
  { id: 'SEND_EMAIL_RELAYFI_ACCOUNT_APPROVED' },
  { id: 'SEND_EMAIL_REMINDER_SIGNUP_1' },
  { id: 'SEND_EMAIL_REMINDER_SIGNUP_2' },
  { id: 'SEND_EMAIL_REMINDER_PAYMENT_1' },
  { id: 'SEND_EMAIL_REMINDER_PAYMENT_2' },
  { id: 'SEND_EMAIL_REMINDER_PAYMENT_3' },
  { id: 'SEND_EMAIL_REMINDER_PAYMENT_4' },
  { id: 'SEND_EMAIL_REMINDER_DATA_VERIFICATION,' },
  { id: 'SEND_EMAIL_REMINDER_SS4_SIGNATURE,' },
  { id: 'SEND_EMAIL_REMINDER_PASSPORT_UPLOAD,' },
  { id: 'SEND_EMAIL_REMINDER_COMPANY_NAME_CORRECTION,' },
  { id: 'SEND_EMAIL_REMINDER_FRANCHISE_TAX' },
  { id: 'SEND_EMAIL_REMINDER_REGISTERED_AGENT_FEE_RENEWAL_1' },
  { id: 'SEND_EMAIL_REMINDER_REGISTERED_AGENT_FEE_RENEWAL_2' },
  { id: 'SEND_EMAIL_REMINDER_REGISTERED_AGENT_FEE_RENEWAL_3' },
  { id: 'SEND_EMAIL_REMINDER_VIRTUAL_PHONE_NUMBER_EXPIRY' },
  { id: 'SEND_EMAIL_REMINDER_FRANCHISE_TAX_1' },
  { id: 'SEND_EMAIL_REMINDER_FRANCHISE_TAX_2' },
  { id: 'SEND_EMAIL_REMINDER_FRANCHISE_TAX_3' },
  { id: 'SEND_EMAIL_REMINDER_REMARKS_UPDATE_FOR_FEDERAL_TAX' },
  { id: 'SEND_EMAIL_REMINDER_ESIGN_FEDERAL_TAX_DOCUMENT' },
  { id: 'SEND_EMAIL_REMINDER_FEDERAL_TAX_FINAL' },
  { id: 'SEND_EMAIL_REMINDER_FEDERAL_TAX_AFTER_DUE_DATE' },
  { id: 'SEND_EMAIL_PAYMENT_FAILED' },
  { id: 'SEND_EMAIL_POST_INCORPORATION_LEGAL_NOTICE_FOR_CORPORATION' },
  { id: 'SEND_EMAIL_OPERATING_AGREEMENT' },
  { id: 'SEND_EMAIL_BYLAWS' },
  { id: 'SEND_EMAIL_NOTIFY_FEDERAL_TAX_REMINDER_AND_CONNECT_BANK_ACCOUNT' },
  { id: 'SEND_EMAIL_NOTIFY_FEDERAL_TAX_FILING_CHARGE_ESTIMATED' },
  { id: 'SEND_EMAIL_NOTIFY_FEDERAL_TAX_FILING_CONFIRMED' },
  { id: 'SEND_EMAIL_NOTIFY_FEDERAL_TAX_CHAT' },
  { id: 'ACTION_CHECK_AVAILABILITY_OF_COMPANY_NAME' },
  { id: 'ACTION_GENERATE_PAYPAL_INVOICE' },
  { id: 'ACTION_GENERATE_SS4_TO_SIGN' },
  { id: 'ACTION_CONVERT_PDF_PASSPORT_TO_JPG' },
  { id: 'ACTION_CONVERT_HEIC_PASSPORT_TO_JPG' },
  { id: 'ACTION_INITIATE_COMPANY_INCORPORATION_WORKFLOW' },
  { id: 'ACTION_INITIATE_MERCURY' },
  { id: 'ACTION_INITIATE_MERCURY_APPEAL' },
  { id: 'ACTION_RETRY_MERCURY' },
  { id: 'ACTION_INITIATE_RELAYFI' },
  { id: 'ACTION_RETRY_RELAYFI' },
  { id: 'ACTION_INITIATE_BREX' },
  { id: 'ACTION_SEND_WEEKLY_SUMMARY_FOR_SMARTSTEWARDS' },
  { id: 'ACTION_SEND_MONTHLY_SUMMARY_FOR_SMARTSTEWARDS' },
  { id: 'ACTION_GENERATE_ADDITIONAL_INFO' },
  { id: 'ACTION_GENERATE_OPERATING_AGREEMENT' },
  { id: 'ACTION_GENERATE_BYLAWS' },
  { id: 'ACTION_ADMIN_ASSIGN_PASSPORT_VERIFICATION_TASK' },
  { id: 'ACTION_UPDATE_CRM' },
  { id: 'ACTION_UPDATE_CRM_PAYMENT_STATUS' },
  { id: 'ACTION_UPDATE_CRM_PHONE_NUMBER' },
  { id: 'ACTION_UPDATE_CRM_EMAIL' },
  { id: 'ACTION_ESIGN_FEDERAL_TAX_DOCUMENT' },
  { id: 'ACTION_ADMIN_PASSPORT_VERIFICATION_TASK' },
  { id: 'ACTION_GENERATE_INITIAL_RESOLUTION' },
  { id: 'ACTION_GENERATE_FAX_CONFIRMATION_REPORT' },
  { id: 'SCHEDULE_REMINDERS_FOR_PAYMENT' },
  { id: 'SCHEDULE_REMINDERS_FOR_DATA_VERIFICATION' },
  { id: 'SCHEDULE_REMINDERS_FOR_SS4_SIGNATURE' },
  { id: 'SCHEDULE_REMINDERS_FOR_COMPANY_NAME_NOT_AVAILABLE' },
  { id: 'SCHEDULE_REMINDERS_FOR_PASSPORT_UPLOAD' },
  { id: 'SCHEDULE_REMINDERS_FOR_FRANCHISE_TAX' },
  { id: 'SCHEDULE_REMINDERS_FOR_REGISTERED_AGENT_FEE' },
  { id: 'SCHEDULE_REMINDERS_FOR_EIN_DELAY' },
  { id: 'SCHEDULE_REMINDERS_FOR_STATE_FILING_DELAY' },
  { id: 'SCHEDULE_REMINDERS_FOR_PASSPORT_UPLOAD_DELAY' },
  { id: 'SCHEDULE_REMINDERS_FOR_SS4_SIGNING_DELAY' },
  { id: 'EVENT_SIGNIN' },
  { id: 'EVENT_FORGOT_PASSWORD_REQUESTED' },
  { id: 'EVENT_PASSWORD_RESET_DONE_VIA_URL' },
  { id: 'EVENT_PAYMENT_CONFIRMED' },
  { id: 'EVENT_PAYMENT_SUBSCRIPTION_CONFIRMED' },
  { id: 'EVENT_SIGNUP_COMPLETED' },
  { id: 'EVENT_PAYMENT_SUBSCRIPTION_FAILED' },
  { id: 'EVENT_PAYMENT_FAILED' },
  { id: 'EVENT_EMAIL_VERIFIED' },
  { id: 'EVENT_DOCUMENT_UPLOAD_SUCCESS' },
  { id: 'EVENT_DATA_VERIFICATION_SUCCESS' },
  { id: 'EVENT_COUPON_NOT_AVAILABLE' },
  { id: 'EVENT_COUPON_AVAILABLE' },
  { id: 'EVENT_BANK_ACCOUNT_CONNECTED_THROUGH_PLAID' },
  { id: 'EVENT_BANK_ACCOUNT_REMOVED_THROUGH_PLAID' },
  { id: 'EVENT_ADMIN_SIGNIN' },
  { id: 'EVENT_ADMIN_SIGNIN_FAILED' },
  { id: 'EVENT_UPDATE_COMPANY_DATA' },
  { id: 'EVENT_CREATE_LEAD' },
  { id: 'EVENT_PHONE_BLOCKED_AS_SPAM' },
  { id: 'EVENT_EMAIL_BLOCKED_AS_SPAM' },
  { id: 'EVENT_REFERRAL_RESPONSE' },
  { id: 'DELETE_MERCURY_DATA' },
  { id: 'DELETE_RELAYFI_DATA' },
  { id: 'EVENT_HBS_PAYMENTS' },
];
export const BankAccountStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'REGISTERED', label: 'Registered' },
  { id: 'PROCESSING_BY_BANK', label: 'Processing By Bank' },
  { id: 'APPROVED', label: 'Approved' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'AWAITING_RESPONSE', label: 'Awaiting Response' },
  { id: 'SUBMITTED_FOR_APPEAL', label: 'Submitted For Appeal' },
];
export const CompanyDocumentPurpose = [
  { id: 'PASSPORT', label: 'Passport' },
  { id: 'CERTIFICATE_OF_INCORPORATION', label: 'Certificate of Incorporation' },
  { id: 'EIN', label: 'EIN' },
  { id: 'ACTION_OF_INCORPORATOR', label: 'Action of Incorporator' },
  { id: 'BYLAWS', label: 'Bylaws' },
  { id: 'INITIAL_BOARD_CONSENT', label: 'Initial Board Consent' },
  { id: 'IDEMNIFICATION_AGREEMENT', label: 'Idemnification Agreement' },
  { id: 'RESTRICTED_STOCK_PURCHASE_AGREEMENTS', label: 'Restricted Stock Purchase Agreements' },
  { id: 'FORM_SS_4_SIGNED', label: 'SS4 (signed)' },
  { id: 'AUDIT_LOG_FORM_SS_4_SIGNED', label: 'SS4 Audit Log' },
  { id: 'FORM_8821', label: 'Form 8821' },
  { id: 'SIGNATURE_CERTIFICATE', label: 'Signature Certificate' },
  { id: 'CERTIFICATE_OF_FORMATION', label: 'Certificate Of Formation' },
  { id: 'STATEMENT_OF_AUTHORISED_PERSON', label: 'Statement of Authorised Person' },
  { id: 'GENERAL_DIRECTION_FROM_SG', label: 'General Direction' },
  { id: 'NOTICES_OF_STOCK_ISSUANCE', label: 'Notice of Stock Issuance' },
  {
    id: 'PREFILLED_83B_ELECTION_AND_FILLING_INSTRUCTIONS',
    label: '83B Election & Filing Instruction',
  },
  {
    id: 'CONFIDENTIAL_INFORMATION_AND_INVENTION_ASSIGNMENT_AGREEMENTS',
    label: 'Confidential Info. & Invention Assignment Agreements',
  },
  { id: 'ARTICLE_OF_ORGANIZATION', label: 'Article of organization' },
  { id: 'GENERAL_DIRECTION_DOCS', label: 'General Direction Documents' },
  { id: 'MEDIA_KIT', label: 'Media Kit' },
  { id: 'LOGO', label: 'Logo' },
  { id: 'FORM_SS_4_SIGNED_CERTIFICATE', label: 'SS4 certificate' },
  { id: 'INITIAL_RESOLUTION', label: 'Initial Resolution' },
  { id: 'STATE_FILING_INFORMATION', label: 'State Filing information' },
  { id: 'PASSPORT_CONVERTED_JPG', label: 'Passport (jpg)' },
  { id: 'TAX', label: 'Tax' },
  { id: 'MAIL', label: 'Mail' },
  { id: 'RECEIPTS', label: 'Receipts' },
  { id: 'SS4', label: 'SS4' },
  { id: 'OPERATING_AGREEMENT', label: 'Operating Agreement (Pdf)' },
  { id: 'OPERATING_AGREEMENT_EXCEL', label: 'Operating Agreement (Excel)' },
  { id: 'BYLAWS_EXCEL', label: 'Bylaws (Excel)' },
  {
    id: 'FRANCHISE_TAX_REPORT_CONFIRMATION_FROM_STATE',
    label: 'Franchise Tax (Original From State)',
  },
  { id: 'FEDERAL_TAX_1120_5472', label: 'Federal Tax (1120-5472)' },
  { id: 'FEDERAL_TAX_1120_5472_SIGNED', label: 'Federal Tax (1120-5472) Signed' },
  { id: 'AUDIT_LOG_FEDERAL_TAX_1120_5472_SIGNED', label: 'Federal Tax (1120-5472) Audit Log' },
  { id: 'FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION', label: 'Federal Tax (1065-Efile Auth)' },
  {
    id: 'FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED',
    label: 'Federal Tax (1065-Efile Auth) Signed',
  },
  {
    id: 'AUDIT_LOG_FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED',
    label: 'Federal Tax (1065-Efile Auth) Audit Log',
  },
  { id: 'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION', label: 'Federal Tax (1120-Efile Auth)' },
  {
    id: 'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED',
    label: 'Federal Tax (1120-Efile Auth) Signed',
  },
  {
    id: 'AUDIT_LOG_FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED',
    label: 'Federal Tax (1120-Efile Auth) Audit Log',
  },
  { id: 'FEDERAL_TAX_PROOF_OF_SUBMISSION', label: 'Federal Tax - Proof of submission' },
  { id: 'FEDERAL_TAX_PROOF_OF_REJECTION', label: 'Federal Tax - Proof of Rejection' },
  { id: 'TAX_PROFIT_AND_LOSS_STATEMENT', label: 'P&L Statement' },
  { id: 'TAX_TRIAL_BALANCE', label: 'Trial Balance' },
  { id: 'TAX_OTHERS', label: 'Tax (Others)' },
  { id: 'EIN_NAME_CORRECTION_2848', label: 'Form 2848 EIN Name Correction' },
  { id: 'EIN_NAME_CORRECTION_2848_SIGNED', label: 'EIN Name Correction (Signed)' },
  { id: 'AUDIT_LOG_EIN_NAME_CORRECTION_2848_SIGNED', label: 'EIN Name Correction (Audit Log)' },
  { id: 'EIN_WITH_ERROR', label: 'EIN with error' },
  { id: 'FINANCIAL_INPUT_DOCUMENTS', label: 'Financial Documents User upload' },
  { id: 'BE_12_FILINGS', label: 'BE 12 Filings' },
  { id: 'BE_12_FILING_CONFIRMATION', label: 'BE 12 Filing Confirmation' },
  { id: 'DISSOLUTION', label: 'Dissolution Document' },
  { id: 'DISSOLUTION_SIGNED', label: 'Dissolution Document (signed)' },
  { id: 'AUDIT_LOG_DISSOLUTION_SIGNED', label: 'Dissolution Document (Audit)' },

  { id: 'DISSOLUTION_CONFIRMATION', label: 'Dissolution Confirmation Document' },
  { id: 'EIN_8822B_FOR_RESPONSIBLE_PARTY_CHANGE', label: 'Ein 8822b Responsible Party Change' },
  {
    id: 'EIN_8822B_FOR_RESPONSIBLE_PARTY_CHANGE_CUSTOM',
    label: 'Ein 8822b Responsible Party Change (Custom)',
  },
  {
    id: 'REGISTERED_AGENT_TRANSFER_FILING_CUSTOM',
    label: 'RA Transfer (Custom)',
  },
  {
    id: 'AUDIT_LOG_EIN_8822B_FOR_RESPONSIBLE_PARTY_CHANGE',
    label: 'Ein 8822b Responsible Party Change (Audit Log)',
  },
  {
    id: 'STATEMENT_OF_INFORMATION',
    label: 'Statement of Information',
  },
  {
    id: 'INITIAL_BUSINESS_FILING',
    label: 'Initial Business Filing',
  },
  {
    id: 'DISSOLUTION_CUSTOM',
    label: 'Dissolution (Custom)',
  },
  {
    id: 'REGISTERED_AGENT_TRANSFER_FILING_SIGNED',
    label: 'RA Transfer (Signed)',
  },
  {
    id: 'AUDIT_LOG_REGISTERED_AGENT_TRANSFER_FILING_SIGNED',
    label: 'RA Transfer (Audit)',
  },
  {
    id: 'REGISTERED_AGENT_TRANSFER_FILING_PROOF',
    label: 'RA Transfer (Proof)',
  },
  {
    id: 'CERTIFICATE_OF_AMENDMENT_STATE',
    label: 'Certificate Of Amendment (State)',
  },
  {
    id: 'CERTIFICATE_OF_AMENDMENT_FEDERAL',
    label: 'Certificate of Amendment (Federal)',
  },
  {
    id: 'ARTICLE_OF_ORGANIZATION_AMENDMENT',
    label: 'Article of Organization Amendment (For Signature)',
  },
  {
    id: 'CERTIFICATE_OF_REVIVAL',
    label: 'Certificate of revival',
  },
  {
    id: 'CERTIFICATE_OF_REINSTATEMENT',
    label: 'Certificate of Reinstatement',
  },
  {
    id: 'BOI_FILING_CONFIRMATION',
    label: 'Boi Filing Confirmation',
  },
  {
    id: 'IDENTITY_DOCUMENT_FOR_BENEFICIARY_OWNER',
    label: 'Identity document for Beneficiary Owner',
  },
  {
    id: 'IDENTITY_DOCUMENT_FOR_RESPONSIBLE_PARTY',
    label: 'Identity document for Responsible Party',
  },
];

export const CompanyDocumentPurposeForFederalTax = [
  // { id : 'TAX', label : 'Tax' },
  { id: 'FEDERAL_TAX_1120_5472', label: 'Federal Tax (1120-5472)' },
  // { id : 'FEDERAL_TAX_1120_5472_SIGNED', label : 'Federal Tax (1120-5472) Signed' },
  // { id : 'AUDIT_LOG_FEDERAL_TAX_1120_5472_SIGNED', label : 'Federal Tax (1120-5472) Audit Log' },
  { id: 'FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION', label: 'Federal Tax (1065-Efile Auth)' },
  // { id : 'FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1065-Efile Auth) Signed' },
  // { id : 'AUDIT_LOG_FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1065-Efile Auth) Audit Log' },
  { id: 'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION', label: 'Federal Tax (1120-Efile Auth)' },
  { id: 'FEDERAL_TAX_MEMBER_RESOLUTION', label: 'Federal Tax (Member Resolution Only)' },

  // { id : 'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1120-Efile Auth) Signed' },
  // { id : 'AUDIT_LOG_FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1120-Efile Auth) Audit Log' },
  { id: 'FEDERAL_TAX_PROOF_OF_SUBMISSION', label: 'Federal Tax - Proof of submission' },
  { id: 'FEDERAL_TAX_PROOF_OF_REJECTION', label: 'Federal Tax - Proof of Rejection' },
  { id: 'TAX_PROFIT_AND_LOSS_STATEMENT', label: 'P&L Statement' },
  { id: 'TAX_TRIAL_BALANCE', label: 'Trial Balance' },
  // { id : 'TAX_OTHERS', label : 'Tax (Others)' },
  // { id : 'FINANCIAL_INPUT_DOCUMENTS', label : 'Financial Documents User upload' },
];

export const CompanyDocumentPurposeForStrategCubeFederalTax = [
  ,
  // { id : 'TAX', label : 'Tax' },
  { id: 'FEDERAL_TAX_STRATEGCUBE_1120_5472', label: 'Strategcube Federal Tax (1120-5472 Auth) ' },
  {
    id: 'FEDERAL_TAX_STRATEGCUBE_1120_AND_EFILE_AUTHORIZATION',
    label: 'Strategcube Federal Tax (1120-5472 Efile Auth) ',
  },

  // { id : 'FEDERAL_TAX_1120_5472_SIGNED', label : 'Federal Tax (1120-5472) Signed' },
  // { id : 'AUDIT_LOG_FEDERAL_TAX_1120_5472_SIGNED', label : 'Federal Tax (1120-5472) Audit Log' },
  {
    id: 'FEDERAL_TAX_STRATEGCUBE_1065_AND_EFILE_AUTHORIZATION',
    label: 'Strategcube Federal Tax (1065-Efile Auth)',
  },
  // { id : 'FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1065-Efile Auth) Signed' },
  // { id : 'AUDIT_LOG_FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1065-Efile Auth) Audit Log' },
  // { id: 'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION', label: 'Federal Tax (1120-Efile Auth)' },
  // { id : 'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1120-Efile Auth) Signed' },
  // { id : 'AUDIT_LOG_FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED', label : 'Federal Tax (1120-Efile Auth) Audit Log' },
  { id: 'FEDERAL_TAX_PROOF_OF_SUBMISSION', label: 'Federal Tax - Proof of submission' },
  { id: 'FEDERAL_TAX_PROOF_OF_REJECTION', label: 'Federal Tax - Proof of Rejection' },
  { id: 'TAX_PROFIT_AND_LOSS_STATEMENT', label: 'P&L Statement' },
  { id: 'TAX_TRIAL_BALANCE', label: 'Trial Balance' },
  // { id : 'TAX_OTHERS', label : 'Tax (Others)' },
  // { id : 'FINANCIAL_INPUT_DOCUMENTS', label : 'Financial Documents User upload' },
];

export const CompanyDocumentPurposeForBookkeeping = [
  { id: 'TAX_PROFIT_AND_LOSS_STATEMENT', label: 'P&L Statement' },
  { id: 'TAX_TRIAL_BALANCE', label: 'Trial Balance' },
  // { id : 'FINANCIAL_INPUT_DOCUMENTS', label : 'Financial Documents' },
];

export const CompanyDocumentStatus = [
  { id: 'PENDING_VERIFICATION', label: 'Pending Verification' },
  { id: 'ISSUED', label: 'Issued' },
  { id: 'ONHOLD', label: 'Onhold' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'DELETED', label: 'Deleted' },
];

export const CompanyDocumentType = [
  { id: 'GENERAL', label: 'General' },
  { id: 'COMPANY_FORMATION', label: 'Company Formation' },
  { id: 'LEGAL', label: 'Legal' },
  { id: 'TAX', label: 'Tax' },
  { id: 'MAIL', label: 'Mail' },
  { id: 'RECEIPTS', label: 'Receipts' },
  { id: 'IDENTITY_PASSPORT', label: 'Identity - Passport' },
  { id: 'IDENTITY_DRIVER_LICENSE', label: 'Identity - Driver License' },
  { id: 'INTERNAL', label: 'Internal' },
  { id: 'COMPLEMENTORY', label: 'Complementory' },
  { id: 'FRANCHISE_TAX', label: 'Franchise/State Tax' },
  { id: 'FEDERAL_TAX', label: 'Federal Tax' },
];

export const CompanyIncorporationStatus = [
  { id: 'NONE', label: 'None' },
  { id: 'PENDING_PAYMENT', label: 'Pending Payment' },
  { id: 'PENDING_VERIFICATION', label: 'Pending Verification' },
  { id: 'STARTED', label: 'Started' },
  { id: 'COMPLETED', label: 'Completed' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'FREEZED', label: 'Freezed' },
  { id: 'ONHOLD', label: 'Onhold' },
  { id: 'PENDING_FOR_DOCUMENT_VERIFICATION', label: 'Pending for document Verification' },
  { id: 'DISSOLVED', label: 'Dissolved' },
];

export const CompanyNameAvailabilityFlag = [
  { id: 'NOT_CHECKED', label: 'Not Checked' },
  { id: 'AVAILABLE', label: 'Available' },
  { id: 'NOT_AVAILABLE', label: 'Not Available' },
  { id: 'Error', label: 'Error' },
];
export const CompanyNotificationType = [
  { id: 'SS4_SIGNATURE', label: 'SS4 Signature' },
  { id: 'PROCESS_HAS_STARTED', label: 'Process has started' },
  { id: 'DEBIT_CARD_SHIPPED', label: 'Debit card has shipped' },
];

export const CompanyService = [
  { id: 'ONBOARDING_SERVICE', label: 'Company Onboarding Service' },
  { id: 'STATE_FILING_SERVICE', label: 'State Filing Service' },
  { id: 'REGISTERED_AGENT_SERVICE', label: 'Registered Agent Service' },
  { id: 'EIN_SERVICE', label: 'EIN Service' },
  { id: 'BANKING_SERVICE', label: 'Banking Service' },
  { id: 'VIRTUAL_PHONE_NUMBER', label: 'Virtual Phone Number Service' },
  { id: 'MEDIA_KIT_SERVICE', label: 'Media Kit Service' },
  { id: 'SIGNATURE_SERVICE', label: 'E-Signature Service' },
];

export const CompanyServiceStatus = [
  { id: 'NONE', label: 'None' },
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'PROCESSING', label: 'Processing' },
  { id: 'ISSUED', label: 'Issued' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'TERMINATED', label: 'Terminated' },
  { id: 'CLOSED', label: 'Closed' },
  { id: 'NOT_NEEDED', label: 'Not Needed' },
  { id: 'EXPIRED', label: 'Expired' },
];
export const CompanyStatus = [
  { id: 'GOOD_STANDING', label: 'Good' },
  { id: 'FREEZED', label: 'Feezed' },
  { id: 'BLOCKED', label: 'Blocked' },
  { id: 'BLACK_LISTED', label: 'Blacklisted' },
  { id: 'DO_NOT_DISTURB', label: 'DND' },
];
export const CompanyType = [
  { id: 'LLC', label: 'LLC' },
  { id: 'CORPORATION', label: 'CORPORATION' },
];
export const ConfigurationConstants = [
  { id: 'POST_MARK_API_TOKEN', label: 'Postmark API Token' },
  { id: 'PHONE_VALIDATION_ENABLED', label: 'Enable Phone number validation' },
  { id: 'NEVERBOUNCE_API_KEY', label: 'Neverbounce API Token' },
  { id: 'NUMVERIFY_API_KEY', label: 'Numverify API Token' },
  { id: 'STRIPE_SECRET_KEY', label: 'Stripe Secret Key' },
  { id: 'STRIPE_PUBLIC_KEY', label: 'Stripe Public Key' },
  { id: 'AWS_S3_ACCESS_KEY_ID', label: 'AWS S3 Access Key ID' },
  { id: 'AWS_S3_SECRET_ACCESS_KEY', label: 'AWS S3 Scret Access Key' },
  { id: 'AWS_S3_USER_UPLOAD_END_POINT', label: 'AWS S3 user upload end point' },
  { id: 'TEST_EMAIL_ADDRESS', label: 'Test Email Address' },
  { id: 'THANKYOUPH', label: 'Thankyou ph' },
  { id: 'SIGNEASY_API_KEY', label: 'Signeasy API Key' },
  { id: 'REPORTS_OUT_FOLDER', label: 'Reports Out Folder' },
  { id: 'REPORTS_SOURCE_FOLDER', label: 'Reports Source Folder' },
  { id: 'PLAID_ENV', label: 'Plaid Env' },
  { id: 'PLAID_CLIENT_ID', label: 'Plaid Client ID' },
  { id: 'PLAID_SECRET_KEY', label: 'Plaid Secret Key' },
  { id: 'SONETEL_USERNAME', label: 'Sonetel Username' },
  { id: 'SONETEL_PASSWORD', label: 'Sonetel Password' },
  { id: 'SONETEL_ACCOUNT_NUMBER', label: 'Sonetel Account number' },
  { id: 'SONETEL_USERID', label: 'Sonetel Userid' },
  { id: 'SENDGRID_API_TOKEN', label: 'Sendgrid API Token' },
  { id: 'AZURE_COGNITIVE_SERVICE_ENDPOINT', label: 'Azure Cognitive Service Endpoint' },
  {
    id: 'AZURE_COGNITIVE_SERVICE_SUBSCRIPTION_KEY',
    label: 'Azure Cognitive Service Subscription key',
  },
  { id: 'RELAYFI_PARTNER_ID', label: 'Relayfi Partner Id' },
  { id: 'RELAYFI_PARTNER_SECRET', label: 'Relayfi Partner Secret' },
  { id: 'RELAYFI_API_URL', label: 'Relayfi API Url' },
  { id: 'RELAYFI_PUBLIC_KEY_FILE_PATH', label: 'Relayfi public key path' },
  { id: 'STARTGLOBAL_CMS_SERVER_URL', label: 'SG CMS Server URL' },
  { id: 'STARTGLOBAL_CMS_SERVER_USERNAME', label: 'SG CMS Username' },
  { id: 'STARTGLOBAL_CMS_SERVER_PASSWORD', label: 'SG CMS Password' },
  { id: 'PAYPAL_CLIENT_ID', label: 'Paypal Client ID' },
  { id: 'PAYPAL_CLIENT_SECRET', label: 'Paypal Client Secret' },
  { id: 'MERCURY_HMAC_SECRET', label: 'Mercury MMAC Secret' },
  { id: 'STRIPE_CONNECT_CLIENT_ID', label: 'Stripe Connect Client ID' },
  { id: 'QUICKPAY_DIRECT_PAYMENT_URL', label: 'Quickpay Direct payment url' },
  { id: 'QUICKPAY_INVOICE_PAYMENT_URL', label: 'Quickpay Invoice Payment url' },
  { id: 'HELPSCOUT_VERIFICATION_SECRET', label: 'Helpscout verification secret' },
  { id: 'HELPSCOUT_APP_ID', label: 'Helpscout APP ID' },
  { id: 'HELPSCOUT_APP_SECRET', label: 'Helpscout APP Secret' },
  { id: 'CURRENT_TAX_YEAR', label: 'Current Tax Year' },
  { id: 'MARKUP_FOR_CCORP_FRANCHISE_TAX', label: 'Markup CCorp Franchise Tax' },
  { id: 'FEDERAL_TAX_LATE_FEE', label: 'Federal tax late fee' },
  { id: 'FEDERAL_TAX_DUE_DATE_MULTI_MEMBER_LLC', label: 'Federal tax Due date (multimember LLC)' },
  { id: 'FEDERAL_TAX_DUE_DATE_LLC', label: 'Federal tax Due Date (Single Person LLC)' },
  { id: 'FEDERAL_TAX_DUE_DATE_CORP', label: 'Federal Tax Due Date (CCorp)' },
  { id: 'ADMIN_URL', label: 'Admin Url' },
];

export const DataMappingType = [
  { id: 'COUNTRY_NAME', label: 'Country' },
  { id: 'MERCURY_TO_RELAYFI_INDUSTRY', label: 'Mercury --> Relay Industry Mapping' },
];

export const DefaultValueType = [
  { id: 'TODAY', label: 'Today' },
  { id: 'START_OF_THE_MONTH', label: 'Month (Start)' },
  { id: 'END_OF_THE_MONTH', label: 'Month (End)' },
  { id: 'START_OF_THE_YEAR', label: 'Year (Start)' },
  { id: 'CURRENT_YEAR', label: 'Current Year' },
  { id: 'CURRENT_MONTH', label: 'Current Month' },
  { id: 'TRUE', label: 'True' },
  { id: 'FALSE', label: 'False' },
];
export const Event = [
  { id: 'SIGNUP_INITIATED', label: 'Signup Initiated' },
  { id: 'SIGNUP_COMPLETED', label: 'Signup Completed' },
  { id: 'SIGNIN', label: 'Signin' },
  { id: 'FORGOT_PASSWORD', label: 'Forgot Password' },
  { id: 'EMAIL_VERIFICATION', label: 'Email verification' },
  { id: 'FILE_ACCESS', label: 'File Access' },
  { id: 'SIGNOUT', label: 'Signout' },
  {
    id: 'COMPANY_INCORPORATION_PAYMENT_COMPLETED',
    label: 'Company Incorporation Payment Completed',
  },
];
export const EventActionType = [
  { id: 'CREATE', label: 'Create' },
  { id: 'UPDATE', label: 'Update' },
  { id: 'DELETE', label: 'Delete' },
  { id: 'VIEW', label: 'View' },
  { id: 'REJECTION', label: 'Rejection' },
  { id: 'COMMENT', label: 'Comment' },
  { id: 'PAID', label: 'Paid' },
  { id: 'REFUND', label: 'Refund' },
  { id: 'SENT', label: 'Sent' },
  { id: 'ACKNOWLEDGED', label: 'Acknowledged' },
  { id: 'SUCCESS', label: 'Success' },
];
export const EventSourceType = [
  { id: 'USER_EVENT', label: 'User Event' },
  { id: 'ADMIN_EVENT', label: 'Admin Event' },
  { id: 'SYSTEM_EVENT', label: 'System Event' },
];
export const FaxPurpose = [
  { id: 'EIN ', label: 'Ein' },
  { id: 'FEDERAL_TAX_5472', label: 'Federal Tax (5472)' },
];
export const FeatureType = [
  { id: 'DISABLE_AUTOMATION', label: 'Disable Automation' },
  { id: 'SSN_ITIN_EDITABLE', label: 'Enable SSN/ITIN Editable' },
  { id: 'DO_NOT_ASK_FEEDBACK_AFTER_BANK_ACCOUNT', label: 'Do not ask feedback' },
  { id: 'DISABLE_SIGNIN_BY_USER', label: 'Disable Signin' },
  { id: 'BLACKLIST_COMPANY', label: 'Blacklist Company' },
  { id: 'FREEZE_COMPANY', label: 'Freeze Company' },
  { id: 'DELETE_COMPANY', label: 'Delete Company' },
  { id: 'CONNECTED_TO_STRIPE_CONNECT', label: 'Connected to Stripe Account' },
  { id: 'CONNECTED_TO_PLAID', label: 'Connected to Plaid' },
  { id: 'FEDERAL_TAX_ENABLED', label: 'Enable Federal Tax' },
  { id: 'FRANCHISE_TAX_ENABLED', label: 'Enable Franchise/State Tax' },
  { id: 'DO_NOT_SEND_NOTIFICATION_EMAILS', label: 'Do not send notification emails' },
];

export const FieldType = [
  { id: 'NONE', label: 'None' },
  { id: 'TEXT_BOX', label: 'Textbox' },
  { id: 'DATE_PICKER', label: 'Date Picker' },
  { id: 'SELECT_PAID_COMPANY', label: 'Paid company Combo' },
  { id: 'SELECT_SIGNED_UP_COMPANY', label: 'Signed Up company combo' },
  { id: 'SELECT_STATES', label: 'States' },
  { id: 'SELECT_PAYMENT_STATUS', label: 'Payment Status' },
  { id: 'SELECT_INCORPORATION_STATUS', label: 'Incorporation Status' },
  { id: 'SELECT_PARTNERS', label: 'Partners' },
  { id: 'GENERAL_COMBO', label: 'Combo' },
];

export const FranchisePaymentType = [
  { id: 'ONE_TIME_PAYMENT', label: 'One time Payment' },
  { id: 'SUBSCRIPTION', label: 'Subscription' },
];
export const FranchiseStatus = [
  { id: 'GOOD_STANDING', label: 'Good Standing' },
  { id: 'FREEZED', label: 'Freezed' },
  { id: 'BLOCKED', label: 'Blocked' },
  { id: 'BLACK_LISTED', label: 'Blacklisted' },
];
export const FranchiseType = [
  { id: 'WHITE_LABELLED', label: 'White labelled' },
  { id: 'PARTNER', label: 'Partner' },
];
export const LanguageCode = [
  { id: 'ENGLISH', label: 'English' },
  { id: 'SPANISH', label: 'Spanish' },
];
export const MailType = [
  { id: 'REGULAR_MAIL', label: 'Regular Mail' },
  { id: 'INITIAL_FILING', label: 'Initial Filing' },
  { id: 'FILING', label: 'Filing' },
  { id: 'ONGOING_FILING', label: 'Ongoing Filing' },
];
export const MessageType = [
  { id: 'INFO', label: 'Info' },
  { id: 'WARNING', label: 'Warning' },
  { id: 'EXCEPTION', label: 'Exception' },
  { id: 'ERROR', label: 'Error' },
];
export const PasswordResetStatus = [
  { id: 'NONE', label: 'None' },
  { id: 'PENDING', label: 'Pending' },
  { id: 'EXPIRED', label: 'Expired' },
  { id: 'DONE', label: 'Done' },
];
export const PaymentStatus = [
  { id: 'NOT_PAID', name: 'Not Paid' },
  // { id : 'INITIATED', label : 'Initiated' } ,
  // { id : 'CUSTOMER_CREATED', label : 'Customer Created' } ,
  // { id : 'SUBSCRIPTION_CREATED', label : 'Subscription Created' } ,
  { id: 'PAID', name: 'Paid' },
  // { id : 'ERROR', label : 'Error' },
  { id: 'PAID_VIA_SUBSCRIPTION', name: 'Subscription' },
];

export const BE12SurveyStatus = [
  { id: 'INITIATED', name: 'Initiated' },
  { id: 'NOT_PAID', name: 'Not Paid' },
  { id: 'PAID', name: 'Paid' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'TAX_PAID_EXTERNALLY', name: 'Tax Paid Externally' },
  { id: 'FILED_NOT_PROCESSED', name: 'Filed Not Processed' },
  { id: 'INCOMPLETE', name: 'Incomplete' },
];
export const BoiStatus = [
  { id: 'INITIATED', name: 'Initiated' },
  { id: 'NOT_PAID', name: 'Not Paid' },
  { id: 'PAID', name: 'Paid' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'WAITING_FOR_INFO', name: 'Waiting for Info' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'FILED_EXTERNALLY', name: 'Filed Externally' },
  { id: 'WAITING_FOR_APPROVAL_OF_FILED_EXTERNALLY', name: 'Waiting for Approval' },
];

export const RegisteredAgentStatus = [
  { id: 'INITIATED', name: 'Initiated' },
  { id: 'NOT_PAID', name: 'Not Paid' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'PAID_EXTERNALLY', name: 'Paid Externally' },
];

export const PaymentType = [
  { id: 'COMPANY_INCORPORATION', label: 'Company Incorporation' },
  { id: 'TELEPHONE_RECHARGE', label: 'Telephone' },
  { id: 'FEDERAL_TAX_ESTIMATED_AMOUNT', label: 'Federal Tax Estimation fee' },
  { id: 'FRANCHISE_TAX_ESTIMATED_AMOUNT', label: 'Franchise/State Tax' },
  { id: 'REGISTERED_AGENT_YEARLY_DUE', label: 'Registered Agent Renewal' },
  { id: 'ACTUAL_FEDERAL_TAX_OWED', label: 'Federal Tax' },
  { id: 'EXISTING_COMPANY_ONBOARDING_PAYMENT', label: 'Existing Company Onboarding' },
];

export const PlaidTransactionReconcileStatus = [
  { id: 'ONGOING', label: 'Ongoing' },
  { id: 'COMPLETED', label: 'Completed' },
];
export const RecurringFeeType = [
  { id: 'REGISTERED_AGENT_FEE', label: 'Registered Agent Fee' },
  { id: 'FRANCHISE_TAX', label: 'Franchise Tax' },
];
export const ReportType = [
  { id: 'PDF', label: 'Pdf' },
  { id: 'XLS', label: 'Xls' },
  { id: 'TEXT', label: 'Text' },
];
export const RequestMessageType = [
  { id: 'NONE', label: 'None' },
  { id: 'INFO', label: 'Info' },
  { id: 'WARNING', label: 'Warning' },
  { id: 'EXCEPTION', label: 'Exception' },
  { id: 'ERROR', label: 'Error' },
];
export const RequestStatus = [
  { id: 'SUCCESS', label: 'Success' },
  { id: 'EXCEPTION', label: 'Exception' },
  { id: 'ERROR', label: 'Error' },
];
export const StartGlobalPlatformCountry = [
  { id: 'USA', label: 'USA' },
  { id: 'CANADA', label: 'Canada' },
];
export const WorkflowStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'STARTED', label: 'Started' },
  { id: 'PROCESSED', label: 'Processed' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'ERROR', label: 'Error' },
  { id: 'ONHOLD', label: 'Onhold' },
];

export const WorkflowStepDefinition = [
  { id: 'NOTIFY_SERVICE_PROVIDER', label: 'Notify Service Provider' },
  { id: 'RECIEVE_DELIVERY', label: 'Receive Delivery' },
  { id: 'UPDATE_IN_DASHBOARD', label: 'Update Dashboard' },
  { id: 'NOTIFY_CLIENT', label: 'Notify Client' },
];

export const WorkflowStepStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'STARTED', label: 'Started' },
  { id: 'WAITING_FOR_DELIVERY_FROM_PROVIDER', label: 'Waiting for Delivery' },
  { id: 'RECEIVED_DELIVERY_FROM_PROVIDER', label: 'Received Delivery' },
  { id: 'PREPARING_FOR_CLIENT_DELIVERY', label: 'Preparing Client Delivery' },
  { id: 'DELIVERED_TO_CLIENT', label: 'Delivered to client' },
  { id: 'REJECTED', label: 'Rejected' },
];
export const WyomingAgentDocumentStatus = [
  { id: 'NONE', label: 'None' },
  { id: 'DOWNLOADED', label: 'Downloaded' },
  { id: 'ACKNOWLEDGED', label: 'Acknowledged' },
  { id: 'ERROR', label: 'Error' },
];

export const ChatSender = [
  { id: 'CLIENT', label: 'Client' },
  { id: 'CPA', label: 'CPA' },
];
export const ChatStatus = [
  { id: 'OPEN', label: 'Open' },
  { id: 'CLOSED', label: 'Close' },
];
export const FederalTaxStatus = [
  { id: 'INITIATED', label: 'Bank Not Connected' },
  { id: 'PREPARING_ESTIMATE', label: 'Preparing Estimate' },
  { id: 'PENDING_PAYMENT_OF_FILING_CHARGES', label: 'Pending for Estimation Charge Payment' },
  { id: 'WAITING_FOR_TAX_DUE_CALCULATION', label: 'Waiting for Tax Due Calculation' },
  { id: 'PENDING_PAYMENT_OF_TAX_DUE', label: 'Pending for Tax Due Payment' },
  { id: 'WAITING_FOR_CPA_DOCUMENTS', label: 'Waiting for CPA Documentations' },
  { id: 'WAITING_FOR_CLIENT_SIGNATURE', label: 'Waiting for client signature' },
  { id: 'WAITING_FOR_CPA_CONFIRMATION', label: 'Waiting for CPA Confirmation' },
  { id: 'TAX_PAID_AND_CONFIRMED', label: 'Tax Paid and Confirmed' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'WAITING_FOR_FAX', label: 'Waiting for Fax' },
  { id: 'WAITING_FOR_FAX_CONFIRMATION', label: 'Waiting for Fax Confirmation' },
  { id: 'ONHOLD', label: 'Onhold' },
  { id: 'PAID_EXTERNALLY', label: 'Tax Paid Externally' },
  { id: 'WAITING_FOR_BOOKKEEPING', label: 'Waiting for Bookkeeping' },
  { id: 'SUBMITTED_FOR_REVISION', label: 'Submitted For Revision' },
];
export const BookkeepingStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'WAITING_FOR_BANK_CONNECTION', label: 'Bank Not Connected' },
  { id: 'WAITING_FOR_TRANSACTIONS', label: 'No Transactions Yet' },
  { id: 'WAITING_FOR_PAYMENT', label: 'Not Paid' },
  { id: 'ACTIVE', label: 'Active' },
  { id: 'CLOSED', label: 'Closed' },
  { id: 'ONHOLD', label: 'Onhold' },
  { id: 'TERMINATED', label: 'Terminated' },
];

export const SubscriptionStatus = [
  { id: 'ACTIVE', label: 'ACTIVE' },
  { id: 'INCOMPLETE', label: 'INCOMPLETE' },
  { id: 'INCOMPLETE_EXPIRED', label: 'INCOMPLETE_EXPIRED' },
  { id: 'PAST_DUE', label: 'PAST_DUE' },
  { id: 'CANCELED', label: 'CANCELED' },
  { id: 'UNPAID', label: 'UNPAID' },
  { id: 'TRIALING', label: 'TRIALING' },
  { id: 'UNKNOWN', label: 'UNKNOWN' },
];

export const FranchiseTaxStatus = [
  { id: 'INITIATED', label: 'Initiated' },
  { id: 'NOT_PAID', label: 'Not Paid' },
  { id: 'PAID', label: 'Paid' },
  { id: 'IN_PROGRESS', label: 'In Progress' },
  { id: 'CONFIRMED', label: 'Confirmed' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'TAX_PAID_EXTERNALLY', label: 'Paid Externally' },
  { id: 'WAITING_FOR_APPROVAL_OF_TAX_PAID_EXTERNALLY', label: 'Waiting For Approval' },
];
export const CompanyReinstatementStatus = [
  { id: 'INITIATED', name: 'Initiated' },
  { id: 'NOT_PAID', name: 'Not Paid' },
  { id: 'PAID', name: 'Paid' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'REJECTED', name: 'Rejected' },
];
export const RegisteredAgentTransferStatus = [
  { id: 'INITIATED', name: 'Initiated' },
  { id: 'NOT_PAID', name: 'Not Paid' },
  { id: 'PAID', name: 'Paid' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'REJECTED', name: 'Rejected' },
];

export const CompanyDissolutionStatus = [
  { id: 'INITIATED', name: 'Initiated' },
  { id: 'NOT_PAID', name: 'Not Paid' },
  { id: 'PAID', name: 'Paid' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'REJECTED', name: 'Rejected' },
];

export const CompanyServiceComplianceStatus = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'AT_RISK', name: 'AT_RISK' },
  { id: 'INITIATE_CANCELLATION', name: 'INITIATE_CANCELLATION' },
  { id: 'INITIATED_CANCELLATION', name: 'INITIATED_CANCELLATION' },
  { id: 'CANCELLED', name: 'CANCELLED' },
  { id: 'EXTERNAL', name: 'EXTERNAL' },
];
export const CompanyComplianceStatus = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'AT_RISK', name: 'AT_RISK' },
  { id: 'INACTIVE', name: 'INACTIVE' },
  { id: 'ARCHIVED', name: 'ARCHIVED' },
  { id: 'INITIATE_DISSOLUTION', name: 'INITIATE_DISSOLUTION' },
  { id: 'INITIATED_DISSOLUTION', name: 'INITIATED_DISSOLUTION' },
  { id: 'DISSOLVED', name: 'DISSOLVED' },
];

export const QuickpayInvoiceStatus = [
  { id: 'ISSUED', label: 'Issued' },
  { id: 'DRAFT', label: 'Draft' },
  { id: 'PAID', label: 'Paid' },
  { id: 'DELETED', label: 'Deleted' },
  { id: 'CANCELLED', label: 'Cancelled' },
];

export const QuickpayLinkStatus = [
  { id: 'VIEW', label: 'View' },
  { id: 'PAID', label: 'Paid' },
];
export const QuickpayPaymentType = [
  { id: 'DIRECT_PAYMENT', label: 'Direct Payment' },
  { id: 'INVOICE_PAYMENT', label: 'Invoice Payment' },
];
export const QuickpayStripeConnectStatus = [
  { id: 'NOT_ALLOWED', label: 'Not Allowed' },
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'PENDING_APPROVAL', label: 'Pending Approval' },
  { id: 'APPROVED', label: 'Approved' },
];

export const KPIParameterType = [
  { id: 'CUSTOMER_PAYMENT_FOR_FORMATION', label: 'CUSTOMER_PAYMENT_FOR_FORMATION' },
  { id: 'PAYMENT_PROCESSING_FEE', label: 'PAYMENT_PROCESSING_FEE' },
  { id: 'STATE_PROCESSING_FEE_ACTUAL', label: 'STATE_PROCESSING_FEE_ACTUAL' },
  { id: 'EIN_FEE_ACTUAL', label: 'EIN_FEE_ACTUAL' },
  { id: 'REGISTERED_AGENT_CUSTOMER_FEE', label: 'REGISTERED_AGENT_CUSTOMER_FEE' },
  { id: 'REGISTERED_AGENT_FEE_ACTUAL', label: 'REGISTERED_AGENT_FEE_ACTUAL' },
  { id: 'FRANCHISE_TAX_CUSTOMER_FEE', label: 'FRANCHISE_TAX_CUSTOMER_FEE' },
  { id: 'FRANCHISE_TAX_ACTUAL', label: 'FRANCHISE_TAX_ACTUAL' },
  { id: 'FEDERAL_TAX_SINGLE_MEMBER_LLC_ACTUAL', label: 'FEDERAL_TAX_SINGLE_MEMBER_LLC_ACTUAL' },
  { id: 'FEDERAL_TAX_MULTI_MEMBER_LLC_ACTUAL', label: 'FEDERAL_TAX_MULTI_MEMBER_LLC_ACTUAL' },
  { id: 'FEDERAL_TAX_CORPORATION_ACTUAL', label: 'FEDERAL_TAX_CORPORATION_ACTUAL' },
];

export const KPIState = [
  { id: 'DELAWARE', label: 'DELAWARE' },
  { id: 'WYOMING', label: 'WYOMING' },
];

export const US_STATES = [
  { id: 'ALABAMA', label: 'ALABAMA' },
  { id: 'ALASKA', label: 'ALASKA' },
  { id: 'ARIZONA', label: 'ARIZONA' },
  { id: 'ARKANSAS', label: 'ARKANSAS' },
  { id: 'CALIFORNIA', label: 'CALIFORNIA' },
  { id: 'COLORADO', label: 'COLORADO' },
  { id: 'CONNECTICUT', label: 'CONNECTICUT' },
  { id: 'DELAWARE', label: 'DELAWARE' },
  { id: 'FLORIDA', label: 'FLORIDA' },
  { id: 'GEORGIA', label: 'GEORGIA' },
  { id: 'HAWAII', label: 'HAWAII' },
  { id: 'IDAHO', label: 'IDAHO' },
  { id: 'ILLINOIS', label: 'ILLINOIS' },
  { id: 'INDIANA', label: 'INDIANA' },
  { id: 'IOWA', label: 'IOWA' },
  { id: 'KANSAS', label: 'KANSAS' },
  { id: 'KENTUCKY', label: 'KENTUCKY' },
  { id: 'LOUISIANA', label: 'LOUISIANA' },
  { id: 'MAINE', label: 'MAINE' },
  { id: 'MARYLAND', label: 'MARYLAND' },
  { id: 'MASSACHUSETTS', label: 'MASSACHUSETTS' },
  { id: 'MICHIGAN', label: 'MICHIGAN' },
  { id: 'MINNESOTA', label: 'MINNESOTA' },
  { id: 'MISSISSIPPI', label: 'MISSISSIPPI' },
  { id: 'MISSOURI', label: 'MISSOURI' },
  { id: 'MONTANA', label: 'MONTANA' },
  { id: 'NEBRASKA', label: 'NEBRASKA' },
  { id: 'NEVADA', label: 'NEVADA' },
  { id: 'NEW_HAMPSHIRE', label: 'NEW_HAMPSHIRE' },
  { id: 'NEW_JERSEY', label: 'NEW_JERSEY' },
  { id: 'NEW_MEXICO', label: 'NEW_MEXICO' },
  { id: 'NEW_YORK', label: 'NEW_YORK' },
  { id: 'NORTH_CAROLINA', label: 'NORTH_CAROLINA' },
  { id: 'NORTH_DAKOTA', label: 'NORTH_DAKOTA' },
  { id: 'OHIO', label: 'OHIO' },
  { id: 'OKLAHOMA', label: 'OKLAHOMA' },
  { id: 'OREGON', label: 'OREGON' },
  { id: 'PENNSYLVANIA', label: 'PENNSYLVANIA' },
  { id: 'RHODE_ISLAND', label: 'RHODE_ISLAND' },
  { id: 'SOUTH_CAROLINA', label: 'SOUTH_CAROLINA' },
  { id: 'SOUTH_DAKOTA', label: 'SOUTH_DAKOTA' },
  { id: 'TENNESSEE', label: 'TENNESSEE' },
  { id: 'TEXAS', label: 'TEXAS' },
  { id: 'UTAH', label: 'UTAH' },
  { id: 'VERMONT', label: 'VERMONT' },
  { id: 'VIRGINIA', label: 'VIRGINIA' },
  { id: 'WASHINGTON', label: 'WASHINGTON' },
  { id: 'WEST_VIRGINIA', label: 'WEST_VIRGINIA' },
  { id: 'WISCONSIN', label: 'WISCONSIN' },
  { id: 'WYOMING', label: 'WYOMING' },
];
export const KPIType = [{ id: 'FINANCE', label: 'FINANCE' }];

export const CompanyServiceProvider = [
  { id: 'NOT_APPLICABLE', label: 'N/A' },
  { id: 'STATE_FILING_DELAWARE_INC', label: 'HBS (State Filing)' },
  { id: 'STATE_FILING_WYOMING_AGENTS', label: 'WY (State Filing)' },
  { id: 'REGISTERED_AGENT_DELAWAREINC', label: 'HBS (Registered Agent)' },
  { id: 'REGISTERED_AGENT_WYOMINGAGENTS', label: 'WY (Registered Agent)' },
  { id: 'REGISTERED_AGENT_NORTHWEST_AGENTS', label: 'North-West (Registered Agent)' },
  { id: 'EIN_STARTGLOBAL', label: 'EIN (StartGlobal)' },
  { id: 'EIN_STARTGLOBAL_FAX', label: 'EIN (Fax)' },
  { id: 'EIN_USACORPS', label: 'EIN (USACorps)' },
  { id: 'EIN_FIKRI', label: 'EIN (Fikri)' },
  { id: 'VIRTUAL_PHONE_NUMBER_SONTEL', label: 'Sonetel' },
  { id: 'BANKING_MERCURY', label: 'Banking (Mercury)' },
  { id: 'BANKING_RELAYFI', label: 'Banking (Relayfi)' },
  { id: 'BANKING_BREX', label: 'Banking (Brex)' },
  { id: 'STATE_FILING_EXTERNAL', label: 'External (State Filing)' },
  { id: 'REGISTERED_AGENT_EXTERNAL', label: 'External (Registered Agent)' },
  { id: 'EIN_EXTERNAL', label: 'External (EIN)' },
  { id: 'STATE_FILING_STARTGLOBAL', label: 'StartGlobal (State Filing)' },
];

export const EmailProvider = [
  { id: 'POSTMARK', label: 'Postmark' },
  { id: 'SENDGRID', label: 'Sendgrid' },
];

export const PaymentProvider = [
  { id: 'STRIPE', label: 'Stripe' },
  { id: 'PAYPAL', label: 'Paypal' },
];

export const FranchiseMaster = [
  { id: 1, label: 'TryStart', valid: true },
  { id: 3, label: 'StartGlobal', valid: true },
  { id: 4, label: 'Public Note', valid: false },
  { id: 5, label: 'Alphafilings', valid: false },
  { id: 6, label: 'Smartstewards', valid: true },
  { id: 7, label: 'TatianaJames', valid: false },
];

export const Currency = [
  { id: 'INR', label: 'INR' },
  { id: 'USD', label: 'USD' },
  { id: 'EUR', label: 'EUR' },
];

export const ExpenseType = [
  { id: 'OUTSOURCING_SERVICES', label: 'Outsourcing services' },
  { id: 'THIRD_PARTY_SERVICES', label: 'Thirdparty services' },
  { id: 'OTHERS', label: 'Others' },
];

export const KpiParameters = [
  { id: 'NUMBER_OF_COMPANIES', label: 'NUMBER_OF_COMPANIES' },
  { id: 'STATE_FILING_FORMATION_REVENUE', label: 'STATE_FILING_FORMATION_REVENUE' },
  { id: 'STATE_FILING_FORMATION_EXPENSES', label: 'STATE_FILING_FORMATION_EXPENSES' },
  { id: 'STATE_FILING_FORMATION_PROFIT', label: 'STATE_FILING_FORMATION_PROFIT' },

  { id: 'TOTAL_STATE_FILING_FORMATION_REVENUE', label: 'TOTAL_STATE_FILING_FORMATION_REVENUE' },
  { id: 'TOTAL_STATE_FILING_FORMATION_EXPENSES', label: 'TOTAL_STATE_FILING_FORMATION_EXPENSES' },
  { id: 'TOTAL_STATE_FILING_FORMATION_PROFIT', label: 'TOTAL_STATE_FILING_FORMATION_PROFIT' },

  {
    id: 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS',
    label: 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS',
  },

  { id: 'NUMBER_OF_REGISTERED_AGENT_PAYMENTS', label: 'NUMBER_OF_REGISTERED_AGENT_PAYMENTS' },
  { id: 'REGISTERED_AGENT_PAYMENTS_REVENUE', label: 'REGISTERED_AGENT_PAYMENTS_REVENUE' },
  { id: 'REGISTERED_AGENT_PAYMENTS_PROFIT', label: 'REGISTERED_AGENT_PAYMENTS_PROFIT' },

  {
    id: 'TOTAL_NUMBER_OF_REGISTERED_AGENT_PAYMENTS',
    label: 'TOTAL_NUMBER_OF_REGISTERED_AGENT_PAYMENTS',
  },
  {
    id: 'TOTAL_REGISTERED_AGENT_PAYMENTS_REVENUE',
    label: 'TOTAL_REGISTERED_AGENT_PAYMENTS_REVENUE',
  },
  { id: 'TOTAL_REGISTERED_AGENT_PAYMENTS_PROFIT', label: 'TOTAL_REGISTERED_AGENT_PAYMENTS_PROFIT' },

  { id: 'NUMBER_OF_FRANCHISE_TAX_PAYMENTS', label: 'NUMBER_OF_FRANCHISE_TAX_PAYMENTS' },
  { id: 'FRANCHISE_TAX_PAYMENTS_REVENUE', label: 'FRANCHISE_TAX_PAYMENTS_REVENUE' },
  { id: 'FRANCHISE_TAX_PAYMENTS_PROFIT', label: 'FRANCHISE_TAX_PAYMENTS_PROFIT' },

  { id: 'TOTAL_NUMBER_OF_FRANCHISE_TAX_PAYMENTS', label: 'TOTAL_NUMBER_OF_FRANCHISE_TAX_PAYMENTS' },
  { id: 'TOTAL_FRANCHISE_TAX_PAYMENTS_REVENUE', label: 'TOTAL_FRANCHISE_TAX_PAYMENTS_REVENUE' },
  { id: 'TOTAL_FRANCHISE_TAX_PAYMENTS_PROFIT', label: 'TOTAL_FRANCHISE_TAX_PAYMENTS_PROFIT' },

  { id: 'NUMBER_OF_FEDERAL_TAX_CCORP', label: 'NUMBER_OF_FEDERAL_TAX_CCORP' },
  { id: 'NUMBER_OF_FEDERAL_TAX_SINGLE_LLC', label: 'NUMBER_OF_FEDERAL_TAX_SINGLE_LLC' },
  { id: 'NUMBER_OF_FEDERAL_TAX_MULTIMEMBER_LLC', label: 'NUMBER_OF_FEDERAL_TAX_MULTIMEMBER_LLC' },

  { id: 'FEDERAL_TAX_CCORP_REVENUE', label: 'FEDERAL_TAX_CCORP_REVENUE' },
  { id: 'FEDERAL_TAX_SINGLE_LLC_REVENUE', label: 'FEDERAL_TAX_SINGLE_LLC_REVENUE' },
  { id: 'FEDERAL_TAX_MULTIMEMBER_LLC_REVENUE', label: 'FEDERAL_TAX_MULTIMEMBER_LLC_REVENUE' },

  { id: 'FEDERAL_TAX_CCORP_PROFIT', label: 'FEDERAL_TAX_CCORP_PROFIT' },
  { id: 'FEDERAL_TAX_SINGLE_LLC_PROFIT', label: 'FEDERAL_TAX_SINGLE_LLC_PROFIT' },
  { id: 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT', label: 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT' },

  { id: 'TOTAL_NUMBER_OF_FEDERAL_TAX', label: 'TOTAL_NUMBER_OF_FEDERAL_TAX' },
  { id: 'TOTAL_FEDERAL_TAX_REVENUE', label: 'TOTAL_FEDERAL_TAX_REVENUE' },
  { id: 'TOTAL_FEDERAL_TAX_PROFIT', label: 'TOTAL_FEDERAL_TAX_PROFIT' },

  { id: 'ADS_EXPENSES', label: 'ADS_EXPENSES' },
  { id: 'OPERATIONS_EXPENSES', label: 'OPERATIONS_EXPENSES' },
  { id: 'ROAS', label: 'ROAS' },

  { id: 'TOTAL_PHONE_RECHARGES_REVENUE', label: 'TOTAL_PHONE_RECHARGES_REVENUE' },
  { id: 'TOTAL_PHONE_RECHARGES_PROFIT', label: 'TOTAL_PHONE_RECHARGES_PROFIT' },

  { id: 'TOTAL_REVENUE', label: 'TOTAL_REVENUE' },
  { id: 'TOTAL_PROFIT', label: 'TOTAL_PROFIT' },
  { id: 'PROFIT_PERCENTAGE', label: 'PROFIT_PERCENTAGE' },
];

export const Months = [
  { id: 1, label: 'January' },
  { id: 2, label: 'February' },
  { id: 3, label: 'March' },
  { id: 4, label: 'April' },
  { id: 5, label: 'May' },
  { id: 6, label: 'June' },
  { id: 7, label: 'July' },
  { id: 8, label: 'August' },
  { id: 9, label: 'September' },
  { id: 10, label: 'October' },
  { id: 11, label: 'November' },
  { id: 12, label: 'December' },
];

export const getMonth = (id) => {
  let month = '';
  Months.map((a) => {
    if (a.id === id) {
      month = a.label;
    }
  });
  return month;
};

export const getFranchisesMaster = () =>
  FranchiseMaster.filter((a) => a.valid).map((a) => {
    return {
      id: a.id,
      name: a.label,
    };
  });

export const getPaymentStatus = () =>
  PaymentStatus.map((a) => {
    return {
      id: a.id,
      name: a.label,
    };
  });

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const palettes = [
  [
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
  ],
  [
    '#002B5B',
    '#1D5C63',
    '#E78EA9',
    '#8FE3CF',
    '#2F8F9D',
    '#F8CB2E',
    '#4B7BE5',
    '#1D5C63',
    '#FF6363',
    '#F2FA5A',
    '#D29D2B',
    '#E78EA9',
  ],
];
export const StateFilingProvider = [
  { id: 'STATE_FILING_DELAWARE_INC', label: 'HBS' },
  { id: 'STATE_FILING_WYOMING_AGENTS', label: 'WyomingAgents' },
  { id: 'STATE_FILING_STARTGLOBAL', label: 'Startglobal' },
];

export const StateFilingStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'STARTED', label: 'Started' },
  { id: 'PROCESSED', label: 'Processed' },
  { id: 'ERROR', label: 'Error' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'ONHOLD', label: 'Onhold' },
];
export const StateFilingCurrentStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'STARTED', label: 'Started' },
  { id: 'WAITING_FOR_DELIVERY_FROM_PROVIDER', label: 'Waiting for Delivery' },
  { id: 'DELIVERED_TO_CLIENT', label: 'Delivered' },
  { id: 'REJECTED', label: 'Rejected' },
];

export const EINProvider = [
  { id: 'EIN_STARTGLOBAL', label: 'Startglobal' },
  { id: 'EIN_USACORPS', label: 'USACorps' },
  { id: 'EIN_STARTGLOBAL_FAX', label: 'Fax' },
];
export const EINStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'STARTED', label: 'Started' },
  { id: 'PROCESSED', label: 'Processed' },
  { id: 'ERROR', label: 'Error' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'ONHOLD', label: 'Onhold' },
];
export const EINCurrentStatus = [
  { id: 'NOT_STARTED', label: 'Not Started' },
  { id: 'STARTED', label: 'Started' },
  { id: 'WAITING_FOR_DELIVERY_FROM_PROVIDER', label: 'Waiting for Delivery' },
  { id: 'DELIVERED_TO_CLIENT', label: 'Delivered' },
  { id: 'REJECTED', label: 'Rejected' },
];
export const EmployeeCompensationCategory = [
  { id: 'OPERATIONS', label: 'OPERATIONS' },
  { id: 'R_AND_D', label: 'R_AND_D' },
];

export const EIN8822BStatus = [
  { id: 'INITIATED', label: 'Initiated' },
  { id: 'WAITING_FOR_CLIENT_SIGNATURE', label: 'Waiting for Client Signature' },
  { id: 'REJECTED', label: 'Rejected' },
  { id: 'WAITING_FOR_MAIL', label: 'Waiting for Mail' },
  { id: 'ONHOLD', label: 'OnHold' },
  { id: 'COMPLETED_EXTERNALLY', label: 'Completed Externally' },
  { id: 'CONFIRMED', label: 'Confirmed' },
];

export const RecentYears = (function () {
  const start = 2022;
  const end = new Date().getFullYear();
  let years = [];
  for (let i = start; i <= end; i++) {
    years.push({ id: i, name: i });
  }
  return years;
})();
