import * as React from 'react';
import styled from '@emotion/styled';
import { TextInputProps } from 'react-admin';
import { TextInput as TextInputDefault } from 'react-admin';

export interface TextInputProp extends TextInputProps {}

const TextInput = (props: TextInputProp) => {
  return <TextInputDefault {...props} />;
};

export default TextInput;
