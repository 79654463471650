import * as React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { ActionStatus } from '../../config/constants';
const ActionStatusArray = ActionStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const Filters = [
  <TextInput source="companyId" label="Company Ref." alwaysOn />,
  <SelectInput source="status" choices={ActionStatusArray} alwaysOn />,
  <TextInput source="actionType" label="Action" alwaysOn />,
  <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
];
const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};
const ActionItemList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyId" label="Company Ref." />
        <ReferenceField
          source="companyId"
          label="Company Name"
          reference="company-master-data"
          emptyText="N/A"
          link="show"
        >
          <TextField source="companyFullName" />
        </ReferenceField>
        <TextField source="actionType" label="Action" />
        <TextField source="remarks" />
        <SelectField source="status" choices={ActionStatusArray} />
        <TextField source="data" />
        <DateField
          source="createdDate"
          label="Started On"
          showTime
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        />
        <DateField
          source="modifiedDate"
          label="Modified On"
          showTime
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        />
        <TextField source="scheduledAt" label="Scheduled At (UTC)" />

        {/* <ShowButton label="Upload EIN"/> */}
      </Datagrid>
    </List>
  );
};

export default ActionItemList;
