import styled from '@emotion/styled';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { monthShortNames, palettes } from '../../../../config/constants';
import { amountFormat } from '../../../../lib/formatter';
import { getKPIMonths } from '../KpiInstanceDetail';

export const KPITotalRevenueStackedGraphWrapper = (props: { kpis: any[] }) => {
  if (!props.kpis || props.kpis.length == 0) {
    return null;
  }

  const getMonthwiseTotalOfAllStates = (kpis: any[], parameter: string, monthString: string) => {
    if (!kpis || kpis.length <= 0) {
      return 0;
    }
    return kpis
      .filter((kpi: any) => kpi?.parameter === parameter)
      .reduce((total: number, parameter: any) => total + parameter[monthString], 0);
  };

  const data = Array.from(Array(getKPIMonths(props.kpis)).keys()).map((month) => {
    //month is zero based
    const monthIndex = month + 1;
    const monthString = 'month' + (monthIndex >= 10 ? monthIndex : '0' + monthIndex);
    return {
      name: monthShortNames[month],
      'Formation Revenue': getMonthwiseTotalOfAllStates(
        props.kpis,
        'TOTAL_STATE_FILING_FORMATION_REVENUE',
        monthString
      ),
      'RA Revenue': getMonthwiseTotalOfAllStates(
        props.kpis,
        'REGISTERED_AGENT_PAYMENTS_REVENUE',
        monthString
      ),
      'Franchise Tax Revenue': getMonthwiseTotalOfAllStates(
        props.kpis,
        'FRANCHISE_TAX_PAYMENTS_REVENUE',
        monthString
      ),
      'Federal Tax Revenue': getMonthwiseTotalOfAllStates(
        props.kpis,
        'TOTAL_FEDERAL_TAX_REVENUE',
        monthString
      ),
      'Phone Revenue': getMonthwiseTotalOfAllStates(
        props.kpis,
        'TOTAL_PHONE_RECHARGES_REVENUE',
        monthString
      ),
    };
  });
  console.log('revenue data', data);
  const COLORS = palettes[1];

  //total revenue = total formation revenue + total ra revenue +
  // total franchise tax revenue + total federal revenue + total phone revenue

  return (
    <GraphItem>
      <GraphTitle>Monthly Revenue breakup</GraphTitle>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 2,
          right: 2,
          left: 2,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value) =>
            new Intl.NumberFormat('en', { notation: 'compact', compactDisplay: 'short' }).format(
              value
            )
          }
        />
        <Tooltip formatter={amountFormat} />
        <Legend formatter={amountFormat} />
        <Bar dataKey="Formation Revenue" stackId="a" fill={COLORS[0]} />
        <Bar dataKey="RA Revenue" stackId="a" fill={COLORS[1]} />
        <Bar dataKey="Franchise Tax Revenue" stackId="a" fill={COLORS[2]} />
        <Bar dataKey="Federal Tax Revenue" stackId="a" fill={COLORS[3]} />
        <Bar dataKey="Phone Revenue" stackId="a" fill={COLORS[4]} />
      </BarChart>
    </GraphItem>
  );
};

const GraphTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444c66;
`;

const GraphItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-around;
  gap: 1em;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
