import EmployeeIcon from '@mui/icons-material/People';

import RegisteredAgentTransferList from './RegisteredAgentTransferList';
import RegisteredAgentTransferEdit from './RegisteredAgentTransferEdit';
import RegisteredAgentTransferView from './RegisteredAgentTransferView';

const resource = {
  list: RegisteredAgentTransferList,
  edit: RegisteredAgentTransferEdit,
  show: RegisteredAgentTransferView,
  icon: EmployeeIcon,
};

export default resource;
