import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import SubmitButton from '../SubmitButton';
import { CustomSelect } from '../../../../components/CustomSelect';

const OALLCProfitDistributionFrequencyForm = ({ data, instance }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(
        `company-legal-documents/operating-agreement/${companyId}/oa-profit-distribution-freq`,
        { data }
      )
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="Frequency of LLC distribution of profits"
            name="profitDistributionFrequency"
            defaultValue={instance.profitDistributionFrequency}
            options={[
              {
                label: 'Anytime member decides/ Anytime all members unanimously decide',
                value: 'ANYTIME_MEMBER_DECIDES',
              },
              { label: 'Board decides', value: 'BOARD_DECIDES' },
              { label: 'Monthly', value: 'MONTHLY' },
              { label: 'Quarterly', value: 'QUARTERLY' },
              { label: 'Semi-annually', value: 'SEMI_ANNUALLY' },
              { label: 'Annually', value: 'ANNUALLY' },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Update" />
        </Grid>
      </Grid>
    </form>
  );
};

export default OALLCProfitDistributionFrequencyForm;
