import { Box, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import SendIcon from '@mui/icons-material/Send';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';

export interface InvoiceProps {
  data: any;
}
const nf = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

export const Invoices = (props: InvoiceProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getDateFormatted = (date: string) => {
    let dt = new Date(date);
    date = dt.toLocaleDateString();
    return date;
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            {props.data?.length > 0 ? (
              // <TableContainer component={Paper}>
              //     <Table sx={{ minWidth: 650 }} aria-label="simple table">
              //         <TableHead>
              //             <TableRow>
              //                 <TableCell>Start Date</TableCell>
              //                 <TableCell>End Date</TableCell>
              //                 <TableCell align="right">Amount Due</TableCell>
              //                 <TableCell align="right">Amount Remaining</TableCell>
              //                 <TableCell align="center">Status</TableCell>
              //                 <TableCell align="center">Invoice</TableCell>
              //                 <TableCell align="center">Receipt</TableCell>
              //             </TableRow>
              //         </TableHead>
              //         <TableBody>
              //             {props.data.map((row : any) => (
              //                 <TableRow
              //                     key={row.id}
              //                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              //                 >
              //                     <TableCell>
              //                         {getDateFormatted(row.periodStartDate)}
              //                     </TableCell>
              //                     <TableCell>
              //                         {getDateFormatted(row.periodEndDate)}
              //                     </TableCell>
              //                     <TableCell align="right">{nf.format(row.amountDue)}</TableCell>
              //                     <TableCell align="right">{nf.format(row.amountRemaining)}</TableCell>
              //                     <TableCell align="center"><Chip label={row.status.toUpperCase()} color={getVariantBasedOnSubscriptionStatus(row.status)} variant="filled" /></TableCell>
              //                     <TableCell align="center">
              //                         <Button variant="contained" onClick={()=>{window.open(row.hostedInvoiceUrl, "_blank");}} endIcon={<SendIcon/>}>Invoice</Button>
              //                     </TableCell>
              //                     <TableCell align="center">
              //                         <Button variant="contained" onClick={()=>{window.open(row.invoicePdf, "_blank");}} endIcon={<DownloadIcon/>}>Receipt</Button>
              //                     </TableCell>
              //                 </TableRow>
              //             ))}
              //         </TableBody>
              //     </Table>
              // </TableContainer>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell align="right">Amount Due</TableCell>
                      <TableCell align="right">Amount Remaining</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Invoice</TableCell>
                      <TableCell align="center">Receipt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : props.data
                    ).map((row: any) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>{getDateFormatted(row.periodStartDate)}</TableCell>
                        <TableCell>{getDateFormatted(row.periodEndDate)}</TableCell>
                        <TableCell align="right">{nf.format(row.amountDue)}</TableCell>
                        <TableCell align="right">{nf.format(row.amountRemaining)}</TableCell>
                        <TableCell align="center">
                          <Chip
                            label={row.status.toUpperCase()}
                            color={getVariantBasedOnSubscriptionStatus(row.status)}
                            variant="filled"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            onClick={() => {
                              window.open(row.hostedInvoiceUrl, '_blank');
                            }}
                            endIcon={<SendIcon />}
                          >
                            Invoice
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            onClick={() => {
                              window.open(row.invoicePdf, '_blank');
                            }}
                            endIcon={<DownloadIcon />}
                          >
                            Receipt
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={8}
                        count={props.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            ) : (
              <div>No invoice found !!!</div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }} style={{ display: 'flex' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const getVariantBasedOnSubscriptionStatus = (d: any) => {
  switch (d + '') {
    case 'paid':
      return 'success';
    case 'open':
      return 'error';
    default:
      return 'info';
  }
};
