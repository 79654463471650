import * as React from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  SelectField,
  SelectInput,
  TopToolbar,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { Months } from './../../config/constants';
import { KPIParameterType } from './../../config/constants';
import { KPIState } from './../../config/constants';
import { KPIType } from './../../config/constants';
const MonthsArray = Months.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPIParameterTypeArray = KPIParameterType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPIStateArray = KPIState.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPITypeArray = KPIType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <BooleanInput source="isValid" label="Active" alwaysOn />,
  <TextInput source="year" label="Year" alwaysOn />,
  <SelectInput source="month" label="Month" choices={MonthsArray} alwaysOn />,
  <SelectInput source="type" label="KPI Type" choices={KPITypeArray} />,
  <SelectInput source="state" label="State" choices={KPIStateArray} />,
  <SelectInput source="parameter" label="Parameter Type" choices={KPIParameterTypeArray} />,
];

const KPIInstanceParamListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      {/* <CreateButton/> */}
      <ExportButton />
    </TopToolbar>
  );
};

const KPIInstanceParamList = () => {
  return (
    <List
      filters={Filters}
      filterDefaultValues={{ isValid: 'true' }}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<KPIInstanceParamListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="type" label="KPI Type" />
        <TextField source="state" label="State" />
        <TextField source="parameter" label="Parameter Type" />
        <TextField source="description" label="Description" />
        <TextField source="value" label="Value" />
        <TextField source="year" label="Year" />
        <SelectField source="month" label="Month" choices={MonthsArray} />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default KPIInstanceParamList;
