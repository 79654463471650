import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import SubmitButton from '../SubmitButton';
import { CustomTextField, CustomTextFieldStyled } from '../../../../components/CustomTextField';

const OAChangesDaysToBuyIntstAfterJointPrdForm = ({ data, instance }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    // dataProvider.post(`company-legal-documents/operating-agreement/${companyId}`, { data })
    //     .then(({ data, error }) => {
    //         if (data?.key === 'status' && data?.value === 'ok') {
    //             notify("OA Deleted successfully", 'success');
    //             return;
    //         }
    //         if (error) {
    //             throw new Error(error);
    //         }
    //     }).catch(error => {
    //         notify(error.message || error.error || error.code || error, 'error');
    //     }).finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomTextFieldStyled
            control={control}
            label="If the LLC members choose not to buy out the withdrawing member's interest as a group, how many days will be given for individual member(s) to buy the interest AFTER the joint buy-out period has expired?"
            name="daysToBuyIntrstAftrJntPrd"
            defaultValue={instance?.daysToBuyIntrstAftrJntPrd}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Update" />
        </Grid>
      </Grid>
    </form>
  );
};

export default OAChangesDaysToBuyIntstAfterJointPrdForm;
