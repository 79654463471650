import EmployeeIcon from '@mui/icons-material/People';

import TaxEstimatorAddonList from './TaxEstimatorAddonList';

const resource = {
  list: TaxEstimatorAddonList,
  icon: EmployeeIcon,
};

export default resource;
