const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * dateStr is a string of "YYYY-MM-DD" format
 * Backend expects the input in the form dd MMMMM, yyyyy
 */
const transformedDateDDMMMMYYYY = (dataStr) => {
  const match = /(\d{4})-(\d{2})-(\d{2})/.exec(dataStr);
  if (match === null) return null;
  return `${match[3]} ${monthNames[match[2] - 1]}, ${match[1]}`;
};

export const transformDate = (dateStr, pattern) => {
  if (pattern === 'dd MMMM, yyyy') {
    return transformedDateDDMMMMYYYY(dateStr);
  }
};

export const zeroPad = (d) => ('' + d).padStart(2, '0');
export const getDateddMMYYYY = (d, m, y) => `${zeroPad(d)}/${zeroPad(m)}/${y}`;

export const getFranchiseName = (id) => {
  switch (id) {
    case 1:
      return 'TRYSTART';
    case 3:
      return 'StartGlobal';
    case 4:
      return 'Publicnote';
    case 5:
      return 'Alphafilings';
    case 6:
      return 'SmartStewards';
    default:
      return 'Error';
  }
};

export const getVariantBasedOnIncorporationStatus = (d) => {
  switch (d + '') {
    case 'PENDING_PAYMENT':
      return 'error';
    case 'PENDING_VERIFICATION':
      return 'warning';
    case 'STARTED':
      return 'success';
    case 'COMPLETED':
      return 'success';
    case 'REJECTED':
      return 'info';
    case 'FREEZED':
      return 'error';
    case 'ONHOLD':
      return 'error';
    default:
      return 'info';
  }
};
export const getVariantBasedOnPaymentStatus = (d) => {
  switch (d + '') {
    case 'PAID':
      return 'success';
    case 'NOT_PAID':
      return 'error';
    default:
      return 'info';
  }
};

export const hasPermission = (_permission, permissions) => {
  if (!permissions || !Array.isArray(permissions)) {
    return false;
  }
  if (Array.isArray(_permission)) {
    const common = _permission.filter(function (n) {
      return permissions.indexOf(n) !== -1;
    });
    return common.length > 0;
  }
  return permissions.indexOf(_permission) >= 0;
};
