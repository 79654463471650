import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  email,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  BooleanInput,
} from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { ListHeaderActionsEdit } from '../../components/ListHeaderActions';
import { Row } from '../../components/Row';
import { validateForm } from './CompanyArchivedMasterValidateForm';

const CompanyArchivedMasterEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />}>
      <SimpleForm defaultValues={{}} validate={validateForm}>
        <Row>
          <div style={{ width: '100%' }}>
            <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
              <Box mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="companyId" label="Company Ref." disabled />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput
                  disabled
                  label="Company"
                  source="companyId"
                  reference="company-master-data"
                >
                  <AutocompleteInput disabled optionText="companyFullName" />
                </ReferenceInput>
              </Box>
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <DateTimeInput source="startDateTime" label="Started" disabled />
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="reason" label="Reason" multiline resettable fullWidth isRequired />
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <BooleanInput source="isValid" label="Archived" />
            </Box>
          </div>
        </Row>

        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const CompanyArchivedWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Address = styled.div`
  width: 100%;
`;
export default CompanyArchivedMasterEdit;
