import EmployeeIcon from '@mui/icons-material/People';

import OperatingAgreementAddonList from './OperatingAgreementAddonList';

const resource = {
  list: OperatingAgreementAddonList,
  icon: EmployeeIcon,
};

export default resource;
