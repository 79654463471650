import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  email,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
  useShowController,
} from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../../components/SectionTitle';
import Separator from '../../../components/Separator';
import TextInput from '../../../components/TextInput';
import DateInput from '../../../components/DateInput';
import { ListHeaderList } from '../../../components/ListHeaderActions';
import { Row } from '../../../components/Row';
import { WorkflowStatus } from '../../../config/constants';
import { Grid, Typography } from '@mui/material';
import Container from '@mui/system/Container';
import { StateTaxStatus } from './StateTaxStatus';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const IndividualExtensionEdit = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  return (
    <Edit {...props} actions={<ListHeaderList />}>
      <SimpleForm defaultValues={{}}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div>
              <Label>Individual Details</Label>
              <div style={{ marginTop: '0.2em' }}>
                <Box>
                  <Typography variant="body2">
                    {record?.firstName} {record?.lastName}
                  </Typography>
                  <Typography variant="body2">{record?.addressLine1}</Typography>
                  <Typography variant="body2">{record?.city}</Typography>
                  <Typography variant="body2">
                    {record?.state} {record?.postalCode}
                  </Typography>
                  <Typography variant="body2">{record?.country}</Typography>
                  <Typography variant="body2">{record?.email}</Typography>
                </Box>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="body2">
                US Resident : {record?.isUsResident ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                US Citizen : {record?.isUsCitizen ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">SSN : {record?.ssn ? record?.ssn : 'N/A'}</Typography>
              <Typography variant="body2">
                Joint Filing : {record?.isJointFiling ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                Spouse SSN : {record?.spousesSsn ? record?.spousesSsn : 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="body2">
                Form 1040 Required: {record?.isForm1040Required ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                Have Receieved Wages : {record?.haveReceivedWagesAsEmployee ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">ITIN : {record?.itin ? record?.itin : 'N/A'}</Typography>
              <Typography variant="body2">
                Estimated Tax : {record?.estimatedTax ? record?.estimatedTax : 'N/A'}
              </Typography>
              <Typography variant="body2">
                Tax Paid : {record?.taxPaid ? record?.taxPaid : 'N/A'}
              </Typography>
              <Typography variant="body2">
                Is Paying Remaining Full Amount :{' '}
                {record?.isPayingRemainingFullAmount ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                Remaining Amount Payable :{' '}
                {record?.remainingAmountPayable ? record?.remainingAmountPayable : 'N/A'}
              </Typography>
              <Typography variant="body2">
                Follows Calendar Tax Year :{' '}
                {record?.followsCalendarYearForTaxPurpose ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                Tax Start Date : {record?.taxStartDate ? record?.taxStartDate : 'N/A'}
              </Typography>
              <Typography variant="body2">
                Tax End Date : {record?.taxEndDate ? record?.taxEndDate : 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <StateTaxStatus data={record} />
            </Box>
          </Grid>
        </Grid>

        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

export default IndividualExtensionEdit;
