import IndividualExtensionList from './IndividualExtensionList';
import IndividualExtensionEdit from './IndividualExtensionEdit';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import IndividualExtensionShow from './IndividualExtensionShow';

const resource = {
  list: IndividualExtensionList,
  show: IndividualExtensionShow,
  edit: IndividualExtensionEdit,
  icon: AttachEmailIcon,
};

export default resource;
