// in src/posts.js
import * as React from 'react';
import { useForm } from 'react-hook-form';
import SubmitButton from './SubmitButton';

import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import { CustomTextField } from '../../../components/CustomTextField';

const ResponsiblePartyDetailsForm = ({ data }) => {
  const details = data.company.responsiblePartyDetails;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`update/company/${companyId}/responsible-party-details`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="First Name"
            name="firstName"
            defaultValue={details.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Last Name"
            name="lastName"
            defaultValue={details.lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Address Line1"
            name="addressLine1"
            defaultValue={details.addressLine1}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField control={control} label="City" name="city" defaultValue={details.city} />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="State"
            name="stateOrProvince"
            defaultValue={details.stateOrProvince}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Country"
            name="country"
            defaultValue={details.country}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Postal Code"
            name="postalCode"
            defaultValue={details.postalCode}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <SubmitButton processing={processing} label="Update Responsible Party Details" />
        </Grid>
      </Grid>
    </form>
  );
};

export default ResponsiblePartyDetailsForm;
