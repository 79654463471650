import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  email,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
  useShowController,
} from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../../components/SectionTitle';
import Separator from '../../../components/Separator';
import TextInput from '../../../components/TextInput';
import DateInput from '../../../components/DateInput';
import { ListHeaderList } from '../../../components/ListHeaderActions';
import { Row } from '../../../components/Row';
import { WorkflowStatus } from './../../../config/constants';
import { Grid, Typography } from '@mui/material';
import { StateTaxStatus } from './StateTaxStatus';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const BusinessExtensionEdit = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  return (
    <Edit {...props} actions={<ListHeaderList />}>
      <SimpleForm defaultValues={{}}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div>
              <Label>Business Details</Label>
              <div style={{ marginTop: '0.2em' }}>
                <Box>
                  <Typography variant="body2">{record?.entityName}</Typography>
                  <Typography variant="body2">{record?.addressLine1}</Typography>
                  <Typography variant="body2">{record?.city}</Typography>
                  <Typography variant="body2">
                    {record?.state} {record?.postalCode}
                  </Typography>
                  <Typography variant="body2">{record?.country}</Typography>
                  <Typography variant="body2">{record?.email}</Typography>
                </Box>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="body2">
                US Corporation : {record?.isUsCorporation ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                EIN : {record?.einNumber ? record?.einNumber : 'N/A'}
              </Typography>
              <Typography variant="body2">
                Form : {record?.formForFilingExtension ? record?.formForFilingExtension : 'N/A'}
              </Typography>
              <Typography variant="body2">
                Calendar Year : {record?.followsCalendarYearForTaxPurpose ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body2">
                Date : {record?.taxStartDate} - {record?.taxEndDate}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="body2">
                Tax less than 12 months: {record?.isTaxLessThan12Months ? 'YES' : 'No'}
              </Typography>
              <Typography variant="body2">
                Reason (If applicable) :{' '}
                {record?.whyIsLessThan12Months ? record?.whyIsLessThan12Months : 'N/A'}
              </Typography>
              <Typography variant="body2">Tax Owed : {record?.totalTaxToBeOwed}</Typography>
              <Typography variant="body2">Tax Paid : {record?.totalTaxPaid}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <StateTaxStatus data={record} />
            </Box>
          </Grid>
        </Grid>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};
const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

export default BusinessExtensionEdit;
