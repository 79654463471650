import * as React from 'react';
import styled from '@emotion/styled';
import { Show, ShowProps, SimpleShowLayout, Tab, TabbedShowLayout } from 'react-admin';
import { Grid } from '@mui/material';

import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import LabelField from '../../components/LabelField';
import { LabelType } from '../../config/LabelType';
import { ListHeaderActionsView } from '../../components/ListHeaderActions';
import ReferenceField from '../../components/ReferenceField';

const EmployeeCompensationMasterView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderActionsView />}>
      <TabbedShowLayout>
        <Tab label="Employee Compensation Detail">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SimpleShowLayout>
                <EmployeeDetails>
                  <LabelField source="id" label="Ref." fullWidth labelType={LabelType.TEXT_FIELD} />
                  <ReferenceField source="employeeId" reference="sg-employee-master">
                    <LabelField
                      source="fullName"
                      label="Name"
                      fullWidth
                      labelType={LabelType.TEXT_FIELD}
                    />
                  </ReferenceField>
                  <LabelField
                    source="currency"
                    label="Currency"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="baseSalary"
                    label="Base salary"
                    fullWidth
                    labelType={LabelType.NUMBER_FIELD}
                  />
                  <LabelField
                    source="totalSalary"
                    label="Total salary"
                    fullWidth
                    labelType={LabelType.NUMBER_FIELD}
                  />
                  <LabelField
                    source="startDate"
                    label="Start date"
                    fullWidth
                    labelType={LabelType.DATE_FIELD}
                  />
                  <LabelField
                    source="endDate"
                    label="End date"
                    fullWidth
                    labelType={LabelType.DATE_FIELD}
                  />
                  <LabelField
                    source="remarks"
                    label="Remarks"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="category"
                    label="Category"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                </EmployeeDetails>
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const EmployeeDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default EmployeeCompensationMasterView;
