import { email } from 'react-admin';

export const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;
  if (!values.type) {
    errors.type = 'ra.validation.required';
  }
  if (!values.parameter) {
    errors.parameter = 'ra.validation.required';
  }
  if (!values.description) {
    errors.description = 'ra.validation.required';
  }
  if (!values.value) {
    errors.value = 'ra.validation.required';
  }

  return errors;
};
