import BusinessExtensionList from './BusinessExtensionList';
import BusinessExtensionEdit from './BusinessExtensionEdit';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import BusinessExtensionShow from './BusinessExtensionShow';

const resource = {
  list: BusinessExtensionList,
  show: BusinessExtensionShow,
  edit: BusinessExtensionEdit,
  icon: AttachEmailIcon,
};

export default resource;
