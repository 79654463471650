import EmployeeIcon from '@mui/icons-material/People';

import EinServiceList from './EinServiceList';
import EinServiceView from './EinServiceView';

const resource = {
  list: EinServiceList,
  show: EinServiceView,
  icon: EmployeeIcon,
};

export default resource;
