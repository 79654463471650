import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from '@emotion/styled';
import React from 'react';
import dataProvider from '../../../config/dataProvider';
import { useNotify } from 'react-admin';

export interface WorkflowStatusProps {
  data: any;
}
export const WorkflowStatusForm = (props: WorkflowStatusProps) => {
  const [taxStatus, setTaxStatus] = useState('NOT_STARTED');
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const notify = useNotify();
  const [upload, setUpload] = useState(false);

  const handleUploadSuccess = (logo: any) => {
    setUpload(true);
  };
  const handleUploadError = () => {
    setUpload(false);
  };
  React.useEffect(() => {
    if (props.data) {
      setTaxStatus(props.data?.workflowStatus);
    }
  }, [props.data]);
  const handleChange = (event: SelectChangeEvent) => {
    setTaxStatus(event.target.value);
  };

  const submit = (_data: any) => {
    setProcessing(true);
    _data = {
      ..._data,
      id: props.data?.id,
      workflowStatus: taxStatus,
    };
    dataProvider
      .post(`addons/addon_master/${props.data?.id}/workflowStatus`, { data: _data })
      .then(({ data, error }) => {
        if (data?.workflowStatus) {
          notify('Data updated successfully', { type: 'success' });
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Wrapper>
      <StatusUpdate>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Workflow Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={taxStatus}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={'NOT_STARTED'}>Not Started</MenuItem>
            <MenuItem value={'STARTED'}>Started</MenuItem>
            <MenuItem value={'PROCESSED'}>Processed</MenuItem>
            <MenuItem value={'ONHOLD'}>Onhold</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          loading={processing}
          variant="contained"
          loadingPosition="end"
          type="submit"
          fullWidth
          onClick={() => submit(data)}
        >
          {'Update Tax Status'}
        </LoadingButton>
      </StatusUpdate>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
`;

const StatusUpdate = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 50%;
`;
