import * as React from 'react';
import styled from '@emotion/styled';
import { SimpleForm, ShowProps, Edit, email, SelectInput, BooleanInput } from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { ListHeaderActionsEdit } from '../../components/ListHeaderActions';
import { Row } from '../../components/Row';
import { validateForm } from './EmailRegistrationValidateForm';

const EmailRegistrationEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />}>
      <SimpleForm validate={validateForm}>
        <ConfigWrapper>
          <ConfigDetais>
            <SectionTitle label="Email Registration Details" />
            <TextInput source="id" label="Ref." disabled fullWidth />
            <TextInput source="email" label="Email" fullWidth isRequired />
            <BooleanInput source="valid" label="Active" />
          </ConfigDetais>
        </ConfigWrapper>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const ConfigWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const ConfigDetais = styled.div`
  width: 100%;
`;

export default EmailRegistrationEdit;
