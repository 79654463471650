import * as React from 'react';
import styled from '@emotion/styled';
import { EmailFieldProps } from 'react-admin';
import { EmailField as EmailFieldDefault } from 'react-admin';

export interface EmailFieldProp extends EmailFieldProps {}

const EmailField = (props: EmailFieldProp) => {
  return (
    <EmailFieldDefault source={props.source} label={props.label} fullWidth={props.fullWidth} />
  );
};

export default EmailField;
