import styled from '@emotion/styled';
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import * as React from 'react';
import {
  Button,
  Datagrid,
  Edit,
  EditButton,
  FileField,
  FileInput,
  FunctionField,
  Labeled,
  SelectField,
  SelectInput,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useDataProvider,
  useNotify,
  useShowController,
  SimpleForm,
  DateInput,
  DateTimeInput,
} from 'react-admin';

import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import LabelField from '../../components/LabelField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { KPIParameterType } from '../../config/constants';
import { KPIState } from '../../config/constants';
import { KPIType } from '../../config/constants';
import { EINProvider } from '../../config/constants';
import { StateTaxStatus } from '../accounting/state-tax/StateTaxStatus';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { ListHeaderList } from '../../components/ListHeaderActions';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
  any,
} from 'prop-types';
import { LabelType } from '../../config/LabelType';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import dataProvider from '../../config/dataProvider';

import { useForm } from 'react-hook-form';
import { CustomTextField } from '../../components/CustomTextField';
import { Alert, Divider } from '@mui/material';
import { ListHeaderActionsEdit, ListHeaderActionsView } from '../../components/ListHeaderActions';
import Separator from '../../components/Separator';
import SectionTitle from '../../components/SectionTitle';
import { validateForm } from './ActionItemValidatorForm';
import { ActionStatus } from '../../config/constants';
const ActionStatusArray = ActionStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const tranformData = (data: any) => {
  return {
    ...data,
    scheduledAt:
      typeof data.scheduledAt === 'string'
        ? data.scheduledAt
        : data.scheduledAt?.toISOString().slice(0, 19),
  };
};
const dateString = (v: string | number | Date) => {
  /*
   * since v parameter coming is a string then you cannot use isNan
   * undefined is also coming as a parameter so !v is good equivalent
   */
  if (!v) return;

  let parsedDate = new Date(v);
  let adjustedDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);

  /*
   * since parameter coming in is a string you need to pass string further, that's why I added toISOString() call
   */
  return adjustedDate.toISOString().slice(0, 19);
};

const ActionItemEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsView />} transform={tranformData}>
      <SimpleForm>
        <ConfigWrapper>
          <ConfigDetais>
            <SectionTitle label="Action Details" />
            <TextInput source="id" label="Ref." disabled fullWidth />
            <TextInput source="companyId" label="Company Ref." disabled />

            <TextInput source="actionType" label="Action" fullWidth disabled />
            <SelectInput source="status" choices={ActionStatusArray} />

            <DateTimeInput
              source="scheduledAt"
              label="Scheduled At (UTC)"
              parse={dateString}
              fullWidth
            />
            <TextInput source="data" label="Data" />

            <TextInput source="remarks" label="Remarks" fullWidth />

            <DateInput
              source="createdDate"
              label="Created On"
              disabled
              style={{ marginRight: '1rem' }}
            />
            <DateInput source="modifiedDate" label="Last Modified On" disabled />
          </ConfigDetais>
        </ConfigWrapper>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

export default ActionItemEdit;

function data(data: any): void {
  throw new Error('Function not implemented.');
}
const ConfigWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const ConfigDetais = styled.div`
  width: 100%;
  & > * {
    width: 40%;
    margin: 0.5rem;
  }
`;
