import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import CompanyNameForm from '../forms/CompanyNameForm';
import styled from '@emotion/styled';
import EmailForm from '../forms/EmailForm';
import StateForm from '../forms/StateForm';
import WebsiteForm from '../forms/WebsiteForm';
import BusinessDescriptionForm from '../forms/BusinessDescriptionForm';
import CompanyPaymentForm from '../forms/CompanyPaymentForm';
import VerificationStatusForm from '../forms/VerificationStatusForm';
import CompanyNameAvailabilityForm from '../forms/CompanyNameAvailabilityForm';
import DateOfFormationForm from '../forms/DateOfFormationForm';
import StateFileNoForm from '../forms/StateFileNoForm';
import EINForm from '../forms/EINForm';
import ResponsiblePartyDetailsForm from '../forms/ResponsiblePartyDetailsForm';
import PassportDetailsForm from '../forms/PassportDetailsForm';
import Skeleton from '@mui/material/Skeleton';
import CompanyOwners from './CompanyOwners';

import { useNotify, useDataProvider } from 'react-admin';
import InitialDateOfFilingForm from '../forms/InitialDateOfFilingForm';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const CompanyUpdateDetails = (props: { data: any; subTab?: string }) => {
  console.log('update controller', props.data);
  const getTabValue = (subTab: string | undefined) => {
    switch (subTab) {
      case 'state-filing':
        return 3;
      default:
        return 0;
    }
  };

  const [value, setValue] = React.useState(getTabValue(props.subTab));
  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  if (!props?.data) {
    return null;
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} style={{ width: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
            style={{ width: '180px' }}
          >
            <StyledTab
              label="Company"
              {...a11yProps(0)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Owners"
              {...a11yProps(1)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab label="Status" {...a11yProps(2)} style={{ alignItems: 'flex-start' }} />
            <StyledTab
              label="State Filing"
              {...a11yProps(3)}
              style={{ alignItems: 'flex-start' }}
            />
            <StyledTab label="EIN" {...a11yProps(4)} style={{ alignItems: 'flex-start' }} />
            <StyledTab
              label="Responsible Party"
              {...a11yProps(5)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Passport Details"
              {...a11yProps(6)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
            <StyledTab
              label="Signatures"
              {...a11yProps(7)}
              style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <TabPanel value={value} index={0}>
            <CompanyNameForm data={props.data} />
            <EmailForm data={props.data} />
            <StateForm data={props.data} />
            <WebsiteForm data={props.data} />
            <BusinessDescriptionForm data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CompanyOwners data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CompanyPaymentForm data={props.data} />
            {[
              'PENDING_PAYMENT',
              'PENDING_VERIFICATION',
              'STARTED',
              'PENDING_FOR_DOCUMENT_VERIFICATION',
              'COMPLETED',
              'DISSOLVED',
            ].includes(props.data?.company?.incorporationStatus) && (
              <VerificationStatusForm data={props.data} />
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CompanyNameAvailabilityForm data={props.data} />
            <DateOfFormationForm data={props.data} />
            <InitialDateOfFilingForm data={props.data} />

            <StateFileNoForm data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <EINForm data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ResponsiblePartyDetailsForm data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <PassportDetailsForm data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <SignatureList data={props.data} />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;

function useForm(): { control: any; handleSubmit: any; formState: { errors: any } } {
  throw new Error('Function not implemented.');
}

const SignatureList = ({ data }: { data: any }) => {
  const companyId = data?.company?.id;
  const [loading, setIsloading] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    setIsloading(true);
    const url = `company-master-data/${companyId}/signatures`;
    dataProvider
      .get(url, {})
      .then(({ data, error }: { data: any; error: any }) => {
        console.log('signatures', data);
        setPayments(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error: any) => {
        notify(error.message || error.error || error.code || error);
      })
      .finally(() => setIsloading(false));
  }, []);
  if (loading) {
    return null;
  }

  return payments && Array.isArray(payments) && payments.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Purpose</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Sign Url</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments
            .filter((row: any) => {
              return (
                row.status !== 'CANCELED' && row.status !== 'DECLINED' && row.status !== 'REJECTED'
              );
            })
            .map((row: any, index) => (
              <SignatureRow row={row} index={payments.length - index} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div style={{ alignSelf: 'center' }}>No signature requests found !!!</div>
  );
};

const SignatureRow = ({ row, index }: { row: any; index: number }) => {
  const notify = useNotify();

  return (
    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="left">{index}</TableCell>
      <TableCell component="th" scope="row">
        {row.purpose}
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.createdDate}</TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(row.signUrl);
            notify('Signature URL copied to clipboard!');
          }}
        >
          Copy URL
        </Button>
      </TableCell>
    </TableRow>
  );
};
