import * as React from 'react';
import styled from '@emotion/styled';
import { DateFieldProps } from 'react-admin';
import { DateField as DateFieldDefault } from 'react-admin';

export interface DateFieldProp extends DateFieldProps {}

const DateField = (props: DateFieldProp) => {
  return <DateFieldDefault source={props.source} label={props.label} fullWidth={props.fullWidth} />;
};

export default DateField;
