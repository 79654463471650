import React from 'react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

enum Status {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

type FranchiseTaxInputProps = {
  companyId: number;
  id: number;
  company: string;
  amount: number;
  stateFileNo: string;
  checked?: boolean;
  status?: Status;
  error?: string;
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  minWidth: 500,
  maxHeight: 800,
  bgcolor: 'background.paper',
  border: '2px solid #eee',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const WYFranchiseTax = () => {
  const [processing, setIsProcessing] = useState(false);
  const [taxProcessing, setIsTaxProcessing] = useState(false);
  const [stopAll, setStopAll] = useState(false);

  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const [companies, setCompanies] = React.useState<FranchiseTaxInputProps[]>([]);
  const [error, setError] = React.useState('');

  const [selectAll, setSelectAll] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * This is the initial trigger
   * We fetch the latest list of all the companies that have outstanding franchise tax payments
   *
   */
  const onClickOfDeFranchiseTax = () => {
    setIsProcessing(true);
    dataProvider
      .get(`franchise-tax/list/WYOMING`)
      .then((data: { data: FranchiseTaxInputProps[] }, error: any) => {
        console.log(data);
        if (data['data'].length == 0) {
          throw new Error('No data found');
        }
        setCompanies(
          data['data']
            .filter((c) => {
              return (
                c.company.toUpperCase().endsWith('LLC') ||
                c.company.toUpperCase().endsWith('L.L.C.') ||
                c.company.toUpperCase().endsWith('L.L.C') ||
                c.company.toUpperCase().endsWith('LIMITED LIABILITY COMPANY')
              );
            })
            .map((c) => {
              return {
                ...c,
                checked: true, //by default, all the companies are selected
                status: Status.NOT_STARTED,
              };
            })
        );
        setOpen(true);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => setIsProcessing(false));
  };

  /**
   * Common utility function to update the status of the company along with error message
   */
  const updateCompanyStatus = (companyId: number, status: Status, error?: string) => {
    setCompanies((prevState) => {
      return prevState.map((c) => {
        if (c.companyId === companyId) {
          return {
            ...c,
            status: status,
            error: error,
          };
        }
        return c;
      });
    });
    updateCompanies();
  };
  /**
   * This method will fiter out completed companies from the UI.
   * This is done to avoid the UI getting cluttered with completed companies
   * For the time being, we are showing the last completed company
   */
  const updateCompanies = () => {
    setCompanies((prevState) => {
      return prevState.filter((c, index) => {
        return (
          c.status !== Status.COMPLETED ||
          (c.status === Status.COMPLETED && index === getLastIndexWithStatus(Status.COMPLETED))
        );
      });
    });
  };
  const getLastIndexWithStatus = (status: Status) => {
    let index = -1;
    for (let i = companies.length - 1; i >= 0; i--) {
      if (companies[i].status === status) {
        index = i;
        break;
      }
    }
    return index;
  };
  const processFranchiseTaxOfSelectedCompanies = async () => {
    setIsTaxProcessing(true);
    setStopAll(false);
    const selectedCompanies = companies.filter((c) => c.checked && c.status != Status.COMPLETED);
    if (selectedCompanies.length == 0) {
      alert('Please select atleast one company');
      return;
    }
    for (let i = 0; i < selectedCompanies.length; i++) {
      if (stopAll) {
        setCompanies((prevState) => {
          return prevState.map((c) => {
            if (c.status === Status.IN_PROGRESS) {
              return {
                ...c,
                status: Status.NOT_STARTED,
              };
            }
            return c;
          });
        });

        break;
      }
      const company = selectedCompanies[i];
      updateCompanyStatus(company.companyId, Status.IN_PROGRESS);
      //all api
      try {
        const inputdata = {
          id: company.id,
          companyId: company.companyId,
          company: company.company,
          amount: company.amount,
          stateFileNo: company.stateFileNo,
        };
        const _data = await dataProvider.postLocal(`franchise-tax/wyoming`, { data: inputdata });
        console.log(_data);
        updateCompanyStatus(company.companyId, Status.COMPLETED);
      } catch (err: any) {
        updateCompanyStatus(company.companyId, Status.FAILED, err?.message);
      }
    }
    setIsTaxProcessing(false);
  };

  const getNoncompletedCompanyCount = () => {
    return companies.filter((c) => c.status != Status.COMPLETED).length;
  };
  const getNoncompletedAndCheckedCompanyCount = () => {
    return companies.filter((c) => c.status != Status.COMPLETED && c.checked).length;
  };
  return (
    <Box sx={{ minWidth: 275, maxWidth: 500 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" component="div">
            Franchise Tax Payments
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Wyoming
          </Typography>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            You need Startglobal-local-admin docker running in your local machine
          </Typography>
          <br />
          <br />
          <Typography variant="body2">
            This process will list all the outstanding franchise tax payments, pay them and then
            mark them as paid in the system.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            disabled={processing}
            variant="contained"
            size="small"
            onClick={onClickOfDeFranchiseTax}
          >
            Run Now
          </Button>
        </CardActions>
        {error && <Alert severity="error">{error}</Alert>}
      </Card>

      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">List of companies</h2>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="select-all"
                    value="true"
                    color="primary"
                    checked={selectAll}
                    disabled={taxProcessing}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      console.log(e.target.checked);
                      setSelectAll(e.target.checked);
                      setCompanies(
                        companies.map((c) => {
                          return {
                            ...c,
                            checked: e.target.checked,
                          };
                        })
                      );
                    }}
                  />
                }
                label={`Select All (${getNoncompletedCompanyCount()})`}
              />
              <Button
                disabled={!taxProcessing}
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  setStopAll(true);
                }}
              >
                Stop All
              </Button>

              <Button
                disabled={taxProcessing}
                variant="contained"
                size="small"
                color="primary"
                onClick={processFranchiseTaxOfSelectedCompanies}
              >{`Process Now (${getNoncompletedAndCheckedCompanyCount()})`}</Button>
            </Box>
          </Box>
          <List
            sx={{
              width: '100%',
              maxWidth: 750,
              maxHeight: 500,
              overflow: 'scroll',
              bgcolor: 'background.paper',
            }}
          >
            {Array.isArray(companies) &&
              companies
                .filter((c, index) => {
                  //We are filtering completed companies only if they are not the last one
                  return (
                    c.status !== Status.COMPLETED ||
                    (c.status === Status.COMPLETED &&
                      index === getLastIndexWithStatus(Status.COMPLETED))
                  );
                })
                .map((company) => {
                  return (
                    <>
                      <ListItem key={company.companyId} alignItems="flex-start">
                        <Checkbox
                          checked={company.checked}
                          data-company-id={company.companyId + ''}
                          data-a="dbd"
                          color="primary"
                          inputProps={{
                            // @ts-ignore
                            'data-company-id': company.companyId,
                          }}
                          disabled={taxProcessing}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const companyId = e.target.getAttribute('data-company-id');
                            if (companyId != null) {
                              const _company = companies.find((c) => c.companyId === +companyId);
                              if (_company != null) {
                                setCompanies((prevState) => {
                                  return prevState.map((c) => {
                                    if (c.companyId === +companyId) {
                                      return {
                                        ...c,
                                        checked: e.target.checked,
                                      };
                                    }
                                    return c;
                                  });
                                });
                                if (!e.target.checked) {
                                  //user has unselected the checkbox of atleast one company.
                                  setSelectAll(false);
                                }
                              }
                            }
                          }}
                        />

                        <ListItemText
                          primary={company.company}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {company.stateFileNo}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      {company.status === Status.IN_PROGRESS && (
                        <Alert severity="info">Processing Now...</Alert>
                      )}
                      {company.status === Status.COMPLETED && (
                        <Alert severity="success">Processed Successfully...</Alert>
                      )}
                      {company.error && company.status === Status.FAILED && (
                        <Alert severity="error">{company.error}</Alert>
                      )}
                      {!company.error && company.status === Status.FAILED && (
                        <Alert severity="error">Oh no.. Please refresh.</Alert>
                      )}
                      <br />
                      <Divider variant="inset" component="li" />
                    </>
                  );
                })}
          </List>
          <Button onClick={handleClose}>Cancel</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default WYFranchiseTax;
