import styled from '@emotion/styled';
import { Link } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Button, useDataProvider, useGetOne, usePermissions } from 'react-admin';
import { hasPermission } from '../../lib/SGHelper';

export const BookkeeperChatHelp = (props: {
  companyId: any;
  chatId: any;
  onSuccess: () => void;
}) => {
  const [isloading, setIsloading] = useState(false);
  const dataProvider = useDataProvider();
  // const [payLoad, setPayLoad] = useState<any[]>([]);
  const [books, setBooks] = React.useState<any>();
  const [reload, setReload] = useState(false);
  const { data, isLoading, error } = useGetOne('company-master-data', { id: props.companyId });
  console.log(data);
  const [taxData, setTaxData] = React.useState<any>([]);
  const [summary, setSummary] = React.useState<any>();
  const { permissions } = usePermissions();
  const isSuperAdmin = hasPermission('ROLE_SUPER_ADMIN', permissions);

  React.useEffect(() => {
    setIsloading(true);
    Promise.all([
      dataProvider.get(`company_bookkeeping_master/company/${props.companyId}`),
      dataProvider.get(`federal-tax/company/${props.companyId}`),
      dataProvider.get(`company_bookkeeping_master/company/${props.companyId}/status`),
    ])
      .then((values) => {
        setBooks(() =>
          values[0].data.find((item: any) => item.status === 'ACTIVE' && item.year == 2022)
        );
        setTaxData(values[1].data.reverse());
        setSummary(values[2].data.yearSummary);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsloading(false));
  }, [props.companyId]);

  // React.useEffect(() => {
  //     setIsloading(true);
  //     const url = `company_bookkeeping_master/company/${props.companyId}`
  //     dataProvider.get(url, {})
  //         .then(( data:any, error:any ) => {
  //             setBooks( () => data.data.find((item:any)=>(item.status === "ACTIVE")));
  //             if (error) {
  //                 throw new Error(error);
  //             }
  //         }).catch((error:any) => {
  //             // notify(error.message || error.error || error.code || error, 'error');
  //         }).finally(() => setIsloading(false));
  // }, []);

  // React.useEffect(() => {
  //     setIsloading(true);
  //     const url = `federal-tax/company/${props.companyId}`
  //     dataProvider.get(url, {  })
  //         .then(( data:any, error:any ) => {
  //             console.log("federal-tax",data)
  //             setTaxData(data.reverse());
  //             if (error) {
  //                 throw new Error(error);
  //             }
  //         }).catch((error:any) => {
  //             // notify(error.message || error.error || error.code || error, 'error');
  //         }).finally(() => setIsloading(false));
  // }, []);

  const closeChat = () => {
    dataProvider
      .post(`bookkeeper-chat/company/${props.companyId}/chats/${props.chatId}/close`, {})
      .then((data: any, error: any) => {
        if (error) {
          throw new Error(error);
        }
        props.onSuccess();
      })
      .catch((error: any) => {})
      .finally(() => {});
  };
  if (isLoading) {
    return (
      <Wrapper>
        {/* <div style={{fontWeight:'600'}}>COMPANY DETAILS</div> */}
        Loading...
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {/* <div style={{fontWeight:'600'}}>COMPANY DETAILS</div> */}

      <Items>
        <ItemWrapper>
          <div style={{ fontWeight: '600' }}>Company Name</div>
          <div>{data?.company?.companyName}</div>
        </ItemWrapper>
        <ItemWrapper>
          <div style={{ fontWeight: '600' }}>Company Type</div>
          {data?.company?.companyType === 'LLC' ? (
            data?.company?.owners.length === 1 ? (
              <div>SINGLE MEMBER LLC</div>
            ) : (
              <div>MULTI_MEMBER_LLC</div>
            )
          ) : data?.company?.incorporationType === 'C-Corporation' ? (
            <div>C-Corporation</div>
          ) : (
            <div>S-Corporation</div>
          )}
        </ItemWrapper>
        <ItemWrapper>
          {data?.company.owners && data.company.owners.length > 0 && (
            <>
              <div style={{ fontWeight: '600' }}>Owners</div>
              {data?.company.owners.map((owner: any) => (
                <div>
                  {owner?.firstName} {owner?.lastName}
                </div>
              ))}
            </>
          )}
        </ItemWrapper>

        <ItemWrapper>
          <div style={{ fontWeight: '600' }}>Registered Agent</div>
          {/* <div>{data?.registeredAgentDetails?.status}</div> */}
          <div>
            {'Date Of Formation '}{' '}
            {new Date(data?.registeredAgentDetails?.initialFilingDate).toLocaleDateString()}
          </div>
        </ItemWrapper>
        {books && books?.status && (
          <ItemWrapper>
            <div style={{ fontWeight: '600' }}>Bookkeeping</div>
            <div>{books?.paymentStatus}</div>
            <div>
              {books?.status} {'--'}{' '}
              {books?.status === 'CLOSED' ? 'BOOKS DELIVERED' : 'BOOKS NOT DELIVERED'}
            </div>
          </ItemWrapper>
        )}
        {taxData && taxData.length > 0 && (
          <ItemWrapper>
            <>
              <div style={{ fontWeight: '600' }}>Federal Tax</div>
              {taxData.map((tax: any) => (
                <div>
                  {tax?.year} {tax?.status}
                </div>
              ))}
            </>
          </ItemWrapper>
        )}
        {summary && books && (
          <ItemWrapper>
            <div style={{ fontWeight: '600' }}>Summary</div>
            <div>
              {'Transaction Count '} {books?.transactionCount}
            </div>
            <div>
              {'Pending to categorise '} {summary?.categoryNotEntered}
            </div>
            <div>
              {'Waiting to confirm and start bookkeeping'} {summary?.unConfirmed}
            </div>
            <div>
              {'Waiting for answer'} {summary?.queriesUnanswered}
            </div>
          </ItemWrapper>
        )}
        {isSuperAdmin && (
          <Link href={`#/company-master-data/${props.companyId}?tab=bookkeeping`}>
            MORE DETAILS
          </Link>
        )}
        <Button
          variant="contained"
          onClick={() => {
            closeChat();
          }}
          label="close"
        ></Button>
      </Items>
    </Wrapper>
  );
};

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
export default BookkeeperChatHelp;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  background: #fafafb;
  min-width: 20%;
  height: 70vh;
  padding: 12px 16px;
  border-radius: 6px;
  overflow: scroll;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
`;

const NoDataFound = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444c66;
  text-align: center;
`;
