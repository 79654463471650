import React, { useRef, useState } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import styled from '@emotion/styled';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import '../../../index.css';
import { amountFormat, percentageFormat } from './../../../lib/formatter';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { KpiTabs } from '../tabs/KpiTabs';
import TableViewIcon from '@mui/icons-material/TableView';
import { CSVLink, CSVDownload } from 'react-csv';

/**
 * We are only showing January to last month where revenue exists
 * This may or may not be december
 */

export const getKPIMonths = (kpis: any) => {
  const revenueRow = kpis.find((kpi: any) => kpi.parameter === 'TOTAL_REVENUE');
  let lastMonthWithRevenue = 12;
  for (let i = 12; i >= 1; i--) {
    const revenue = revenueRow['month' + (i >= 10 ? i : '0' + i)];
    if (revenue) {
      break;
    }
    lastMonthWithRevenue = lastMonthWithRevenue - 1;
  }
  return lastMonthWithRevenue;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;
const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 2em;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
`;

export const getFormatedValue = (value: number, type: string) => {
  switch (type) {
    case 'ADS_EXPENSES':
    case 'OPERATIONS_EXPENSES':
    case 'STATE_FILING_FORMATION_REVENUE':
    case 'TOTAL_STATE_FILING_FORMATION_REVENUE':
    case 'STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS':
    case 'REGISTERED_AGENT_PAYMENTS_REVENUE':
    case 'REGISTERED_AGENT_PAYMENTS_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_PROFIT':
    case 'FEDERAL_TAX_CCORP_REVENUE':
    case 'FEDERAL_TAX_CCORP_PROFIT':
    case 'FEDERAL_TAX_SINGLE_LLC_REVENUE':
    case 'FEDERAL_TAX_SINGLE_LLC_PROFIT':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_REVENUE':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT':
    case 'TOTAL_FEDERAL_TAX_REVENUE':
    case 'TOTAL_FEDERAL_TAX_PROFIT':
    case 'TOTAL_REVENUE':
    case 'TOTAL_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_PROFIT':
    case 'TOTAL_PROFIT_BEFORE_R_AND_D':
    case 'R_AND_D':
      return amountFormat(value);
    case 'PROFIT_PERCENTAGE':
      return percentageFormat(value);
    default:
      return value;
  }
};

export const getCustomClass = (value: number, type: string) => {
  switch (type) {
    case 'TOTAL_STATE_FILING_FORMATION_REVENUE':
    case 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS':
    case 'REGISTERED_AGENT_PAYMENTS_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_PROFIT':
    case 'TOTAL_PHONE_RECHARGES_PROFIT':
    case 'FEDERAL_TAX_CCORP_PROFIT':
    case 'FEDERAL_TAX_SINGLE_LLC_PROFIT':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT':
    case 'TOTAL_FEDERAL_TAX_PROFIT':
    case 'TOTAL_PROFIT':
    case 'TOTAL_PROFIT_BEFORE_R_AND_D':
      return 'row-higlight ' + getLabelColor(value);
    case 'PROFIT_PERCENTAGE':
      return 'row-higlight-profit ' + getLabelColor(value);
    default:
      return '';
  }
};

export const getFormatedYtdValue = (value: number, type: string) => {
  switch (type) {
    case 'FRANCHISE_TAX_PAYMENTS_PROFIT':
    case 'ADS_EXPENSES':
    case 'OPERATIONS_EXPENSES':
    case 'STATE_FILING_FORMATION_REVENUE':
    case 'TOTAL_STATE_FILING_FORMATION_REVENUE':
    case 'STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS':
    case 'REGISTERED_AGENT_PAYMENTS_REVENUE':
    case 'REGISTERED_AGENT_PAYMENTS_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_PROFIT':
    case 'FEDERAL_TAX_CCORP_REVENUE':
    case 'FEDERAL_TAX_CCORP_PROFIT':
    case 'FEDERAL_TAX_SINGLE_LLC_REVENUE':
    case 'FEDERAL_TAX_SINGLE_LLC_PROFIT':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_REVENUE':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT':
    case 'TOTAL_FEDERAL_TAX_REVENUE':
    case 'TOTAL_FEDERAL_TAX_PROFIT':
    case 'TOTAL_REVENUE':
    case 'TOTAL_PROFIT':
    case 'TOTAL_PROFIT_BEFORE_R_AND_D':
    case 'R_AND_D':
    case 'AMOUNT':
      return amountFormat(value);
    case 'PROFIT_PERCENTAGE':
    case 'PERCENTAGE':
      return percentageFormat(value);
    default:
      return value;
  }
};

export const getLabelColor = (value: number) => {
  if (value > 0) {
    return 'row-label-green';
  } else if (value < 0) {
    return 'row-label-red';
  } else {
    return '';
  }
};

export const KpiInstanceDetail = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [payLoad, setPayLoad] = useState<any[]>([]);
  const [report, setReport] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const CSVheaders = [
    { label: 'Description', key: 'description' },
    { label: 'January', key: 'month01' },
    { label: 'February', key: 'month02' },
    { label: 'March', key: 'month03' },

    { label: 'April', key: 'month04' },
    { label: 'May', key: 'month05' },
    { label: 'June', key: 'month06' },

    { label: 'July', key: 'month07' },
    { label: 'August', key: 'month08' },
    { label: 'September', key: 'month09' },

    { label: 'October', key: 'month10' },
    { label: 'November', key: 'month11' },
    { label: 'December', key: 'month12' },

    { label: 'YTD', key: 'ytd' },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    dataProvider
      .get(`kpi-service/finance/${year}`)
      .then((data: any) => {
        console.log('data', data);
        setPayLoad(data.data.monthWiseKpis);
      })
      .catch((error: any) => {
        console.log('error', error);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [year]);

  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(Number(event.target.value));
  };

  const onClickCalculateKpi = () => {
    console.log('onClickCalculateKpi');
  };

  const exportPdf = (data: any) => {
    setProcessing(true);
    data['REPORT_ID'] = 23;
    data['REPORT_TYPE'] = 'PDF';
    data['YEAR'] = year;
    dataProvider
      .get('reports/generate', { filter: data })
      .then((data: any) => {
        if (data && data.data.url) {
          window.open(data.data.url, '_blank');
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };
  const exportCSV = (data: any) => {
    console.log(payLoad);
    csvLinkRef.current?.link?.click();
  };
  /**
   * for kpi dashboard, we need all the years from 2021 to current year
   */
  const getKPIYears = () => {
    let currentYear = new Date().getFullYear();
    const minYear = 2021;
    const years = [];
    while (currentYear >= minYear) {
      years.push(currentYear);
      currentYear = currentYear - 1;
    }
    return years;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Wrapper>
      <HeaderWrap>
        <Header>KPI Finance Dashboard</Header>
        <FilterBar>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="year">Year</InputLabel>
            <Select
              labelId="year"
              id="year"
              value={year + ''}
              label="Year"
              onChange={handleChangeYear}
              style={{ color: '#000000' }}
            >
              {getKPIYears().map((y) => (
                <MenuItem value={y}>{y}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title="Search">
            <IconButton>
              <SearchIcon
                onClick={onClickCalculateKpi}
                style={{
                  cursor: 'pointer',
                  borderRadius: '4px',
                  padding: '4px',
                  background: '#c9cbcd3d',
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export to CSV">
            <IconButton onClick={() => exportCSV(report)}>
              <TableViewIcon
                style={{
                  cursor: 'pointer',
                  borderRadius: '4px',
                  padding: '4px',
                  background: '#c9cbcd3d',
                }}
              />
              <CSVLink
                data={payLoad}
                filename={'StartGlobal_KPI_' + year + '.csv'}
                style={{
                  display: 'none',
                  visibility: 'hidden',
                }}
                ref={csvLinkRef}
                headers={CSVheaders}
                target="_blank"
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Export to PDF">
            <IconButton onClick={() => exportPdf(report)}>
              <FileDownloadIcon
                style={{
                  cursor: 'pointer',
                  borderRadius: '4px',
                  padding: '4px',
                  background: '#c9cbcd3d',
                }}
              />
            </IconButton>
          </Tooltip>
        </FilterBar>
      </HeaderWrap>
      <BodyWrapper>
        {payLoad != null && payLoad.length && <KpiTabs kpis={payLoad} year={year} />}
      </BodyWrapper>
    </Wrapper>
  );
};

export default KpiInstanceDetail;
