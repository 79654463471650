// in src/posts.js
import * as React from 'react';
import Button from '@mui/material/Button';
import { useNotify, useDataProvider, usePermissions } from 'react-admin';
import PreviewIcon from '@mui/icons-material/Preview';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import Select from 'react-select';

import Divider from '@mui/material/Divider';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { ConfirmationPopupDocumentDownload } from '../../../components/ConfirmationPopupDocumentDownload';
import { ConfirmationPopUp } from './../../../components/ConfirmationPopUp';
import CachedIcon from '@mui/icons-material/Cached';
import AddCardIcon from '@mui/icons-material/AddCard';
import { Box, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CompanyDocumentPurpose } from '../../../config/constants';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DocumentListItem from './../../../components/DocumentListItem';
import { hasPermission } from '../../../lib/SGHelper';

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Documents = ({ companyId }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { permissions } = usePermissions();

  const [reload, setReload] = React.useState(true);
  const [showAddDocument, setShowAddDocument] = React.useState(false);
  const [selectedPurpose, setSelectedPurpose] = React.useState(null);
  const [loading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const restrictDocumentUpload =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_BOOK_KEEPER', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  const handleUpload = ({ target }) => {
    const purpose = selectedPurpose;
    const acceptedFiles = target.files;
    if (!purpose) {
      notify('Unable to upload file', 'error');
    }
    if (!acceptedFiles) {
      return;
    }
    dataProvider
      .upload('company-documents/upload', {
        data: {
          files: acceptedFiles[0],
          companyId: companyId,
          purpose: purpose,
        },
      })
      .then((data) => {
        notify('File uploaded successfully', 'success');
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };

  React.useEffect(() => {
    setIsloading(true);
    const url = `company-documents/company/${companyId}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('documents', data);
        setData(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  if (loading) {
    return null;
  }

  const recentDocuments = [...data].reverse().slice(0, 10);
  const formationDocuments = data.filter((d) =>
    [
      'PASSPORT',
      'CERTIFICATE_OF_INCORPORATION',
      'EIN',
      'ACTION_OF_INCORPORATOR',
      'FORM_SS_4_SIGNED',
      'FORM_SS_4_SIGNED_CERTIFICATE',
      'AUDIT_LOG_FORM_SS_4_SIGNED',
      'INITIAL_RESOLUTION',
      'STATE_FILING_INFORMATION',
      'PASSPORT_CONVERTED_JPG',
      'CERTIFICATE_OF_FORMATION',
      'STATEMENT_OF_AUTHORISED_PERSON',
      'ARTICLE_OF_ORGANIZATION',
      'FORM_8821',
      'SIGNATURE_CERTIFICATE',
      'SS4',
      'EIN_NAME_CORRECTION_2848',
      'EIN_NAME_CORRECTION_2848_SIGNED',
      'AUDIT_LOG_EIN_NAME_CORRECTION_2848_SIGNED',
      'STATEMENT_OF_INFORMATION',
      'INITIAL_BUSINESS_FILING',
    ].includes(d.purpose)
  );
  const franchiseDocuments = data.filter((d) =>
    ['FRANCHISE_TAX_REPORT_CONFIRMATION_FROM_STATE', 'FRANCHISE_TAX_REPORT_CONFIRMATION'].includes(
      d.purpose
    )
  );
  const federalDocuments = data.filter((d) =>
    [
      'FEDERAL_TAX_1120_5472',
      'FEDERAL_TAX_1120_5472_SIGNED',
      'AUDIT_LOG_FEDERAL_TAX_1120_5472_SIGNED',
      'FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION',
      'FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED',
      'AUDIT_LOG_FEDERAL_TAX_1065_AND_EFILE_AUTHORIZATION_SIGNED',
      'FEDERAL_TAX_RESOLUTION_1120_5472',
      'FEDERAL_TAX_RESOLUTION_1120_5472_SIGNED',
      'AUDIT_LOG_FEDERAL_TAX_RESOLUTION_1120_5472_SIGNED',
      'FEDERAL_TAX_POA_2848',
      'FEDERAL_TAX_POA_2848_SIGNED',
      'AUDIT_LOG_FEDERAL_TAX_POA_2848_SIGNED',
      'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION',
      'FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED',
      'AUDIT_LOG_FEDERAL_TAX_1120_AND_EFILE_AUTHORIZATION_SIGNED',
      'FEDERAL_TAX_PROOF_OF_SUBMISSION',
      'FEDERAL_TAX_PROOF_OF_REJECTION',
      'TAX_PROFIT_AND_LOSS_STATEMENT',
      'TAX_TRIAL_BALANCE',
      'TAX_OTHERS',
      'FINANCIAL_INPUT_DOCUMENTS',
      'TAX',
    ].includes(d.purpose)
  );
  const legalDocuments = data.filter((d) =>
    [
      'BYLAWS_TEMPLATE',
      'INITIAL_BOARD_CONSENT',
      'IDEMNIFICATION_AGREEMENT',
      'RESTRICTED_STOCK_PURCHASE_AGREEMENTS',
      'NOTICES_OF_STOCK_ISSUANCE',
      'PREFILLED_83B_ELECTION_AND_FILLING_INSTRUCTIONS',
      'CONFIDENTIAL_INFORMATION_AND_INVENTION_ASSIGNMENT_AGREEMENTS',
      'OPERATING_AGREEMENT_TEMPLATE',
      'OPERATING_AGREEMENT_TEMPLATE_EXCEL',
      'BYLAWS_TEMPLATE_EXCEL',
      'OPERATING_AGREEMENT',
      'OPERATING_AGREEMENT_SIGNED',
      'AUDIT_LOG_OPERATING_AGREEMENT_SIGNED',
    ].includes(d.purpose)
  );
  const mailDocuments = data.filter((d) => ['MAIL'].includes(d.purpose));

  const receiptDocuments = data.filter((d) => ['RECEIPTS'].includes(d.purpose));

  const otherDocuments = data.filter((d) =>
    ['GENERAL_DIRECTION_FROM_SG', 'GENERAL_DIRECTION_DOCS', 'MEDIA_KIT', 'LOGO'].includes(d.purpose)
  );

  const booksDocuments = data.filter((d) => ['BOOKS'].includes(d.purpose));

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={10} sm={10} md={10} />
        <Grid item xs={1} sm={1} md={1}>
          {!restrictDocumentUpload && (
            <IconButton size="large" color="primary" onClick={() => setShowAddDocument(true)}>
              <AddCardIcon fontSize="large" />
            </IconButton>
          )}
          <IconButton size="large" color="primary" onClick={() => setReload((reload) => !reload)}>
            <CachedIcon fontSize="large" />
          </IconButton>
        </Grid>

        <Grid item xs={10} sm={10} md={10}>
          <Dialog fullWidth open={showAddDocument} onClose={() => setShowAddDocument(false)}>
            <DialogTitle>Add New Document</DialogTitle>
            <DialogContent style={{ minHeight: 400 }}>
              <Select
                name="purpose"
                options={CompanyDocumentPurpose.map((c) => {
                  return { value: c.id, label: c.label };
                })}
                onChange={(selectedOption) => setSelectedPurpose(selectedOption?.value)}
              />

              <input
                accept="application/zip, application/pdf, image/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id={'s'}
                style={{ display: 'none' }}
                onChange={handleUpload}
                type="file"
                disabled={!!!selectedPurpose}
              />
              <label htmlFor={'s'}>
                <Tooltip title="Upload">
                  <IconButton
                    edge="end"
                    aria-label="upload"
                    component="span"
                    disabled={!!!selectedPurpose}
                  >
                    <UploadIcon className="documentIcons" />
                  </IconButton>
                </Tooltip>
              </label>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAddDocument(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={2} sm={2} md={2}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                  style={{ width: '180px' }}
                >
                  <StyledTab
                    label="Recent"
                    {...a11yProps(0)}
                    style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
                  />
                  <StyledTab
                    label="Formation"
                    {...a11yProps(1)}
                    style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
                  />
                  <StyledTab
                    label="State Tax"
                    {...a11yProps(2)}
                    style={{ alignItems: 'flex-start' }}
                  />
                  <StyledTab
                    label="Federal Tax"
                    {...a11yProps(3)}
                    style={{ alignItems: 'flex-start' }}
                  />
                  <StyledTab label="Books" {...a11yProps(4)} style={{ alignItems: 'flex-start' }} />
                  <StyledTab
                    label="Legal"
                    {...a11yProps(5)}
                    style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
                  />
                  <StyledTab
                    label="Mails"
                    {...a11yProps(6)}
                    style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
                  />
                  <StyledTab
                    label="Receipts"
                    {...a11yProps(7)}
                    style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
                  />
                  <StyledTab
                    label="Others"
                    {...a11yProps(8)}
                    style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
                  />
                </Tabs>
              </Grid>
              <Grid item xs={10} sm={10} md={10}>
                <TabPanel value={value} index={0}>
                  <List>
                    {recentDocuments.length ? (
                      recentDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <List>
                    {formationDocuments.length ? (
                      formationDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <List>
                    {franchiseDocuments.length ? (
                      franchiseDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <List>
                    {federalDocuments.length ? (
                      federalDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <List>
                    {booksDocuments.length ? (
                      booksDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <List>
                    {legalDocuments.length ? (
                      legalDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <List>
                    {mailDocuments.length ? (
                      mailDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <List>
                    {receiptDocuments.length ? (
                      receiptDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={value} index={8}>
                  <List>
                    {otherDocuments.length ? (
                      otherDocuments.map((d) => (
                        <DocumentListItem
                          id={d.id}
                          companyId={d.companyId}
                          purpose={d.purpose}
                          text={d.purpose}
                          uuid={d.uuid}
                          exists
                        />
                      ))
                    ) : (
                      <div>No Data found</div>
                    )}
                  </List>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Documents;
