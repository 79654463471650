import * as React from 'react';
import { Create, SimpleForm, email, AutocompleteInput } from 'react-admin';
import { Box } from '@mui/material';
import SectionTitle from '../../components/SectionTitle';
import TextInput from '../../components/TextInput';
import { Employee } from '../../types';
import NumberInput from '../../components/NumberInput';
import DateInput from '../../components/DateInput';
import { Currency } from '../../config/constants';
import ReferenceInput from '../../components/ReferenceInput';
import SelectInput from '../../components/SelectInput';
import { Row } from '../../components/Row';
import { validateForm } from './EmployeeCompensationMasterFormValidation';
import { EmployeeCompensationCategory } from '../../config/constants';

const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));
const EmployeeCompensationCategoryArray = EmployeeCompensationCategory.map((f) => ({
  id: f.id,
  name: f.label,
}));
const EmployeeCompensationMasterCreate = () => (
  <Create>
    <SimpleForm
      sx={{ maxWidth: 500 }}
      defaultValues={{
        startDate: new Date(),
      }}
      validate={validateForm}
    >
      <SectionTitle label="Employee Compensation Detail" />
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <ReferenceInput source="employeeId" reference="sg-employee-master">
          <AutocompleteInput
            optionText={(choice?: Employee) => (choice?.id ? `${choice.fullName}` : '')}
            fullWidth
          />
        </ReferenceInput>
      </Box>
      <SelectInput
        source="currency"
        label="Currency"
        choices={CurrencyArray}
        fullWidth
        isRequired
      />
      <NumberInput source="baseSalary" label="Base salary" fullWidth isRequired />
      {/* <NumberInput source="totalSalary" label="Total salary" fullWidth isRequired/> */}
      <Row>
        <DateInput source="startDate" label="Start date" fullWidth isRequired />
        <DateInput source="endDate" label="End date" fullWidth />
      </Row>
      <TextInput source="remarks" label="Remarks" fullWidth />
      <SelectInput
        source="category"
        label="Category"
        choices={EmployeeCompensationCategoryArray}
        fullWidth
        isRequired
      />
    </SimpleForm>
  </Create>
);

export default EmployeeCompensationMasterCreate;
