import React, { useState } from 'react';
import styled from '@emotion/styled';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDataProvider } from 'react-admin';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { FormControl } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { CustomTextField } from '../../../components/CustomTextField';
import Select from 'react-select';

export const ReportParameterForm = (props) => {
  const dataProvider = useDataProvider();
  const { control, handleSubmit } = useForm({ defaultValues: {} });
  const [reportType, setReportType] = useState('PDF');
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);
    for (let item of Object.keys(data)) {
      if (data[item] && typeof data[item].getMonth === 'function') {
        data[item] =
          data[item].getFullYear() +
          zeroPad(data[item].getMonth() + 1) +
          zeroPad(data[item].getDate());
      }
      if (data[item] && typeof data[item] === 'object') {
        data[item] = data[item].value;
      }
      data[item] = data[item] || null;
    }
    data['REPORT_ID'] = props.reportId;
    data['REPORT_TYPE'] = reportType;
    console.log(data);
    dataProvider
      .get('reports/generate', { filter: data })
      .then((data) => {
        if (data && data.data.url) {
          setIsLoading(false);
          window.open(data.data.url, '_blank');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const handleChange = (e) => {
    setReportType(e.target.value);
  };
  const getDefaultValue = (param) => {
    if (param.defaultValue) {
      return param.defaultValue;
    }
    if (!param.defaultValueType) {
      return null;
    }
    let defaultValue = null;
    const today = new Date();
    switch (param.defaultValueType) {
      case 'TODAY':
        defaultValue = new Date();
        break;
      case 'START_OF_THE_MONTH':
        defaultValue = new Date(today.getFullYear(), today.getMonth(), 1);
        break;
      case 'END_OF_THE_MONTH':
        defaultValue = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case 'START_OF_THE_YEAR':
        defaultValue = new Date(today.getFullYear(), 0, 1);
        break;
      case 'CURRENT_YEAR':
        defaultValue = today.getFullYear();
        break;
      default:
        break;
    }
    return defaultValue;
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', width: '70%', alignItems: 'stretch' }}
    >
      {props.parameters
        .filter((param) => param.enabled === true)
        .sort((param1, param2) => param1.columnIndex - param2.columnIndex)
        .map((param) => {
          switch (param.fieldType) {
            case 'TEXT_BOX':
              return (
                <CustomTextField
                  control={control}
                  label={param.displayName}
                  name={param.name}
                  defaultValue={getDefaultValue(param)}
                />
              );
            case 'DATE_PICKER':
              return (
                <CustomDatePicker
                  control={control}
                  label={param.displayName}
                  name={param.name}
                  defaultValue={getDefaultValue(param)}
                />
              );
            case 'SELECT_STATES':
            case 'SELECT_PAYMENT_STATUS':
            case 'SELECT_INCORPORATION_STATUS':
            case 'SELECT_PARTNERS':
              return (
                <CustomSelect
                  control={control}
                  label={param.displayName}
                  name={param.name}
                  options={props.masterdataArrayMap[param.fieldType]}
                  defaultValue={getDefaultValue(param)}
                />
              );
            default:
              return (
                <CustomTextField
                  control={control}
                  label={param.displayName}
                  name={param.name}
                  defaultValue={getDefaultValue(param)}
                />
              );
          }
        })}
      <CustomTextField
        style={{ display: 'none' }}
        control={control}
        label={'REPORT_ID'}
        name={'REPORT_ID'}
        defaultValue={props.reportId}
      />
      {props.parameters && props.parameters?.length && (
        <ReportType style={{ display: 'flex', padding: '1em', alignItems: 'center' }}>
          <FormControl
            style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'row' }}
          >
            <FormLabel
              id="demo-radio-buttons-group-label"
              style={{ flex: '1 0 50%', color: '#000000' }}
            >
              Report Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={reportType}
              name="REPORT_TYPE"
              onChange={handleChange}
              style={{
                display: 'flex',
                flex: '1 0 50%',
                alignItems: 'stretch',
                flexDirection: 'row',
              }}
            >
              <FormControlLabel value="PDF" control={<Radio />} label="PDF" />
              <FormControlLabel value="XLS" control={<Radio />} label="XLS" />
            </RadioGroup>
          </FormControl>
        </ReportType>
      )}
      <LoadingButton type="submit" loading={isLoading} loadingPosition="start" variant="contained">
        Generate Report
      </LoadingButton>
    </form>
  );
};

const ReportType = styled.div``;

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '1em',
    'align-items': 'center',
    '& > *': {
      flex: '1 1 100%',
    },
    '& > label': {
      'margin-left': 'auto',
    },
    '& > div  >div': {
      width: '100%',
    },
  },
  item: {},
});
const CustomSelect = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <label>{props.label}</label>
      </div>
      <div>
        <Controller
          render={({ field }) => (
            // <Select {...field} {...props}>
            //     {props.options.map( ({value, displayName}) =>  <MenuItem value={value}>{displayName}</MenuItem>)}
            // </Select>
            <Select {...field} {...props} options={props.options}></Select>
          )}
          name={props.name}
          control={props.control}
        />
      </div>
    </div>
  );
};

const CustomDatePicker = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <label>{props.label}</label>
      </div>
      <div>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
          render={({ field }) => (
            <div style={{ display: 'flex', gap: '0.5em' }}>
              <DatePicker
                placeholderText="Select date"
                className="datepickerStyle"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat="dd-MM-yyyy"
              />
              {/* <span><CalendarMonthIcon fontSize="large"/></span> */}
            </div>
          )}
        />
        {/* <Controller
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
          render={
              ({ field: { onChange, ...restField } }) =>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                      label={props.label}
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      renderInput={(params) =>
                      <TextField
                          {...params}
                      />}
                      {...restField}
                  />
              </LocalizationProvider>
            }
        />  */}

        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              name={props.name}
              control={props.control}
              defaultValue={props.defaultValue}
              render={({ field: { ref, ...rest } }) => (
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  
                  {...rest}
                />
              )}
            />
      </MuiPickersUtilsProvider> */}
      </div>
    </div>
  );
};

export const zeroPad = (d) => ('' + d).padStart(2, '0');
