import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { palettes, monthNames } from '../../../../config/constants';
import { getKPIMonths } from '../KpiInstanceDetail';

export const KPIMonthyCompanyVerticalComposedChart = (props: { kpis: any[] }) => {
  if (!props.kpis || props.kpis.length == 0) {
    return null;
  }

  const getStateWiseDataForMonth = (kpis: any[], parameter: any, monthString: string) => {
    const stateDataArray = kpis
      .filter((kpi) => kpi?.parameter === parameter)
      .map((kpi) => {
        return { [kpi.state]: kpi[monthString] };
      });
    return stateDataArray.reduce((result, current) => Object.assign(result, current), {});
  };
  const getAllStates = (kpis: any[], parameter: any) => {
    return kpis.filter((kpi) => kpi?.parameter === parameter).map((kpi) => kpi.state);
  };
  const data = Array.from(Array(getKPIMonths(props.kpis)).keys()).map((month) => {
    //month is zero based
    const monthIndex = month + 1;
    const monthString = 'month' + (monthIndex >= 10 ? monthIndex : '0' + monthIndex);
    return {
      name: monthNames[month],
      ...getStateWiseDataForMonth(props.kpis, 'NUMBER_OF_COMPANIES', monthString),
    };
  });
  console.log(data);
  const palette = palettes[0];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        layout="vertical"
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" scale="band" />
        <Tooltip />
        <Legend />
        {getAllStates(props.kpis, 'NUMBER_OF_COMPANIES').map((state, index) => {
          return <Bar dataKey={state} barSize={10} fill={palette[index]} />;
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
