import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Avatar, Button, Card, CardActions, CircularProgress } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { Form, required, TextInput, useTranslate, useLogin, useNotify } from 'react-admin';
import Box from '@mui/material/Box';

const logo = require('../cover.png');
const Login = () => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, location.state ? (location.state as any).nextPathname : '/').catch(
      (error: Error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          alignContent: 'center',
          gap: '2em',
          justifyContent: 'center',
          // backgroundImage:'url(../cover.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: '#ffffff',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <svg
            width="311"
            height="50"
            viewBox="0 0 311 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.2866 24.6998L25.2902 24.6999V24.6941C25.289 24.696 25.2878 24.6979 25.2866 24.6998ZM25.2866 24.6998L25.2818 24.6996C25.1345 24.6939 24.9898 24.6884 24.8423 24.6884C18.8838 24.6884 14.0552 29.514 14.0552 35.4625C14.0552 41.4109 18.8867 46.2365 24.8423 46.2365C24.9926 46.2365 25.1428 46.2308 25.2902 46.225C23.2963 43.1137 22.1346 39.4223 22.1346 35.4596C22.1346 31.4992 23.2949 27.8099 25.2866 24.6998ZM35.6323 35.4625C35.6323 37.8667 34.8434 40.089 33.5084 41.8813C32.1734 40.0861 31.3816 37.8638 31.3816 35.4625C31.3816 33.0612 32.1734 30.8388 33.5084 29.0436C34.8434 30.8359 35.6323 33.0583 35.6323 35.4625Z"
              fill="#91B9FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.8452 48.56C25.5415 48.56 26.2264 48.505 26.8968 48.3985V48.3954C28.0873 49.7981 29.4686 51.0392 31.0001 52.0723C29.0814 52.7854 27.0066 53.175 24.8422 53.175C15.0636 53.175 7.1084 45.2292 7.1084 35.4625C7.1084 25.6957 15.0636 17.75 24.8394 17.75C27.0037 17.75 29.0785 18.1396 30.9972 18.8525C29.4657 19.8858 28.0873 21.1268 26.8939 22.5295C26.2264 22.4227 25.5415 22.3679 24.8422 22.3679C17.6123 22.3679 11.7347 28.2442 11.7347 35.4654C11.7347 42.6866 17.6152 48.56 24.8452 48.56ZM42.1802 53.1777C32.4016 53.1777 24.4464 45.2323 24.4464 35.4654C24.4464 25.6985 32.4016 17.75 42.1802 17.75C51.959 17.75 59.914 25.6957 59.914 35.4625C59.914 45.2292 51.959 53.175 42.1802 53.175V53.1777ZM42.1802 22.3679C34.9503 22.3679 29.0698 28.2413 29.0698 35.4625C29.0698 42.6837 34.9503 48.5569 42.1802 48.5569C49.4102 48.5569 55.2907 42.6837 55.2907 35.4625C55.2907 28.2413 49.4102 22.3679 42.1802 22.3679ZM39.2097 25.0954C37.6753 25.5341 36.2795 26.3047 35.1035 27.3264C36.8864 29.5632 37.9527 32.3916 37.9527 35.4625C37.9527 38.5334 36.8835 41.3648 35.1035 43.5987C36.2766 44.6173 37.6724 45.3881 39.2097 45.8296C41.3249 42.9118 42.579 39.33 42.579 35.4625C42.579 31.595 41.3278 28.0104 39.2097 25.0954Z"
              fill="#0050FF"
            />
            <path
              d="M82.7116 46.4216C87.8475 46.4216 91.0499 43.8568 91.0499 40.0245C91.0499 36.5544 88.4518 34.5629 82.8928 33.899C79.5394 33.4766 78.0892 32.7222 78.0892 30.7306C78.0892 29.1012 79.6904 27.9545 82.4397 27.9545C85.34 27.9545 86.7901 29.1314 87.062 31.0626H90.4457C90.1738 27.2907 87.2131 24.756 82.4699 24.756C77.6965 24.756 74.5847 27.2002 74.5847 30.7608C74.5847 34.3215 77.2131 36.4337 82.2886 37.007C85.8838 37.3691 87.485 38.2744 87.485 40.1151C87.485 42.0463 85.6723 43.1929 82.6512 43.1929C79.6602 43.1929 77.7871 41.9256 77.6663 39.7831H74.2222C74.4336 43.7662 77.7267 46.4216 82.7116 46.4216Z"
              fill="#001F64"
            />
            <path
              d="M110.166 25.0276H94.4556V28.2563H100.528V46.15H104.093V28.2563H110.166V25.0276Z"
              fill="#001F64"
            />
            <path
              d="M122.189 24.8767H120.98L110.92 45.8785V46.15H114.727L116.69 41.8954H126.449L128.412 46.15H132.249V45.8785L122.189 24.8767ZM124.968 38.727H118.171L121.554 30.7608L124.968 38.727Z"
              fill="#001F64"
            />
            <path
              d="M148.623 38.3046C151.342 37.3088 152.913 35.0155 152.913 31.817C152.913 27.7433 149.983 25.0276 145.602 25.0276H136.75V46.15H140.285V38.8477H144.998L150.103 46.15H154.091V45.9992L148.623 38.3046ZM140.285 28.1356H145.421C147.868 28.1356 149.318 29.5538 149.318 31.9377C149.318 34.3517 147.868 35.8001 145.421 35.8001H140.285V28.1356Z"
              fill="#001F64"
            />
            <path
              d="M172.781 25.0276H157.071V28.2563H163.143V46.15H166.708V28.2563H172.781V25.0276Z"
              fill="#001F64"
            />
            <path
              d="M187.182 34.6232V37.7312H193.768C193.375 40.8393 190.837 43.0722 187.242 43.0722C183.073 43.0722 180.142 40.1452 180.142 35.5586C180.142 31.1531 183.043 28.0752 187.242 28.0752C190.082 28.0752 192.197 29.2219 193.405 31.6057H196.94C195.792 27.3209 191.925 24.756 187.272 24.756C181.381 24.756 176.517 29.0107 176.517 35.5586C176.517 42.348 181.321 46.4216 187.242 46.4216C193.828 46.4216 197.484 41.835 197.484 34.7741V34.6232H187.182Z"
              fill="#001F64"
            />
            <path
              d="M206.617 42.9214V25.0276H203.083V46.15H215.711V42.9214H206.617Z"
              fill="#001F64"
            />
            <path
              d="M229.874 46.4216C236.158 46.4216 240.689 41.9557 240.689 35.5888C240.689 29.2219 236.158 24.756 229.874 24.756C223.59 24.756 219.028 29.2219 219.028 35.5888C219.028 41.9256 223.469 46.4216 229.874 46.4216ZM229.874 43.0722C225.553 43.0722 222.653 40.0849 222.653 35.5888C222.653 31.0927 225.614 28.0752 229.874 28.0752C234.194 28.0752 237.064 31.1229 237.064 35.5888C237.064 40.0547 234.194 43.0722 229.874 43.0722Z"
              fill="#001F64"
            />
            <path
              d="M259.317 35.1664C260.888 34.231 261.795 32.6317 261.795 30.5798C261.795 27.2304 259.166 25.0276 255.209 25.0276H246.508V46.15H255.36C260.194 46.15 262.731 44.1585 262.731 40.3867C262.731 37.7614 261.553 35.9811 259.317 35.1664ZM258.26 31.0022C258.26 32.7524 257.021 33.8689 255.058 33.8689H250.043V28.1356H255.058C257.021 28.1356 258.26 29.2521 258.26 31.0022ZM255.209 43.0421H250.043V36.8562H255.209C257.777 36.8562 259.166 37.9123 259.166 39.934C259.166 41.9256 257.746 43.0421 255.209 43.0421Z"
              fill="#001F64"
            />
            <path
              d="M276.995 24.8767H275.787L265.727 45.8785V46.15H269.533L271.497 41.8954H281.255L283.219 46.15H287.056V45.8785L276.995 24.8767ZM279.775 38.727H272.977L276.361 30.7608L279.775 38.727Z"
              fill="#001F64"
            />
            <path
              d="M295.091 42.9214V25.0276H291.556V46.15H304.185V42.9214H295.091Z"
              fill="#001F64"
            />
          </svg>
          <div
            style={{
              fontSize: '1.875rem',
              fontWeight: '800',
              lineHeight: '2.25rem',
              fontFamily: 'sans-serif',
            }}
          >
            Sign in to your admin account
          </div>
        </div>

        <Card
          sx={{
            minWidth: '50vh',
            padding: '16px',
            border: '1px solid #D4DDEB',
            boxShadow: '0px 3px 5px rgb(0 0 0 / 4%)',
            borderRadius: '16px',
          }}
        >
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '0.5em',
                alignItems: 'center',
                color: '#005eff',
                background: '#edf4ff',
                borderRadius: '24px',
                padding: '10px',
                border: '1px solid #edf4ff',
              }}
            >
              <LockIcon />
            </div>
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                autoFocus
                source="username"
                label="Username"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                source="password"
                label="Password"
                type="password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
              style={{ background: '#005eff' }}
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              Sign in
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export { Login };

interface FormValues {
  username?: string;
  password?: string;
}
