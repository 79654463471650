import React from 'react';
import styled from '@emotion/styled';
import DEFranchiseTax from './views/DEFranchiseTax';
import DEFranchiseTaxLastYearDue from './views/DEFranchiseTaxLastYearDue';
import WYFranchiseTax from './views/WYFranchiseTax';
import WyobizRegistration from './views/WyobizRegistration';
import HBSMail from './views/HBSMail';
import WYMail from './views/WYMail';
import Ein8822b from './views/Ein8822b';
import WYReinstatements from './views/WYReinstatements';

/**
 *
 * @returns May be in the future, we can add additional cards for each processes
 */
export const LocalBatchView = () => {
  return (
    <Wrapper>
      <HeaderWrap>
        <Header>Local Batches</Header>
      </HeaderWrap>
      <BodyWrapper>
        <WyobizRegistration />
        <DEFranchiseTax />
        <WYFranchiseTax />
        <WYReinstatements />
        <HBSMail />
        <WYMail />
        <Ein8822b />
      </BodyWrapper>
    </Wrapper>
  );
};

export default LocalBatchView;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const BodyWrapper = styled.div`
  display: flex;
  gap: 1em;
  padding: 0px 32px;
  flex-wrap: wrap;
`;

const NoDataFound = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444c66;
  text-align: center;
`;
