import React from 'react';
import { useShowController } from 'react-admin';
import { Box, Tabs, Typography, Tab } from '@mui/material';
import styled from '@emotion/styled';
import { BasicDetails } from './CompanyTabs/BasicDetails';
import { CompanyUpdateDetails } from './CompanyTabs/CompanyUpdateDetails';
import Documents from './CompanyTabs/Documents';
import CompanyActions from './CompanyTabs/CompanyActions';
import { CompanyServices } from './CompanyTabs/CompanyServices';
import { SonetelServices } from './CompanyTabs/SonetelServices';
import { OperatingAgreement } from './CompanyTabs/OperatingAgreement';
import CompanyPayments from './CompanyTabs/CompanyPayments';
import CompanySubscription from './CompanyTabs/CompanySubscription';
import CompanyNotes from './CompanyTabs/CompanyNotes';
import FederalTaxMaster from './CompanyTabs/FederalTaxMaster';
import BookkeepingMaster from './CompanyTabs/BookkeepingMaster';
import CompliaceMaster from './CompanyTabs/ComplianceMaster';

export interface CompanyHomeProps {
  data: any;
  permissions: any;
  tab?: string;
  subTab?: string;
}
export const CompanyHome = (props: CompanyHomeProps) => {
  const getTabValue = (tab: string | undefined) => {
    switch (tab) {
      case 'federal-tax':
        return 11;
      case 'bookkeeping':
        return 12;
      case 'name-check-availability':
        return 1;
      case 'compliance':
        return 4;
      default:
        return 0;
    }
  };
  const [value, setValue] = React.useState(getTabValue(props?.tab));

  const isEditableAllowed = () => {
    // return permissions && permissions.indexOf("ROLE_EXTERNAL_SALES") < 0;
    return true;
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <BodyWrapper>
      <Box sx={{ width: '100%' }} aria-label="basic tabs ">
        <Box sx={{ borderBottom: 2, borderColor: '#1a1a1a' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons={false}
            style={{ height: '30px' }}
          >
            <StyledTab label="Basic Details" {...a11yProps(0)} />
            <StyledTab label="Update" {...a11yProps(1)} disabled={!isEditableAllowed()} />
            <StyledTab label="Documents" {...a11yProps(2)} />
            <StyledTab label="Services" {...a11yProps(3)} />
            <StyledTab label="Compliance" {...a11yProps(4)} />
            <StyledTab label="Sonetel" {...a11yProps(5)} />
            <StyledTab label="Actions" {...a11yProps(6)} />
            <StyledTab label="Operating Agreement" {...a11yProps(7)} />
            <StyledTab label="Payments" {...a11yProps(8)} />
            <StyledTab label="Subscription" {...a11yProps(9)} />
            <StyledTab label="Notes" {...a11yProps(10)} />
            <StyledTab label="Federal Tax" {...a11yProps(11)} />
            <StyledTab label="Books" {...a11yProps(12)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BasicDetails
            data={props.data.company}
            einDetails={props.data.einDetails}
            stateFilingDetails={props.data.stateFilingDetails}
            registeredAgentDetails={props.data.registeredAgentDetails}
          />
        </TabPanel>
        {isEditableAllowed() && (
          <TabPanel value={value} index={1}>
            <CompanyUpdateDetails data={props.data} subTab={props.subTab} />
          </TabPanel>
        )}
        <TabPanel value={value} index={2}>
          <Documents companyId={props.data.id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CompanyServices data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CompliaceMaster data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <SonetelServices data={props.data} />
        </TabPanel>
        {isEditableAllowed() && (
          <TabPanel value={value} index={6}>
            <CompanyActions data={props.data} />
          </TabPanel>
        )}
        <TabPanel value={value} index={7}>
          <OperatingAgreement data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <CompanyPayments companyId={props.data.id} />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <CompanySubscription data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <CompanyNotes data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <FederalTaxMaster data={props.data} subTab={props.subTab} />
        </TabPanel>
        <TabPanel value={value} index={12}>
          <BookkeepingMaster
            data={props.data}
            subTab={props.subTab}
            permissions={props.permissions}
          />
        </TabPanel>
      </Box>
    </BodyWrapper>
  );
};

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 4px 0px 0px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
  // @media (min-width: 1200px){
  //     max-width:1100px;
  // }
  // @media (min-width: 1536px){
  //     max-width:fit-content;
  // }
`;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
