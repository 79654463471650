import AddonMasterList from './AddonMasterList';
import AddonMasterEdit from './AddonMasterEdit';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';

const resource = {
  list: AddonMasterList,
  edit: AddonMasterEdit,
  icon: AttachEmailIcon,
};

export default resource;
