import List from '../../components/List';
import { AutocompleteInput, Datagrid, EditButton, Identifier, RaRecord } from 'react-admin';
import TextField from '../../components/TextField';
import NumberField from '../../components/NumberField';
import DateField from '../../components/DateField';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import { CompanyComplianceStatus, CompanyServiceComplianceStatus } from '../../config/constants';

const Filters = [
  <TextInput source="companyName" label="Company" alwaysOn />,
  <TextInput source="state" label="State" alwaysOn />,

  <SelectInput
    source="service"
    choices={[
      { id: 'REGISTERED_AGENT_SERVICE', name: 'Registered Agent Service' },
      { id: 'FRANCHISE_TAX_SERVICE', name: 'Franchise Tax Service' },
    ]}
    alwaysOn
  />,
];

const CompanyUpcomingPaymentsViewList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={(id: Identifier, resource: string, record: RaRecord) => {
          return `/company-master-data/${record?.companyId}`;
        }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />

        <TextField source="companyName" label="Company Name" />
        <TextField source="state" label="State" />

        <TextField source="email" label="Email" />
        <TextField source="email" label="Email" />

        <TextField source="amount" label="Amount" />

        <DateField source="dueDate" label="Due Date" />
      </Datagrid>
    </List>
  );
};

export default CompanyUpcomingPaymentsViewList;
