import * as React from 'react';
import styled from '@emotion/styled';
import { SimpleForm, ShowProps, Edit } from 'react-admin';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { ListHeaderActionsEdit } from '../../components/ListHeaderActions';
import NumberInput from '../../components/NumberInput';
import { Currency, ExpenseType } from '../../config/constants';
import BooleanInput from '../../components/BooleanInput';
import SelectInput from '../../components/SelectInput';
import { validateForm } from './ExpenseMasterValidateForm';

const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));
const ExpenseTypeArray = ExpenseType.map((f) => ({
  id: f.id,
  name: f.label,
}));

const ExpenseMasterEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />}>
      <SimpleForm validate={validateForm}>
        <EmployeeWrapper>
          <BasicDetais>
            <SectionTitle label="Expense Detail" />
            <TextInput source="id" label="Ref." disabled />
            <SelectInput
              source="type"
              label="Expense type"
              choices={ExpenseTypeArray}
              fullWidth
              isRequired
            />
            <Box>
              <SelectInput source="currency" label="Currency" choices={CurrencyArray} isRequired />
              <NumberInput source="amount" label="Amount" fullWidth isRequired />
            </Box>
            <Box>
              <DateInput source="startDate" label="Start date" fullWidth isRequired />
              <DateInput source="endDate" label="End date" fullWidth />
            </Box>
            <BooleanInput source="isValid" label="Active expense" fullWidth isRequired />
            <TextInput source="description" label="Description" fullWidth />
          </BasicDetais>
        </EmployeeWrapper>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const EmployeeWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Box = styled.div`
  display: flex;
  gap: 1em;
`;
export default ExpenseMasterEdit;
