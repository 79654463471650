import * as React from 'react';
import styled from '@emotion/styled';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useShowController,
  FunctionField,
  Labeled,
  useNotify,
  useDataProvider,
  usePermissions,
} from 'react-admin';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import LabelField from '../../components/LabelField';
import { LabelType } from '../../config/LabelType';
import { ListHeaderList } from '../../components/ListHeaderActions';
import { Box, FormHelperText, Typography } from '@mui/material';
// import { BE12SurveyStatus } from './BE12SurveyStatus';
import DocumentListItem from '../../components/DocumentListItem';
import { hasPermission } from '../../lib/SGHelper';
import dataProvider from '../../config/dataProvider';
import { BoiStatus } from '../../config/constants';
import { BoiStatusField } from './BoiStatus';

const BE12SurveyView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderList />}>
      <MyShow {...props} />
    </Show>
  );
};

const EmployeeDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default BE12SurveyView;

const MyShow = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  // const company = record?.company;
  const boiMaster = record?.boiMaster;
  const boiCompanyDetails = boiMaster?.boiCompanyDetail;

  const rp = boiCompanyDetails?.boiResponsiblePartyDetail;
  const company = record?.companyMaster;
  const documents = record?.documents;
  const { permissions } = usePermissions();

  const [reloadDocuments, setReloadDocuments] = React.useState(false);

  console.log({ record });

  if (!record?.id) {
    return null;
  }

  return (
    <TabbedShowLayout>
      <Tab label="Basic Details">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <SimpleShowLayout> */}
            <EmployeeDetails>
              <LabelField
                record={boiCompanyDetails}
                source="companyName"
                label="Company Name"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={boiCompanyDetails}
                source="state"
                label="State"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={boiCompanyDetails}
                source="email"
                label="Email"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={boiCompanyDetails}
                source="dba"
                label="DBA"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={boiCompanyDetails}
                source="dateOfFormation"
                label="Date of Formation (yyyy-mm-dd)"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={boiCompanyDetails}
                source="memberCount"
                label="Number of members"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
            </EmployeeDetails>
            <>
              <Grid container spacing={1} marginTop={4}>
                <Label>Principal Place of Business</Label>
              </Grid>
              <Grid item xs={12} sm={12} md={6} mt={0.5}>
                <div style={{ marginTop: '0.2em' }}>
                  <Box>
                    <Typography variant="body2">{boiCompanyDetails?.addressLine1}</Typography>
                    {boiCompanyDetails?.addressLine2 && (
                      <Typography variant="body2">{boiCompanyDetails?.addressLine2}</Typography>
                    )}

                    <Typography variant="body2">{boiCompanyDetails?.city}</Typography>
                    <Typography variant="body2">{boiCompanyDetails?.stateOrProvince}</Typography>
                    <Typography variant="body2">{boiCompanyDetails?.postalCode}</Typography>
                    <Typography variant="body2">{boiCompanyDetails?.country}</Typography>

                    {boiCompanyDetails?.tel && (
                      <Typography variant="body2">{boiCompanyDetails?.tel}</Typography>
                    )}
                  </Box>
                </div>
              </Grid>
            </>

            <>
              <Grid container spacing={1} mt={4}>
                <Label>Responsible Party Details</Label>
              </Grid>
              <Grid item xs={12} sm={12} md={6} mt={0.5}>
                <Typography>
                  {rp?.firstName} {rp?.lastName}
                </Typography>
                <Typography>Date of Birth : {rp?.dateOfBirth ? rp?.dateOfBirth : 'N/A'}</Typography>
                {rp?.email && <Typography variant="body2">{rp?.email}</Typography>}
                <div style={{ marginTop: '0.2em' }}>
                  <Label>Address</Label>
                  <Box>
                    <Typography variant="body2">{rp?.addressLine1}</Typography>
                    {rp?.addressLine2 && (
                      <Typography variant="body2">{rp?.addressLine2}</Typography>
                    )}

                    <Typography variant="body2">{rp?.city}</Typography>
                    <Typography variant="body2">{rp?.stateOrProvince}</Typography>
                    <Typography variant="body2">{rp?.postalCode}</Typography>
                    <Typography variant="body2">{rp?.country}</Typography>

                    {rp?.tel && <Typography variant="body2">{rp?.tel}</Typography>}
                  </Box>
                </div>
              </Grid>
            </>

            {boiCompanyDetails?.boiBeneficiaryOwnerDetails?.length > 0 && (
              <>
                <Grid container spacing={1} mt={4}>
                  <Label>Owners</Label>
                </Grid>
                {boiCompanyDetails?.boiBeneficiaryOwnerDetails.map((o: any, index: number) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      mt={0.5}
                      style={{
                        backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                        padding: '0.5em',
                      }}
                    >
                      <Typography>
                        {o.firstName} {o.lastName}
                      </Typography>
                      <Typography>Ownership : {o.percent}%</Typography>
                      <Typography>
                        Indirect Beneficiary Owner : {o.indirectBeneficiaryOwner ? 'Yes' : 'No'}
                      </Typography>
                      <Typography>
                        Date of Birth(yyyy-mm-dd) : {o?.dateOfBirth ? o?.dateOfBirth : 'N/A'}
                      </Typography>{' '}
                      {o?.email && <Typography variant="body2">{o?.email}</Typography>}
                      <div style={{ marginTop: '0.3em' }}>
                        <Label>Address</Label>
                        <Box>
                          <Typography variant="body2">{o?.addressLine1}</Typography>
                          {o?.addressLine2 && (
                            <Typography variant="body2">{o?.addressLine2}</Typography>
                          )}

                          <Typography variant="body2">{o?.city}</Typography>
                          <Typography variant="body2">{o?.stateOrProvince}</Typography>
                          <Typography variant="body2">{o?.postalCode}</Typography>
                          <Typography variant="body2">{o?.country}</Typography>

                          {o?.tel && <Typography variant="body2">{o?.tel}</Typography>}
                        </Box>
                      </div>
                    </Grid>
                  );
                })}
              </>
            )}

            {/* </SimpleShowLayout> */}
          </Grid>

          <Spacer />
        </Grid>
        <Grid xs={12} container spacing={1} marginTop={4}>
          <Grid item xs={12} sm={12} md={12} mt={0.5}>
            <BoiStatusField data={record} />
          </Grid>
        </Grid>
      </Tab>
      <Tab label="Documents Details">
        <Grid container spacing={2}>
          {record && Array.isArray(record?.documents) && (
            <>
              <Grid item xs={12} md={12}>
                <List>
                  {record.documents.map((d: any) => (
                    <DocumentListItem
                      companyId={d?.companyId}
                      purpose={d.purpose}
                      text={d.purpose}
                      exists
                      readOnly
                    />
                  ))}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </Tab>
    </TabbedShowLayout>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;
