import EmployeeIcon from '@mui/icons-material/People';

import EinServiceView from '../ein-service/EinServiceView';
import FederalTaxPenaltyList from './FederalTaxPenaltyList';

const resource = {
  list: FederalTaxPenaltyList,

  show: EinServiceView,
  icon: EmployeeIcon,
};

export default resource;
