import { CompanyHome } from './CompanyHome';
import { BookkeeperCompanyHome } from './BookkeeperCompanyHome';
import { CPACompanyHome } from './CPACompanyHome';

import { usePermissions, useShowController } from 'react-admin';
import { useGetOne } from 'react-admin';
import Skeleton from '@mui/material/Skeleton';
import { hasPermission } from '../../lib/SGHelper';

export const CompanyData = (props) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const { permissions } = usePermissions();
  console.log('permissions', permissions);
  if (!record) {
    return null;
  }
  let tab = null;
  let subTab = null;
  const url = window.location.href;
  // const searchParams = new URLSearchParams(window.location.search);
  // const tab = searchParams.get('tab');
  // const subTab = searchParams.get('subTab');

  if (url.indexOf('?') >= 0) {
    let searchParams = url.substring(url.indexOf('?') + 1);
    if (searchParams) {
      let searchParamsList = searchParams.split('&');
      if (Array.isArray(searchParamsList) && searchParamsList.length > 0) {
        //split by "="
        for (let i = 0; i < searchParamsList.length; i++) {
          //split by "="
          const keyValue = searchParamsList[i].split('=');
          if (keyValue[0] == 'tab') {
            tab = keyValue[1];
          } else if (keyValue[0] == 'subTab') {
            subTab = keyValue[1];
          }
        }
      }
    }
  }
  if (hasPermission('ROLE_BOOK_KEEPER', permissions)) {
    return (
      <BookkeeperCompanyHome permissions={permissions} data={record} tab={tab} subTab={subTab} />
    );
  } else if (
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions)
  ) {
    return <CPACompanyHome permissions={permissions} data={record} tab={tab} subTab={subTab} />;
  }
  return <CompanyHome permissions={permissions} data={record} tab={tab} subTab={subTab} />;
};
/**
 * This is a generic component which shows everything in company view
 * Only input required is the companyID
 */
export const CompanyDataWrapper = (props) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const { data, isLoading, error } = useGetOne('company-master-data', { id: record.companyId });

  const permissions = props.permissions;
  console.log('permissions', permissions);
  if (isLoading) {
    return <Skeleton variant="rectangular" width={210} height={118} />;
  }

  return <CompanyHome permissions={permissions} data={data} />;
};
