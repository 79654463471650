import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
} from 'react-admin';
import BooleanInput from '../../../components/BooleanInput';
import DateField from '../../../components/DateField';
import EmailField from '../../../components/EmailField';
import List from '../../../components/List';
import TextField from '../../../components/TextField';
import TextInput from '../../../components/TextInput';
import { WorkflowStatus } from './../../../config/constants';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="id" label="Ref." alwaysOn />,
  <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
];

const BusinessExtensionList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="email" label="Email" />
        <TextField source="entityName" />
        <TextField source="addressLine1" />
        <TextField source="state" />
        <TextField source="country" />
        <TextField source="city" />
        <TextField source="postalCode" />

        <TextField source="isUsCorporation" />
        <TextField source="einNumber" />
        <TextField source="formForFilingExtension" />
        <TextField source="followsCalendarYearForTaxPurpose" />
        <TextField source="taxStartDate" />
        <TextField source="taxEndDate" />
        <TextField source="isTaxLessThan12Months" />
        <TextField source="whyIsLessThan12Months" />
        <TextField source="totalTaxToBeOwed" />
        <TextField source="totalTaxPaid" />
        <TextField source="businessRegDate" />
        <TextField source="signatoryFirstName" />
        <TextField source="signatoryLastName" />
        <TextField source="signatorySsn" />
        <TextField source="signatoryAddressLine1" />
        <TextField source="signatoryState" />
        <TextField source="signatoryCountry" />
        <TextField source="signatoryCity" />
        <TextField source="signatoryPostalCode" />
        <SelectField source="status" choices={WorkflowStatusArray} />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default BusinessExtensionList;
