import EmployeeIcon from '@mui/icons-material/People';

import CompanyDissolutionList from './CompanyDissolutionList';
import CompanyDissolutionEdit from './CompanyDissolutionEdit';
import CompanyDissolutionView from './CompanyDissolutionView';

const resource = {
  list: CompanyDissolutionList,
  edit: CompanyDissolutionEdit,
  show: CompanyDissolutionView,
  icon: EmployeeIcon,
};

export default resource;
