import * as React from 'react';
import { Create, SimpleForm, email } from 'react-admin';
import { Box, Typography } from '@mui/material';
import Separator from '../../components/Separator';
import SectionTitle from '../../components/SectionTitle';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { Row } from '../../components/Row';
import { validateForm } from './EmployeeMasterValidateForm';

const EmployeeMasterCreate = () => (
  <Create>
    <SimpleForm
      defaultValues={{
        startDate: new Date(),
      }}
      validate={validateForm}
    >
      <Row>
        <div style={{ width: '100%' }}>
          <SectionTitle label="Employee Details" />
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="firstName" label="First name" fullWidth isRequired />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="lastName" label="Last name" fullWidth isRequired />
            </Box>
          </Box>
          <TextInput source="designation" label="Designation" fullWidth isRequired />
          <TextInput type="email" source="email" label="Company Email" fullWidth isRequired />
          <TextInput type="email" source="email2" label="Personal Email" fullWidth isRequired />
          <Row>
            <TextInput source="tel" label="Mobile No" fullWidth isRequired />
            <DateInput source="startDate" label="Start date" fullWidth isRequired />
          </Row>
        </div>
        <div style={{ width: '100%' }}>
          <SectionTitle label="Communication Details" />
          <TextInput source="addressLine1" label="Address" fullWidth />
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="city" label="City" fullWidth />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="stateOrProvince" label="State" fullWidth />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="country" label="Country" fullWidth />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="postalCode" label="Zip code" fullWidth />
            </Box>
          </Box>
        </div>
      </Row>
      <Separator />
      <TextInput source="remarks" label="Remarks" fullWidth />
    </SimpleForm>
  </Create>
);

export default EmployeeMasterCreate;
