import styled from '@emotion/styled';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tab,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { getMonth, monthNames } from '../../../config/constants';
import { amountFormat } from '../../../lib/formatter';
import {
  getCustomClass,
  getFormatedValue,
  getFormatedYtdValue,
  getKPIMonths,
} from '../kpi-dashboard/KpiInstanceDetail';
import { useQuery } from 'react-query';
export interface KpiParameterListProps {
  kpis: any[];
  year: number;
}

export const KpiParameterList = (props: KpiParameterListProps) => {
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const onClickColumnHeader = (month: number) => {
    setOpen(true);
    setSelectedMonth(month);
  };
  const closeWindow = (flag: boolean) => {
    setOpen(flag);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
  };
  return (
    <>
      {props.kpis != null && props.kpis.length ? (
        <KpiWrapper>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer
              sx={{
                maxHeight: 550,
                maxWidth: {
                  xs: 300, // theme.breakpoints.up('xs')
                  sm: 620, // theme.breakpoints.up('sm')
                  md: 900, // theme.breakpoints.up('md')
                  lg: 1366, // theme.breakpoints.up('lg')
                  xl: 1846, // theme.breakpoints.up('xl')
                },
              }}
            >
              <Table aria-label="customized table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{ color: '#005eff', position: 'sticky', left: 0, zIndex: 9999 }}
                    >
                      Description
                    </StyledTableCell>
                    {Array.from(Array(getKPIMonths(props.kpis)).keys()).map((m) => (
                      <StyledTableCell
                        onClick={() => onClickColumnHeader(m + 1)}
                        align="right"
                        style={{ cursor: 'pointer' }}
                      >
                        {monthNames[m]}
                      </StyledTableCell>
                    ))}
                    {props.kpis != null && props.kpis.length && props.kpis[0]?.ytd && (
                      <StyledTableCell align="right">{'YTD'}</StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.kpis &&
                    props.kpis?.length &&
                    props.kpis?.map((row: any) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell
                          style={{ position: 'sticky', left: 0 }}
                          className={getCustomClass(row.description, row.parameter)}
                        >
                          {row.description}
                        </StyledTableCell>

                        {Array.from(Array(getKPIMonths(props.kpis)).keys()).map((m) => {
                          m = m + 1;
                          const monthCell = 'month' + (m >= 10 ? m : '0' + m);
                          return (
                            <StyledTableCell
                              className={getCustomClass(row[monthCell], row.parameter)}
                              align="right"
                              type={m == 1 ? row.parameter : null}
                            >
                              {getFormatedValue(row[monthCell], row.parameter)}
                            </StyledTableCell>
                          );
                        })}
                        {row.ytd != null && (
                          <StyledTableCell
                            className={getCustomClass(row.ytd, row.parameter)}
                            align="right"
                          >
                            {getFormatedYtdValue(row.ytd, row.parameter)}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </KpiWrapper>
      ) : (
        <NoDataFound>
          <svg
            width="100"
            height="100"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25" cy="25" r="24" stroke="#757A89" stroke-width="2" />
            <path
              d="M37 13H13L22.6 26.1389V35.2222L27.4 38V26.1389L37 13Z"
              stroke="#757A89"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37.661 31.2764L31.3306 37.5527"
              stroke="#757A89"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M31.3306 31.2764L34.4958 34.4145L37.661 37.5527"
              stroke="#757A89"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div style={{ color: '#444c66', fontWeight: 900, fontSize: '1.5rem' }}>
            No records found
          </div>
          <div style={{ color: '#757A89' }}>If using a custom view, try adjusting the filters</div>
        </NoDataFound>
      )}
      {open && selectedMonth && (
        <ModalView close={closeWindow} month={selectedMonth} year={props.year} />
      )}
    </>
  );
};

export interface CellProps {
  type?: string;
}
const KpiWrapper = styled.div``;

const StyledTableCell = styled(TableCell)((props: CellProps) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#005eff',
    color: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    border: 'none',
    // borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
}));

const NoDataFound = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 2em;
`;
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: "aliceblue",
    borderRadius: '10px',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export interface ModalProps {
  close: (arg0: boolean) => void;
  month: number;
  year: number;
}
export const ModalView = (props: ModalProps) => {
  const dataProvider = useDataProvider();
  const [payLoad, setPayLoad] = useState<any[]>([]);
  const [month, setMonth] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(props.month);
  const [selectedYear, setSelectedYear] = useState(props.year);
  const [processing, setProcessing] = useState(true);

  React.useEffect(() => {
    dataProvider
      .get('kpi-instance-parameters', { id: props.year, month: props.month })
      .then((data: any) => {
        if (data && data.data) {
          setPayLoad(data?.data);
          setMonth(getMonth(props.month));
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setProcessing(false));
  }, []);

  const closeModal = () => {
    props.close(false);
  };
  const onChangeMonth = (event: SelectChangeEvent) => {
    setSelectedMonth(Number(event.target.value));
  };

  const onChangeYear = (event: SelectChangeEvent) => {
    setSelectedYear(Number(event.target.value));
  };

  return (
    <Popup>
      <Dialog>
        <ModalHeader>
          <Title>{'KPI Parameters for ' + month + ' ' + props.year}</Title>
          <div onClick={closeModal} style={{ cursor: 'pointer' }}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.1748 5.72899L12.8013 11.1024L7.42734 5.72843L5.73028 7.42548L11.1043 12.7995L5.72971 18.1741L7.42677 19.8711L12.8013 14.4965L18.1748 19.87L19.8718 18.1729L14.4984 12.7995L19.8718 7.42605L18.1748 5.72899Z"
                fill="#757A89"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalData>
          {payLoad &&
            payLoad?.length &&
            payLoad?.map((row: any) => (
              <Row>
                <Parameter>{row.description}</Parameter>
                <Value>
                  {amountFormat(row.value).toString().split('.')[0]}.
                  <span style={{ color: '#757A8966' }}>
                    {amountFormat(row.value).toString().split('.')[1]}
                  </span>
                </Value>
              </Row>
            ))}
        </ModalData>
        <ReCalculate>
          <div style={{ display: 'flex' }}>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="year">Year</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  value={selectedYear.toString()}
                  label="Year"
                  onChange={onChangeYear}
                  style={{ color: '#000000' }}
                >
                  <MenuItem value={2022}>2022</MenuItem>
                  <MenuItem value={2021}>2021</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="month">Month</InputLabel>
                <Select
                  labelId="month"
                  id="month"
                  value={selectedMonth.toString()}
                  label="Year"
                  onChange={onChangeMonth}
                  style={{ color: '#000000' }}
                >
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <Button variant="contained" style={{ background: '#005eff' }}>
            edit kpi parameters
          </Button>
        </ReCalculate>
      </Dialog>
    </Popup>
  );
};

const Popup = styled.div`
  background: rgb(73 49 49 / 29%);
  width: -webkit-fill-available;
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
`;

const Dialog = styled.div`
  background-color: #f6f8fa;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow:
    0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 220px);
  padding: 0px 24px 0px 24px;
  gap: 2em;
  width: 40%;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #f6f8fa;
  z-index: 9999999;
  padding-top: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d4ddeb;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const ModalData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const Parameter = styled.div`
  font-family: 'Camphor';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #444c66;
`;

const Value = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #444c66;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border: 1px solid #ffffff;
  background: #ffffff;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 4%);
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  &:hover {
    border: 1px solid #d4ddeb;
  }
`;

const ReCalculate = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 24px;
  background: #f6f8fa;
`;
