import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  EditButton,
  ReferenceField,
  ReferenceInput,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';

const Filters = [
  <TextInput source="id" label="Archival Ref." alwaysOn />,
  <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
];

const CompanyArchivedMasterList = () => {
  return (
    <List
      filters={Filters}
      filterDefaultValues={{ isValid: 'true' }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        {/* <TextField source="id" label="Ref." /> */}
        <TextField source="companyId" label="Company Ref." />
        <ReferenceField source="companyId" label="Company Name" reference="company-master-data">
          <TextField source="companyFullName" />
        </ReferenceField>
        <TextField source="reason" />
        <DateField source="startDateTime" showTime label="Archived On" />
        <DateField source="endDateTime" label="Ended" />
        <BooleanField source="isValid" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CompanyArchivedMasterList;
