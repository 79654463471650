import * as React from 'react';
import styled from '@emotion/styled';
import { TextFieldProps } from 'react-admin';

import { TextField as TextFieldDefault } from 'react-admin';

export interface TextFieldProp extends TextFieldProps {}

const TextField = (props: TextFieldProp) => {
  return (
    <TextFieldDefault
      record={props.record}
      source={props.source}
      label={props.label}
      fullWidth={props.fullWidth}
    />
  );
};

export default TextField;
