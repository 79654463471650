import EmployeeIcon from '@mui/icons-material/People';

import EmployeeCompensationMasterCreate from './EmployeeCompensationMasterCreate';
import EmployeeCompensationMasterList from './EmployeeCompensationMasterList';
import EmployeeCompensationMasterEdit from './EmployeeCompensationMasterEdit';
import EmployeeCompensationMasterView from './EmployeeCompensationMasterView';

const resource = {
  list: EmployeeCompensationMasterList,
  create: EmployeeCompensationMasterCreate,
  edit: EmployeeCompensationMasterEdit,
  show: EmployeeCompensationMasterView,
  icon: EmployeeIcon,
};

export default resource;
