import EmployeeIcon from '@mui/icons-material/People';

import KPIInstanceParamCreate from './KPIInstanceParamCreate';
import KPIInstanceParamEdit from './KPIInstanceParamEdit';
import KPIInstanceParamView from './KPIInstanceParamView';
import KPIInstanceParamList from './KPIInstanceParamList';

const resource = {
  list: KPIInstanceParamList,
  create: KPIInstanceParamCreate,
  edit: KPIInstanceParamEdit,
  show: KPIInstanceParamView,
  icon: EmployeeIcon,
};

export default resource;
