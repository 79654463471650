import React from 'react';
import { useShowController } from 'react-admin';
import { Box, Tabs, Typography, Tab } from '@mui/material';
import styled from '@emotion/styled';
import { BasicDetails } from './CompanyTabs/BasicDetails';
import { CompanyUpdateDetails } from './CompanyTabs/CompanyUpdateDetails';
import Documents from './CompanyTabs/Documents';
import CompanyActions from './CompanyTabs/CompanyActions';
import { CompanyServices } from './CompanyTabs/CompanyServices';
import { SonetelServices } from './CompanyTabs/SonetelServices';
import { OperatingAgreement } from './CompanyTabs/OperatingAgreement';
import CompanyPayments from './CompanyTabs/CompanyPayments';
import CompanySubscription from './CompanyTabs/CompanySubscription';
import CompanyNotes from './CompanyTabs/CompanyNotes';
import FederalTaxMaster from './CompanyTabs/FederalTaxMaster';
import BookkeepingMaster from './CompanyTabs/BookkeepingMaster';

export interface CompanyHomeProps {
  data: any;
  permissions: any;
  tab?: string;
  subTab: string;
}
export const BookkeeperCompanyHome = (props: CompanyHomeProps) => {
  const getTabValue = (tab: string | undefined) => {
    switch (tab) {
      case 'federal-tax':
        return 10;
      case 'bookkeeping':
        return 1;
      default:
        return 0;
    }
  };
  const [value, setValue] = React.useState(getTabValue(props?.tab));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <BodyWrapper>
      <Box sx={{ width: '100%' }} aria-label="basic tabs ">
        <Box sx={{ borderBottom: 2, borderColor: '#1a1a1a' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons={false}
            style={{ height: '30px' }}
          >
            <StyledTab label="Basic Details" {...a11yProps(0)} />
            <StyledTab label="Books" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BasicDetails
            data={props.data.company}
            einDetails={props.data.einDetails}
            stateFilingDetails={props.data.stateFilingDetails}
            registeredAgentDetails={props.data.registeredAgentDetails}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BookkeepingMaster
            data={props.data}
            subTab={props.subTab}
            permissions={props.permissions}
          />
        </TabPanel>
      </Box>
    </BodyWrapper>
  );
};

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 4px 0px 0px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
  // @media (min-width: 1200px){
  //     max-width:1100px;
  // }
  // @media (min-width: 1536px){
  //     max-width:fit-content;
  // }
`;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
