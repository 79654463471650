import * as React from 'react';
import { ListProps } from 'react-admin';
import { List as CustomList } from 'react-admin';
import ListActions from './ListActions';

export interface ListProp extends ListProps {}

const List = (props: ListProp) => {
  return (
    <CustomList
      title={props.title}
      filters={props.filters}
      filterDefaultValues={props.filterDefaultValues}
      sort={props.sort}
      actions={props.actions ? props.actions : <ListActions />}
      sx={{
        paddingLeft: '20px',
        '& .RaList-content': {
          marginTop: '20px',
        },
        '& .MuiToolbar-regular': {
          paddingLeft: '4px',
          paddingTop: '10px',
        },
        '& .RaDatagrid-rowOdd': {
          backgroundColor: '#444c6629',
        },
        '& .RaDatagrid-headerCell': {
          backgroundColor: '#444C66 !important',
          color: 'white',
          fontWeight: 'bold',
          '&:hover': { color: 'white !important' },
        },
        '& .Mui-active': {
          color: 'white !important',
          '&:hover': { color: 'white !important' },
        },
        '& .MuiTableSortLabel-icon': {
          color: 'white !important',
          '&:hover': { color: 'white !important' },
        },
        '& .MuiTooltip-popper': {
          color: 'white !important',
        },
      }}
    >
      {props.children}
    </CustomList>
  );
};

export default List;
