import EmployeeIcon from '@mui/icons-material/People';

import RegisteredAgentPaymentsList from './RegisteredAgentPaymentsList';
import RegisteredAgentPaymentsEdit from './RegisteredAgentPaymentsEdit';
import RegisteredAgentPaymentsView from './RegisteredAgentPaymentsView';

const resource = {
  list: RegisteredAgentPaymentsList,
  edit: RegisteredAgentPaymentsEdit,
  show: RegisteredAgentPaymentsView,
  icon: EmployeeIcon,
};

export default resource;
