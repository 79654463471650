import styled from '@emotion/styled';
import { ReportParameterForm } from './ReportParameterForm';

export const ReportParameters = (props) => {
  const masterdataArrayMap = {};
  /**
   * Custom Select expects each opton as a {key:'key Value', displayName : 'display Name'}
   * But our masterdata map returns just a map.
   * This function converts masterdata to proper function of CustomSElect
   */
  for (let masterdataItem of Object.keys(props.masterdata)) {
    const array = [];
    for (let key of Object.keys(props.masterdata[masterdataItem])) {
      array.push({
        value: key,
        label: props.masterdata[masterdataItem][key],
      });
    }
    masterdataArrayMap[masterdataItem] = array;
  }
  return (
    <Wrapper>
      <ReportTitle>{props.title}</ReportTitle>
      <ParameterWrapper>
        <Label>REPORT PARAMETERS</Label>
        {masterdataArrayMap != null && props.parameters != null && props.parameters.length ? (
          <ReportParameterForm
            parameters={props.parameters}
            masterdataArrayMap={masterdataArrayMap}
            reportId={props.reportId}
          />
        ) : (
          <NoDataFound>Sorry no report parameters found!!!</NoDataFound>
        )}
      </ParameterWrapper>
    </Wrapper>
  );
};

const NoDataFound = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444c66;
  text-align: center;
  width: 100%;
`;

const ReportTitle = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const Label = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Camphor';
  font-weight: 600;
`;

const ParameterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  gap: 2em;
`;
