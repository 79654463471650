import * as React from 'react';
import { Show, ShowButton } from 'react-admin';
import { ListHeaderList } from '../../components/ListHeaderActions';
import BackButton from '../../components/BackButton';

import { hasPermission } from '../../lib/SGHelper';
import { CompanyData } from './CompanyData';
import { CompanyHome } from './CompanyHome';
import { TopToolbar, FilterButton, CreateButton, ExportButton, Button, List } from 'react-admin';

const PostEditActions = () => <TopToolbar></TopToolbar>;

export const CompanyMasterDataEdit = ({ permissions, ...props }) => {
  return (
    <Show
      actions={<PostEditActions />}
      {...props}
      component="div"
      // actions={<ListHeaderList/>}
    >
      <CompanyData permissions={permissions} {...props} />
    </Show>
  );
};

export default CompanyMasterDataEdit;
