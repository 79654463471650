import * as React from 'react';
import styled from '@emotion/styled';
import { DateInputProps } from 'react-admin';
import { DateInput as DateInputDefault } from 'react-admin';

export interface DateInputProp extends DateInputProps {}

const DateInput = (props: DateInputProp) => {
  return (
    <DateInputDefault
      source={props.source}
      label={props.label}
      fullWidth={props.fullWidth}
      required={props.required}
      disabled={props.disabled}
    />
  );
};

export default DateInput;
