import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  email,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
  useShowController,
} from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../../components/SectionTitle';
import Separator from '../../../components/Separator';
import TextInput from '../../../components/TextInput';
import DateInput from '../../../components/DateInput';
import { ListHeaderList } from '../../../components/ListHeaderActions';
import { Row } from '../../../components/Row';
import { WorkflowStatusForm } from './WorkflowStatus';
import { Grid, Typography } from '@mui/material';
import { WorkflowStatus } from '../../../config/constants';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const AddonMasterEdit = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  return (
    <Edit {...props} actions={<ListHeaderList />}>
      <SimpleForm defaultValues={{}}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="body2">Email : {record?.email ? record?.email : ''}</Typography>
              <Typography variant="body2">Tel : {record?.tel ? record?.tel : 'N/A'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <WorkflowStatusForm data={record} />
            </Box>
          </Grid>
        </Grid>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};
const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

export default AddonMasterEdit;
