import EmployeeIcon from '@mui/icons-material/People';

import SubscriptionMasterDataList from './SubscriptionMasterDataList';
import SubscriptionMasterDataView from './SubscriptionMasterDataView';

const resource = {
  list: SubscriptionMasterDataList,
  show: SubscriptionMasterDataView,
  icon: EmployeeIcon,
};

export default resource;
