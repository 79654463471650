import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export const CustomCheckBox = (props) => {
  const control = props.control;
  const classes = useStyles();
  return (
    <Controller
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              control={control}
              name={props.name}
              onClick={props.onClick}
              checked={props.checked}
              disabled={props.disabled}
            />
          }
          label={props.label}
          {...field}
        />
      )}
      name={props.name}
      control={control}
    />
  );
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '1em',
    'align-items': 'center',
    '& > *': {
      flex: '1 1 100%',
    },
    '& > label': {
      'margin-left': 'auto',
    },
    '& > div  >div': {
      width: '100%',
    },
  },
  item: {},
});
