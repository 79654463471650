import * as React from 'react';
import styled from '@emotion/styled';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  EditButton,
  TopToolbar,
  ListButton,
  SelectField,
} from 'react-admin';
import { Grid } from '@mui/material';

import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import LabelField from '../../components/LabelField';
import { LabelType } from '../../config/LabelType';
import { ListHeaderActionsView } from '../../components/ListHeaderActions';
import { Months } from './../../config/constants';
const KPIInstanceParamView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  const MonthsArray = Months.map((f) => ({
    id: f.id,
    name: f.label,
  }));

  return (
    <Show {...props} actions={<ListHeaderActionsView />}>
      <TabbedShowLayout>
        <Tab label="KPI Instance Parameters">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SimpleShowLayout>
                <KPIInstanceParamDetails>
                  <LabelField source="id" label="Ref." fullWidth labelType={LabelType.TEXT_FIELD} />
                  <LabelField
                    source="type"
                    label="KPI Type"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="state"
                    label="State"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="parameter"
                    label="Parameter Type"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="description"
                    label="Description"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="value"
                    label="Value"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="year"
                    label="Year"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <SelectField source="month" label="Month" choices={MonthsArray} />
                </KPIInstanceParamDetails>
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const KPIInstanceParamDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default KPIInstanceParamView;
