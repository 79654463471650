import * as React from 'react';
import styled from '@emotion/styled';
import { Labeled, RaRecord } from 'react-admin';
import { LabelType } from '../config/LabelType';
import EmailField from './EmailField';
import TextField from './TextField';
import DateField from './DateField';
import NumberField from './NumberField';
import SelectField from './SelectField';
import BooleanField from './BooleanField';

export interface LabelFieldProp {
  labelType: LabelType;
  label: string;
  source: string;
  fullWidth?: boolean;
  choices?: RaRecord[];
  record?: RaRecord;
}

const LabelField = (props: LabelFieldProp) => {
  return (
    <>
      {props.labelType === LabelType.DATE_FIELD && (
        <Labeled label={props.label}>
          <DateField
            record={props.record}
            source={props.source}
            label={props.label}
            fullWidth={props.fullWidth}
          />
        </Labeled>
      )}
      {props.labelType === LabelType.TEXT_FIELD && (
        <Labeled label={props.label}>
          <TextField
            record={props.record}
            source={props.source}
            label={props.label}
            fullWidth={props.fullWidth}
          />
        </Labeled>
      )}
      {props.labelType === LabelType.EMAIL_FIELD && (
        <Labeled label={props.label}>
          <EmailField
            record={props.record}
            source={props.source}
            label={props.label}
            fullWidth={props.fullWidth}
          />
        </Labeled>
      )}
      {props.labelType === LabelType.NUMBER_FIELD && (
        <Labeled label={props.label}>
          <NumberField
            record={props.record}
            source={props.source}
            label={props.label}
            fullWidth={props.fullWidth}
          />
        </Labeled>
      )}
      {props.labelType === LabelType.SELECT_FIELD && (
        <Labeled label={props.label}>
          <SelectField
            record={props.record}
            source={props.source}
            label={props.label}
            choices={props.choices}
            fullWidth={props.fullWidth}
          />
        </Labeled>
      )}
      {props.labelType === LabelType.BOOLEAN && (
        <Labeled label={props.label}>
          <BooleanField
            record={props.record}
            source={props.source}
            label={props.label}
            fullWidth={props.fullWidth}
          />
        </Labeled>
      )}
    </>
  );
};

export default LabelField;
