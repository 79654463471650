import { email } from 'react-admin';

export const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;
  if (!values.email) {
    errors.email = 'ra.validation.required';
  }

  return errors;
};
