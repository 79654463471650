import * as React from 'react';
import { Create, SimpleForm } from 'react-admin';
import { Box } from '@mui/material';
import SectionTitle from '../../components/SectionTitle';
import TextInput from '../../components/TextInput';
import { Employee } from '../../types';
import NumberInput from '../../components/NumberInput';
import DateInput from '../../components/DateInput';
import { Currency } from '../../config/constants';
import { Row } from '../../components/Row';
import SelectInput from '../../components/SelectInput';
import { validateForm } from './AdsMasterFormValidation';

const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));

const AdsMasterCreate = () => (
  <Create>
    <SimpleForm
      sx={{ maxWidth: 500 }}
      defaultValues={{
        startDate: new Date(),
      }}
      validate={validateForm}
    >
      <SectionTitle label="Ads Detail" />
      <Row>
        <SelectInput
          source="currency"
          label="Currency"
          choices={CurrencyArray}
          fullWidth
          isRequired
        />
        <NumberInput source="amount" label="Amount" fullWidth isRequired />
      </Row>
      <DateInput source="startDate" label="Start date" fullWidth isRequired />
      <DateInput source="endDate" label="End date" fullWidth isRequired />
      <TextInput source="description" label="Description" fullWidth />
    </SimpleForm>
  </Create>
);

export default AdsMasterCreate;
