import React, { useRef, useState } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import styled from '@emotion/styled';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import '../../../index.css';
import { amountFormat, percentageFormat } from './../../../lib/formatter';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { KpiTabs } from '../tabs/KpiTabs';
import TableViewIcon from '@mui/icons-material/TableView';
import { CSVLink, CSVDownload } from 'react-csv';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

/**
 * We are only showing January to last month where revenue exists
 * This may or may not be december
 */

export const getKPIMonths = (kpis: any) => {
  const revenueRow = kpis.find((kpi: any) => kpi.parameter === 'TOTAL_REVENUE');
  let lastMonthWithRevenue = 12;
  for (let i = 12; i >= 1; i--) {
    const revenue = revenueRow['month' + (i >= 10 ? i : '0' + i)];
    if (revenue) {
      break;
    }
    lastMonthWithRevenue = lastMonthWithRevenue - 1;
  }
  return lastMonthWithRevenue;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;
const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 2em;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
`;

export const getFormatedValue = (value: number, type: string) => {
  switch (type) {
    case 'ADS_EXPENSES':
    case 'OPERATIONS_EXPENSES':
    case 'STATE_FILING_FORMATION_REVENUE':
    case 'TOTAL_STATE_FILING_FORMATION_REVENUE':
    case 'STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS':
    case 'REGISTERED_AGENT_PAYMENTS_REVENUE':
    case 'REGISTERED_AGENT_PAYMENTS_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_PROFIT':
    case 'FEDERAL_TAX_CCORP_REVENUE':
    case 'FEDERAL_TAX_CCORP_PROFIT':
    case 'FEDERAL_TAX_SINGLE_LLC_REVENUE':
    case 'FEDERAL_TAX_SINGLE_LLC_PROFIT':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_REVENUE':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT':
    case 'TOTAL_FEDERAL_TAX_REVENUE':
    case 'TOTAL_FEDERAL_TAX_PROFIT':
    case 'TOTAL_REVENUE':
    case 'TOTAL_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_PROFIT':
    case 'TOTAL_PROFIT_BEFORE_R_AND_D':
    case 'R_AND_D':
      return amountFormat(value);
    case 'PROFIT_PERCENTAGE':
      return percentageFormat(value);
    default:
      return value;
  }
};

export const getCustomClass = (value: number, type: string) => {
  switch (type) {
    case 'TOTAL_STATE_FILING_FORMATION_REVENUE':
    case 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS':
    case 'REGISTERED_AGENT_PAYMENTS_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_PROFIT':
    case 'TOTAL_PHONE_RECHARGES_PROFIT':
    case 'FEDERAL_TAX_CCORP_PROFIT':
    case 'FEDERAL_TAX_SINGLE_LLC_PROFIT':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT':
    case 'TOTAL_FEDERAL_TAX_PROFIT':
    case 'TOTAL_PROFIT':
    case 'TOTAL_PROFIT_BEFORE_R_AND_D':
      return 'row-higlight ' + getLabelColor(value);
    case 'PROFIT_PERCENTAGE':
      return 'row-higlight-profit ' + getLabelColor(value);
    default:
      return '';
  }
};

export const getFormatedYtdValue = (value: number, type: string) => {
  switch (type) {
    case 'FRANCHISE_TAX_PAYMENTS_PROFIT':
    case 'ADS_EXPENSES':
    case 'OPERATIONS_EXPENSES':
    case 'STATE_FILING_FORMATION_REVENUE':
    case 'TOTAL_STATE_FILING_FORMATION_REVENUE':
    case 'STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_STATE_FILING_FORMATION_PROFIT':
    case 'TOTAL_FORMATION_PROFIT_INCLUDING_ADS_AND_OPS':
    case 'REGISTERED_AGENT_PAYMENTS_REVENUE':
    case 'REGISTERED_AGENT_PAYMENTS_PROFIT':
    case 'FRANCHISE_TAX_PAYMENTS_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_REVENUE':
    case 'TOTAL_PHONE_RECHARGES_PROFIT':
    case 'FEDERAL_TAX_CCORP_REVENUE':
    case 'FEDERAL_TAX_CCORP_PROFIT':
    case 'FEDERAL_TAX_SINGLE_LLC_REVENUE':
    case 'FEDERAL_TAX_SINGLE_LLC_PROFIT':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_REVENUE':
    case 'FEDERAL_TAX_MULTIMEMBER_LLC_PROFIT':
    case 'TOTAL_FEDERAL_TAX_REVENUE':
    case 'TOTAL_FEDERAL_TAX_PROFIT':
    case 'TOTAL_REVENUE':
    case 'TOTAL_PROFIT':
    case 'TOTAL_PROFIT_BEFORE_R_AND_D':
    case 'R_AND_D':
    case 'AMOUNT':
      return amountFormat(value);
    case 'PROFIT_PERCENTAGE':
    case 'PERCENTAGE':
      return percentageFormat(value);
    default:
      return value;
  }
};

export const getLabelColor = (value: number) => {
  if (value > 0) {
    return 'row-label-green';
  } else if (value < 0) {
    return 'row-label-red';
  } else {
    return '';
  }
};

export const KpiPayments = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [payLoad, setPayLoad] = useState<any[]>([]);
  const [report, setReport] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const [summary, setSummary] = useState<any[]>([]);
  const [stateWiseSummary, setStateWiseSummary] = useState<any[]>([]);
  const [bookkeepingSummary, setBookkeepingSummary] = useState<any[]>([]);

  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [isStateWiseSummaryLoading, setIsStateWiseSummaryLoading] = useState(false);

  const CSVheaders = [
    { label: 'Description', key: 'description' },
    { label: 'January', key: 'month01' },
    { label: 'February', key: 'month02' },
    { label: 'March', key: 'month03' },

    { label: 'April', key: 'month04' },
    { label: 'May', key: 'month05' },
    { label: 'June', key: 'month06' },

    { label: 'July', key: 'month07' },
    { label: 'August', key: 'month08' },
    { label: 'September', key: 'month09' },

    { label: 'October', key: 'month10' },
    { label: 'November', key: 'month11' },
    { label: 'December', key: 'month12' },

    { label: 'YTD', key: 'ytd' },
  ];

  React.useEffect(() => {
    setIsSummaryLoading(true);
    Promise.all([
      dataProvider.get(`kpi-service/summary`),
      dataProvider.get(`kpi-service/summary/monthwise`),
      dataProvider.get(`kpi-service/summary/monthwise/bookkeeping`),
    ])
      .then((values) => {
        setSummary(values[0].data);
        setStateWiseSummary(values[1].data);
        setBookkeepingSummary(values[2].data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsSummaryLoading(false));
  }, []);
  const getProfit = (product: string, value: any) => {
    const count = value?.count;
    const revenue = value?.amount;
    if (!count || !revenue) {
      return 0;
    }
    if (product === 'BANK_ACCOUNT' || product === 'EIN' || product === 'INVOICING_AND_PAYMENTS') {
      return revenue;
    } else if (
      product === 'OPERATING_AGREEMENT_GENERATION' ||
      product === 'MAILING_ADDRESS' ||
      product === 'REINSTATEMENT_FILING'
    ) {
      return revenue;
    } else if (product === 'SPLIT_PRICING_COMPANY_ONBOARDING_BUNDLED') {
      return 399 * count;
    } else if (product === 'VIRTUAL_PHONE_NUMBER') {
      return 60 * count;
    } else if (product === 'REGISTERED_AGENT_RENEWAL') {
      return 50 * count;
    } else if (product === 'BOOKKEEPING') {
      const transactionCount =
        bookkeepingSummary.find((s: any) => s.year == value.year && s.month == value.month)
          ?.count || 0;
      return revenue - 4 * (transactionCount / 10);
    } else if (product === 'FEDERAL_TAX_PREPARATION') {
      return revenue - 200 * count;
    } else if (product === 'FRANCHISE_TAX') {
      const stateWiseProducts = getStateWiseData(value.product, value.year, value.month);
      const de = stateWiseProducts.find((s: any) => s.state === 'DELAWARE')?.count || 0;
      const wy = stateWiseProducts.find((s: any) => s.state === 'WYOMING')?.count || 0;
      return 99 * de + 237 * wy;
    } else if (product === 'VIRTUAL_PHONE_NUMBER_RECHARGE') {
      return 0.03 * revenue;
    } else if (product === 'BOI_FILING') {
      return revenue - 9 * count;
    }
  };
  if (isSummaryLoading || isStateWiseSummaryLoading) {
    return null;
  }

  const getStateWiseData = (product: string, year: number, month: number) => {
    const data = stateWiseSummary.filter(
      (item) => item.product === product && item.year === year && item.month === month
    );
    return data;
  };
  const distinctProducts = Array.from(
    new Set(
      summary
        .filter((item) => item.product !== 'COMPANY_ONBOARDING_BUNDLED')
        .map((item) => item.product)
    )
  ).sort();
  const periods = Array.from(
    summary.reduce((acc, item) => {
      Object.keys(item).forEach((key) => {
        const yearMonth = `${item.year}-${item.month}`;
        acc.add(yearMonth);
      });
      return acc;
    }, new Set())
  ).sort((a: any, b: any) => {
    const [yearA, monthA] = a.split('-').map(Number);
    const [yearB, monthB] = b.split('-').map(Number);

    if (yearA !== yearB) {
      return yearA - yearB;
    } else {
      return monthA - monthB;
    }
  });

  const transformedData: any = {};

  summary.forEach((item) => {
    const yearMonth = `${item.year}-${item.month}`;

    if (!transformedData[item.product]) {
      transformedData[item.product] = {};
    }

    transformedData[item.product][yearMonth] = {
      ...item,
    };
  });

  console.log({
    distinctProducts,
    periods,
    summary,
    transformedData,
    stateWiseSummary,
  });

  const result = Object.entries(transformedData).map(([product, data]) => ({
    product: product,
    ...(data as {}),
  }));

  console.log('result', result);

  const data: any = [];
  distinctProducts.forEach((product) => {
    const columns: any = [];
    periods.reverse().forEach((period: any) => {
      const [year, month] = period.split('-');
      const value = transformedData[product][period] || 'N/A';
      columns.push({
        product,
        year,
        month,
        amount: value?.amount,
        count: value?.count,
        profit: getProfit(product, value),
      });
    });
    data.push({
      product,
      columns,
    });
  });

  console.log('data', data);

  const exportPdf = (data: any) => {
    setProcessing(true);
    data['REPORT_ID'] = 23;
    data['REPORT_TYPE'] = 'PDF';
    data['YEAR'] = year;
    dataProvider
      .get('reports/generate', { filter: data })
      .then((data: any) => {
        if (data && data.data.url) {
          window.open(data.data.url, '_blank');
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };
  const exportCSV = (data: any) => {
    console.log(payLoad);
    csvLinkRef.current?.link?.click();
  };

  const yearsAndMonths = periods.map((period: any) => {
    const [year, month] = period.split('-').map(Number);
    const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });
    return { year, month, monthName };
  });

  // Calculate sums
  const sums = yearsAndMonths.map((period) => {
    let amountSum = 0;
    let profitSum = 0;

    data.forEach((item: any) => {
      const column = item.columns.find(
        (column: any) => column.year == period.year && column.month == period.month
      );

      if (column) {
        amountSum += column.amount || 0;
        profitSum += column.profit || 0;
      }
    });

    return {
      year: period.year + '',
      month: period.month + '',
      amount: amountSum,
      profit: profitSum,
    };
  });

  console.log({ sums });

  // Add sums to data
  data.push({
    product: 'Total',
    columns: sums,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <TableContainer style={{ overflowX: 'auto', maxWidth: '1200px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff' }}>
              Product
            </TableCell>
            {yearsAndMonths.flatMap((item, index) => [
              <TableCell key={`${index}-month`} colSpan={3}>
                {item.year} {item.monthName}
              </TableCell>,
            ])}
          </TableRow>
          <TableRow>
            <TableCell
              style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff' }}
            />
            {yearsAndMonths.flatMap((item, index) => [
              <TableCell key={`${index}-count`}>Count</TableCell>,
              <TableCell key={`${index}-amount`}>Revenue</TableCell>,
              <TableCell key={`${index}-profit`}>Profit</TableCell>,
            ])}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any, index: number) => (
            <TableRow key={index}>
              <TableCell
                style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff' }}
              >
                {item.product}
              </TableCell>
              {yearsAndMonths.flatMap((period, index) => {
                const column = item.columns.find(
                  (column: any) =>
                    column.year === period.year.toString() &&
                    column.month === period.month.toString()
                );
                return [
                  <TableCell key={`${index}-count`}>{column?.count || '-'}</TableCell>,

                  <TableCell key={`${index}-amount`}>
                    {column && column.amount
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(column.amount)
                      : '-'}
                  </TableCell>,
                  <TableCell key={`${index}-profit`}>
                    {column && column.profit
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(column.profit)
                      : '-'}
                  </TableCell>,
                ];
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KpiPayments;
