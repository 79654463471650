import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import SubmitButton from '../SubmitButton';
import { CustomSelect } from '../../../../components/CustomSelect';

const OALLCFiscalYearEndForm = ({ data, instance }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`company-legal-documents/operating-agreement/${companyId}/oa-fiscal-year`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomSelect
            control={control}
            label="End of LLC's fiscal year"
            name="fiscalYearEnd"
            defaultValue={instance.fiscalYear}
            options={[
              { label: 'January', value: 'JANUARY' },
              { label: 'February', value: 'FEBRUARY' },
              { label: 'March', value: 'MARCH' },
              { label: 'April', value: 'APRIL' },
              { label: 'May', value: 'MAY' },
              { label: 'June', value: 'JUNE' },
              { label: 'July', value: 'JULY' },
              { label: 'August', value: 'AUGUST' },
              { label: 'September', value: 'SEPTEMBER' },
              { label: 'October', value: 'OCTOBER' },
              { label: 'November', value: 'NOVEMBER' },
              { label: 'December (recommended)', value: 'DECEMBER' },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Update" />
        </Grid>
      </Grid>
    </form>
  );
};

export default OALLCFiscalYearEndForm;
