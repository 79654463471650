import * as React from 'react';
import { useTranslate } from 'react-admin';
import { Box, Divider, Grid, Typography } from '@mui/material';

const Separator = () => {
  const translate = useTranslate();

  return <Box pt="1em" />;
};

export default Separator;
