import styled from '@emotion/styled';
import React, { useState } from 'react';
import { KPIMonthyCompanyVerticalComposedChart } from '../kpi-dashboard/kpi-charts/KPIMonthyCompanyVerticalComposedChart';
import { KPIStatewiseCompaniesChart } from '../kpi-dashboard/kpi-charts/KPIStatewiseCompaniesChart';

const CompanyCount = styled.div`
  font-family: 'Camphor';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #757a89;
`;
const State = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 53px;
  display: flex;
  align-items: center;
  color: #005eff;
`;
const GraphTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444c66;
  margin-bottom: 16px;
`;
const CardStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 16px;
`;

const CompanyFormationWrapper = styled.div`
  background: white;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: stretch;
  gap: 1em;
  width: 100%;
`;

const GraphBg = styled.div`
  background: white;
  border-radius: 4px;
  padding: 12px;
`;

export interface CompanyFormationProps {
  kpis: any[];
}

export const CompanyFormation = (props: CompanyFormationProps) => {
  const totalStates = props.kpis.filter((kpi: any) => kpi?.parameter === 'NUMBER_OF_COMPANIES');
  return (
    <SummaryWrapper>
      <CompanyFormationWrapper style={{ width: '100%' }}>
        <GraphTitle>Number of companies paid</GraphTitle>
        <KPIMonthyCompanyVerticalComposedChart kpis={props.kpis} />
      </CompanyFormationWrapper>
      <CompanyFormationWrapper>
        <GraphTitle>Company Formation</GraphTitle>
        {totalStates &&
          totalStates?.length &&
          totalStates.map((row: any) => (
            <CardStyle>
              <State>{row.state}</State>
              <CompanyCount>
                {'Number of Companies : '} <span style={{ fontWeight: '900' }}>{row.ytd}</span>
              </CompanyCount>
            </CardStyle>
          ))}
        <KPIStatewiseCompaniesChart kpis={props.kpis} />
      </CompanyFormationWrapper>
    </SummaryWrapper>
  );
};
