import * as React from 'react';
import styled from '@emotion/styled';
import { BooleanFieldProps } from 'react-admin';
import { BooleanField as BooleanFieldDefault } from 'react-admin';

export interface BooleanFieldProp extends BooleanFieldProps {}

const BooleanField = (props: BooleanFieldProp) => {
  return (
    <BooleanFieldDefault source={props.source} label={props.label} fullWidth={props.fullWidth} />
  );
};

export default BooleanField;
