import * as React from 'react';
import styled from '@emotion/styled';
import { SimpleForm, ShowProps, Edit, email } from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { ListHeaderActionsEdit } from '../../components/ListHeaderActions';
import { Row } from '../../components/Row';
import { validateForm } from './EmployeeMasterValidateForm';

const EmployeeMasterEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />}>
      <SimpleForm validate={validateForm}>
        <EmployeeWrapper>
          <BasicDetais>
            <SectionTitle label="Employee Details" />
            <TextInput source="id" label="Employee Id" disabled fullWidth />
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="firstName" label="First name" fullWidth isRequired />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="lastName" label="Last name" fullWidth isRequired />
              </Box>
            </Box>
            <TextInput source="designation" label="Designation" fullWidth isRequired />
            <TextInput type="email" source="email" label="Company Email" fullWidth isRequired />
            <TextInput type="email" source="email2" label="Personal Email" fullWidth isRequired />
            <TextInput source="tel" label="Mobile No" fullWidth isRequired />
            <Row>
              <DateInput source="startDate" label="Start date" fullWidth isRequired />
              <DateInput source="endDate" label="End date" fullWidth />
            </Row>
          </BasicDetais>
          <Address>
            <SectionTitle label="Communication Details" />
            <TextInput source="addressLine1" label="Address" fullWidth />
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="city" label="City" fullWidth />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="stateOrProvince" label="State" fullWidth />
              </Box>
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="country" label="Country" fullWidth />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="postalCode" label="Zip code" fullWidth />
              </Box>
            </Box>
          </Address>
        </EmployeeWrapper>
        <Separator />
        <TextInput source="remarks" label="Remarks" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

const EmployeeWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Address = styled.div`
  width: 100%;
`;
export default EmployeeMasterEdit;
