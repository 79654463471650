// in src/posts.js
import * as React from 'react';

import {
  List,
  TextField,
  ReferenceField,
  EditButton,
  SimpleForm,
  CloneButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  Filter,
  BooleanInput,
  BooleanField,
  TextInput,
  required,
  Show,
} from 'react-admin';
import Box from '@mui/material/Box';

import { FranchiseMaster } from './../../config/constants';

const franchiseIdNameArray = FranchiseMaster.map((f) => ({
  id: f.id,
  name: f.label,
}));

const FranchiseChangeLogShow = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <SelectInput source="oldFranchiseId" label="From" choices={franchiseIdNameArray} />,
        <SelectInput source="newFranchiseId" label="To" choices={franchiseIdNameArray} />
      </SimpleForm>
    </Show>
  );
};

export default FranchiseChangeLogShow;
