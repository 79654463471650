import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';

import Select from 'react-select';
export const CustomSelect = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div style={{ width: '40%' }}>
        <Label>{props.label}</Label>
      </div>
      <div style={{ width: '60%' }}>
        <Controller
          control={props.control}
          render={({ field: { onChange, value, name, ref } }) => {
            const currentSelection = props.options.find((c) => c.value === value);

            const handleSelectChange = (selectedOption) => {
              onChange(selectedOption?.value);
            };

            return (
              <Select
                inputRef={ref}
                value={currentSelection}
                name={props.name}
                options={props.options}
                defaultValue={props.options.filter((option) => option.value === props.defaultValue)}
                onChange={handleSelectChange}
                isDisabled={!!props.disabled}
              />
            );
          }}
          name={props.name}
          rules={{
            required: true,
          }}
        />
      </div>
    </div>
  );
};

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.5em;
`;

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '1em',
    // flexDirection: 'column',
    'align-items': 'center',
    '& > *': {
      // gap:'2em'
      // width:'100%',
      // flex : '1 1 100%'
    },
    '& > label': {
      'margin-left': 'auto',
    },
    '& > div  >div': {
      // width : '100%'
    },
  },
  item: {},
});
