import EmployeeIcon from '@mui/icons-material/People';

import AdsMasterCreate from './AdsMasterCreate';
import AdsMasterList from './AdsMasterList';
import AdsMasterEdit from './AdsMasterEdit';
import AdsMasterView from './AdsMasterView';

const resource = {
  list: AdsMasterList,
  create: AdsMasterCreate,
  edit: AdsMasterEdit,
  show: AdsMasterView,
  icon: EmployeeIcon,
};

export default resource;
