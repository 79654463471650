import EmployeeIcon from '@mui/icons-material/People';

import StateFilingList from './StateFilingList';

const resource = {
  list: StateFilingList,
  icon: EmployeeIcon,
};

export default resource;
