import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { TextField, Labeled } from 'react-admin';
import { useNotify, useDataProvider } from 'react-admin';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import OADeleteForm from '../forms/operating-agreement/OADeleteForm';
import OAEffectiveDateForm from '../forms/operating-agreement/OAEffectiveDateForm';
import OALLCPurposeForm from '../forms/operating-agreement/OALLCPurposeForm';
import OALLCManagementTypeForm from '../forms/operating-agreement/OALLCManagementTypeForm';
import OALLCAppointOfficialsForm from '../forms/operating-agreement/OALLCAppointOfficialsForm';
import OALLCProfitDistributionFrequencyForm from '../forms/operating-agreement/OALLCProfitDistributionFrequencyForm';
import OALLCFiscalYearEndForm from '../forms/operating-agreement/OALLCFiscalYearEndForm';
import OALLCTaxElectionTreatmentForm from '../forms/operating-agreement/OALLCTaxElectionTreatmentForm';
import OADecisionVotingRightsForm from '../forms/operating-agreement/OADecisionVotingRightsForm';
import OADecisionGeneralDecisionPolicyForm from '../forms/operating-agreement/OADecisionGeneralDecisionPolicyForm';
import OADecisionAddMemberPolicyForm from '../forms/operating-agreement/OADecisionAddMemberPolicyForm';
import OADecisionRemoveMemberPolicyForm from '../forms/operating-agreement/OADecisionRemoveMemberPolicyForm';
import OADecisionAdditionalContributionPolicyForm from '../forms/operating-agreement/OADecisionAdditionalContributionPolicyForm';
import OADecisionDissolutionPolicyForm from '../forms/operating-agreement/OADecisionDissolutionPolicyForm';
import OADecisionManagerAppointPolicyForm from '../forms/operating-agreement/OADecisionManagerAppointPolicyForm';
import OADecisionBoardDecisionPolicyForm from '../forms/operating-agreement/OADecisionBoardDecisionPolicyForm';
import OAChangesAmmendOAForm from '../forms/operating-agreement/OAChangesAmmendOAForm';
import OAChangesDaysToBuyWithdrwnIntrstForm from '../forms/operating-agreement/OAChangesDaysToBuyWithdrwnIntrstForm';
import OAChangesMebersIntrstSplitForm from '../forms/operating-agreement/OAChangesMebersIntrstSplitForm';
import OAChangesDaysToBuyIntstAfterJointPrdForm from '../forms/operating-agreement/OAChangesDaysToBuyIntstAfterJointPrdForm';
import OAChangesRemoveMeberByOtherMemberForm from '../forms/operating-agreement/OAChangesRemoveMeberByOtherMemberForm';
import OAChangesDissolveLLCForm from '../forms/operating-agreement/OAChangesDissolveLLCForm';
import OAChangesDissolutionNoticePeriodForm from '../forms/operating-agreement/OAChangesDissolutionNoticePeriodForm';
import OAMemberForm from '../forms/operating-agreement/OAMemberForm';
import GenerateOAForm from '../forms/operating-agreement/GenerateOAForm';
import InitiateSignatureWorkflowForm from '../forms/operating-agreement/InitiateSignatureWorkflowForm';

export const OperatingAgreement = ({ data }) => {
  const [loading, setIsloading] = React.useState(true);
  const [oaList, setOaList] = React.useState([]);
  const companyId = data.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  React.useEffect(() => {
    const url = `company-legal-documents/operating-agreement/${data.id}`;
    dataProvider
      .get(url, { data })
      .then(({ data, error }) => {
        console.log('oaList', data);
        setOaList(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, []);

  if (oaList.length < 1) {
    return null;
  }

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          {loading ? (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          ) : (
            <OAInstances data={data} oaList={oaList} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const OAInstances = ({ data, oaList }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} style={{ width: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
            // style={{width:'180px'}}
          >
            {oaList &&
              oaList?.length &&
              oaList.map((row, index) => (
                <StyledTab label={'Version ' + row.instance} {...a11yProps(index++)} />
              ))}
          </Tabs>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          {oaList &&
            oaList?.length &&
            oaList.map((row, index) => (
              <TabPanel value={value} index={index++}>
                <OAInstanceDetail instance={row} data={data} />
              </TabPanel>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;

const OAInstanceDetail = ({ instance, data }) => {
  console.log('instance', instance);
  console.log('data', data);

  return (
    <Grid container spacing={12} className="styledGrid" style={{ gap: '1em' }}>
      <Grid item xs={12}>
        <DeleteWrapper>
          <Title>{'delete operating agreement'}</Title>
          <DescriptionWrapper>
            <Desccription>
              {'The operating agreement version ' +
                instance.instance +
                ' will be fully deleted in this case, and the user will be prompted to start a new operating agreement from beginning.'}
            </Desccription>
            <OADeleteForm data={data} />
          </DescriptionWrapper>
        </DeleteWrapper>
      </Grid>
      <Grid item xs={12}>
        <UpdateWrapper>
          <Title>{'update operating agreement'}</Title>
          <OAUpdateTabs data={data} instance={instance} />
        </UpdateWrapper>
      </Grid>
    </Grid>
  );
};

const Title = styled.div`
  margin-bottom: 10px;
  color: #444c66;
  font-family: 'Camphor';
  font-weight: 800;
  user-select: none !important;
  text-transform: uppercase;
  font-size: 1.2rem;
`;
const Desccription = styled.div`
  font-family: 'Camphor';
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
`;
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const DeleteWrapper = styled.div`
  border: 2px solid #bd1010;
  border-radius: 7px;
  padding: 10px;
`;

const UpdateWrapper = styled.div`
  border: 2px solid #78e3a5;
  border-radius: 7px;
  padding: 10px;
`;

const OAUpdateTabs = ({ data, instance }) => {
  return (
    <TabWrapper>
      <BasicDetails>
        <TabLabel>Basic Details</TabLabel>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={8} style={{ padding: '1em' }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Labeled label="Company Name">
                    <TextField record={instance} source="companyName"></TextField>
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Labeled label="State">
                    <TextField record={instance} source="companyRegisteredState"></TextField>
                  </Labeled>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={{ padding: '1em' }}>
              <Label>Address</Label>
              <Box>
                <Typography>{instance.companyStreetAddress}</Typography>
                <Typography>{instance.companyCity}</Typography>
                <Typography>{instance.companyZipCode}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ padding: '1em' }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Labeled label="Name of the Registered agent">
                    <TextField record={instance} source="registeredAgentName"></TextField>
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Labeled label="State of Registered agent">
                    <TextField record={instance} source="registeredAgentState"></TextField>
                  </Labeled>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={{ padding: '1em' }}>
              <Label>Address of the Registered agent</Label>
              <Box>
                <Typography>{instance.registeredAgentAddress}</Typography>
                <Typography>{instance.registeredAgentCity}</Typography>
                <Typography>{instance.registeredAgentZipCode}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <OAEffectiveDateForm instance={instance} data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <OALLCPurposeForm instance={instance} data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <OALLCManagementTypeForm instance={instance} data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <OALLCAppointOfficialsForm instance={instance} data={data} />
            </Grid>
          </Grid>
        </Box>
      </BasicDetails>
      <Members>
        <TabLabel>Members</TabLabel>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <MembersList instance={instance} data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <OALLCProfitDistributionFrequencyForm instance={instance} data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <OALLCFiscalYearEndForm instance={instance} data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <OALLCTaxElectionTreatmentForm instance={instance} data={data} />
            </Grid>
          </Grid>
        </Box>
      </Members>
      {(instance.votingRight ||
        instance.decisionToAppointManager ||
        instance.decisionToMakeBoardDecision ||
        instance.memberDecision ||
        instance.decisionToAddNewMember ||
        instance.decisionToRemoveMember ||
        instance.decisionToAddContribution ||
        instance.decisionToDissolveLLC) && (
        <DecisionMaking>
          <TabLabel>Decision Making</TabLabel>
          <Box>
            <Grid container spacing={1}>
              {instance.votingRight && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionVotingRightsForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.memberDecision && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionGeneralDecisionPolicyForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.decisionToAddNewMember && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionAddMemberPolicyForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.decisionToRemoveMember && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionRemoveMemberPolicyForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.decisionToAddContribution && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionAdditionalContributionPolicyForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.decisionToDissolveLLC && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionDissolutionPolicyForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.decisionToAppointManager && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionManagerAppointPolicyForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.decisionToMakeBoardDecision && (
                <Grid item xs={12} sm={12} md={12}>
                  <OADecisionBoardDecisionPolicyForm instance={instance} data={data} />
                </Grid>
              )}
            </Grid>
          </Box>
        </DecisionMaking>
      )}
      {instance.decisionToAmendOA && (
        <Changes>
          <TabLabel>Changes</TabLabel>
          <Box>
            <Grid container spacing={1}>
              {instance.decisionToAmendOA && (
                <Grid item xs={12} sm={12} md={12}>
                  <OAChangesAmmendOAForm instance={instance} data={data} />
                </Grid>
              )}
              {instance?.daysToBuyWtdrwnIntrst && (
                <Grid item xs={12} sm={12} md={12}>
                  <OAChangesDaysToBuyWithdrwnIntrstForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.oaMembershipInterestSplit && (
                <Grid item xs={12} sm={12} md={12}>
                  <OAChangesMebersIntrstSplitForm instance={instance} data={data} />
                </Grid>
              )}
              {instance?.daysToBuyIntrstAftrJntPrd && (
                <Grid item xs={12} sm={12} md={12}>
                  <OAChangesDaysToBuyIntstAfterJointPrdForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.removeMbrByOthrMbr && (
                <Grid item xs={12} sm={12} md={12}>
                  <OAChangesRemoveMeberByOtherMemberForm instance={instance} data={data} />
                </Grid>
              )}
              {instance.voteToDissolve && (
                <Grid item xs={12} sm={12} md={12}>
                  <OAChangesDissolveLLCForm instance={instance} data={data} />
                </Grid>
              )}
              {instance?.noticePrdToDissolution && (
                <Grid item xs={12} sm={12} md={12}>
                  <OAChangesDissolutionNoticePeriodForm instance={instance} data={data} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Changes>
      )}
      <div>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <GenerateOAForm instance={instance} data={data} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <InitiateSignatureWorkflowForm instance={instance} data={data} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </TabWrapper>
  );
};

const TabWrapper = styled.div``;
const BasicDetails = styled.div``;
const Changes = styled.div``;
const Members = styled.div``;
const DecisionMaking = styled.div``;
const TabLabel = styled.div`
  text-decoration: underline;
  color: #1a1a1a;
  font-family: 'Camphor';
  font-weight: 800;
  user-select: none !important;
  text-transform: uppercase;
  font-size: 1rem;
`;
// const TabWrapper = styled.div`
// `
// const TabWrapper = styled.div`
// `

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

const MembersList = ({ data, instance }) => {
  if (instance?.oaMemberDTOList?.length < 1) {
    return null;
  }
  return (
    <div>
      {instance &&
        instance?.oaMemberDTOList?.length &&
        instance?.oaMemberDTOList.map((row, index) => (
          <div style={{ marginBottom: '16px' }}>
            <div style={{ padding: '1em' }}>{row.firstName + ' ' + row.lastName}</div>
            <OAMemberForm data={data} member={row} />
          </div>
        ))}
    </div>
  );
};
