import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useGetList, Datagrid, useList, List, useNotify, useDataProvider } from 'react-admin';
import { useListController, ListContextProvider } from 'react-admin';
import DateField from '../../components/DateField';
import SelectInput from '../../components/SelectInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)`
  // min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;

const CompanyBookkeepingChatShow = ({ companyId }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={1}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <StyledTab label={'Details'} {...a11yProps(0)} />
            <StyledTab label={'Transactions'} {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item xs={10} sm={10} md={11}>
          <TabPanel value={value} index={0}>
            <Details companyId={companyId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Transactions companyId={companyId} />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyBookkeepingChatShow;

const Filters = [
  <TextInput source="receiptNumber" label="Receipt Number" alwaysOn />,
  <TextInput source="amount" label="Amount" alwaysOn />,
  <SelectInput
    source="paymentType"
    choices={[
      { id: 'COMPANY_INCORPORATION', name: 'COMPANY_INCORPORATION' },
      { id: 'TELEPHONE_RECHARGE', name: 'TELEPHONE_RECHARGE' },
      { id: 'FEDERAL_TAX_ESTIMATED_AMOUNT', name: 'FEDERAL_TAX_ESTIMATED_AMOUNT' },
      { id: 'FRANCHISE_TAX_ESTIMATED_AMOUNT', name: 'FRANCHISE_TAX_ESTIMATED_AMOUNT' },
      { id: 'REGISTERED_AGENT_YEARLY_DUE', name: 'REGISTERED_AGENT_YEARLY_DUE' },
      { id: 'ACTUAL_FEDERAL_TAX_OWED', name: 'ACTUAL_FEDERAL_TAX_OWED' },
      { id: 'EXISTING_COMPANY_ONBOARDING_PAYMENT', name: 'EXISTING_COMPANY_ONBOARDING_PAYMENT' },
      { id: 'OTHERS', name: 'OTHERS' },
      { id: 'STATE_FORMATION_FEE', name: 'STATE_FORMATION_FEE' },
      { id: 'EIN_FEE', name: 'EIN_FEE' },
      { id: 'BANK_ACCOUNT_FEE', name: 'BANK_ACCOUNT_FEE' },
      { id: 'VIRTUAL_PHONE_NUMBER_FEE', name: 'VIRTUAL_PHONE_NUMBER_FEE' },
    ]}
    alwaysOn
  />,
];

const Transactions = ({ companyId }) => {
  const [reload, setReload] = React.useState(true);
  const [loading, setIsloading] = React.useState(false);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    setIsloading(true);
    const url = `company-master-data/${companyId}/subscription/invoices`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('payments', data);
        setSubscriptions(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);
  if (loading) {
    return null;
  }
  const total = subscriptions
    ?.filter((s) => s.status == 'paid')
    .reduce((partialSum, a) => partialSum + a.amountDue, 0);

  return subscriptions && Array.isArray(subscriptions) && subscriptions.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Start Date</TableCell>
            <TableCell align="right">End Date</TableCell>
            <TableCell align="right">Attempted</TableCell>
            <TableCell align="right">Collection Method</TableCell>
            <TableCell align="right">URL</TableCell>
            <TableCell align="right">Invoice</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((row, index) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{subscriptions.length - index}</TableCell>

              <TableCell align="right">${parseFloat(row.amountDue).toFixed(2)}</TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{row.periodStartDate}</TableCell>
              <TableCell align="right">{row.periodEndDate}</TableCell>
              <TableCell align="right">{row.attemptCount}</TableCell>
              <TableCell align="right">{row.collectionMethod}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  endIcon={<OpenInNewIcon />}
                  onClick={() => window.open(row.hostedInvoiceUrl, '_target')}
                >
                  Open
                </Button>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  endIcon={<OpenInNewIcon />}
                  onClick={() => window.open(row.invoicePdf, '_target')}
                >
                  PDF
                </Button>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2} align="right">
              <h2>
                <b>Total</b>
              </h2>
            </TableCell>
            <TableCell align="right">
              <h2>
                <b>${parseFloat(total).toFixed(2)}</b>
              </h2>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div style={{ alignSelf: 'center' }}>No Susbscription invoices found !!!</div>
  );
};

const Details = ({ companyId }) => {
  const [reload, setReload] = React.useState(true);
  const [loading, setIsloading] = React.useState(false);
  const [payments, setPayments] = React.useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    setIsloading(true);
    const url = `payment-ledger/company/${companyId}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('payments', data);
        setPayments(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);
  if (loading) {
    return null;
  }
  const total = 0;

  return payments && Array.isArray(payments) && payments.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Purpose</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((row, index) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{payments.length - index}</TableCell>
              <TableCell component="th" scope="row">
                {row.type || row.product}
              </TableCell>
              <TableCell align="right">${parseFloat(row.amount / 100).toFixed(2)}</TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{row.createdDate}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2} align="right">
              <h2>
                <b>Total</b>
              </h2>
            </TableCell>
            <TableCell align="right">
              <h2>
                <b>${parseFloat(total / 100).toFixed(2)}</b>
              </h2>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div style={{ alignSelf: 'center' }}>No Stripe payments found !!!</div>
  );
};
const Receipts = ({ companyId }) => {
  const [reload, setReload] = React.useState(true);
  const [loading, setIsloading] = React.useState(false);
  const [receipts, setReceipts] = React.useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    setIsloading(true);
    const url = `company-receipts/company/${companyId}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('receipts', data);
        setReceipts(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);
  if (loading) {
    return null;
  }

  return receipts && Array.isArray(receipts) && receipts.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Receipt Number</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Currency</TableCell>
            <TableCell align="right">Payment Type</TableCell>
            <TableCell align="right">Receipt Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {receipts.map((row, index) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{receipts.length - index}</TableCell>
              <TableCell component="th" scope="row">
                {row.receiptNumber}
              </TableCell>
              <TableCell align="right">{row.description}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
              <TableCell align="right">{row.currency}</TableCell>
              <TableCell align="right">{row.paymentType}</TableCell>
              <TableCell align="right">{row.receiptDateTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div style={{ alignSelf: 'center' }}>No Receipts found !!!</div>
  );
};
