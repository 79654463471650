import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { BE12SurveyStatus } from '../../config/constants';
// import {PaymentStatus} from '../../config/constants'

const BE12SurveyStatusArray = BE12SurveyStatus.filter((f) =>
  ['IN_PROGRESS', 'CONFIRMED', 'FILED_NOT_PROCESSED', 'INCOMPLETE'].includes(f.id)
).map((f) => ({
  id: f.id,
  name: f.name,
}));

// const PaymentStatusArray = PaymentStatus.map(f =>  ({
//     id : f.id,
//     name : f.name
// }));

const BE12SurveyListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const Filters = [
  <SelectInput source="status" label="Status" choices={BE12SurveyStatusArray} alwaysOn />,
  <TextInput source="companyName" label="Company Name" alwaysOn />,
];

const BE12SurveyList = () => {
  return (
    <List
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<BE12SurveyListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyId" label="Company Ref." />
        <TextField source="year" label="Year" />
        <TextField source="companyName" label="Company Name" />
        <TextField source="ein" label="EIN" />
        <TextField source="streetAddress" label="Street Address" />
        <TextField source="state" label="State" />
        <TextField source="city" label="City" />
        <TextField source="zipCode" label="Zip Code" />
        <TextField source="dateOfFormation" label="Date of Formation" />
        <TextField source="owner" label="Owner" />
        <TextField source="title" label="Title" />
        <TextField source="tel" label="Tel" />
        <TextField source="countryOfResidence" label="Country of Residence" />
        <TextField source="citizenship" label="Citizenship" />
        <TextField source="totalAssets" label="Total Assets" />
        <TextField source="totalRevenue" label="Total Revenue" />
        <TextField source="totalIncome" label="Total Income" />
        <TextField source="totalInvestment" label="Total Investment" />
        <TextField source="totalLoans" label="Total Loans" />
        <SelectField source="status" label="Status" choices={BE12SurveyStatusArray} />
        {/* <TextField source="amount" label="Amount" /> */}
        {/* <SelectField source="paymentStatus" label="Payment Status" choices={PaymentStatusArray} /> */}
        {/* <TextField source="payable" label="Payable" /> */}
        <TextField source="numberOfEmployeesInUSA" label="Number of Employees in USA" />
        <TextField source="description" label="Description" />
        <TextField source="dueDate" label="Due Date" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default BE12SurveyList;
