// in src/posts.js
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useGetOne, Loading, Error, useDataProvider } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { EmptyPage } from '../EmptyPage';

const useStyles = makeStyles({
  wrapper: {
    display: 'grid',
    'grid-gap': '1em',
    'grid-template-columns': 'minmax(200px, 50%) 50%',
    'background-color': '#eee',
    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    'margin-right': 'auto',
  },
  item: {
    'grid-column': '1 / 2',
  },
  typeItem: {
    'grid-column': '2 / 3',
  },
  button: {
    'border-radius': '2px',
    'background-color': '#f4511e',
    border: 'none',
    color: '#FFFFFF',
    'text-align': 'center',
    'font-size': '14px',
    padding: '10px',
    width: '200px',
    transition: 'all 0.5s',
    cursor: 'pointer',
    margin: '5px',
    '& span': {
      cursor: 'pointer',
      display: 'inline-block',
      position: 'relative',
      transition: '0.5s',
    },
    '& span:after': {
      content: '',
      position: 'absolute',
      opacity: '0',
      top: '0',
      right: '-20px',
      transition: '0.5s',
    },
    '& :hover span': {
      'padding-right': '25px',
    },
    '& :hover span:after': {
      opacity: 1,
      right: 0,
    },
  },
});
const statusColors = {
  NOT_STARTED: '#6e84a3',
  STARTED: '#f6c343',
  WAITING_FOR_DELIVERY_FROM_PROVIDER: '#f6c343',
  RECEIVED_DELIVERY_FROM_PROVIDER: '#0F0',
  PREPARING_FOR_CLIENT_DELIVERY: '#0F0',
  DELIVERED_TO_CLIENT: '#0F0',
  REJECTED: '#e63757',
  ERROR: '#e63757',
  ONHOLD: '#6e84a3',
};
const StyledTableCell = styled(TableCell)(({ theme, status }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#b3dbfb',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    backgroundColor: statusColors[status] ? statusColors[status] : '#EEE',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PendingDays = (props) => {
  const getDateDifference = (d) => Math.ceil((new Date() - new Date(d)) / (1000 * 60 * 60 * 24));
  if (props.status !== 'WAITING_FOR_DELIVERY_FROM_PROVIDER') {
    return null;
  }
  if (!props.startTime) {
    return (
      <div>
        <span>N/A</span>
      </div>
    );
  }
  return (
    <div>
      <span>{getDateDifference(props.startTime)} Days</span>
    </div>
  );
};

export const CompanyPendingOrders = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [pendingOrders, setPendingOrders] = useState([]);
  const [processing, setProcessing] = useState(true);

  React.useEffect(() => {
    dataProvider
      .get('company-master-data/pending-orders')
      .then((data) => {
        if (data && data.data) {
          setPendingOrders(data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setProcessing(false));
  }, []);

  const getProvider = (providerName) => {
    let provider = 'N/A';
    switch (providerName) {
      case 'STATE_FILING_DELAWARE_INC':
        provider = 'HBS';
        break;
      case 'STATE_FILING_WYOMING_AGENTS':
        provider = 'WY';
        break;
      case 'STATE_FILING_STARTGLOBAL':
        provider = 'SG';
        break;
      case 'REGISTERED_AGENT_DELAWAREINC':
        provider = 'HBS';
        break;
      case 'REGISTERED_AGENT_WYOMINGAGENTS':
        provider = 'WY';
        break;
      case 'REGISTERED_AGENT_NORTHWEST_AGENTS':
        provider = 'NW';
        break;
      case 'EIN_STARTGLOBAL':
        provider = 'Startglobal';
        break;
      case 'EIN_USACORPS':
        provider = 'USACorps';
        break;
      case 'EIN_FIKRI':
        provider = 'Fikri';
        break;
      case 'EIN_STARTGLOBAL_FAX':
        provider = 'Fax';
        break;
      case 'VIRTUAL_PHONE_NUMBER_SONTEL':
        provider = 'Sonetel';
        break;
      case 'BANKING_MERCURY':
        provider = 'Mercury';
        break;
      case 'BANKING_RELAYFI':
        provider = 'Relayfi';
        break;
      case 'STATE_FILING_EXTERNAL':
        provider = 'External';
        break;
      case 'REGISTERED_AGENT_EXTERNAL':
        provider = 'External';
        break;

      default:
        provider = 'N/A';
    }
    return provider;
  };

  if (processing) return <Loading />;
  if (!pendingOrders || pendingOrders.length == 0) return <EmptyPage />;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell>Company</StyledTableCell>
            <StyledTableCell>Phone</StyledTableCell>
            <StyledTableCell>State Filing</StyledTableCell>
            <StyledTableCell>Registered Agent</StyledTableCell>
            <StyledTableCell>Ein</StyledTableCell>

            <StyledTableCell>Relay</StyledTableCell>
            <StyledTableCell>Mercury</StyledTableCell>
            <StyledTableCell>Franchise</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>State</StyledTableCell>
            <StyledTableCell>Internal Ref.</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingOrders
            .filter((value, index, self) => {
              return index === self.findIndex((t) => t.id === value.id);
            })
            .map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="right">{index + 1}</StyledTableCell>
                <StyledTableCell>{row.company}</StyledTableCell>

                <StyledTableCell status={row.virtualPhoneNumberCurrentStatus}>
                  <Tooltip
                    title={
                      row.virtualPhoneNumberCurrentStatus === 'NOT_STARTED'
                        ? row.virtualPhoneNumberRemarks || ''
                        : ''
                    }
                  >
                    <>
                      <div>
                        <span>{getProvider(row.virtualPhoneNumberProvider)}</span>
                      </div>
                      <PendingDays
                        status={row.virtualPhoneNumberCurrentStatus}
                        startTime={row.virtualPhoneNumberStartTime}
                      />
                    </>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell status={row.stateFilingCurrentStatus}>
                  <Tooltip
                    title={
                      row.stateFilingCurrentStatus === 'NOT_STARTED'
                        ? row.stateFilingRemarks || ''
                        : ''
                    }
                  >
                    <>
                      <div>
                        <span>{getProvider(row.stateFilingProvider)}</span>
                        <PendingDays
                          status={row.stateFilingCurrentStatus}
                          startTime={row.stateFilingStartTime}
                        />
                      </div>
                    </>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell status={row.registeredAgentCurrentStatus}>
                  <Tooltip
                    title={
                      row.registeredAgentCurrentStatus === 'NOT_STARTED'
                        ? row.registeredAgentRemarks || ''
                        : ''
                    }
                  >
                    <>
                      <div>
                        <span>{getProvider(row.registeredAgentProvider)}</span>
                      </div>
                      <PendingDays
                        status={row.registeredAgentCurrentStatus}
                        startTime={row.virturegisteredAgentStartTimealPhoneNumberStartTime}
                      />
                    </>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell status={row.einCurrentStatus}>
                  <Tooltip
                    title={row.einCurrentStatus === 'NOT_STARTED' ? row.einRemarks || '' : ''}
                  >
                    <>
                      <div>
                        <span>{getProvider(row.einProvider)}</span>
                      </div>
                      <PendingDays status={row.einCurrentStatus} startTime={row.einStartTime} />
                    </>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell status={row.relayfiCurrentStatus}>
                  <Tooltip
                    title={
                      row.relayfiCurrentStatus === 'NOT_STARTED' ? row.relayfiRemarks || '' : ''
                    }
                  >
                    <span>{getProvider(row.relayfiProvider)}</span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell status={row.mercuryCurrentStatus}>
                  <Tooltip
                    title={
                      row.mercuryCurrentStatus === 'NOT_STARTED' ? row.mercuryRemarks || '' : ''
                    }
                  >
                    <span>{getProvider(row.mercuryProvider)}</span>
                  </Tooltip>
                </StyledTableCell>

                <StyledTableCell align="center">{row.team}</StyledTableCell>
                <StyledTableCell align="left">{row.email}</StyledTableCell>
                <StyledTableCell align="center">{row.state}</StyledTableCell>
                <StyledTableCell align="center">{row.id}</StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompanyPendingOrders;
