import * as React from 'react';
import styled from '@emotion/styled';
import {
  SimpleForm,
  ShowProps,
  Edit,
  AutocompleteInput,
  Show,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout,
  useShowController,
  usePermissions,
  TextField,
  Labeled,
  FunctionField,
} from 'react-admin';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../../components/SectionTitle';
import Separator from '../../../components/Separator';
import TextInput from '../../../components/TextInput';
import DateInput from '../../../components/DateInput';
import {
  ListHeaderActionsEdit,
  ListHeaderActionsView,
} from '../../../components/ListHeaderActions';
import { Employee } from '../../../types';
import NumberInput from '../../../components/NumberInput';
import { Currency } from '../../../config/constants';
import ReferenceInput from '../../../components/ReferenceInput';
import SelectInput from '../../../components/SelectInput';
import { hasPermission, transformDate } from '../../../lib/SGHelper';
import BooleanInput from '../../../components/BooleanInput';
import { Grid } from '@mui/material';
import LabelField from '../../../components/LabelField';
import { LabelType } from '../../../config/LabelType';
import BooleanField from '../../../components/BooleanField';
import DocumentListItem from '../../../components/DocumentListItem';
const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));
const transform = (data: { dateOfFormation: any }) => {
  const modifiedData = {
    ...data,
    dateOfFormation: transformDate(data.dateOfFormation, 'dd MMMM, yyyy'),
  };
  return modifiedData;
};
const CompanyDissolutionView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  const signedDocument = record?.documents?.find((d: any) => d.purpose === 'DISSOLUTION_SIGNED');
  const confirmationDocument = record?.documents?.find(
    (d: any) => d.purpose === 'DISSOLUTION_CONFIRMATION'
  );
  const taxResolutionDocument = record?.documents?.find(
    (d: any) => d.purpose === 'TAX_OFFICER_RESOLUTION_SIGNED'
  );

  return (
    <Show {...props} actions={<ListHeaderActionsView />}>
      <TabbedShowLayout>
        <Tab label="Reinstatement Details">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SimpleShowLayout>
                <Wrapper>
                  <LabelField
                    source="data.id"
                    label="Ref."
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  {!isCPA && (
                    <LabelField
                      source="data.email"
                      label="Email"
                      fullWidth
                      labelType={LabelType.EMAIL_FIELD}
                    />
                  )}
                  <LabelField
                    source="data.company"
                    label="Company"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="data.state"
                    label="State"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="data.paymentStatus"
                    label="Payment Status"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />

                  <LabelField
                    source="data.status"
                    label="Status"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="data.finalTaxStatus"
                    label="Final Tax Status"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />

                  <LabelField
                    source="data.url"
                    label="Dissolution Document"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <LabelField
                    source="data.taxResolutionUrl"
                    label="Tax Resolution Document"
                    fullWidth
                    labelType={LabelType.TEXT_FIELD}
                  />
                  <Labeled
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <TextField source="data.notes" label="Notes" fullWidth />
                  </Labeled>
                </Wrapper>
              </SimpleShowLayout>
            </Grid>
            <Grid ml={6} item xs={6}>
              {record && signedDocument && (
                <>
                  <DocumentListItem
                    companyId={record?.data.companyId}
                    purpose="DISSOLUTION_SIGNED"
                    text="Signed Document"
                    exists={!!signedDocument}
                    readOnly
                    uuid={signedDocument?.uuid}
                  />
                </>
              )}
              {record && taxResolutionDocument && (
                <>
                  <DocumentListItem
                    companyId={record?.data.companyId}
                    purpose="TAX_OFFICER_RESOLUTION_SIGNED"
                    text="Signed Tax Resolution"
                    exists={!!taxResolutionDocument}
                    readOnly
                    uuid={taxResolutionDocument?.uuid}
                  />
                </>
              )}

              <>
                <DocumentListItem
                  companyId={record?.data?.companyId}
                  purpose="DISSOLUTION_CONFIRMATION"
                  text="Confirmation Document"
                  exists={!!confirmationDocument}
                  uuid={signedDocument?.uuid}
                />
              </>
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;
const EmployeeWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Box = styled.div`
  display: flex;
  gap: 1em;
`;
export default CompanyDissolutionView;
