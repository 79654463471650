import * as React from 'react';
import styled from '@emotion/styled';
import {
  Show,
  ShowProps,
  BooleanField,
  DateField,
  ReferenceField,
  TextField,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import { ListHeaderActionsView } from '../../components/ListHeaderActions';
import { Box } from '@mui/material';
import { Row } from '../../components/Row';
import LabelField from '../../components/LabelField';
import { LabelType } from '../../config/LabelType';

const CompanyArchivedMasterView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderActionsView />}>
      <SimpleForm>
        <Row>
          <div style={{ width: '100%' }}>
            <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
              <Box mr={{ xs: 0, sm: '0.5em' }}>
                <LabelField
                  source="companyId"
                  label="Company Ref."
                  labelType={LabelType.TEXT_FIELD}
                />
              </Box>
              <Box mr={{ xs: 0, sm: '0.5em', width: '50%' }}>
                <ReferenceInput
                  disabled
                  label="Company"
                  source="companyId"
                  reference="company-master-data"
                >
                  <AutocompleteInput disabled optionText="companyFullName" />
                </ReferenceInput>
              </Box>
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <DateTimeInput source="startDateTime" label="Started" disabled />
            </Box>
          </div>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <DateTimeInput source="endDateTime" label="Ended" disabled />
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="reason" label="Reason" disabled />
            </Box>
          </div>
        </Row>
        <Row>
          <div style={{ width: '50%' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <BooleanInput source="isValid" label="Archived" disabled />
            </Box>
          </div>
        </Row>
      </SimpleForm>
    </Show>
  );
};

export default CompanyArchivedMasterView;
