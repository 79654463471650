import * as React from 'react';
import styled from '@emotion/styled';
import { SimpleForm, ShowProps, Edit, email } from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { ListHeaderActionsEdit } from '../../components/ListHeaderActions';
import { Row } from '../../components/Row';
import BooleanInput from '../../components/BooleanInput';

const PhoneNumberRegistrationDetailEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />}>
      <SimpleForm>
        <EmployeeWrapper>
          <BasicDetais>
            <SectionTitle label="Employee Details" />
            <TextInput source="id" label="Employee Id" disabled fullWidth />
            <TextInput source="phone" label="Phone Number" fullWidth />
            <BooleanInput source="valid" label="Is Valid" />
          </BasicDetais>
        </EmployeeWrapper>
      </SimpleForm>
    </Edit>
  );
};

const EmployeeWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const BasicDetais = styled.div`
  width: 100%;
`;
const Address = styled.div`
  width: 100%;
`;
export default PhoneNumberRegistrationDetailEdit;
