import * as React from 'react';
import { Datagrid, EditButton, ExportButton, TextInput, TopToolbar } from 'react-admin';
import BooleanField from '../../components/BooleanField';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import SelectInput from '../../components/SelectInput';
import TextField from '../../components/TextField';

const Filters = [
  <TextInput source="email" label="Email" alwaysOn />,
  <TextInput source="phone" label="Phone" alwaysOn />,
  <TextInput source="firstName" label="First Name" alwaysOn />,
  <TextInput source="lastName" label="Last Name" alwaysOn />,
  <TextInput source="companyName" label="Company" alwaysOn />,
];
const OperatingAgreementAddonListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const OperatingAgreementAddonList = () => {
  return (
    <List
      title={'StartGlobal Tax Estimator Addon'}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<OperatingAgreementAddonListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sort={{ field: 'id', order: 'DESC' }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <EmailField source="email" label="Email" />
        <TextField source="companyName" label="Company" />
        <TextField source="phone" label="Phone" />
      </Datagrid>
    </List>
  );
};

export default OperatingAgreementAddonList;
