import * as React from 'react';
import { Datagrid, EditButton } from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';

const Filters = [
  <BooleanInput source="isValid" label="Current Employees" alwaysOn />,
  <TextInput source="id" label="Employee Ref." alwaysOn />,
  <TextInput source="fullName" label="Name" alwaysOn />,
  <TextInput source="email" label="Email" alwaysOn />,
];

const EmployeeMasterList = () => {
  return (
    <List
      filters={Filters}
      filterDefaultValues={{ isValid: 'true' }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="fullName" label="Name" />
        <EmailField source="email" label="Email" />
        <TextField source="designation" label="Designation" />
        <DateField source="startDate" label="Start date" />
        <TextField source="addressLine1" label="Address" />
        <TextField source="city" label="City" />
        <TextField source="stateOrProvince" label="State" />
        <TextField source="country" label="Country" />
        <TextField source="postalCode" label="Zip Code" />
        <TextField source="tel" label="Mobile No" />
        <EmailField source="email2" label="Personal email" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default EmployeeMasterList;
