import PhoneNumberRegistrationDetailList from './PhoneNumberRegistrationDetailList';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneNumberRegistrationDetailEdit from './PhoneNumberRegistrationDetailEdit';

const resource = {
  list: PhoneNumberRegistrationDetailList,
  edit: PhoneNumberRegistrationDetailEdit,
  icon: PhoneIcon,
};

export default resource;
