import styled from '@emotion/styled';
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import * as React from 'react';
import {
  Button,
  Datagrid,
  Edit,
  EditButton,
  FileField,
  FileInput,
  FunctionField,
  Labeled,
  SelectField,
  SelectInput,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useDataProvider,
  useNotify,
  useShowController,
} from 'react-admin';

import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import LabelField from '../../components/LabelField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { KPIParameterType } from '../../config/constants';
import { KPIState } from '../../config/constants';
import { KPIType } from '../../config/constants';
import { EINProvider } from '../../config/constants';
import { StateTaxStatus } from '../accounting/state-tax/StateTaxStatus';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { ListHeaderList } from '../../components/ListHeaderActions';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
  any,
} from 'prop-types';
import { LabelType } from '../../config/LabelType';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import dataProvider from '../../config/dataProvider';

import { useForm } from 'react-hook-form';
import { CustomTextField } from '../../components/CustomTextField';
import { Alert, Divider } from '@mui/material';
const EINProviderArray = EINProvider.map((f) => ({
  id: f.id,
  name: f.label,
}));

const EinServiceView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderList />}>
      <MyShow {...props} />
    </Show>
  );
};

const EmployeeDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;
const handleDownload = (uuid: any) => {
  dataProvider
    .get(`company-documents/download/uuid/${uuid}`, {})
    .then((data: any) => {
      console.log(data);
      if (data && data.data.url) {
        window.open(data.data.url, '_blank');
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
};

const MyShow = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const legalAddress = record?.legalAddress;
  const residentialAddress = record?.residentialAddress;

  const company = record?.company;
  const companyId = record?.companyId;
  const stateFilingDetails = record?.stateFilingDetails;
  const einDetails = record?.einDetails;
  const stateTax = record?.stateTax;
  const Spacer = () => <Box m={1}>&nbsp;</Box>;

  const [upload, setUpload] = React.useState(false);

  const handleUploadSuccess = (logo: any) => {
    setUpload(true);
  };
  const handleUploadError = () => {
    setUpload(false);
  };
  const handleResendFax = () => {
    dataProvider
      .post(`update/company/${companyId}/ein/resend`, {})
      .then((data: any) => {
        if (data.data.key === 'status' && data.data.value === 'ok') {
          notify('Data updated successfully', { type: 'success' });
          return;
        }
      })
      .catch((error: any) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setProcessing(false));
  };
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  console.log('record', record);

  const [processing, setProcessing] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const onSubmit = (data: any) => {
    setProcessing(true);
    dataProvider
      .post(`update/company/${companyId}/ein/ein-number`, { data: { ein: getValues('ein') } })
      .then((data: any) => {
        if (data.data.key === 'status' && data.data.value === 'ok') {
          notify('Data updated successfully', { type: 'success' });
          return;
        }
      })
      .catch((error: any) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setProcessing(false));
  };
  const handleSSNNumber = (e: any) => {
    const value = e.target.value
      .replace(/[^\d0-9]/g, '')
      .replace(/(.{2})(.{7})/g, '$1-$2')
      .substring(0, 10)
      .trim();
    setValue('ein', value);
  };
  return (
    <TabbedShowLayout>
      <Tab label="Details">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SimpleShowLayout>
              <EmployeeDetails>
                <LabelField
                  source="serviceStartTime"
                  label="EIN Applied On"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  source="pendingDays"
                  label="Pending For (Days)"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <Labeled label="Company">
                  <FunctionField
                    record={company}
                    render={(record: { companyName: any; companyNameType: any }) =>
                      `${record.companyName} ${record.companyNameType}`
                    }
                  />
                </Labeled>
                <LabelField
                  record={company}
                  source="companyType"
                  label="Type"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  record={legalAddress}
                  source="stateOrProvince"
                  label="State"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  source="dateOfFormationString"
                  label="State Filing On"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  source="businessDescription"
                  label="Service"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  source="responsibleParty"
                  label="Name of the owner"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
                <LabelField
                  source="noOfMembers"
                  label="No. Of Members"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
              </EmployeeDetails>
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={6}>
            <SimpleShowLayout>
              <EmployeeDetails style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Label>Legal Address of Entity</Label>
                  <div style={{ marginTop: '0.2em' }}>
                    <Box>
                      <Typography variant="body2">
                        {record?.companyName} {record?.companyNameType}
                      </Typography>
                      <Typography variant="body2">{record?.legalAddress?.addressLine1}</Typography>
                      <Typography variant="body2">{record?.legalAddress?.city}</Typography>
                      <Typography variant="body2">
                        {record?.legalAddress?.stateOrProvince} {record?.legalAddress?.postalCode}{' '}
                        {record?.legalAddress?.country}
                      </Typography>
                    </Box>
                  </div>
                </div>
                <div>
                  <Label>Address of Owner</Label>
                  <div style={{ marginTop: '0.2em' }}>
                    <Box>
                      <Typography variant="body2">{record?.responsibleParty}</Typography>
                      <Typography variant="body2">
                        {record?.residentialAddress?.addressLine1}
                      </Typography>
                      <Typography variant="body2">{record?.residentialAddress?.city}</Typography>
                      <Typography variant="body2">
                        {record?.residentialAddress?.stateOrProvince}{' '}
                        {record?.residentialAddress?.postalCode}
                      </Typography>
                      <Typography variant="body2">{record?.residentialAddress?.country}</Typography>
                    </Box>
                  </div>
                </div>
              </EmployeeDetails>
            </SimpleShowLayout>
          </Grid>

          <Spacer />
          <Grid container xs={12} style={{ padding: 12 }}>
            {record?.procurementType === 'FAX' && (
              <>
                <LoadingButton
                  onClick={() => handleResendFax()}
                  variant="contained"
                  color="error"
                  startIcon={<CloudDownloadIcon />}
                >
                  {' '}
                  Resend Fax
                </LoadingButton>
                <Alert severity="warning">
                  This may take 3-5 minutes to send fax from Startglobal
                </Alert>{' '}
              </>
            )}
          </Grid>

          <Grid container xs={12} style={{ padding: 12 }}>
            {record?.procurementType === 'ONLINE_WITH_8822B' ? (
              <Alert severity="success">This client is okay with any name in EIN letter</Alert>
            ) : (
              <Alert severity="error">This client needs his own name in the EIN</Alert>
            )}
          </Grid>

          <Grid container spacing={2} xs={12} md={12} xl={6} style={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={12} md={4}>
              <LoadingButton
                onClick={() => handleDownload(record?.passportUUID)}
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
              >
                {' '}
                {'PASSPORT'}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <LoadingButton
                onClick={() => handleDownload(record?.formationDocumentUUID)}
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
              >
                {'FORMATION'}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <LoadingButton
                onClick={() => handleDownload(record?.ss4UUID)}
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
              >
                {'SIGNED SS4'}
              </LoadingButton>
            </Grid>
          </Grid>
          <Spacer />
          {record?.einErrorUUID ? (
            <Grid container spacing={2} xs={12} md={12} xl={6}>
              <Grid item xs={12} sm={12} md={4}>
                <LoadingButton
                  onClick={() => handleDownload(record?.einErrorUUID)}
                  variant="contained"
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                >
                  {' '}
                  {'EIN (Error)'}
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <LoadingButton
                  onClick={() => handleDownload(record?.ein2848Signed)}
                  variant="contained"
                  color="primary"
                  disabled={!!!record?.ein2848Signed}
                  startIcon={<CloudDownloadIcon />}
                >
                  {'Signed 2848'}
                </LoadingButton>
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Spacer />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <CustomTextField onChange={handleSSNNumber} control={control} label="EIN" name="ein" />
          </Grid>
          <Grid item xs={2} style={{ marginTop: 30 }}>
            <LoadingButton
              loading={processing}
              variant="contained"
              loadingPosition="end"
              //    type="submit"

              onClick={() => onSubmit(data)}
            >
              {'Update EIN'}
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item spacing={1} xs={1}>
            <Typography>Upload EIN</Typography>
          </Grid>
          <Grid item spacing={1} xs={10}>
            {/* <EinServiceUpload onSuccess={handleUploadSuccess} onError={handleUploadError} companyId={companyId}/> */}
          </Grid>
        </Grid>
      </Tab>
    </TabbedShowLayout>
  );
};

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;
const DocumentListItem = (props: {
  exists: any;
  companyId: any;
  purpose: string | undefined;
  id?: any;
  text: any;
}) => {
  const [exists, setExists] = React.useState(props.exists);

  const dataProvider = useDataProvider();

  const notify = useNotify();
  const handleUpload = (target: any) => {
    const purpose = target.id;
    const acceptedFiles = target.files;
    if (!purpose) {
      notify('Unable to upload file', { type: 'error' });
    }
    if (!acceptedFiles) {
      return;
    }
    dataProvider
      .upload('company-documents/upload', {
        data: {
          files: acceptedFiles[0],
          companyId: props.companyId,
          purpose: purpose,
        },
      })
      .then((data: any) => {
        notify('File uploaded successfully', { type: 'success' });
        setExists(true);
      })
      .catch((error: any) => {
        notify('Oh no.. Error occured', { type: 'warning' });
      });
  };
  // return <ListItem
  //         secondaryAction={<>
  //             <input
  //                 accept="application/zip, application/pdf, image/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //                 id={props.purpose}
  //                 style={{ display: 'none' }}
  //                 onChange={handleUpload}
  //                 type="file"
  //             />

  // <IconButton edge="end" aria-label="download" disabled={!exists} onClick={ () => handleDownload(props.id, props.purpose)}>
  //     <DownloadIcon />
  // </IconButton>

  //     </>
  //     }
  // >

  //     <ListItemText
  //     primary={props.text}
  //     />
  // </ListItem>
};

export default EinServiceView;

function data(data: any): void {
  throw new Error('Function not implemented.');
}
