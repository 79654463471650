// in src/posts.js
import * as React from 'react';
import { useForm } from 'react-hook-form';
import SubmitButton from './SubmitButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PreviewIcon from '@mui/icons-material/Preview';
import styled from '@emotion/styled';

import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import { CustomTextField } from '../../../components/CustomTextField';
import { CustomSelect } from '../../../components/CustomSelect';

import { FileUpload } from '../file-uploads/FileUpload';

const EINProcurementForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processingEINProcurementType, setProcessingEINProcurementType] = React.useState(false);

  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmitOfEINProcurementType = (data) => {
    setProcessingEINProcurementType(true);
    dataProvider
      .post(`update/company/${companyId}/ein/procurement-type`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessingEINProcurementType(false));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitOfEINProcurementType)} style={{ width: '100%' }}>
        <Grid container className="styledGrid">
          <Grid item xs={6}>
            <CustomSelect
              control={control}
              label="Procurement Type"
              name="procurementType"
              defaultValue={data?.einDetails?.procurementType}
              options={[
                { value: 'ONLINE', label: 'ONLINE (SSN)' },
                { value: 'ONLINE_WITH_8822B', label: 'ONLINE (USACORPS)' },
                { value: 'FAX', label: 'FAX' },
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <SubmitButton
              processing={processingEINProcurementType}
              label="Update Procurement Type"
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const EINNumberForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);

  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`update/company/${companyId}/ein/ein-number`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container className="styledGrid">
          <Grid item xs={6}>
            <CustomTextField
              control={control}
              label="EIN"
              name="ein"
              defaultValue={data?.einDetails?.ein}
            />
          </Grid>
          <Grid item xs={3}>
            <SubmitButton processing={processing} label="Update EIN" />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
const EINFileUploadForm = ({ data }) => {
  const [upload, setUpload] = React.useState(false);
  const einUploaded = data.documents.some((e) => e.purpose === 'EIN');
  const [EINUploaded, setEINUploaded] = React.useState(einUploaded);
  const [preview, setPreview] = React.useState(false);
  const [url, setUrl] = React.useState();

  React.useEffect(() => {
    setEINUploaded(EINUploaded);
  }, [EINUploaded]);

  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);

  const handleUploadSuccess = (logo) => {
    setUpload(true);
    setEINUploaded(true);
  };
  const handleUploadError = () => {
    setUpload(false);
    setEINUploaded(false);
  };
  const handleDownload = (companyId, purpose) => {
    dataProvider
      .get(`company-documents/download/${companyId}/${purpose}`, {})
      .then((payload) => {
        if (payload && payload.data && payload.data.url) {
          window.open(payload.data.url, '_blank');
        }
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const handleDelete = (companyId, purpose) => {
    setOpen(false);
    dataProvider
      .post(`company-documents/delete/${companyId}/${purpose}`)
      .then((data) => {
        setUpload(false);
        setEINUploaded(false);
        setPreview(false);
        notify('File Deleted  successfully', { type: 'success' });
        // window.location.reload();
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const previewDocument = (companyId, purpose) => {
    dataProvider
      .get(`company-documents/download/${companyId}/${purpose}`, {})
      .then((payload) => {
        if (payload && payload.data && payload.data.url) {
          setUrl(payload.data.url);
          setPreview(true);
        }
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  return (
    <>
      <Grid container item spacing={2} style={{ margin: '1em', gap: '1em', display: 'flex' }}>
        {!einUploaded && (
          <div style={{ width: '50%', display: 'flex', gap: '1em', flexDirection: 'column' }}>
            <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
              Upload EIN Document
            </div>
            <FileUpload
              onSuccess={handleUploadSuccess}
              onError={handleUploadError}
              companyId={companyId}
              purpose="EIN"
            />
          </div>
        )}
        {einUploaded && (
          <>
            <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
              <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
                Download
              </div>
              <CloudDownloadIcon
                className="fileActionStyle"
                onClick={() => handleDownload(companyId, 'EIN')}
              />
            </div>
            <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
              <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
                Delete
              </div>
              <DeleteIcon className="fileActionStyle" onClick={() => handleClickOpen()} />
            </div>
            <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
              <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
                Preview
              </div>
              <PreviewIcon
                className="fileActionStyle"
                onClick={() => previewDocument(companyId, 'EIN')}
              />
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Delete EIN document'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you really want to delete the file?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>CLOSE</Button>
                <Button onClick={() => handleDelete(companyId, 'EIN')}>DELETE</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Grid>
      <Grid container item spacing={2} style={{ margin: '1em', gap: '1em', display: 'flex' }}>
        {preview && (
          <div
            style={{
              paddingTop: '50px',
              position: 'relative',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
              alignItems: 'flex-end',
            }}
          >
            <>
              {/* <HighlightOffIcon  onClick={() => setPreview(false)} alt="" /> */}
              <StyledButton onClick={() => setPreview(false)} variant="contained">
                CLOSE PREVIEW
              </StyledButton>
              <iframe
                style={{
                  width: '100%',
                  height: '88vh',
                  borderRadius: '12px',
                  boxShadow: '0px 4px 40px rgb(0 0 0 / 12%)',
                }}
                src={url}
              />
            </>
          </div>
        )}
      </Grid>
    </>
  );
};

const EINForm = ({ data }) => {
  return (
    <>
      <EINProcurementForm data={data} />
      <EINNumberForm data={data} />
      <EINFileUploadForm data={data} />
    </>
  );
};

export default EINForm;

const StyledButton = styled(Button)`
  background-color: #7c7c7c;
  cursor: pointer;
  &:hover {
    background-color: #1a1a1a;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;
