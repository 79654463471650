import * as React from 'react';
import { BooleanField, Datagrid, EditButton, ReferenceField } from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { WorkflowStatus } from './../../config/constants';

const WorkflowStatusArray = WorkflowStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="id" label="Mail Id" alwaysOn />,
  <TextInput source="companyName" label="Company" alwaysOn />,
];

const MailReceivedLogList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyName" label="Company" />
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="url" />
        <TextField source="remarks" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default MailReceivedLogList;
