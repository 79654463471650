import * as React from 'react';
import { Datagrid, ExportButton, Identifier, RaRecord, ShowButton, TopToolbar } from 'react-admin';
import DateField from '../../../components/DateField';
import List from '../../../components/List';
import NumberField from '../../../components/NumberField';
import SelectInput from '../../../components/SelectInput';
import TextField from '../../../components/TextField';
import TextInput from '../../../components/TextInput';

const StateTaxListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const Filters = [
  <TextInput label="Company" source="company" alwaysOn />,
  <SelectInput
    source="state"
    choices={[
      { id: 'DELAWARE', name: 'DELAWARE' },
      { id: 'WYOMING', name: 'WYOMING' },
    ]}
    alwaysOn
  />,
  <TextInput label="Owner" source="responsibleParty" alwaysOn />,
];

const StateTaxApprovalList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }} actions={<StateTaxListActions />}>
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        rowClick={(id: Identifier, resource: string, record: RaRecord) => {
          return `/company-master-data/${record?.companyRef}?tab=compliance`;
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="company" label="Company" />
        <TextField source="state" label="State" />
        <TextField source="responsibleParty" label="Owner" />
        <TextField source="country" label="Country" />
        <DateField source="dateOfFormation" label="Formed On" />
        <TextField source="dueDate" label="Due Date" />
        <TextField source="status" label="Status" />
        <NumberField source="paidAmount" label="Paid amount" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default StateTaxApprovalList;
