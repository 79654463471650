import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  SelectField,
  SelectInput,
  TopToolbar,
} from 'react-admin';
import DateField from '../../components/DateField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { StateFilingProvider } from '../../config/constants';
import { StateFilingStatus } from '../../config/constants';
import { StateFilingCurrentStatus } from '../../config/constants';
const StateFilingProviderArray = StateFilingProvider.map((f) => ({
  id: f.id,
  name: f.label,
}));
const StateFilingStatusArray = StateFilingStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const StateFilingCurrentStatusArray = StateFilingCurrentStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <TextInput source="email" label="Email" alwaysOn />,
  <TextInput source="company" label="Company" alwaysOn />,
  <SelectInput source="provider" label="Provider" choices={StateFilingProviderArray} alwaysOn />,
  <SelectInput source="status" label="Status" choices={StateFilingStatusArray} alwaysOn />,
  <SelectInput
    source="currentStatus"
    label="Current Status"
    choices={StateFilingCurrentStatusArray}
    alwaysOn
  />,
];
const StateFilingServiceListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};

const StateFilingServiceList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<StateFilingServiceListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyId" label="Company Ref." />
        <TextField source="companyName" label="Company" />
        <TextField source="state" label="State" />
        <TextField source="team" label="Team" />
        <TextField source="pendingDays" label="Pending" />
        <SelectField source="status" label="Status" choices={StateFilingStatusArray} />
        <SelectField
          source="currentStatus"
          label="Current Status"
          choices={StateFilingCurrentStatusArray}
        />
        <SelectField source="provider" label="Provider" choices={StateFilingProviderArray} />
        <TextField source="remarks" label="Remarks" />
        <DateField source="serviceStartDateTime" label="Started On" />
        <DateField source="deliveryDate2" label="Delivery Date" />
        <TextField source="remarks" label="Remarks" />
      </Datagrid>
    </List>
  );
};

export default StateFilingServiceList;
