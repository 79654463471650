import * as React from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Tooltip } from '@mui/material';

import { useNotify, useDataProvider } from 'react-admin';
import { Grid } from '@mui/material';
import SubmitButton from '../SubmitButton';
import Switch from '@mui/material/Switch';
import { CustomTextField } from '../../../../components/CustomTextField';

const OAMemberForm = ({ data, member }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.company?.id;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [cashContribution, setCashContribution] = React.useState(member?.cashContribution);
  const [nonCashContribution, setNonCashContribution] = React.useState(member?.nonCashContribution);
  const [nonCashContributionArray, setNonCashContributionArray] = React.useState(
    member?.oaNonCashContributionDTOList
  );
  const [nonCashContributionObj, setNonCashContributionObj] = React.useState({
    contributionType: '',
    contributionAmount: 0,
  });

  const handleActionCashContibution = (e) => {
    setCashContribution(e.target.checked);
  };
  const handleActionNonCashContibution = (e) => {
    setNonCashContribution(e.target.checked);
  };
  const addRow = () => {
    setNonCashContributionArray([...nonCashContributionArray, nonCashContributionObj]);
  };
  const deleteRow = () => {
    let array = [...nonCashContributionArray];
    if (array.length > 1) {
      array.pop();
      setNonCashContributionArray(array);
    }
  };

  const onSubmit = (data) => {
    setProcessing(true);
    data['cashContribution'] = cashContribution;
    data['nonCashContribution'] = nonCashContribution;
    data['oaNonCashContributions'] = nonCashContributionArray;
    dataProvider
      .post(`company-legal-documents/operating-agreement/${companyId}/update-member`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Member updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="First name"
            name="firstName"
            defaultValue={member?.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Last name"
            name="lastName"
            defaultValue={member?.lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Email"
            name="email"
            defaultValue={member?.email}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Address"
            name="address"
            defaultValue={member?.address}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="State"
            name="state"
            defaultValue={member?.state}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Country"
            name="country"
            defaultValue={member?.country}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField control={control} label="City" name="city" defaultValue={member?.city} />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Zip"
            name="zipCode"
            defaultValue={member?.zipCode}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Cash contribution</Label>
          <Switch defaultChecked={member?.cashContribution} onClick={handleActionCashContibution} />
        </Grid>
        {cashContribution && (
          <Grid item xs={6}>
            <CustomTextField
              control={control}
              label="How much cash will member initially contribute to the LLC?"
              name="cashContributionAmount"
              defaultValue={member?.cashContributionAmount}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Label>Non-cash contribution</Label>
          <Switch
            defaultChecked={member?.nonCashContribution}
            onClick={handleActionNonCashContibution}
          />
        </Grid>

        {nonCashContribution && (
          <Grid item xs={12}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Label>Non-cash contributions</Label>
              <div style={{ display: 'flex', marginRight: '1em', gap: '1em' }}>
                <Tooltip title="Add Contribution">
                  <AddCircleIcon className="documentIcons" onClick={() => addRow()} />
                </Tooltip>
                <Tooltip title="Remove Contribution">
                  <RemoveCircleIcon className="documentIcons" onClick={() => deleteRow()} />
                </Tooltip>
              </div>
            </div>
            <div>
              {nonCashContributionArray &&
                nonCashContributionArray.length &&
                nonCashContributionArray.map((row, index) => (
                  <div style={{ marginBottom: '16px', display: 'flex', width: '100%' }}>
                    <CustomTextField
                      control={control}
                      label="Contribution type"
                      name="contributionType"
                      defaultValue={row?.contributionType}
                      style={{ width: '100%' }}
                    />
                    <CustomTextField
                      control={control}
                      label="Contribution Amount"
                      name="contributionAmount"
                      defaultValue={row?.contributionAmount}
                      style={{ width: '100%' }}
                    />
                  </div>
                ))}
            </div>
          </Grid>
        )}
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Ownership / percentage"
            name="ownershipPercentage"
            defaultValue={member?.ownershipPercentage}
          />
        </Grid>
        <Grid item xs={3} style={{ marginLeft: '1em' }}>
          <SubmitButton processing={processing} label="Update" />
        </Grid>
      </Grid>
    </form>
  );
};

export default OAMemberForm;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  padding: 1em;
`;
