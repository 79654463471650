import EmployeeIcon from '@mui/icons-material/People';

// import KPIConfigParamCreate from './KPIConfigParamCreate';
// import KPIConfigParamEdit from './KPIConfigParamEdit';
// import KPIConfigParamView from './KPIConfigParamView';
import StateFilingServiceList from './StateFilingServiceList';
import StateFilingNameCheckServiceList from './StateFilingNameCheckServiceList';

const resource = {
  list: StateFilingServiceList,
  nameCheckList: StateFilingNameCheckServiceList,
  // create: KPIConfigParamCreate,
  // edit : KPIConfigParamEdit,
  // show:KPIConfigParamView,
  icon: EmployeeIcon,
};

export default resource;
