import * as React from 'react';
import { Datagrid, EditButton } from 'react-admin';
import DateField from '../../components/DateField';
import List from '../../components/List';
import NumberField from '../../components/NumberField';
import SelectInput from '../../components/SelectInput';
import TextField from '../../components/TextField';
import { Currency } from '../../config/constants';

const CurrencyArray = Currency.map((f) => ({
  id: f.id,
  name: f.label,
}));

const Filters = [
  <SelectInput source="currency" label="Currency" choices={CurrencyArray} alwaysOn />,
];

const AdsMasterList = () => {
  return (
    <List filters={Filters} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sort={{ field: 'id', order: 'DESC' }}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="currency" label="Currency" />
        <NumberField source="amount" label="Amount" />
        <DateField source="startDate" label="Start date" />
        <DateField source="endDate" label="End date" />
        <TextField source="description" label="Description" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AdsMasterList;
