import * as React from 'react';
import styled from '@emotion/styled';
import { SimpleForm, ShowProps, Edit, email, SelectInput, BooleanInput } from 'react-admin';
import { Box } from '@mui/material';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';
import { ListHeaderActionsEdit } from '../../components/ListHeaderActions';
import { Row } from '../../components/Row';
import { validateForm } from './KPIConfigParamValidateForm';
import { KPIParameterType } from './../../config/constants';
import { KPIState } from './../../config/constants';
import { KPIType } from './../../config/constants';

const KPIParameterTypeArray = KPIParameterType.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPIStateArray = KPIState.map((f) => ({
  id: f.id,
  name: f.label,
}));
const KPITypeArray = KPIType.map((f) => ({
  id: f.id,
  name: f.label,
}));

const KPIConfigParamEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      never
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsEdit />}>
      <SimpleForm validate={validateForm}>
        <KPIConfigWrapper>
          <ConfigDetais>
            <SectionTitle label="KPI Configuration Parameters" />
            <TextInput source="id" label="Ref." disabled fullWidth />
            <SelectInput
              source="type"
              label="KPI Type"
              choices={KPITypeArray}
              fullWidth
              isRequired
            />
            <SelectInput source="state" label="State" choices={KPIStateArray} fullWidth />
            <SelectInput
              source="parameter"
              label="Parameter Type"
              choices={KPIParameterTypeArray}
              fullWidth
              isRequired
            />
            ,
            <TextInput source="description" label="Description" fullWidth isRequired />
            <TextInput source="value" label="Value" fullWidth isRequired />
            <BooleanInput source="isValid" label="Active" />
          </ConfigDetais>
        </KPIConfigWrapper>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const KPIConfigWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const ConfigDetais = styled.div`
  width: 100%;
`;

export default KPIConfigParamEdit;
