// in src/posts.js
import * as React from 'react';

import {
  List,
  TextField,
  ReferenceField,
  EditButton,
  SimpleForm,
  CloneButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  NumberField,
  Filter,
  BooleanInput,
  BooleanField,
  TextInput,
  required,
} from 'react-admin';
import { Datagrid, ExportButton, TopToolbar, Show } from 'react-admin';

const DataFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Company" source="company" alwaysOn />
  </Filter>
);
const OrderTitle = ({ record }) => {
  return record ? <span>View Company Details</span> : null;
};

export const DataList = (props) => (
  <List
    title="List of all Emails which are blacklisted"
    filters={<DataFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="Ref." />
      <TextField source="company" />
      <TextField source="email" />
      <TextField source="currency" />
      <NumberField source="income" />
      <NumberField source="expense" />
    </Datagrid>
  </List>
);
// export const DataShow = (props) => {
//   return (
//     <Show title={<OrderTitle />} {...props} component="div">
//       <CompanyAccountingForm />
//     </Show>
//   );
// };
