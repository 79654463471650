import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';

const apiUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://api-v3.startglobal.dev/admin/v1'
    : 'https://api.trystart.com/admin/v1';
const apiLocal = 'http://localhost:8000/api/v1';

const httpClient = fetchUtils.fetchJson;
const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  const token = JSON.parse(localStorage.getItem('auth'));
  options.headers.set('Authorization', 'Bearer ' + token);
  console.log({
    url,
    options,
  });
  return fetchUtils.fetchJson(url, options);
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */

const dataProviderUtil = (function () {
  /**
   * This will return a promise, which converts a File object to base64 encoded string,
   * after remvoing all metadata
   * https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
   */
  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  /**
   * Given a object, it will iterate all the items and return a array of keys of file objects.
   * This one also checks whether the key holds  multiple file objects
   * Object.entries returns an array of arrays.
   * Inner array contain 2 elements, key and value of eaach object entries.
   * Here we are returning all the keys flattened to a single array
   *
   */
  const getFileObjects = (data) => {
    return Object.entries(data)
      .filter(([key, value]) => {
        return (
          (value && value instanceof File) ||
          (Array.isArray(value) && value.every((file) => file && file instanceof File))
        );
      })
      .map((arr) => arr[0]);
  };

  return {
    convertFileToBase64,
    getFileObjects,
  };
})();
const dataProvider = {
  getList: (resource, params) => {
    console.log('getList', { resource, params });
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    let search = '';
    for (const key of Object.keys(params.filter)) {
      search = search + key + ':' + params.filter[key] + ',';
    }
    search = search.slice(0, -1); //added to remove trailing comma

    if (params.filter.q) {
      //we are tyring for full text search, but we dont have the ability
      if (resource === 'company' || resource === 'company-master-data') {
        search = 'companyFullName:' + params.filter.q;
      }
    }

    //https://api.startglobal.dev/admin/v1/coupon/?search=id<10&size=3&sort=id,DESC
    //const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const url = `${apiUrl}/${resource}/?filter=${search}&size=${perPage}&page=${
      page - 1
    }&sort=${field},${order}`;
    return fetchJson(url)
      .then(({ headers, json }) => {
        console.log(json);
        return {
          data: json?.payload?.content || [],
          total: json?.payload?.totalElements,
        };
      })
      .catch((err) => console.log(err));
  },
  //done
  getOne: (resource, params) => {
    console.log('getOne', { resource, params });
    return fetchJson(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json?.payload || {},
    }));
  },
  //done
  getMany: (resource, params) => {
    console.log('getMany', { resource, params });
    const url = `${apiUrl}/${resource}/?filter=id:${encodeURIComponent(
      JSON.stringify(params.ids)
    )}`;
    return fetchJson(url).then(({ json }) => {
      return {
        data: json?.payload?.content || [],
        total: json?.payload?.totalElements,
      };
    });
  },

  getManyReference: (resource, params) => {
    console.log('getManyReference', { resource, params });
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return fetchJson(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },
  //done
  update: async (resource, params) => {
    console.log('update', { resource, params });
    /*
        logic: 
            loop through all params.data[key], check for files.
            if files repalce it with base64 encoded format
            params.data = {
                id : 1000
                file : File OBJ
                files : [File Obj]
            }

        params.data[key] can be a file object. --> [source] = {
            base64 : <base64string>,
            name : <filename>
        }
        if params.data[key] is an array, then it can be an array of file object --> [source] = [{
            base64 : <base64String>,
            name : <filename></filename>
        }]
        */
    const fileObjects = dataProviderUtil.getFileObjects(params.data);
    if (fileObjects) {
      for (const file of fileObjects) {
        if (params.data[file].rawFile) {
          const base64 = await dataProviderUtil.convertFileToBase64(params.data[file].rawFile);
          const fileName = params.data[file].rawFile.name;
          params.data[file] = {
            base64,
            name: fileName,
          };
        } else {
          //this is an array of objects
          const files = await Promise.all(
            params.data[file].map(async (fileObject) => {
              const base64 = await dataProviderUtil.convertFileToBase64(fileObject.rawFile);
              const fileName = fileObject.rawFile.name;
              return {
                base64,
                name: fileName,
              };
            })
          );
          params.data[file] = files;
        }
      }
    }

    return fetchJson(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json?.payload }));
  },

  updateMany: (resource, params) => {
    console.log('updateMany', { resource, params });
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return fetchJson(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  //done
  create: (resource, params) => {
    console.log(resource, params);
    return fetchJson(`${apiUrl}/${resource}/`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json?.payload?.id },
    }));
  },

  delete: (resource, params) => {
    console.log('delete', { resource, params });
    return fetchJson(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json?.payload }));
  },

  deleteMany: (resource, params) => {
    console.log('deleteMany', { resource, params });
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return fetchJson(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  //added just ot fetch an api.
  //Everythign shoudl be handled by the caller
  get: (resource, params) => {
    console.log(params);
    let search = '';
    if (params && params.filter) {
      for (const key of Object.keys(params.filter)) {
        search = search + key + '=' + params.filter[key] + '&';
      }
    }

    return fetchJson(`${apiUrl}/${resource}?${search}`).then(({ json }) => ({
      data: json?.payload || {},
    }));
  },

  post: async (resource, params) => {
    console.log('post', { resource, params });
    return fetchJson(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: params && params.data ? JSON.stringify(params.data) : null,
    }).then(({ json }) => ({ data: json?.payload, error: json?.error }));
  },

  //expects params.data as an array of File Objects.
  //params.additionalData will also be sent along with it
  upload: async (resource, params) => {
    const fileObjects = dataProviderUtil.getFileObjects(params.data);
    if (fileObjects) {
      for (const file of fileObjects) {
        if (params.data[file] instanceof File) {
          const base64 = await dataProviderUtil.convertFileToBase64(params.data[file]);
          const fileName = params.data[file].rawFile
            ? params.data[file].rawFile.name
            : params.data[file].name;
          params.data[file] = [
            {
              base64,
              name: fileName,
            },
          ];
        } else {
          //this is an array of objects
          const files = await Promise.all(
            params.data[file].map(async (fileObject) => {
              const base64 = await dataProviderUtil.convertFileToBase64(fileObject);
              const fileName = fileObject.name;
              return {
                base64,
                name: fileName,
              };
            })
          );
          params.data[file] = files;
        }
      }
    }
    return fetchJson(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json?.payload }));
  },
  getKpiInstanceParameterByYearAndMonth: (resource, params) => {
    console.log('getKpiInstanceParameterByYearAndMonth');
    return fetchJson(`${apiUrl}/${resource}/${params.id}/${params.month}`).then(({ json }) => ({
      data: json?.payload || {},
    }));
  },

  //localapi starts

  getLocal: (resource, params) => {
    console.log(params);
    let search = '';
    if (params && params.filter) {
      for (const key of Object.keys(params.filter)) {
        search = search + key + '=' + params.filter[key] + '&';
      }
    }

    return fetchJson(`${apiLocal}/${resource}?${search}`).then(({ json }) => ({
      data: json?.payload || {},
    }));
  },

  postLocal: async (resource, params) => {
    console.log('post', { resource, params });
    return fetchJson(`${apiLocal}/${resource}`, {
      method: 'POST',
      body: params && params.data ? JSON.stringify(params.data) : null,
    }).then(({ json }) => ({ data: json, error: json?.error }));
  },
  //localapi ends
  getImpersonateUrl: (_, params) => {
    const auth = window?.localStorage?.getItem('auth');
    if (auth) {
      const token = JSON.parse(auth);
      return `${apiUrl}/impersonate/company/${params.id}?company-uuid=${params.uuid}&Authorization=${token}`;
    }
    return null;
  },
};

export default dataProvider;
