import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  FilterButton,
  CreateButton,
  ExportButton,
  SelectField,
  TopToolbar,
  SelectInput,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';

import { FranchiseMaster } from './../../config/constants';

const franchiseIdNameArray = FranchiseMaster.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [
  <ReferenceInput label="Company" source="companyId" reference="company">
    <AutocompleteInput optionText="companyFullName" />
  </ReferenceInput>,
  <SelectInput source="oldFranchiseId" label="From" choices={franchiseIdNameArray} />,
  <SelectInput source="newFranchiseId" label="To" choices={franchiseIdNameArray} />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const FranchiseChangeLogList = () => {
  return (
    <List
      filters={Filters}
      //sort={{ field: 'createdDate', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        optimized
        rowClick="show"
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
      >
        <TextField source="id" />
        <ReferenceField source="companyId" reference="company-master-data">
          <TextField source="companyFullName" />
        </ReferenceField>
        <SelectField source="oldFranchiseId" label="From" choices={franchiseIdNameArray} />,
        <SelectField source="newFranchiseId" label="To" choices={franchiseIdNameArray} />
        <DateField source="createdDate" />
      </Datagrid>
    </List>
  );
};

export default FranchiseChangeLogList;
