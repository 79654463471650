import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from '@emotion/styled';
import React from 'react';
import dataProvider from '../../../config/dataProvider';
import { useNotify } from 'react-admin';
import { StateTaxUpload } from './StateTaxUpload';

export interface StateTaxStatusProps {
  data: any;
}
export const StateTaxStatus = (props: StateTaxStatusProps) => {
  const [taxStatus, setTaxStatus] = useState('IN_PROGRESS');
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const companyId = props.data?.company?.id;
  const notify = useNotify();
  const [upload, setUpload] = useState(false);

  const handleUploadSuccess = (logo: any) => {
    setUpload(true);
  };
  const handleUploadError = () => {
    setUpload(false);
  };
  React.useEffect(() => {
    if (props.data) {
      setTaxStatus(props.data?.stateTax?.status);
    }
  }, [props.data]);
  const handleChange = (event: SelectChangeEvent) => {
    setTaxStatus(event.target.value);
  };

  const submit = (_data: any) => {
    setProcessing(true);
    _data = {
      ..._data,
      id: props.data?.stateTax.id,
      status: taxStatus,
    };
    dataProvider
      .post(`update/company/${companyId}/franchise-tax/status`, { data: _data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', { type: 'success' });
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setProcessing(false));
  };

  return (
    <Wrapper>
      <StatusUpdate>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={taxStatus}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={'IN_PROGRESS'}>In Progress</MenuItem>
            <MenuItem value={'CONFIRMED'}>Paid to State</MenuItem>
            <MenuItem value={'REJECTED'}>Rejected</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          loading={processing}
          variant="contained"
          loadingPosition="end"
          type="submit"
          fullWidth
          onClick={() => submit(data)}
        >
          {'Update Tax Status'}
        </LoadingButton>
      </StatusUpdate>
      <div style={{ width: '50%', display: 'flex', gap: '1em', flexDirection: 'column' }}>
        <div style={{ fontSize: '0.75em', fontFamily: 'Camphor', color: '#00000099' }}>
          Upload Tax Document
        </div>
        <StateTaxUpload
          onSuccess={handleUploadSuccess}
          onError={handleUploadError}
          companyId={companyId}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
`;

const StatusUpdate = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 50%;
`;
