import * as React from 'react';
import { AutocompleteInput, Datagrid, EditButton } from 'react-admin';
import DateField from '../../components/DateField';
import List from '../../components/List';
import NumberField from '../../components/NumberField';
import NumberInput from '../../components/NumberInput';
import ReferenceField from '../../components/ReferenceField';
import ReferenceInput from '../../components/ReferenceInput';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { Employee } from '../../types';

const Filters = [
  <ReferenceInput source="employeeId" reference="sg-employee-master" alwaysOn>
    <AutocompleteInput
      optionText={(choice?: Employee) => (choice?.id ? `${choice.fullName}` : '')}
      fullWidth
    />
  </ReferenceInput>,
  <TextInput source="currency" label="Currency" alwaysOn />,
  <NumberInput source="baseSalary" label="Base salary" />,
];

const EmployeeCompensationMasterList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <ReferenceField source="employeeId" reference="sg-employee-master">
          <TextField source="fullName" />
        </ReferenceField>
        <NumberField source="baseSalary" label="Base Salary" />
        <NumberField source="totalSalary" label="Total Salary" />
        <TextField source="currency" label="Currency" />
        <DateField source="startDate" label="Start date" />
        <DateField source="endDate" label="End date" />
        <TextField source="category" label="Category" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default EmployeeCompensationMasterList;
