export const validateForm = (values: Record<string, any>): Record<string, any> => {
  const errors = {} as any;
  if (!values.employeeId) {
    errors.employeeId = 'ra.validation.required';
  }
  if (!values.baseSalary) {
    errors.baseSalary = 'ra.validation.required';
  }
  // if (!values.totalSalary) {
  //     errors.totalSalary = 'ra.validation.required';
  // }
  if (!values.currency) {
    errors.currency = 'ra.validation.required';
  }
  if (!values.startDate) {
    errors.startDate = 'ra.validation.required';
  }
  if (!values.category) {
    errors.category = 'ra.validation.required';
  }
  // if (!values.endDate) {
  //     errors.endDate = 'ra.validation.required';
  // }

  return errors;
};
