import * as React from 'react';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import SubmitButton from '../../forms/SubmitButton';
import { useNotify, useDataProvider } from 'react-admin';

import { CustomDatePicker } from '../../../../components/CustomDatePicker';

const DueDateForm = ({ data, disabled }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.companyId;
  const id = data?.id;

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`federal-tax/company/${companyId}/federal-tax/${id}/dueDate`, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomDatePicker
            disabled={disabled}
            control={control}
            label="Due Date"
            name="dueDate"
            defaultValue={data.dueDate}
          />
        </Grid>
        <Grid item xs={3}>
          {!disabled && <SubmitButton processing={processing} label="Update Due Date" />}
        </Grid>
      </Grid>
    </form>
  );
};

export default DueDateForm;
