import * as React from 'react';
import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  ReferenceField,
  SelectField,
  SelectInput,
  ShowButton,
  TopToolbar,
} from 'react-admin';
import BooleanInput from '../../components/BooleanInput';
import DateField from '../../components/DateField';
import EmailField from '../../components/EmailField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { KPIParameterType } from '../../config/constants';
import { KPIState } from '../../config/constants';
import { KPIType } from '../../config/constants';
import { EINProvider } from '../../config/constants';
const EINProviderArray = EINProvider.map((f) => ({
  id: f.id,
  name: f.label,
}));
const Filters = [<TextInput source="company" label="Company" alwaysOn />];
const EinServiceListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};
const StateFilingList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'pendingDays', order: 'DESC' }}
      actions={<EinServiceListActions />}
    >
      <Datagrid
        sort={{ field: 'pendingDays', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <ReferenceField source="companyId" reference="company-master-data">
          <TextField source="id" />
        </ReferenceField>

        <TextField source="company" label="Company" />
        <TextField source="state" label="State" />
        <TextField source="pendingDays" label="Pending" />
        {/* <SelectField source="provider" label="Provider" choices={EINProviderArray} /> */}

        <DateField
          source="serviceStartDateTime"
          label="Started On"
          showTime
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        />
        {/* <ShowButton label="Upload EIN" /> */}
        {/* <ShowButton label="Upload EIN"/> */}
      </Datagrid>
    </List>
  );
};

export default StateFilingList;
