import * as React from 'react';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import SubmitButton from './../../forms/SubmitButton';
import { useNotify, useDataProvider } from 'react-admin';

import { CustomTextField } from '../../../../components/CustomTextField';

const BookkeepingAmountForm = ({ data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const companyId = data?.companyId;
  const id = data?.id;

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);
    dataProvider
      .post(`company_bookkeeping_master/company/${companyId}/books/${id}/amount`, { data })
      .then(({ data, error }) => {
        if (data?.id) {
          notify('Data updated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Bookkeeping Amount"
            name="amount"
            defaultValue={data.amount}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="update Amount" />
        </Grid>
      </Grid>
    </form>
  );
};

export default BookkeepingAmountForm;
