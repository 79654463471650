import styled from '@emotion/styled';
import React from 'react';
import { useState } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import { hasPermission } from '../../lib/SGHelper';

interface FederalTaxChatListCustom {
  chats: any;
  onClick: (chat: any) => void;
}
export const FederalTaxChatListCustom = (props: FederalTaxChatListCustom) => {
  const { permissions } = usePermissions();
  const isAdmin = hasPermission('ROLE_SUPER_ADMIN', permissions);
  const [selectedChat, setSelectedChat] = useState(props.chats[0].companyId);
  const dataProvider = useDataProvider();
  const [payLoad, setPayLoad] = useState<any[]>([]);
  const [error, setError] = useState(false);

  function stringToColor(string: string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const onCLickMenuItem = (chat: any) => {
    setSelectedChat(chat.companyId);
    props.onClick(chat);
  };
  return (
    <Wrapper>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {props.chats.map((chat: any) => (
          <MenuItem
            onClick={() => onCLickMenuItem(chat)}
            selected={selectedChat === chat.companyId}
          >
            <ListItem
              alignItems="center"
              style={{ cursor: 'pointer', alignItems: 'center' }}
              onClick={() => {
                props.onClick(chat);
              }}
            >
              <ListItemAvatar>
                <Avatar {...stringAvatar(`${chat.companyName}`)} />
              </ListItemAvatar>
              <ListItemText
                primary={chat.companyName}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {isAdmin ? chat.email : ''}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </MenuItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default FederalTaxChatListCustom;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  // background:red;
  height: 70vh;
  overflow: scroll;
  background: white;
  padding: 12px 16px;
  border-radius: 6px;
  min-width: 30%;
`;

const Company = styled.div`
  background: #9fa7ae;
  padding: 12px 16px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: #005eff;
    color: white;
  }
`;

const Header = styled.div`
  font-size: larger;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #444c66;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0px 32px;
`;

const NoDataFound = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444c66;
  text-align: center;
`;
