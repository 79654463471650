import * as React from 'react';
import styled from '@emotion/styled';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  useShowController,
  FunctionField,
  Labeled,
  useNotify,
  useDataProvider,
  usePermissions,
} from 'react-admin';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import {
  InferProps,
  Requireable,
  ReactElementLike,
  Validator,
  ReactNodeLike,
  ReactComponentLike,
} from 'prop-types';
import LabelField from '../../components/LabelField';
import { LabelType } from '../../config/LabelType';
import { ListHeaderList } from '../../components/ListHeaderActions';
import { Box, FormHelperText, Typography } from '@mui/material';
import { BE12SurveyStatus } from './BE12SurveyStatus';
import DocumentListItem from '../../components/DocumentListItem';
import { hasPermission } from '../../lib/SGHelper';
import dataProvider from '../../config/dataProvider';

const BE12SurveyView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderList />}>
      <MyShow {...props} />
    </Show>
  );
};

const EmployeeDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
`;

export default BE12SurveyView;

const MyShow = (props: any) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  // const company = record?.company;
  const be12survey = record?.be12survey;
  const company = record?.company;
  const { permissions } = usePermissions();

  const isLiabilityShowable =
    hasPermission('ROLE_STATE_FILER', permissions) ||
    hasPermission('ROLE_SUPER_ADMIN', permissions) ||
    hasPermission('ROLE_EIN_SERVICE_STARTGLOBAL', permissions);
  const [reloadDocuments, setReloadDocuments] = React.useState(false);
  const [filings, setFilings] = React.useState(record?.be12filings);

  console.log({ record });

  React.useEffect(() => {
    if (be12survey?.companyId && be12survey?.year) {
      dataProvider
        .get(
          `be-12-surveys/company/${be12survey.companyId}/year/${be12survey.year}/filing-documents`
        )
        .then((data) => {
          console.log('filing documents', data.data);
          setFilings(data?.data);
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {});
    }
  }, [reloadDocuments, record?.id, be12survey?.companyId, be12survey?.year]);

  if (!record?.id) {
    return null;
  }

  return (
    <TabbedShowLayout>
      <Tab label="Basic Details">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* <SimpleShowLayout> */}
            <EmployeeDetails>
              <LabelField
                record={be12survey}
                source="dateOfFormation"
                label="Formed On"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="totalAssets"
                label="Total Assets"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="companyName"
                label="Company"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="totalRevenue"
                label="Total Revenue"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="state"
                label="State"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="totalIncome"
                label="Total Income"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="ein"
                label="EIN"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="totalInvestment"
                label="Total Investment"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="description"
                label="Description"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              <LabelField
                record={be12survey}
                source="totalLoans"
                label="Total Loans"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              {/* <LabelField record={be12survey} source="title" label="Title" fullWidth labelType={LabelType.TEXT_FIELD} /> */}
              {/* <LabelField record={be12survey} source="amount" label="Amount" fullWidth labelType={LabelType.TEXT_FIELD} /> */}
              {/* <LabelField record={be12survey} source="owner" label="Owner" fullWidth labelType={LabelType.TEXT_FIELD} /> */}
              {/* <LabelField record={be12survey} source="dueDate" label="Due Date" fullWidth labelType={LabelType.TEXT_FIELD} /> */}
              <LabelField
                record={be12survey}
                source="numberOfEmployeesInUSA"
                label="Number of Employees in USA"
                fullWidth
                labelType={LabelType.TEXT_FIELD}
              />
              {isLiabilityShowable && (
                <LabelField
                  record={be12survey}
                  source="totalLiabilities"
                  label="Total Liabilities"
                  fullWidth
                  labelType={LabelType.TEXT_FIELD}
                />
              )}
            </EmployeeDetails>
            {company?.owners?.length > 0 && (
              <>
                <Grid container spacing={1}>
                  <Label>Owners</Label>
                </Grid>
                {company?.owners.map((o: any) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} mt={2}>
                      <Typography>
                        {o.firstName} {o.lastName} {o.percent}%
                      </Typography>
                    </Grid>
                  );
                })}
              </>
            )}
            {company?.directors?.length > 0 && (
              <>
                <Grid container spacing={1} mt={4}>
                  <Grid xs={6}>
                    <Label>Directors</Label>
                    {company?.directors.map((o: any) => {
                      return (
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography>
                            {o.firstName} {o.lastName}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid xs={6}>
                    <Label>Officers and Roles</Label>
                    <Grid container item xs={12}>
                      {company.officers.map((o: any) => {
                        return (
                          <>
                            <Grid item xs={6}>
                              <Typography>{o.role}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{o.fullName}</Typography>
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {/* </SimpleShowLayout> */}
          </Grid>
          <Grid item xs={6}>
            {/* <SimpleShowLayout> */}
            <EmployeeDetails style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <Label>Company Address</Label>
                <div style={{ marginTop: '0.2em' }}>
                  <Box>
                    <Typography variant="body2">{be12survey?.streetAddress}</Typography>
                    <Typography variant="body2">{be12survey?.city}</Typography>
                    <Typography variant="body2">{be12survey?.state}</Typography>
                    <Typography variant="body2">{be12survey?.zipCode}</Typography>
                    <Typography variant="body2">{be12survey?.tel}</Typography>
                  </Box>
                </div>
              </div>
              {/* <Spacer /> */}
              <div>
                <Label>Responsible Party</Label>
                <div style={{ marginTop: '0.2em' }}>
                  <Box>
                    <Typography variant="body2">
                      {be12survey?.owner}, {be12survey?.title}
                    </Typography>
                    <p>
                      <LabelField
                        record={be12survey}
                        source="citizenship"
                        label="Citizenship"
                        fullWidth
                        labelType={LabelType.TEXT_FIELD}
                      />
                    </p>
                    <p>
                      <LabelField
                        record={be12survey}
                        source="countryOfResidence"
                        label="Country of Residence"
                        fullWidth
                        labelType={LabelType.TEXT_FIELD}
                      />
                    </p>
                  </Box>
                </div>
              </div>
            </EmployeeDetails>

            <Spacer />
            {/* </SimpleShowLayout> */}
          </Grid>

          <Spacer />
          <Grid item xs={12}>
            {record && Array.isArray(record.books) && record.books?.length > 0 && (
              <Grid item xs={10}>
                <Typography>Supported Documents By Client</Typography>
                <List>
                  {record.books?.map((d: any) => (
                    <DocumentListItem
                      key={d.uuid}
                      id={d.id}
                      companyId={d.companyId}
                      purpose={d.purpose}
                      text={d.originalFile}
                      uuid={d.uuid}
                      exists
                      readOnly
                    />
                  ))}
                </List>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            {/* <SimpleShowLayout> */}
            {be12survey?.id && (
              <BE12SurveyStatus
                data={be12survey}
                onSuccess={() => setReloadDocuments((_oldValue) => !_oldValue)}
              />
            )}
            {/* </SimpleShowLayout> */}
          </Grid>

          <Grid item xs={12}>
            {filings && Array.isArray(filings) && filings.length > 0 && (
              <Grid item xs={10}>
                <Typography>Uploaded Documents By Consultant</Typography>
                <List>
                  {filings.map((d: any) => (
                    <DocumentListItem
                      key={d.uuid}
                      id={d.id}
                      companyId={d.companyId}
                      purpose={d.purpose}
                      text={d.originalFile}
                      uuid={d.uuid}
                      exists
                    />
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Tab>
      <Tab label="Documents Details">
        <Grid container spacing={2}>
          {record && Array.isArray(record?.documents) && (
            <>
              <Grid item xs={12} md={6}>
                <List>
                  {/* <DocumentListItem companyId={record?.company.id} purpose="PASSPORT" text="Passport" exists={record?.documents.some((e: { purpose: string; }) => e.purpose==="PASSPORT")}/> */}
                  {record?.documents.some(
                    (e: { purpose: string }) => e.purpose === 'CERTIFICATE_OF_INCORPORATION'
                  ) && (
                    <DocumentListItem
                      companyId={record?.company.id}
                      purpose="CERTIFICATE_OF_INCORPORATION"
                      text="State Filing"
                      exists={record?.documents.some(
                        (e: { purpose: string }) => e.purpose === 'CERTIFICATE_OF_INCORPORATION'
                      )}
                      readOnly
                    />
                  )}
                  {record?.documents.some(
                    (e: { purpose: string }) => e.purpose === 'STATE_FILING_INFORMATION'
                  ) && (
                    <DocumentListItem
                      companyId={record?.company.id}
                      purpose="STATE_FILING_INFORMATION"
                      text="State Filing"
                      exists={record?.documents.some(
                        (e: { purpose: string }) => e.purpose === 'STATE_FILING_INFORMATION'
                      )}
                      readOnly
                    />
                  )}

                  {/* {record?.company.state === 'WYOMING' && <DocumentListItem companyId={record?.company.id} purpose="INITIAL_RESOLUTION" text="Initial Resolution" exists={record?.documents.some((e: { purpose: string; }) => e.purpose==="INITIAL_RESOLUTION")}/>} */}
                  {record?.company.companyType === 'LLC' &&
                    record?.company.state === 'DELAWARE' && (
                      <DocumentListItem
                        companyId={record?.company.id}
                        purpose="STATEMENT_OF_AUTHORISED_PERSON"
                        text="Statement of Authorised Person"
                        exists={record?.documents.some(
                          (e: { purpose: string }) => e.purpose === 'STATEMENT_OF_AUTHORISED_PERSON'
                        )}
                        readOnly
                      />
                    )}
                  {record?.company.companyType === 'CORPORATION' &&
                    record?.company.state === 'DELAWARE' && (
                      <DocumentListItem
                        companyId={record?.company.id}
                        purpose="ACTION_OF_INCORPORATOR"
                        text="Action of incorporator"
                        exists={record?.documents.some(
                          (e: { purpose: string }) => e.purpose === 'ACTION_OF_INCORPORATOR'
                        )}
                        readOnly
                      />
                    )}

                  <DocumentListItem
                    companyId={record?.company.id}
                    purpose="EIN"
                    text="EIN"
                    exists={record?.documents.some((e: { purpose: string }) => e.purpose === 'EIN')}
                    readOnly
                  />
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </Tab>
    </TabbedShowLayout>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;
